import React from 'react';
import { Button, Divider } from 'semantic-ui-react';
import fileDownload from 'js-file-download';
import { Buffer } from 'buffer';

import {
	BackButtonContainer,
	Container,
	FileHeader,
	Header,
	MainContainer,
	PdfContainer,
} from '../pdf-view/PdfViewStyles';
import Download from '../../../assets/images/download.png';

interface PropType {
	filename: string;
	source: string;
	onClose?: () => void;
	visible?: boolean;
}

const ImageView = (props: PropType) => {
	const data = Buffer.from(props.source, 'binary').toString('base64');
	if (data) {
		return (
			<MainContainer>
				<Container>
					<Header>
						<FileHeader>{props.filename?.split('.')[0]}</FileHeader>
						<BackButtonContainer>
							{/*<Divider />*/}
							<img
								title={'Download File'}
								src={Download}
								onClick={() => fileDownload(props.source, props.filename)}
								alt={'icon'}
								color={'black'}
								style={{ cursor: 'pointer' }}
							/>
							<Button circular color="black" icon="close" onClick={props.onClose} />
						</BackButtonContainer>
					</Header>
					<Divider />

					<PdfContainer>
						<img src={`data:image/*;base64,${data}`} alt={'icon'} />
					</PdfContainer>
				</Container>
			</MainContainer>
		);
	} else return <></>;
};

export default ImageView;
