import { GEOCODE_API, ORGANISATIONS_API, VEHICLES_GROUPS } from '../constants/api';
import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import axios from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import { ColumnState } from 'ag-grid-community';

import Store from '../redux/store';
import { getUserDeviceFromServer } from '../helper/services/firebase';
import { getData } from '../helper/services/axios';
import { handleError } from '../helper/view specific/messaging';
import { TrackEvent } from '../dtos/track';
import CarImage from '../assets/images/vehicle-type-1.png';
import BikeImage from '../assets/images/vehicle-type-2.png';
import TruckImage from '../assets/images/vehicle-type-3.png';
import ConstructionImage from '../assets/images/vehicle-type-4.png';
import BusImage from '../assets/images/vehicle-type-5.png';
import GeneratorImage from '../assets/images/vehicle-type-6.png';
import ContainerImage from '../assets/images/vehicle-type-7.png';
import MiniTruckImage from '../assets/images/vehicle-type-8.png';
import AutoImg from '../assets/images/vehicle-type-9.png';
import { THEME } from '../constants/Theme';

interface VehicleEvent {
	type: string;
	status: boolean;
	value?: number;
	startAddress?: string | null;
	geofence: {
		name: string;
	};
	vehicle?: {
		idlingDuration?: number;
		speedLimit?: number;
		parkingDuration?: number;
	};
}

export function initializeShownGeoFences(): void {
	try {
		const shownGeofences = localStorage.getItem('shownGeofences');
		if (shownGeofences === 'true') {
			Store.dispatch({ type: 'ENABLE_SHOWN_GEOFENCES' });
		} else {
			setGeoFencesShown(false);
			Store.dispatch({ type: 'DISABLE_SHOWN_GEOFENCES' });
		}
	} catch (error) {
		datadogLogs.logger.error('Common initializeShownGeoFences', {}, error as Error);
	}
}

export function setGeoFencesShown(value: boolean): void {
	try {
		localStorage.setItem('shownGeofences', JSON.stringify(value));
		if (value) Store.dispatch({ type: 'ENABLE_SHOWN_GEOFENCES' });
		else Store.dispatch({ type: 'DISABLE_SHOWN_GEOFENCES' });
	} catch (error) {
		datadogLogs.logger.error('Common setGeoFencesShown', {}, error as Error);
	}
}

export function initializeMapType() {
	try {
		const type = localStorage.getItem('mapType');
		if (type) {
			Store.dispatch({
				type: 'SET_MAP_TYPE',
				payload: type,
			});
		} else {
			setMapType('roadmap');
		}
	} catch (error) {
		datadogLogs.logger.error('Common initializeMapType Error', {}, error as Error);
	}
}

export function setMapType(mapType: string): void {
	try {
		localStorage.setItem('mapType', mapType);
		Store.dispatch({
			type: 'SET_MAP_TYPE',
			payload: mapType,
		});
	} catch (error) {
		datadogLogs.logger.error('Common setMapType Error', {}, error as Error);
	}
}

export function initializePushNotificationEnableStorage(): void {
	if (localStorage.getItem('pushNotificationsEnabled')) {
		Store.dispatch({ type: 'ENABLE_PUSH_NOTIFICATIONS' });
	} else {
		setPushNotificationPreference();
	}
}

export function initializePushNotificationEnableStore() {
	try {
		const pushNotificationEnabled = localStorage.getItem('pushNotificationsEnabled');
		if (pushNotificationEnabled === 'true') {
			Store.dispatch({ type: 'ENABLE_PUSH_NOTIFICATIONS' });
		} else {
			Store.dispatch({ type: 'DISABLE_PUSH_NOTIFICATIONS' });
		}
	} catch (error) {
		datadogLogs.logger.error('Common initializePushNotificationEnableStore Error', {}, error as Error);
	}
}

export function setPushNotificationPreference() {
	getUserDeviceFromServer()
		.then((response) => {
			localStorage.setItem('pushNotificationsEnabled', response?.data?.pushNotification);
			if (response?.data?.pushNotification) {
				Store.dispatch({ type: 'ENABLE_PUSH_NOTIFICATIONS' });
			} else {
				Store.dispatch({ type: 'DISABLE_PUSH_NOTIFICATIONS' });
			}
		})
		.catch((error) => {
			handleError('common.tsx => setPushNotificationPreference(): ', error);
		});
}

export function initializeTrafficLayer() {
	const type = localStorage.getItem('traffic');
	if (type === 'true') {
		Store.dispatch({
			type: 'SHOW_TRAFFIC',
		});
	} else {
		setTrafficView(false);
	}
}

export function setTrafficView(value: boolean): void {
	localStorage.setItem('traffic', JSON.stringify(value));
	if (value) {
		Store.dispatch({ type: 'SHOW_TRAFFIC' });
	} else {
		Store.dispatch({ type: 'REMOVE_TRAFFIC' });
	}
}

interface FormatTableCellProps {
	iotData: {
		latitude: number;
		longitude: number;
		totalOdometer: number | string;
		externalPowerVoltage?: number;
	};
	text: string;
	timeStamp?: React.ReactNode | null;
}

export const FormatTableCell = ({ iotData, text, timeStamp = null }: FormatTableCellProps) => {
	const [showAddress, setShowAddress] = useState(false);
	const [address, setAddress] = useState<string>('');
	if (showAddress) {
		return (
			<>
				<AddressLink value={address} coordinates={iotData} infoWindow={false} />
				{timeStamp}
			</>
		);
	} else {
		return (
			<span
				className="viewAddress"
				style={{ cursor: 'pointer', textDecorationLine: 'underline' }}
				onClick={(event) => {
					event.stopPropagation();
					getData(GEOCODE_API, `?latitude=${iotData.latitude}&longitude=${iotData.longitude}`)
						.then((res) => {
							setAddress(res.data.address);
							setShowAddress(true);
						})
						.catch((error) => {
							handleError('common.tsx => FormatTableCell(): Geocode Api Error', error);
						});
				}}>
				{text}
			</span>
		);
	}
};

export const formatVehicleEvent = (vehicleEvent: VehicleEvent | TrackEvent) => {
	return (
		<>
			{vehicleEvent.startAddress ? (
				<>
					{vehicleEvent?.type === 'Ignition' && (
						<>
							<Icon name="bolt" color="green" /> Ignition On
						</>
					)}
					{vehicleEvent?.type === 'Relay' && (
						<>
							<Icon name="toggle on" color="red" /> Relay
						</>
					)}

					{vehicleEvent?.type === 'Ac' && (
						<>
							<Icon name="bolt" color="red" /> AC turned On
						</>
					)}
					{vehicleEvent?.type === 'Geofence' && vehicleEvent.geofence && (
						<>
							<Icon name="object ungroup outline" color="green" />
							{vehicleEvent.geofence.name} Entered
						</>
					)}
					{vehicleEvent?.type === 'Main Power' && (
						<>
							<Icon name="plug" color="green" /> Main Power Connected
						</>
					)}
					{vehicleEvent?.type === 'Idling' && (
						<>
							<Icon name="caret down" color="green" />
							{vehicleEvent?.vehicle?.idlingDuration !== undefined
								? `idling Started Duration ${vehicleEvent?.vehicle?.idlingDuration} minutes`
								: 'idling Started'}
						</>
					)}

					{vehicleEvent?.type === 'OverSpeed' && (
						<>
							<Icon name="tachometer alternate" color="red" />
							{vehicleEvent?.vehicle?.speedLimit !== undefined
								? `Over Speeding Started(${vehicleEvent?.value} KM/H) SpeedLimit ${vehicleEvent?.vehicle?.speedLimit} KM/H`
								: `Over Speeding (${vehicleEvent?.value} KM/H)`}
						</>
					)}
					{vehicleEvent?.type === 'SOS' && (
						<>
							<Icon name="exclamation triangle" color="red" /> Panic Button Pressed
						</>
					)}
					{vehicleEvent?.type === 'Parking' && (
						<>
							<Icon name="warning" color="red" />
							{vehicleEvent?.vehicle?.parkingDuration !== undefined
								? `Parked Parking Duration ${vehicleEvent?.vehicle?.parkingDuration} minutes`
								: 'Parked'}
						</>
					)}
				</>
			) : (
				<>
					{vehicleEvent?.type === 'Ignition' && (
						<>
							<Icon name="bolt" color="red" /> Ignition Off
						</>
					)}
					{vehicleEvent?.type === 'Relay' && (
						<>
							<Icon name="toggle on" color="green" /> Mobilized
						</>
					)}

					{vehicleEvent?.type === 'Ac' && (
						<>
							<Icon name="bolt" color="green" /> AC turned Off
						</>
					)}

					{vehicleEvent?.type === 'Geofence' && vehicleEvent.geofence && (
						<>
							<Icon name="object ungroup outline" color="red" />
							{vehicleEvent.geofence.name} Exited
						</>
					)}
					{vehicleEvent?.type === 'Main Power' && (
						<>
							<Icon name="plug" color="red" /> Main Power Disconnected
						</>
					)}
					{vehicleEvent?.type === 'Idling' && (
						<>
							<Icon name="caret up" color="red" />
							{vehicleEvent?.vehicle?.idlingDuration !== undefined
								? `idling Ended Duration ${vehicleEvent?.vehicle?.idlingDuration} minutes`
								: 'idling Ended'}
						</>
					)}
					{vehicleEvent?.type === 'OverSpeed' && (
						<>
							<Icon name="tachometer alternate" color="green" />
							{vehicleEvent?.vehicle?.speedLimit !== undefined
								? `Over Speeding Stopped(${vehicleEvent?.value} KM/H) SpeedLimit ${vehicleEvent?.vehicle?.speedLimit} KM/H`
								: `Over Speeding Ended(${vehicleEvent?.value} KM/H)`}
						</>
					)}

					{vehicleEvent?.type === 'Parking' && (
						<>
							<Icon name="warning" color="green" />
							{vehicleEvent?.vehicle?.parkingDuration !== undefined
								? `Moved Parking Duration ${vehicleEvent?.vehicle?.parkingDuration} minutes`
								: 'Moved'}
						</>
					)}
				</>
			)}
		</>
	);
};

export function formatDistance(distanceInMeters: number): string {
	if (distanceInMeters < 1000) return distanceInMeters + ' M';
	else return (distanceInMeters / 1000).toFixed(1) + ' KM';
}

export function fetchOrganisations() {
	return axios(ORGANISATIONS_API + '?dropdown=true', {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
}

export function fetchVehicleGroups() {
	return axios(VEHICLES_GROUPS + '?dropdown=true', {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
}

export const handleDataSizeLimitChange = (pageLimit: number): void => {
	Store.dispatch({
		type: 'SET_DATA_SIZE_LIMIT',
		payload: pageLimit,
	});
	Store.dispatch({
		type: 'SET_PAGE_LIMIT',
		payload: 1,
	});
};

export const getVehicleIcon = (vehicleType: string) => {
	return vehicleType === 'Car'
		? CarImage
		: vehicleType === 'Bike'
		  ? BikeImage
		  : vehicleType === 'Truck'
		    ? TruckImage
		    : vehicleType === 'Construction Machinery'
		      ? ConstructionImage
		      : vehicleType === 'Bus'
		        ? BusImage
		        : vehicleType === 'Generator'
		          ? GeneratorImage
		          : vehicleType === 'Container'
		            ? ContainerImage
		            : vehicleType === 'Mini Truck'
		              ? MiniTruckImage
		              : vehicleType === 'Auto'
		                ? AutoImg
		                : CarImage;
};

export const setLocalStorage = (data: ColumnState[], key = 'grid') => {
	localStorage.setItem(key, JSON.stringify(data));
};

export const getLocalStorage = (key = 'grid') => {
	const data = localStorage.getItem(key);

	if (data) return JSON.parse(data);
	else return null;
};

export const clearLocalStorage = (key: string) => {
	localStorage.removeItem(key);
};

export function setCookie(cname: string, cvalue: string, expiryDate: string) {
	const d = new Date(expiryDate);
	const expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(name: string) {
	const nameEQ = name + '=';
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

export function eraseCookie(name: string) {
	document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

interface Cookie {
	expiryDate: string;
}

export function isCookieExpired(cookieData: Cookie) {
	const expirationTime = new Date(cookieData.expiryDate).getTime();
	const currentTime = new Date().getTime();
	return currentTime >= expirationTime;
}

export interface Coordinates {
	latitude: number;
	longitude: number;
}

export const AddressLink = ({
	value,
	coordinates,
	infoWindow,
}: {
	value: string;
	coordinates: Coordinates;
	infoWindow: boolean;
}) => {
	return (
		<a
			title={value}
			href={`https://www.google.com/maps/place/${coordinates.latitude},${coordinates.longitude}`}
			onClick={(event) => event.stopPropagation()}
			target="_blank"
			rel="noreferrer"
			style={{
				textDecorationLine: 'underline',
				cursor: 'pointer',
				color: infoWindow ? THEME.COLORS.theme : 'black',
			}}>
			{value !== ' ' ? value : '-'}
		</a>
	);
};

/*
export function createOrganisationDTO(data: Organisation[]): Organisation[] {
	const organisations: Organisation[] = [];
	if (data !== undefined && data.length !== 0) {
		data.forEach((organisation: OrganisationInterface) => {
			// Create a new Organisation object and push it to the array
			const org = new Organisation(
				organisation.id,
				organisation.name,
				organisation.email,
				organisation.phone,
				organisation.address,
				organisation.website,
				organisation.CIN,
				organisation.GST,
				organisation.PAN
			);
			organisations.push(org);
		});
	}
	return organisations;
}

export function initializeFreshDeskChat() {
  let showFreshDeskChat = localStorage.getItem('showFreshDeskChat');
  if (showFreshDeskChat === 'false') {
    Store.dispatch({type: 'HIDE_FRESHDESK_WIDGET'});
    document.getElementById('fc_frame').style.display = 'none';
  } else {
    localStorage.setItem('showFreshDeskChat', true);
    Store.dispatch({type: 'SHOW_FRESHDESK_WIDGET'});
    document.getElementById('fc_frame').style.display = 'block';
  }
}

interface OrganisationInterface {
	id: string;
	name: string;
	email: string;
	phone: string;
	address: string;
	website: string;
	CIN: string;
	GST: string;
	PAN: string;
}
/*export function hideFreshDeskWidget() {
	document.getElementById('fc_frame').style.display = 'none';
}*/

/*export function showFreshDeskWidget() {
	document.getElementById('fc_frame').style.display = 'block';
}*/
