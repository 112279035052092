import Store from '../../redux/store';

export function dispatch(type: string, payload: string | object) {
	Store.dispatch({
		type,
		payload,
	});
}

export function dispatchNoPayload(type: string) {
	Store.dispatch({
		type,
	});
}
