import styled from 'styled-components';
import { themeColor } from '../../constants/Theme';

export const Container = styled.section`
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: row;
`;

export const DashboardContainer = styled.section`
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: calc(100% - 4em);
`;

export const MenuBar = styled.section`
	height: 4em;
	width: 100%;
	background: #8badd3;
	display: flex;
	position: sticky;
	top: 0;
	z-index: 1;
	padding: 0.5em 0.5em;
`;

export const LogoHeader = styled.section`
	color: #1b3c71;
	display: flex;
	margin-top: 10px;
	justify-content: flex-start;
	//justifyContent: flex-start;
	align-items: baseline;
	//alignItems: baseline;
`;

export const Nav = styled.section`
	width: 4em;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	background: ${(props) => props.theme.COLORS.theme};
`;

export const Logo = styled.section`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	//padding-bottom: 0.5em;
	//margin-bottom: 0.5em;

	section {
		box-sizing: border-box;
		padding: 0 0.5em 0.25em 0.5em;

		//background: #fff;
		border-radius: 5px;
	}

	img {
		height: 100%;
		max-height: 3em;
	}
`;

export const Options = styled.section`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	//padding: 0.5em 0;

	.ui.circular.label {
		background-color: ${themeColor};
		color: white;
	}
`;

export const Main = styled.section`
	width: 100%;
	height: calc(100vh - 4em);
	overflow: auto;
`;

export const Card = styled.div`
	box-shadow: 10px 10px 15px 3px rgba(0, 0, 0, 0.1);
`;
