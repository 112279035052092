import { MessageType } from '../../constants/message-type';
import { toast } from 'react-toastify';
import { StatusCode } from '../../constants/status-code';
import Store from '../../redux/store';
import { dispatchNoPayload } from './store';
import { logout } from './auth';
import { datadogLogs } from '@datadog/browser-logs';

interface CustomError {
	response?: {
		status?: number;
		data?: {
			name?: string;
			message?: string;
		};
	};
}

interface Response {
	status?: number;
	data?: {
		name?: string;
		message?: string;
	};
}

export function handleError(errorLocation: string, error: CustomError): void {
	const status: number | undefined = error?.response?.status;
	const message: string | null = error?.response?.data?.message ? JSON.stringify(error.response.data.message) : null;

	if (error?.response?.data?.name !== undefined && error?.response?.data?.message !== undefined) {
		datadogLogs.logger.error(
			errorLocation,
			{},
			{
				name: error?.response?.data?.name,
				message: error?.response?.data?.message,
			}
		);
	}
	try {
		if (status) {
			if (status === StatusCode.UnAuthorized) {
				if (message) {
					showMessage(message, MessageType.Error);
				} else {
					showMessage('Unauthorized - Requested resource requires valid Credentials.', MessageType.Error);
				}
				Store.dispatch({ type: 'CLEAR_NOTIFICATION_PERMISSIONS' });
				dispatchNoPayload('SIDEBAR_UNMOUNT');

				Store.dispatch({
					type: 'LOGOUT',
				});
				logout().catch();
			} else if (message) {
				//when wrong params are passed to the server
				// showMessage("Invalid input - Please wait till our team fixes it.", MessageType.Error);
				showMessage(message, MessageType.Error);
			} else {
				//404
				showMessage('Requested Resource not available.', MessageType.Error);
			}
		} else {
			// datadogLogs.logger.error('Error Handler', {}, error);
			if (process.env.REACT_APP_BUILD_ENVIRONMENT === 'development') showMessage('Network Error', MessageType.Error);
		}
	} catch (err) {
		// if (err) datadogLogs.logger.error('Error Handler', {}, err);
	}
}

export function handleWarning(message: object): void {
	showMessage(JSON.stringify(message), 'WARNING');
}

export function handleSuccess(response: Response): void {
	if (response?.data?.message) {
		showMessage(JSON.stringify(response.data.message), 'SUCCESS');
	}
}

export function generateErrorMessage(message: object): void {
	showMessage(JSON.stringify(message), 'ERROR');
}

export function generateInfoMessage(message: string): void {
	showMessage(JSON.stringify(message), 'INFO');
}

export function showMessage(content: string, type: string): void {
	switch (type) {
		case MessageType.Info:
			toast.info(content, { position: 'top-right', theme: 'dark' });
			break;

		case MessageType.Error:
			toast.error(content, { position: 'top-right', theme: 'dark' });
			break;

		case MessageType.Warn:
			toast.warn(content, { position: 'top-right', theme: 'dark' });
			break;

		case MessageType.Success:
			toast.success(content, { position: 'top-right', theme: 'dark' });
			break;

		default:
			toast(content, { position: 'top-right', theme: 'dark' });
			break;
	}
}
