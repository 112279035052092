import styled from 'styled-components';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../../../../constants/constants';

export const Container = styled.section`
	width: 100%;
	height: 100%;

	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const TableContainer = styled.section`
	width: 100%;
	height: calc(100% - calc(${HEADER_HEIGHT} + ${FOOTER_HEIGHT}));
	z-index: 0;

	box-sizing: border-box;
	margin-right: 0;

	.ui.table {
		border: none;
		border-top: none !important;
		border-bottom: 1px solid #efefef;
	}

	.ui.table tbody tr:hover td {
		background: #f5f5f5 !important;
	}

	.ui.table tr:nth-child(even) td:first-child {
		position: sticky;
		left: 0;
		z-index: 3;
		background: #fafafb;
	}

	.ui.table tr:nth-child(odd) td:first-child {
		position: sticky;
		left: 0;
		z-index: 3;
		background: #fff;
	}

	.ui.table tr:nth-child(even) td:nth-child(2) {
		position: sticky;
		left: 51px;
		z-index: 3;
		background: #fafafb;
	}

	.ui.table tr:nth-child(odd) td:nth-child(2) {
		position: sticky;
		left: 51px;
		z-index: 3;
		background: #fff;
	}

	.ui.table tr:nth-child(even) td:nth-child(3) {
		position: sticky;
		left: 157.5px;
		z-index: 3;
		background: #fafafb;
		border-right: 1px solid #efefef;
	}

	.ui.table tr:nth-child(odd) td:nth-child(3) {
		position: sticky;
		left: 157.5px;
		z-index: 3;
		background: #fff;
		border-right: 1px solid #efefef;
	}

	.ui.table th:first-child {
		position: sticky;
		left: 0;
		top: 0;
		z-index: 4;
	}

	.ui.table th:nth-child(2) {
		position: sticky;
		left: 51px;
		top: 0;
		z-index: 4;
	}

	.ui.table th:nth-child(3) {
		position: sticky;
		left: 157.5px;
		top: 0;
		z-index: 4;
		border-right: 1px solid #efefef;
	}

	overflow: auto;
`;

export const PageSelector = styled.section`
	width: 6em;

	margin-right: 1em;

	border: none;
	border-radius: 5px;
	box-shadow:
		rgb(60 64 67 / 30%) 0 1px 2px 0,
		rgb(60 64 67 / 15%) 0 2px 6px 2px;
`;
