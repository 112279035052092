import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../constants/constants';
import { THEME } from '../../../constants/Theme';

interface stypeProps {
	borderRadius?: string;
	fontStyle?: string;
	fontColor?: string;
}

export const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin: 1em;
`;

export const RowElement = styled.div`
	width: 50%;
	max-width: 320px;
`;

export const DriverOrgName = styled.section`
	span {
		font-size: 0.95em;
	}
`;

export const DriverVehicle = styled.section`
	margin-top: 1em;

	span {
		font-size: 0.95em;
		font-weight: bold;
		color: ${(props) => props.theme.COLORS.theme};
	}
`;

export const DriverUser = styled.section`
	span {
		font-size: 0.95em;
		font-weight: bold;
		color: ${(props) => props.theme.COLORS.theme};
	}
`;

export const DriverCardFooter = styled.section`
	display: flex;
	justify-content: space-between;
`;

export const Content = styled.section`
	width: 100%;

	box-sizing: border-box;
	padding: 1.5em;

	background: #fff;

	h1 {
		color: ${(props) => props.theme.COLORS.theme};
	}

	overflow-y: auto;
`;

export const Title = styled.section`
	display: flex;
	align-items: center;
	justify-content: space-between;

	h1 {
		margin: 0;
	}

	h4 {
		margin: 0;
	}
`;

export const Block = styled.section`
	margin: 2em 0;
`;

export const MessageBlock = styled.section`
	display: flex;
	align-items: center;
	justify-content: space-between;

	button {
		margin-left: 2em !important;
	}
`;

export const DriverViewModalFooter = styled.section`
	display: flex;
	justify-content: space-between;
`;

export const Placeholder = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	img {
		width: 30%;
		height: 50%;
	}

	h4 {
		margin: 3em 0;

		color: #888;
	}
`;

export const PlaceHolder = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	img {
		width: 500px;
	}

	h4 {
		color: #aaa;
	}
`;

export const Container = styled.section`
	width: 100%;
	height: 100%;

	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const MainContent = styled.section`
	width: 100%;
	height: calc(100% - ${HEADER_HEIGHT});
`;

export const MainContainer = styled.section`
	width: 100%;
	height: 100%;
	background-color: #f5f6fe;
`;

export const CardContainer = styled.section`
	height: calc(100% - 4em);

	display: flex;
	align-content: flex-start;
	//justify-content: space-evenly;
	flex-wrap: wrap;

	box-sizing: border-box;
	padding: 1em;

	background: #fafcfe;

	overflow-y: auto;
`;

export const CategoryHeader = styled.section<stypeProps>`
	width: 100%;
	height: ${HEADER_HEIGHT};

	display: flex;
	align-items: center;
	justify-content: space-between;

	box-sizing: border-box;
	//padding: 0 1em;

	background-color: #f5f5f5;
	//border-bottom: 1px solid ${THEME.COLORS.headerBorder};
	border-top-left-radius: ${(props) => props.borderRadius || 0};
	border-top-right-radius: ${(props) => props.borderRadius || 0};

	.ui.button:disabled {
		pointer-events: auto !important;
	}
`;

export const SearchContainer = styled.section`
	height: 4em;

	box-sizing: border-box;
	padding: 0.5em;

	.ui.input {
		height: 100%;
	}
`;

export const Filter = styled.section`
	display: flex;
	align-items: center;
`;

export const NoMatchFoundContainer = styled.section`
	width: 100%;
	height: 100%;

	.header {
		color: ${THEME.COLORS.theme} !important;
	}

	.ui.icon {
		color: ${THEME.COLORS.theme} !important;
	}

	.ui.icon.header > img {
		margin: 0 auto 0.45em !important;
		display: block;
		height: 5em;
		width: auto;
	}

	//img {
	//  display: block;
	//  margin: 0 auto 0.45em !important;
	//}
`;
export const Card = styled.section`
	//flex-basis: 1%;
	//flex-grow: 1;
	width: 19%;

	margin: 0.5em;
	box-sizing: border-box;
	padding: 1em;

	background: #fff;
	border: 1px solid #ddd;
	border-radius: 10px;

	display: flex;
	//flex: 1;
	flex-direction: column;

	&:hover {
		box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
	}
`;

export const VerticalBlock = styled.section`
	max-height: 50%;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
`;

export const DriverInfo = styled.section`
	width: 100%;
	min-height: 60%;
	max-height: 70%;
	display: flex;
	align-items: center;
	flex: 1;
	flex-direction: row;

	img {
		margin-right: 1em;
	}
`;

export const DriverName = styled.section`
	span {
		font-size: 1.25em;
		font-weight: bold;
	}
`;

export const OrgName = styled.section`
	span {
		font-size: 0.8em;
	}
`;

export const VehicleChasis = styled.section`
	flex-direction: row;
	padding: 2.5px;
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	display: flex;
	justify-content: space-between;
	align-items: center;

	img {
		width: 10%;
		height: 10%;
		margin-right: 0;
	}

	span {
		font-size: 1em;
		font-weight: bold;
	}
`;
export const VehicleChasisEmpty = styled.section`
	flex-direction: row;
	padding: 2.5px;
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	img {
		width: 10%;
		height: 10%;
		margin-right: 0;
	}

	span {
		font-size: 1em;
		font-weight: bold;
	}
`;

export const DriverContact = styled.section`
	flex: 1;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

export const Phone = styled.section`
	flex-direction: row;
	align-items: center;

	span {
		font-size: 0.8em;
		flex-wrap: wrap;
	}
`;
export const Email = styled.section`
	flex-direction: row;
	align-items: center;

	span {
		font-size: 0.8em;
		flex-wrap: wrap;
	}
`;

export const DocumentBlock = styled.section<stypeProps>`
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	border-radius: 5px;
	padding: 5px;
	margin: 0.25em;
	background-color: ${(props) => props.color};
	font-style: ${(props) => props.fontStyle};
	color: ${(props) => props.fontColor};
	cursor: pointer;

	&:hover {
		box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
	}

	span {
		width: 50%;
		text-align: center;
	}
`;

export const DocumentName = styled.section`
	display: flex;
	max-width: 50%;
	min-width: 25%;

	span {
		color: ${(props) => props.color};
	}
`;

export const PageSelector = styled.section`
	width: 6em;

	margin-right: 1em;

	border: none;
	border-radius: 5px;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0 1px 2px 0,
		rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
`;

export const Label = styled.section`
	width: 10em;

	span {
		font-size: 0.9em;
		font-weight: bold;
	}
`;
