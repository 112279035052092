import React from 'react';
import styled from 'styled-components';

import VerificationPageFooter from './VerificationPageFooter';
import Expired from '../../assets/images/link-invalid.png';
import LogoImage from '../../assets/images/tracknerd-logo.png';

const EmailVerificationExpired = () => {
	return (
		<Container>
			<Header>
				<Logo>
					<img src={LogoImage} alt="TrackNerdGPS" />
				</Logo>
			</Header>
			<Content>
				<ImageContainer>
					<img src={Expired} alt="TrackNerdGPS" />
					<Status>
						<span>Your email verification link has been expired.</span>
						<span>kindly contact +91 7483055320 for verification.</span>
					</Status>
				</ImageContainer>
				<VerificationPageFooter />
			</Content>
		</Container>
	);
};

export default EmailVerificationExpired;

const Container = styled.section`
	width: 100%;
	height: calc(100vh);

	display: block;
	align-items: center;
	justify-content: center;

	position: relative;
`;

const Content = styled.section`
	width: 100%;

	display: block;
	align-items: center;
	justify-content: center;

	position: relative;
`;

const Status = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	box-sizing: border-box;
	padding: 0.75em 2em;

	font-size: 1.25em;
	font-weight: bold;
	word-break: break-word;

	border-radius: 10px;
	background-color: rgba(86%, 16%, 16%, 0.4);
`;

const Header = styled.section`
	width: 100%;
	height: 5em;

	box-sizing: border-box;
	padding: 1em 1em 0 1em;

	display: flex;
	justify-content: center;
	align-items: center;
`;

const Logo = styled.section`
	height: 100%;

	box-sizing: border-box;
	padding: 0.25em;

	display: flex;
	align-items: center;
	justify-content: center;

	img {
		height: 100%;
		max-height: 5em;
	}
`;

const ImageContainer = styled.section`
	margin: 5em 0 2em 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: relative;

	img {
		height: 120px;
		margin: 1em 0 4em 0;
	}
`;
