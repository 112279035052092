import React, { useEffect, useRef, useState } from 'react';
import TrackNerdLoader from '../../../../components/loader/Loader';
import { GraphCard, GraphContainer, GraphHeader } from '../OverviewStyles';
import { BarDatum, ComputedDatum, ResponsiveBar } from '@nivo/bar';
import { THEME } from '../../../../constants/Theme';
import moment from 'moment';
import { dispatch, dispatchNoPayload } from '../../../../helper/view specific/store';
import { NavLink } from 'react-router-dom';
import { hoursToHoursMinutes } from '../../../../utils/calendar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/RootState';
import { mostIdleVehicle } from '../Overview';

interface EngineHourProps {
	data: mostIdleVehicle[];
}

interface GraphProps {
	Date: string;
	'Idle Hours': string;
	'Movement Hours': string;
	'Engine Hours': string;
	'Total Idle Hours': string;
	Vehicle: string;
}

const EngineHour = (props: EngineHourProps) => {
	const [graphData, setGraphData] = useState<GraphProps[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const navLinkRef = useRef<HTMLAnchorElement | null>(null);

	const GeofencePermission = useSelector((state: RootState) => state.rolePermissions.permissions.Geofence);

	useEffect(() => {
		formatData(props.data);
	}, []);

	const formatData = (reports: mostIdleVehicle[]) => {
		const tempData: GraphProps[] = [];
		const formattedData: Record<string, Partial<mostIdleVehicle>> = {};
		reports.forEach((day) => {
			formattedData[day.date] = {
				['date']: day.date ? moment(day.date).format('DD-MM-YYYY') : '',
				idleHours: day.idleHours || 0,
				movementHours: day.movementHours || 0,
				engineHours: day.engineHours || 0,
				totalIdleHours: day.totalIdleHours || '',
				registrationNumber: day.registrationNumber || '',
			};
		});
		Object.keys(formattedData)
			.sort((start, end) => {
				const startTime = moment(start, 'YYYY-MM-DD');
				const endTime = moment(end, 'YYYY-MM-DD');
				return startTime.diff(endTime);
			})
			.forEach((data) => {
				if (formattedData[data]) {
					tempData.push({
						Date: formattedData[data]?.date ?? '',
						'Idle Hours': ((formattedData[data]?.idleHours || 0) / 3600).toFixed(2),
						'Movement Hours': ((formattedData[data]?.movementHours || 0) / 3600).toFixed(2),
						'Engine Hours': ((formattedData[data]?.engineHours || 0) / 3600).toFixed(2),
						'Total Idle Hours': (parseInt(formattedData[data]?.totalIdleHours || '0') / 3600).toFixed(2),
						Vehicle: formattedData[data]?.registrationNumber ?? '',
					});
				}
			});
		setGraphData([...tempData]);
		setLoading(false);
	};

	const barData = graphData.map((item) => ({
		Date: item.Date,
		'Idle Hours': item['Idle Hours'],
		'Movement Hours': item['Movement Hours'],
		'Engine Hours': item['Engine Hours'],
		'Total Idle Hours': item['Total Idle Hours'],
		Vehicle: item.Vehicle,
	}));

	return (
		<GraphContainer>
			{loading ? (
				<TrackNerdLoader />
			) : barData.length ? (
				<GraphCard>
					<GraphHeader>
						<h1>Daily Idling Data</h1>
					</GraphHeader>
					<ResponsiveBar
						data={barData}
						enableGridY={false}
						enableGridX={false}
						keys={['Movement Hours', 'Idle Hours']}
						indexBy="Date"
						margin={{ top: 50, right: 50, bottom: 80, left: 50 }}
						padding={0.3}
						valueScale={{ type: 'linear' }}
						indexScale={{ type: 'band', round: true }}
						borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
						axisTop={null}
						axisRight={null}
						enableLabel={true}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor="black"
						label={({ value, data }: ComputedDatum<BarDatum>) => {
							const engineHours =
								typeof data['Engine Hours'] === 'string' ? parseFloat(data['Engine Hours']) : data['Engine Hours'] || 0;
							if (value && engineHours > 0) {
								return `${Math.round((value / engineHours) * 1000) / 10} %`;
							} else {
								return '0 %';
							}
						}}
						axisBottom={{
							tickRotation: -45,
							format: (date) => `${moment(date, 'DD-MM-YYYY').format('D MMM')}`,
							tickPadding: 5,
							legendOffset: 40,
						}}
						axisLeft={null}
						colors={[THEME.COLORS.vehicleMoving, THEME.COLORS.vehicleIdle]}
						legends={[
							{
								dataFrom: 'keys',
								anchor: 'bottom',
								direction: 'row',
								justify: false,
								translateX: 0,
								translateY: 70,
								itemsSpacing: 18,
								itemWidth: 100,
								itemHeight: 20,
								itemDirection: 'left-to-right',
								itemOpacity: 0.85,
								symbolSize: 15,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemOpacity: 1,
										},
									},
								],
							},
						]}
						onClick={(node) => {
							if (GeofencePermission.manage || GeofencePermission.read) {
								dispatch('SET_SEARCH_QUERY', node.data['Vehicle']);
								dispatchNoPayload('SET_VEHICLE_SELECTED_FROM_GRAPH');
								dispatch('SELECTED_TAB', 'Summary');
								dispatchNoPayload('DAILY_SUMMARY');
								dispatch('SET_DATES', {
									initialDate: moment(node.data.Date, 'DD-MM-YYYY').startOf('day').toISOString(),
									finalDate: moment(node.data.Date, 'DD-MM-YYYY').endOf('day').toISOString(),
								});
								if (navLinkRef?.current) navLinkRef?.current.click();
							}
						}}
						tooltip={(e) => {
							const offsetX = -20;
							const offsetY = 5;
							const x = window.screenX + offsetX;
							const y = window.screenY + offsetY;
							return (
								<div
									style={{
										padding: '12px',
										color: 'black',
										background: 'white',
										display: 'flex',
										flexDirection: 'column',
										boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
										position: 'absolute',
										right: x + 'px',
										bottom: y + 'px',
										width: '200px',
									}}>
									<div style={{ justifyContent: 'left' }}>
										<strong>{'Highest Idling:'}</strong>
									</div>
									<div style={{ justifyContent: 'left' }}>Vehicle: {e.data.Vehicle}</div>
									<div style={{ justifyContent: 'left' }}>
										Idle Hours: {hoursToHoursMinutes(e.data['Idle Hours'].toLocaleString())}
									</div>
									<div style={{ justifyContent: 'left' }}>
										Movement Hours: {hoursToHoursMinutes(e.data['Movement Hours'].toLocaleString())}
									</div>
									<div style={{ justifyContent: 'left' }}>
										Engine Hours: {hoursToHoursMinutes(e.data['Engine Hours'].toLocaleString())}
									</div>
								</div>
							);
						}}
						animate={true}
					/>
				</GraphCard>
			) : (
				<GraphCard>
					<GraphHeader>
						<h1>Daily Idling Data</h1>
					</GraphHeader>
					<div
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							flex: 1,
						}}>
						<p>No Data!</p>
					</div>
				</GraphCard>
			)}
			<NavLink
				ref={navLinkRef}
				to={{
					pathname: '/vehicles',
				}}
			/>
		</GraphContainer>
	);
};

// Connect to Store

export default EngineHour;
