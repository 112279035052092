import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
	height: 100%;
`;

export const ImageContainer = styled.section`
	height: 50%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: relative;

	img {
		height: 100%;
		max-height: 150px;
	}
`;

export const InfoContainer = styled.section`
	height: 50%;

	display: flex;
	justify-content: center;

	img {
		width: 150px;
		height: 4em;
	}
	a {
		padding: 5px;
	}
`;
