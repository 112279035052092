interface tabState {
	sidebarConfig: {
		showCalendar: boolean;
		multiSelectAllowed: boolean;
	};
}

interface tabAction {
	type: string;
	payload?: object;
}

export default function tab(
	initialState: tabState = {
		sidebarConfig: {
			showCalendar: false,
			multiSelectAllowed: true,
		},
	},
	action: tabAction
) {
	switch (action.type) {
		case 'SET_SIDEBAR_CONFIG':
			return {
				...initialState,
				sidebarConfig: action.payload,
			};

		case 'RESTORE_SIDEBAR_CONFIG':
			return {
				sidebarConfig: {
					showCalendar: false,
					multiSelectAllowed: true,
				},
			};

		default:
			return initialState;
	}
}
