import { Icon, Label, Menu, Popup } from 'semantic-ui-react';
import { Options } from './DashboardStyles';
import styles from './Dashboard.module.css';
import { logout } from '../../helper/view specific/auth';
import React, { useState } from 'react';
import { getData } from '../../helper/services/axios';
import { USERS_API } from '../../constants/api';
import { THEME } from '../../constants/Theme';
import axios from 'axios';
import { handleError, handleSuccess } from '../../helper/view specific/messaging';
import { setGeoFencesShown } from '../../utils/common';
// import Store from "../../../redux/store";
// import {useSelector} from "react-redux";
import { RootState } from '../../redux/RootState';
import { useSelector } from 'react-redux';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

interface itemInterface {
	icon?: SemanticICONS;
	title?: string;
	text: string | null;
	subText?: string;
	callback?: () => void;
	color?: string;
}

interface itemToggleInterface {
	toggle?: boolean;
	text: string;
	subText: string;
	icon?: SemanticICONS;
	callback?: () => void;
}

interface dashboardSettingsInterface {
	onChangePassword: () => void;
	onServicePolicyClick: () => void;
	onPaymentPolicyClick: () => void;
	onAssetVerifyClick: (assetType: number, asset: string) => void;
}

interface updateNotificationStateInterface {
	isEnabledEmail: boolean;
	isEnabledSMS: boolean;
	isEnabledPush: boolean;
}

export const DashboardSetting = (props: dashboardSettingsInterface) => {
	const [isEnabledSMS, setIsEnabledSMS] = useState(localStorage.getItem('isEnabledSMS') === 'true');
	const [isEnabledEmail, setIsEnabledEmail] = useState(localStorage.getItem('isEnabledEmail') === 'true');
	const [isEnabledPush, setIsEnabledPush] = useState(localStorage.getItem('isEnabledPush') === 'true');

	// const GeoFenceState = useSelector((state: boolean) => state.auth.shownGeoFences);
	// const UserPermission   = (Store.getState() as RootState).rolePermissions.permissions.User;
	const GeoFenceState = useSelector((state: RootState) => state.auth.shownGeoFences);
	const UserPermission = useSelector((state: RootState) => state.rolePermissions.permissions.User);
	// const GeofencePermission = (Store.getState() as RootState).rolePermissions.permissions.Geofence;
	const GeofencePermission = useSelector((state: RootState) => state.rolePermissions.permissions.Geofence);

	function Item({ icon, title, text, subText, callback, color = 'black' }: itemInterface) {
		return (
			<li className={styles.listItem} onClick={callback === undefined ? undefined : callback}>
				{icon ? (
					<span className={styles.itemIcon} style={{ color: 'black' }}>
						<Icon name={icon} />
					</span>
				) : null}
				<div
					style={{
						display: 'flex',
						flexDirection: title && text ? 'row' : 'column',
					}}>
					{title ? <span className={styles.itemTitle}>{title}</span> : null}
					{text ? (
						<span style={{ color: color }} className={styles.itemText}>
							{text}
						</span>
					) : null}
					{subText ? <span className={styles.itemSubText}>{subText}</span> : null}
				</div>
			</li>
		);
	}

	function ItemToggle({ toggle, text, subText, icon, callback }: itemToggleInterface) {
		return (
			<li
				className={styles.listItem}
				style={{ justifyContent: 'space-between' }}
				// eslint-disable-next-line no-undef
				onClick={callback === undefined ? undefined : callback}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{icon ? (
						<span className={styles.itemIcon} style={{ color: 'black' }}>
							<Icon name={icon} />
						</span>
					) : null}
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span className={styles.itemText}>{text}</span>
						{subText ? <span className={styles.itemSubText}>{subText}</span> : null}
					</div>
				</div>
				{toggle ? (
					<span className={styles.itemIcon} style={{ fontSize: 28, color: `${THEME.COLORS.vehicleMoving}` }}>
						<Icon name="toggle on" />
					</span>
				) : (
					<span className={styles.itemIcon} style={{ fontSize: 28, color: `${THEME.COLORS.vehicleNoDataOneDay}` }}>
						<Icon flipped="horizontally" name="toggle on" />
					</span>
				)}
			</li>
		);
	}

	function fetchUserData() {
		getData(`${USERS_API}/${localStorage.getItem('userId')}`)
			.then((response) => {
				const { isEnabledEmail, isEnabledPush, isEnabledSMS } = response.data;
				localStorage.setItem('isEnabledEmail', isEnabledEmail);
				localStorage.setItem('isEnabledPush', isEnabledPush);
				localStorage.setItem('isEnabledSMS', isEnabledSMS);
				updateNotificationState({
					isEnabledEmail: isEnabledEmail,
					isEnabledPush: isEnabledPush,
					isEnabledSMS: isEnabledSMS,
				});
			})
			.catch((error) => {
				handleError('DashboardSettings.tsx => fetchUserData()', error);
			});
	}

	const updateNotificationState = ({
		isEnabledEmail,
		isEnabledSMS,
		isEnabledPush,
	}: updateNotificationStateInterface) => {
		setIsEnabledSMS(isEnabledSMS);
		setIsEnabledPush(isEnabledPush);
		setIsEnabledEmail(isEnabledEmail);
	};
	const updateNotificationData = (payload: object) => {
		//let query = {isEnabledEmail: !isEnabledEmail};
		axios
			.patch(USERS_API + `/${localStorage.getItem('userId')}`, payload, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				handleSuccess(response);
			})
			.catch((error) => {
				handleError('DashboardSettings.tsx => updateNotificationData()', error);
			});
	};

	const smsUpdateNotification = () => {
		const sms = !isEnabledSMS;
		const isSMS = sms.toString();
		localStorage.setItem('isEnabledSMS', isSMS);
		updateNotificationData({ isEnabledSMS: !isEnabledSMS });
		setIsEnabledSMS((prevState) => !prevState);
	};
	const emailUpdateNotification = () => {
		const bool = !isEnabledEmail;
		const isEmail = bool.toString();
		localStorage.setItem('isEnabledEmail', isEmail);
		updateNotificationData({ isEnabledEmail: !isEnabledEmail });
		setIsEnabledEmail((prevState) => !prevState);
	};
	const pushUpdateNotification = () => {
		const bool = !isEnabledPush;
		const isPush = bool.toString();
		localStorage.setItem('isEnabledPush', isPush);
		updateNotificationData({ isEnabledPush: !isEnabledPush });
		setIsEnabledPush((prevState) => !prevState);
	};

	const toggleGeoFencesVisibility = () => {
		setGeoFencesShown(!GeoFenceState);
	};

	const getAsyncData = (field: string) => {
		const phone = localStorage.getItem(field);
		if (phone) props.onAssetVerifyClick(1, field);
	};

	return (
		<div style={{ margin: '0 1em' }}>
			{/*<style>*/}
			{/*  @import*/}
			{/*  url(&apos;https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap&apos;);*/}
			{/*</style>*/}
			<Options>
				<Popup
					on="click"
					onOpen={fetchUserData}
					closeOnPortalMouseLeave={false}
					trigger={
						<Label
							size={'huge'}
							circular
							style={{
								fontSize: '1.2rem',
								backgroundColor: '#1b3c71',
								color: '#fff',
								cursor: 'pointer',
								marginTop: '5px',
								marginRight: '-10px',
							}}>
							{localStorage.getItem('name')?.[0]}
						</Label>
					}
					// trigger={<Icon name="cog" style={{ color: "white", fontsize: 20, marginBottom: 10 }} />}
					position={'bottom right'}
					flowing
					basic
					hoverable
					style={{ padding: 0, cursor: 'pointer' }}>
					<Menu size={'massive'} vertical>
						<Menu.Item className={styles.menuItem}>
							<Menu.Header>Profile</Menu.Header>
							<Menu.Menu>
								<Menu.Item>
									<Item title={'Name:'} text={localStorage.getItem('name')} icon="user outline" />
								</Menu.Item>
								<Menu.Item>
									<Item
										title="Phone:"
										text={localStorage.getItem('phone')}
										icon="mobile alternate"
										color={localStorage.getItem('phoneVerified') === 'true' ? 'green' : 'red'}
										callback={
											localStorage.getItem('phoneVerified') === 'false' ? () => getAsyncData('phone') : undefined
										}
									/>
								</Menu.Item>
								<Menu.Item>
									<Item
										text={localStorage.getItem('email')}
										title="Email:"
										icon="envelope outline"
										color={localStorage.getItem('emailVerified') === 'true' ? 'green' : 'red'}
										callback={
											localStorage.getItem('emailVerified') === 'false' ? () => getAsyncData('email') : undefined
										}
									/>
								</Menu.Item>
								<Menu.Item>
									<Item title="Role:" text={localStorage.getItem('roleName')} icon="user outline" />
								</Menu.Item>
								<Menu.Item>
									<Item title="Organization:" text={localStorage.getItem('organisationName')} icon="globe" />
								</Menu.Item>
							</Menu.Menu>
						</Menu.Item>
						<Menu.Item className={styles.menuItem}>
							<Menu.Header>General</Menu.Header>
							<Menu.Menu>
								{UserPermission.manage || UserPermission.update ? (
									<>
										<Menu.Item>
											<ItemToggle
												text={'Email Notifications'}
												subText="Show/Hide Email Notifications"
												toggle={isEnabledEmail}
												callback={emailUpdateNotification}
												icon="bell outline"
											/>
										</Menu.Item>
										<Menu.Item>
											<ItemToggle
												text={'WhatsApp Notifications'}
												subText="Allow/Disallow WhatsApp Notifications"
												toggle={isEnabledSMS}
												callback={smsUpdateNotification}
												icon="bell outline"
											/>
										</Menu.Item>
										<Menu.Item>
											<ItemToggle
												text={'Push Notification'}
												subText="Allow/Disallow Push Notifications"
												toggle={isEnabledPush}
												callback={pushUpdateNotification}
												icon="bell outline"
											/>
										</Menu.Item>
									</>
								) : (
									<>
										<Menu.Item>
											<ItemToggle
												text={'Email Notifications'}
												subText="Show/Hide Email Notifications"
												icon="bell outline"
											/>
										</Menu.Item>
										<Menu.Item>
											<ItemToggle
												text={'WhatsApp Notifications'}
												subText="Allow/Disallow WhatsApp Notifications"
												icon="bell outline"
											/>
										</Menu.Item>
										<Menu.Item>
											<ItemToggle
												text={'Push Notification'}
												subText="Allow/Disallow Push Notifications"
												icon="bell outline"
											/>
										</Menu.Item>
									</>
								)}
								{GeofencePermission.manage || GeofencePermission.read ? (
									<Menu.Item>
										<ItemToggle
											text={'GeoFence Visibility'}
											subText="Show/Hide Geofences on All Maps"
											callback={toggleGeoFencesVisibility}
											toggle={GeoFenceState}
											icon="crosshairs"
										/>
									</Menu.Item>
								) : (
									<Menu.Item>
										<ItemToggle
											text={'GeoFence Visibility'}
											subText="Show/Hide Geofences on All Maps"
											icon="crosshairs"
										/>
									</Menu.Item>
								)}
							</Menu.Menu>
						</Menu.Item>

						<Menu.Item className={styles.menuItem}>
							<Menu.Menu>
								<Menu.Item>
									<Item
										text={'Installation, Service & Repair Policy'}
										subText="Click to Read Policy"
										icon="cogs"
										callback={props.onServicePolicyClick}
									/>
								</Menu.Item>
								<Menu.Item>
									<Item
										text={'Payment Policy'}
										subText="Click to Read Policy"
										icon="rupee"
										callback={props.onPaymentPolicyClick}
									/>
								</Menu.Item>
							</Menu.Menu>
						</Menu.Item>

						<Menu.Item className={styles.menuItem}>
							{/*<Menu.Header></Menu.Header>*/}
							<Menu.Menu>
								{/*<Menu.Item>*/}
								{/*  <Item*/}
								{/*    text={"Reset Password"}*/}
								{/*    subText="Set New Password"*/}
								{/*    icon="lock"*/}
								{/*    callback={props.onChangePassword}*/}
								{/*  />*/}
								{/*</Menu.Item>*/}
								{/*<Menu.Item>*/}
								{/*  <Item text={"Logout"} callback={logout} icon="sign out" />*/}
								{/*</Menu.Item>*/}

								<Menu.Item>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}>
										<Item text={'Reset Password'} icon="lock" callback={props.onChangePassword} />
										<Item text={'Logout'} callback={logout} icon="sign out" />
									</div>
								</Menu.Item>
							</Menu.Menu>
						</Menu.Item>
					</Menu>
				</Popup>
			</Options>
		</div>
	);
};
