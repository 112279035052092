import React, { useEffect, useState } from 'react';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { Button, Dropdown, Modal } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { HOUR_OPTIONS, MINUTE_OPTIONS } from '../../constants/constants';
import CalendarImage from '../../assets/images/calendar.png';
import Store from '../../redux/store';
import {
	CalendarBlock,
	Container,
	DayBlock,
	PickerContainer,
	PickerControls,
	PickerPane,
	PickerView,
	PlaceholderView,
	ShortcutsBlock,
	ShortcutsPane,
	TimePickerBlock,
} from './CalendarStyles';
import { RootState } from '../../redux/RootState';
import { dispatch } from '../../helper/view specific/store';

const Calendar = () => {
	const {
		initialDate,
		initialDateTime,
		finalDate,
		finalDateTime,
		calendarVisible,
		disableTimePicker,
		disableDatePicker,
		track7Days,
	} = useSelector((state: RootState) => state.dates);

	const [datePart1, setDatePart1] = useState<string>(moment().format('DD-MM-YY'));
	const [datePart2, setDatePart2] = useState<string>(moment().format('DD-MM-YY'));
	const [selectedFromHour, setSelectedFromHour] = useState<string>('12');
	const [selectedFromMinute, setSelectedFromMinute] = useState<string>('00');
	const [selectedToHour, setSelectedToHour] = useState<string>('11');
	const [selectedToMinute, setSelectedToMinute] = useState<string>('59');
	const [isTime1Am, setIsTime1Am] = useState<boolean>(true);
	const [isTime2Am, setIsTime2Am] = useState<boolean>(false);
	const [sevenDaysError, setSevenDaysError] = useState<boolean>(false);

	useEffect(() => {
		const difference = moment.duration(moment(finalDate).diff(moment(initialDate))).asDays();
		if (track7Days && difference >= 7) {
			setSevenDaysError(true);
			setDatePart1(moment().format('DD-MM-YY'));
			setDatePart2(moment().format('DD-MM-YY'));
		} else {
			setSevenDaysError(false);
			setDatePart1(moment(initialDate).format('DD-MM-YY'));
			setDatePart2(moment(finalDate).format('DD-MM-YY'));
			dispatch('EVENT_DATA_STORE', []);
			dispatch('SUMMARY_DATA_STORE', []);

			dispatch('TRIP_DATA_STORE', []);
			dispatch('TRACK_DATA_STORE', []);
		}
	}, [initialDate, finalDate]);
	const handleChange = (
		event: React.SyntheticEvent<HTMLElement>,
		{
			name,
			value,
		}: {
			name: string;
			value: string;
		}
	) => {
		let daysDiff = 0;

		if (name === 'date1') {
			setDatePart1(value);
			daysDiff = moment(datePart2, 'DD-MM-YY').diff(moment(value, 'DD-MM-YY'), 'day');
			if (daysDiff < 0) setDatePart2(value);
		}

		if (name === 'date2') {
			setDatePart2(value);
			daysDiff = moment(value, 'DD-MM-YY').diff(moment(datePart1, 'DD-MM-YY'), 'day');
			if (daysDiff < 0) setDatePart2(value);
		}

		if (track7Days && daysDiff >= 7) {
			setSevenDaysError(true);
		} else {
			setSevenDaysError(false);
		}
	};

	const handleToday = () => {
		const date1 = moment().format('DD-MM-YY');
		const date2 = moment().format('DD-MM-YY');
		setDatePart1(date1);
		setDatePart2(date2);
		setSelectedFromHour('12');
		setSelectedFromMinute('00');
		setSelectedToHour('11');
		setSelectedToMinute('59');
		setIsTime1Am(true);
		setIsTime2Am(false);

		Store.dispatch({
			type: 'SET_DATES',
			payload: {
				initialDate: moment().startOf('day').toISOString(),
				finalDate: moment().endOf('day').toISOString(),
			},
		});

		Store.dispatch({
			type: 'HIDE_CALENDAR',
		});
	};

	const handleYesterday = () => {
		const date1 = moment().subtract(1, 'days').format('DD-MM-YY');
		const date2 = moment().subtract(1, 'days').format('DD-MM-YY');
		const time1 = '00:00';
		const time2 = '23:59:59:999';
		setDatePart1(date1);
		setDatePart2(date2);
		setSelectedFromHour('12');
		setSelectedFromMinute('00');
		setSelectedToHour('11');
		setSelectedToMinute('59');
		setIsTime1Am(true);
		setIsTime2Am(false);
		submitDates(date1, date2, time1, time2);
	};

	const handleLast3Days = () => {
		const date1 = moment().subtract(2, 'days').format('DD-MM-YY');
		const date2 = moment().subtract(0, 'days').format('DD-MM-YY');
		const time1 = '00:00';
		const time2 = '23:59:59:999';
		setDatePart1(date1);
		setDatePart2(date2);
		setSelectedFromHour('12');
		setSelectedFromMinute('00');
		setSelectedToHour('11');
		setSelectedToMinute('59');
		setIsTime1Am(true);
		setIsTime2Am(false);

		submitDates(date1, date2, time1, time2);
	};
	const handlePastWeek = () => {
		const date1 = moment().subtract(6, 'days').format('DD-MM-YY');
		const date2 = moment().subtract(0, 'days').format('DD-MM-YY');
		const time1 = '00:00';
		const time2 = '23:59:59:999';
		setDatePart1(date1);
		setDatePart2(date2);
		setSelectedFromHour('12');
		setSelectedFromMinute('00');
		setSelectedToHour('11');
		setSelectedToMinute('59');
		setIsTime1Am(true);
		setIsTime2Am(false);

		submitDates(date1, date2, time1, time2);
	};

	const handlePastMonth = () => {
		const date1 = moment().subtract(1, 'month').format('DD-MM-YY');
		const date2 = moment().subtract(0, 'days').format('DD-MM-YY');
		const time1 = '00:00:00';
		const time2 = '23:59:59:999';
		setDatePart1(date1);
		setDatePart2(date2);

		setSelectedFromHour('12');
		setSelectedFromMinute('00');
		setSelectedToHour('11');
		setSelectedToMinute('59');
		setIsTime1Am(true);
		setIsTime2Am(false);

		submitDates(date1, date2, time1, time2);
	};

	const handleMonthToDate = () => {
		const date1 = moment().startOf('month').format('DD-MM-YY');
		const date2 = moment().subtract(0, 'days').format('DD-MM-YY');
		const time1 = '00:00:00';
		const time2 = '23:59:59:999';
		setDatePart1(date1);
		setDatePart2(date2);
		setSelectedFromHour('12');
		setSelectedFromMinute('00');
		setSelectedToHour('11');
		setSelectedToMinute('59');
		setIsTime1Am(true);
		setIsTime2Am(false);

		submitDates(date1, date2, time1, time2);
	};

	const handleApply = () => {
		let hourFrom = isTime1Am
			? selectedFromHour === '12'
				? '00'
				: selectedFromHour
			: `${12 + parseInt(selectedFromHour)}`;

		if (hourFrom === '24') hourFrom = '12';

		let hourTo = isTime2Am ? (selectedToHour === '12' ? '00' : selectedToHour) : `${12 + parseInt(selectedToHour)}`;

		if (hourTo === '24') hourTo = '12';

		setTimeout(() => {
			submitDates(datePart1, datePart2, `${hourFrom}:${selectedFromMinute}`, `${hourTo}:${selectedToMinute}:59:999`);
		}, 1);
	};

	const submitDates = (date1: string, date2: string, time1: string, time2: string) => {
		Store.dispatch({
			type: 'SET_DATES',
			payload: {
				initialDate: moment(`${date1}-${time1}`, 'DD-MM-YYYY-HH:mm:ss:SSS').toISOString(),
				finalDate: moment(`${date2}-${time2}`, 'DD-MM-YYYY-HH:mm:ss:SSS').toISOString(),
			},
		});

		Store.dispatch({
			type: 'SET_DATE_TIME',
			payload: {
				initialDateTime: time1,
				finalDateTime: time2,
			},
		});

		Store.dispatch({
			type: 'HIDE_CALENDAR',
		});
	};

	const resetTime = () => {
		Store.dispatch({ type: 'HIDE_CALENDAR' });

		setDatePart1(moment(initialDate).format('DD-MM-YY'));
		setDatePart2(moment(finalDate).format('DD-MM-YY'));

		setSelectedFromHour(formatHours(initialDateTime));
		setSelectedFromMinute(
			moment(initialDateTime, 'HH:mm:ss:SSS')
				.get('minutes')
				.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
				.toString()
		);
		setSelectedToHour(formatHours(finalDateTime));
		setSelectedToMinute(
			moment(finalDateTime, 'HH:mm:ss:SSS')
				.get('minutes')
				.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
				.toString()
		);
		setIsTime1Am(isAm(initialDateTime));
		setIsTime2Am(isAm(finalDateTime));
	};

	const formatHours = (date: string) => {
		let hours = moment(date, 'HH:mm').get('hours');

		if (hours > 12) {
			hours = hours - 12;
		} else if (hours === 0) hours = 12;

		return hours.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }).toString();
	};

	const isAm = (date: string) => {
		const hours = moment(date, 'HH:mm').get('hours');
		const minutes = moment(date, 'HH:mm').get('minutes');

		if (hours === 0 && minutes === 0) {
			return true;
		} else return hours < 12 && minutes <= 59;
	};

	return (
		<Container>
			<PlaceholderView
				onClick={() => {
					if (!disableTimePicker || !disableDatePicker) Store.dispatch({ type: 'SHOW_CALENDAR' });
				}}>
				<DayBlock>
					<span>{moment(initialDate).format('DD-MM-YYYY')}</span>
					<span>{moment(initialDate).format('hh:mm A')}</span>
				</DayBlock>
				<img src={CalendarImage} alt="Calendar" />
				<DayBlock>
					<span>{moment(finalDate).format('DD-MM-YYYY')}</span>
					<span>{moment(finalDate).format('hh:mm A')}</span>
				</DayBlock>
			</PlaceholderView>
			<Modal className="CalendarModal" open={calendarVisible} onClose={resetTime}>
				<Modal.Content>
					<PickerContainer>
						<CalendarBlock>
							<PickerView>
								<PickerPane>
									<DateInput
										name="date1"
										inline
										value={datePart1}
										dateFormat="DD-MM-YY"
										maxDate={moment()}
										onChange={handleChange}
										// marked={moment(datePart1, 'DD-MM-YY')}
										markColor="red"
									/>
									{!disableTimePicker && (
										<TimePickerBlock>
											<Dropdown
												basic
												compact
												placeholder="12"
												search
												selection
												options={HOUR_OPTIONS}
												value={selectedFromHour}
												onChange={(e: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
													if (data.value) setSelectedFromHour(data.value.toString());
												}}
											/>
											<Dropdown
												basic
												compact
												placeholder="00"
												search
												selection
												options={MINUTE_OPTIONS}
												value={selectedFromMinute}
												onChange={(e, data: DropdownProps) => {
													if (data.value) setSelectedFromMinute(data.value.toString());
												}}
											/>
											<Button.Group>
												<Button primary={isTime1Am} onClick={() => setIsTime1Am(true)}>
													AM
												</Button>
												<Button primary={!isTime1Am} onClick={() => setIsTime1Am(false)}>
													PM
												</Button>
											</Button.Group>
										</TimePickerBlock>
									)}
								</PickerPane>
								<PickerPane>
									<DateInput
										name="date2"
										inline
										value={datePart2}
										dateFormat="DD-MM-YY"
										minDate={datePart1}
										maxDate={moment()}
										onChange={handleChange}
										// marked={moment(datePart2, 'DD-MM-YY')}
										markColor="red"
									/>
									{!disableTimePicker && (
										<TimePickerBlock>
											<TimePickerBlock>
												<Dropdown
													basic
													compact
													placeholder="11"
													search
													selection
													options={HOUR_OPTIONS}
													value={selectedToHour}
													onChange={(e, data: DropdownProps) => {
														if (data.value) setSelectedToHour(data.value.toString());
													}}
												/>
												<Dropdown
													basic
													compact
													placeholder="59"
													search
													selection
													options={MINUTE_OPTIONS}
													value={selectedToMinute}
													onChange={(e, data: DropdownProps) => {
														if (data.value) setSelectedToMinute(data.value.toString());
													}}
												/>
												<Button.Group>
													<Button.Group>
														<Button primary={isTime2Am} onClick={() => setIsTime2Am(true)}>
															AM
														</Button>
														<Button primary={!isTime2Am} onClick={() => setIsTime2Am(false)}>
															PM
														</Button>
													</Button.Group>
												</Button.Group>
											</TimePickerBlock>
										</TimePickerBlock>
									)}
								</PickerPane>
							</PickerView>
							<PickerControls>
								<h3 style={{ color: 'red' }}>{sevenDaysError ? 'Please Select Only 7 Days' : ' '}</h3>
							</PickerControls>
							<PickerControls>
								<Button primary disabled={sevenDaysError} onClick={handleApply}>
									Apply
								</Button>
							</PickerControls>
						</CalendarBlock>
						<ShortcutsBlock>
							<ShortcutsPane>
								<Button primary onClick={handleToday}>
									Today
								</Button>
								<Button primary onClick={handleYesterday}>
									Yesterday
								</Button>
								<Button primary onClick={handleLast3Days}>
									Last 3 Days
								</Button>
								<Button primary onClick={handlePastWeek}>
									Past Week
								</Button>

								<Button primary onClick={handlePastMonth} disabled={track7Days}>
									Past Month
								</Button>
								<Button primary onClick={handleMonthToDate} disabled={track7Days}>
									Month to Date
								</Button>
							</ShortcutsPane>
						</ShortcutsBlock>
					</PickerContainer>
				</Modal.Content>
			</Modal>
		</Container>
	);
};

// Connect to Store
/*const mapStateToProps = (state) => ({
    initialDate: state.dates.initialDate,
    initialDateTime: state.dates.initialDateTime,
    finalDate: state.dates.finalDate,
    finalDateTime: state.dates.finalDateTime,
    calendarVisible: state.dates.calendarVisible,
    disableTimePicker: state.dates.disableTimePicker,
    disableDatePicker: state.dates.disableDatePicker,
    track7Days: state.dates.track7Days
});*/

export default Calendar;
