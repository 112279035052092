import styled from 'styled-components';

import { THEME } from '../../../constants/Theme';

export const Container = styled.section`
	width: 100%;
	height: 100%;

	.header {
		color: ${THEME.COLORS.theme} !important;
	}

	.ui.icon {
		color: ${THEME.COLORS.theme} !important;
	}

	.ui.icon.header .icon {
		margin: 0 auto 0.45em !important;
	}
`;
