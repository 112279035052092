import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';
import {
	Button,
	Checkbox,
	Dropdown,
	Form,
	Header,
	Icon,
	Input,
	Modal,
	Pagination,
	Popup,
	Segment,
} from 'semantic-ui-react'; // View
import { EXPORT_API } from '../../../constants/api';
import ImageView from '../../../components/documentView/image-view/ImageView';
import PdfView from '../../../components/documentView/pdf-view/PdfView';
import { Expander } from '../vehicles/sidebar/SidebarStyles';
import TrackNerdLoader from '../../../components/loader/Loader';
import { DOCUMENTS_TYPE_OPTIONS, PAGE_COUNT_OPTIONS } from '../../../constants/constants';
import { createVehiclesDTO } from '../../../dtos/vehicle';
import { fetchOrganisations, fetchVehicleGroups, handleDataSizeLimitChange } from '../../../utils/common';
import {
	Card,
	CardContainer,
	CategoryHeader,
	Container,
	DocumentBlock,
	DocumentExpiryDate,
	DocumentName,
	DocumentTypeBlock,
	DocumentTypeHeader,
	Filter,
	MainContainer,
	MainContent,
	OrgName,
	PageSelector,
	SearchContainer,
	ToBeExpired,
	VehicleInfo,
	VehicleNumber,
	VerticalBlock,
} from './DocumentsStyles';
import { loadDocument, searchDocument } from './DocumentsMiddleware';
import { handleError } from '../../../helper/view specific/messaging';
import { fetchVehicles } from '../vehicles/VehiclesMiddleware';
import TransitionLoader from '../../../components/loader/TransitionLoader';
import { CategoryFooter } from '../../../components/STYLED/CategoryFooter';
import CarImage from '../../../assets/images/vehicle-type-1.png';
import BikeImage from '../../../assets/images/vehicle-type-2.png';
import TruckImage from '../../../assets/images/vehicle-type-3.png';
import ConstructionImage from '../../../assets/images/vehicle-type-4.png';
import BusImage from '../../../assets/images/vehicle-type-5.png';
import GeneratorImg from '../../../assets/images/vehicle-type-6.png';
import ContainerImg from '../../../assets/images/vehicle-type-7.png';
import MiniTruck from '../../../assets/images/vehicle-type-8.png';
import AutoImg from '../../../assets/images/vehicle-type-9.png';
import { THEME } from '../../../constants/Theme';
import NoResult from '../../../assets/images/no-results.png';
import { NoMatchFoundContainer } from '../drivers/DriversStyles';
import { getFileData } from '../../../helper/services/axios';
import { datadogLogs } from '@datadog/browser-logs';
import { RootState } from '../../../redux/RootState';
import { VehicleGroup } from '../../../types/vehicleGroup';
import { Organisation } from '../../../types/organisation';
import { VehicleDocs } from '../../../dtos/document';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { PaginationProps } from 'semantic-ui-react/dist/commonjs/addons/Pagination/Pagination';
import { CheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';

interface OrganisationsForFilter {
	key: number;
	value: number;
	text: string;
}

interface VehicleGroupsForFilter {
	key: number;
	value: number;
	text: string;
}

interface VehiclesForFilter {
	key: number;
	value: number;
	text: string;
}

interface ActiveFilters {
	organisations: number[];
	vehicleGroups: number[];
	vehicles: number[];
	documentType: string[];
	expiryStartDate: string;
	expiryEndDate: string;
	isExpired: boolean;
}

function Documents() {
	const [initialLoading, setInitialLoading] = useState<boolean>(true);
	const [vehicles, setVehicles] = useState<VehicleDocs[]>([]);

	//filters
	const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
	const [filterCount, setFilterCount] = useState<number>(0);

	const [organisationsForFilter, setOrganisationsForFilter] = useState<OrganisationsForFilter[]>([]);
	const [vehiclesGroupsForFilter, setVehiclesGroupsForFilter] = useState<VehicleGroupsForFilter[]>([]);
	const [vehiclesForFilter, setVehiclesForFilter] = useState<VehiclesForFilter[]>([]);

	const [filteredOrgs, setFilteredOrgs] = useState<number[]>([]);

	const [filteredVehicleGroups, setFilteredVehicleGroups] = useState<number[]>([]);
	const [filteredVehicles, setFilteredVehicles] = useState<number[]>([]);
	const [filteredDocumentType, setFilteredDocumentType] = useState<string[]>([]);

	const [expiryStart, setExpiryStart] = useState<string | null>(null);
	const [expiryEnd, setExpiryEnd] = useState<string | null>(null);

	const [activeFilters, setActiveFilters] = useState<ActiveFilters>({
		organisations: [],
		vehicleGroups: [],
		vehicles: [],
		documentType: [],
		expiryStartDate: '',
		expiryEndDate: '',
		isExpired: false,
	});

	//file preview
	const [preview, setPreview] = useState<boolean>(false);
	const [previewType, setPreviewType] = useState('application/pdf');
	const [previewSource, setPreviewSource] = useState<string | ''>('');
	const [fileName, setFileName] = useState<string>('');
	const [isExpired, setIsExpired] = useState<boolean>(false);

	//search
	const [searchQuery, setSearchQuery] = useState<string>('');

	const isSuperAdmin = localStorage.getItem('isSuperAdmin') === 'true' ? true : false;
	//expiry docs counter
	const [expiryCounterHeader, setExpiryCounterHeader] = useState({
		roadTax: 0,
		vehicleFitness: 0,
		vehicleRegistration: 0,
		vehicleInsurance: 0,
		puc: 0,
	});

	const { loading, count, pageNumber } = useSelector((state: RootState) => state.document);
	const dataSizeLimit = useSelector((state: RootState) => state.ui.dataSizeLimit);

	useEffect(() => {
		getFilteredDocuments();
	}, [searchQuery, filterCount, dataSizeLimit]);

	function fetchDocumentLink(docId: number, fileName: string) {
		setInitialLoading(true);
		getFileData(EXPORT_API, `/documents/${docId}`)
			.then((response) => {
				setPreview(true);
				setPreviewType(response.headers['content-type']);
				setPreviewSource(response.data);

				setInitialLoading(false);
				const extension = response.headers['content-type'].split('/').at(-1);

				setFileName(`${fileName}.${extension}`);
			})
			.catch((error) => {
				handleError('Documents.tsx => fetchDocumentLink()', error);
				setInitialLoading(false);
				setPreview(false);
			});
	}

	const getFilteredDocuments = (activePage = 1) => {
		let query = '';

		query = query + `?pagecount=${dataSizeLimit}&pagenumber=${activePage}`;

		//search, org, vehicle, docType,

		if (searchQuery !== '') {
			query = query + searchDocument(searchQuery);
		}

		if (filteredOrgs.length) {
			query = query + `&organisations[]=${filteredOrgs.join('&organisations[]=')}`;
		}
		if (filteredVehicleGroups.length) {
			query = query + `&vehicleGroups[]=${filteredVehicleGroups.join('&vehicleGroups[]=')}`;
		}

		if (filteredVehicles.length) {
			query = query + `&vehicles[]=${filteredVehicles.join(',')}`;
		}

		if (filteredDocumentType.length) {
			query = query + `&documentType[]=${filteredDocumentType.join('&documentType[]=')}`;
		}

		if (expiryStart !== null && expiryEnd !== null) {
			query =
				query +
				`&expiryStartDate=${moment(expiryStart, 'DD-MM-YYYY').toISOString()}&expiryEndDate=${moment(
					expiryEnd,
					'DD-MM-YYYY'
				).toISOString()}`;
		}

		if (isExpired) query += `&isDocumentExpired=${isExpired}`;

		loadDocument(query, activePage)
			.then((response) => {
				setVehicles(response.vehicleList);
				setExpiryCounterHeader({
					roadTax: response.expiryCounters.roadTaxExpiring,
					vehicleFitness: response.expiryCounters.vehicleFitnessExpiring,
					vehicleRegistration: response.expiryCounters.vehicleRegistrationExpiring,
					vehicleInsurance: response.expiryCounters.vehicleInsuranceExpiring,
					puc: response.expiryCounters.pucExpiring,
				});
				setInitialLoading(false);
			})
			.catch((error) => {
				datadogLogs.logger.error('Document load Document Error', {}, error);
			});
	};

	const getOrganisations = () => {
		fetchOrganisations()
			.then((response) => {
				const organisations = response?.data?.data.map((organisation: Organisation, index: number) => {
					return {
						key: index,
						value: organisation.id,
						text: organisation.name,
					};
				});
				setOrganisationsForFilter(organisations);
			})
			.catch((error) => {
				handleError('Documents.tsx => getOrganisations()', error);
			});
	};

	// VehiclesGroups
	const getVehicleGroups = () => {
		fetchVehicleGroups()
			.then((response) => {
				const vehicleGroups = response?.data?.data.map((vehicleGroup: VehicleGroup, index: number) => {
					return {
						key: index,
						value: vehicleGroup.id,
						text: vehicleGroup.name,
					};
				});
				setVehiclesGroupsForFilter(vehicleGroups);
			})
			.catch((error) => {
				handleError('Documents.tsx => getVehicleGroups()', error);
			});
	};

	const getVehicles = (params = '') => {
		return fetchVehicles(params)
			.then((response) => {
				return createVehiclesDTO(response?.data?.data).map((vehicle, index) => {
					return {
						key: index,
						value: vehicle.id,
						text: vehicle.registrationNumber,
					};
				});
			})
			.catch((error) => {
				handleError('Documents.tsx => getVehicles()', error);
			});
	};

	interface DateInputHandleOnChange {
		name: string;
		value: string;
	}

	const handleExpiryDateChange = (event: React.SyntheticEvent<HTMLElement>, data: DateInputHandleOnChange) => {
		if (data.name === 'expiryStart') {
			setActiveFilters({ ...activeFilters, expiryStartDate: data.value });
		}
		if (data.name === 'expiryEnd') {
			setActiveFilters({ ...activeFilters, expiryEndDate: data.value });
		}
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(e.target.value);
	};

	const closePreviewModal = () => {
		setPreview(false);
	};

	const openFilterModal = () => {
		setActiveFilters({
			organisations: filteredOrgs,
			vehicleGroups: filteredVehicleGroups,
			vehicles: filteredVehicles,
			documentType: filteredDocumentType,
			expiryStartDate: expiryStart ? expiryStart : '',
			expiryEndDate: expiryEnd ? expiryEnd : '',
			isExpired: isExpired,
		});
		setFilterModalOpen(true);
	};

	const closeFilterModal = () => {
		setActiveFilters({
			organisations: [],
			vehicleGroups: [],
			vehicles: [],
			documentType: [],
			expiryStartDate: '',
			expiryEndDate: '',
			isExpired: false,
		});
		setFilterModalOpen(false);
	};

	const resetFilterModal = () => {
		setActiveFilters({
			organisations: [],
			vehicleGroups: [],
			vehicles: [],
			documentType: [],
			expiryStartDate: '',
			expiryEndDate: '',
			isExpired: false,
		});
		setFilterCount(0);
		setFilterModalOpen(true);
		setFilteredOrgs([]);
		setFilteredVehicleGroups([]);
		setFilteredVehicles([]);
		setFilteredDocumentType([]);
		setExpiryStart(null);
		setExpiryEnd(null);
		setIsExpired(false);
	};

	const getVehicleIcon = (vehicleType: string) => {
		return vehicleType === 'Car'
			? CarImage
			: vehicleType === 'Bike'
			  ? BikeImage
			  : vehicleType === 'Truck'
			    ? TruckImage
			    : vehicleType === 'Construction Machinery'
			      ? ConstructionImage
			      : vehicleType === 'Bus'
			        ? BusImage
			        : vehicleType === 'Generator'
			          ? GeneratorImg
			          : vehicleType === 'Container'
			            ? ContainerImg
			            : vehicleType === 'Mini Truck'
			              ? MiniTruck
			              : vehicleType === 'Auto'
			                ? AutoImg
			                : CarImage;
	};

	const handleDocumentHeaderFilters = (docType: string) => {
		if (!filteredDocumentType.includes(docType)) {
			const localArray = filteredDocumentType;
			localArray.push(docType);
			setFilteredDocumentType(localArray);

			setExpiryStart(moment().format('DD-MM-YYYY'));
			setExpiryEnd(moment().add(1, 'M').format('DD-MM-YYYY'));
			setFilterCount(filterCount + 1);
		} else {
			const localList = filteredDocumentType.filter((item) => item !== docType);
			setFilteredDocumentType(localList);
			setExpiryStart(null);
			setExpiryEnd(null);
			setFilterCount(localList.length);
		}
	};

	const setFilters = () => {
		setFilteredOrgs(activeFilters.organisations);
		setFilteredVehicleGroups(activeFilters.vehicleGroups);
		setFilteredVehicles(activeFilters.vehicles);
		setFilteredDocumentType(activeFilters.documentType);
		setExpiryStart(activeFilters.expiryStartDate);
		setExpiryEnd(activeFilters.expiryEndDate);
		setIsExpired(activeFilters.isExpired);
	};

	return (
		<Container>
			{!searchQuery && initialLoading ? (
				<TrackNerdLoader />
			) : (
				<MainContainer>
					<CategoryHeader>
						<SearchContainer>
							<Input
								value={searchQuery}
								style={{ width: '20em' }}
								{...(searchQuery && {
									icon: (
										<Icon
											name="times circle outline"
											link
											onClick={() => {
												setSearchQuery('');
												getFilteredDocuments();
											}}
										/>
									),
								})}
								placeholder="Search Vehicle/ Organisation..."
								disabled={!searchQuery && loading}
								onChange={handleSearch}
							/>
						</SearchContainer>

						<DocumentTypeBlock color="#E5F6FD">
							<Button
								fluid
								disabled={!expiryCounterHeader.roadTax}
								onClick={() => {
									handleDocumentHeaderFilters('Road Tax');
								}}>
								<Icon style={{ marginBottom: '2%' }} name="shield alternate" link />
								<DocumentTypeHeader>
									<span>Road Tax</span>
								</DocumentTypeHeader>
								<ToBeExpired>
									<Icon style={{ marginBottom: '0.3em' }} name="calendar alternate" link />
									<span>{expiryCounterHeader.roadTax}</span>
								</ToBeExpired>
							</Button>
						</DocumentTypeBlock>

						<DocumentTypeBlock color="#E2F6E9">
							<Button
								fluid
								disabled={!expiryCounterHeader.vehicleFitness}
								onClick={() => {
									handleDocumentHeaderFilters('Vehicle Fitness');
								}}>
								<Icon style={{ marginBottom: '2%' }} name="shield alternate" link />
								<DocumentTypeHeader>
									<span>Fitness</span>
								</DocumentTypeHeader>
								<ToBeExpired>
									<Icon style={{ marginBottom: '0.3em' }} name="calendar alternate" link />
									<span>{expiryCounterHeader.vehicleFitness}</span>
								</ToBeExpired>
							</Button>
						</DocumentTypeBlock>

						<DocumentTypeBlock color="#FDF0E9">
							<Button
								fluid
								disabled={!expiryCounterHeader.vehicleInsurance}
								onClick={() => {
									handleDocumentHeaderFilters('Vehicle Insurance');
								}}>
								<Icon style={{ marginBottom: '2%' }} name="shield alternate" link />
								<DocumentTypeHeader>
									<span>Insurance</span>
								</DocumentTypeHeader>
								<ToBeExpired>
									<Icon style={{ marginBottom: '0.3em' }} name="calendar alternate" link />
									<span>{expiryCounterHeader.vehicleInsurance}</span>
								</ToBeExpired>
							</Button>
						</DocumentTypeBlock>

						<DocumentTypeBlock color="#D9D3FC">
							<Button
								fluid
								disabled={!expiryCounterHeader.vehicleRegistration}
								onClick={() => {
									handleDocumentHeaderFilters('Vehicle Registration');
								}}>
								<Icon style={{ marginBottom: '2%' }} name="shield alternate" link />
								<DocumentTypeHeader>
									<span>Registration</span>
								</DocumentTypeHeader>
								<ToBeExpired>
									<Icon style={{ marginBottom: '0.3em' }} name="calendar alternate" link />
									<span>{expiryCounterHeader.vehicleRegistration}</span>
								</ToBeExpired>
							</Button>
						</DocumentTypeBlock>

						<DocumentTypeBlock color="#E9EBFD">
							<Button
								fluid
								disabled={!expiryCounterHeader.puc}
								onClick={() => {
									handleDocumentHeaderFilters('PUC');
								}}>
								<Icon style={{ marginBottom: '2%' }} name="shield alternate" link />
								<DocumentTypeHeader>
									<span>PUC</span>
								</DocumentTypeHeader>
								<ToBeExpired>
									<Icon style={{ marginBottom: '0.3em' }} name="calendar alternate" link />
									<span>{expiryCounterHeader.puc}</span>
								</ToBeExpired>
							</Button>
						</DocumentTypeBlock>

						<Filter>
							<Button
								icon
								disabled={loading}
								primary={filterCount <= 0}
								style={{ backgroundColor: filterCount > 0 ? 'orange' : 'inherit', color: '#fff' }}
								onClick={() => {
									{
										isSuperAdmin ? getOrganisations() : getVehicleGroups();
									}
									openFilterModal();
								}}>
								<Icon name="filter" />
							</Button>
						</Filter>
					</CategoryHeader>
					<MainContent>
						{vehicles !== null && vehicles.length > 0 ? (
							<>
								<CardContainer id="CardContainer">
									{vehicles.map((vehicle, index: number) => {
										return (
											<Card key={index}>
												<VehicleInfo>
													<img src={getVehicleIcon(vehicle.type)} alt="" />
													<section style={{ width: '80%', paddingRight: '5px' }}>
														<VehicleNumber>
															<span>{vehicle.registrationNumber}</span>
														</VehicleNumber>
														<OrgName>
															<span>{vehicle.org}</span>
														</OrgName>
													</section>
												</VehicleInfo>
												<VerticalBlock>
													{(filteredDocumentType.length === 0 || filteredDocumentType.includes('Road Tax')) && (
														<Popup
															basic
															style={{ fontSize: '0.85em', flex: 1 }}
															content={
																vehicle.roadTaxDocument !== null
																	? vehicle.roadTaxDocument.expiry
																		? `Road Tax expires on ${vehicle.roadTaxDocument.expiry}`
																		: 'Open to view Road Tax'
																	: 'Please upload Road Tax'
															}
															trigger={
																<DocumentBlock
																	onClick={() => {
																		if (vehicle.roadTaxDocument)
																			fetchDocumentLink(
																				vehicle.roadTaxDocument.docId,
																				`${vehicle.registrationNumber.replaceAll(' ', '')} - Road Tax`
																			);
																	}}
																	color="#E5F6FD"
																	fontStyle={vehicle.roadTaxDocument !== null ? 'normal' : 'italic'}
																	fontColor={vehicle.roadTaxDocument !== null ? 'black' : '#909B9E'}>
																	<Icon name="shield alternate" style={{ marginBottom: '2%' }} link />
																	<DocumentName>
																		<span>Road Tax</span>
																	</DocumentName>
																	<DocumentExpiryDate>
																		{vehicle.roadTaxDocument !== null ? vehicle.roadTaxDocument.expiry : 'not uploaded'}
																	</DocumentExpiryDate>
																</DocumentBlock>
															}
														/>
													)}
													{(filteredDocumentType.length === 0 || filteredDocumentType.includes('Vehicle Fitness')) && (
														<Popup
															basic
															style={{ fontSize: '0.85em' }}
															content={
																vehicle.fitnessDocument !== null
																	? vehicle.fitnessDocument.expiry
																		? `Fitness Certificate expires on ${vehicle.fitnessDocument.expiry}`
																		: 'Open to view Road Tax'
																	: 'Please upload vehicle Fitness Certificate'
															}
															trigger={
																<DocumentBlock
																	onClick={() => {
																		if (vehicle.fitnessDocument)
																			fetchDocumentLink(
																				vehicle.fitnessDocument.docId,
																				`${vehicle.registrationNumber.replaceAll(' ', '')} - Vehicle Fitness`
																			);
																	}}
																	color="#E2F6E9"
																	fontStyle={vehicle.fitnessDocument !== null ? 'normal' : 'italic'}
																	fontColor={vehicle.fitnessDocument !== null ? 'black' : '#909B9E'}>
																	<Icon name="shield alternate" style={{ marginBottom: '2%' }} link />
																	<DocumentName>
																		<span>Fitness</span>
																	</DocumentName>
																	<DocumentExpiryDate>
																		{vehicle.fitnessDocument !== null ? vehicle.fitnessDocument.expiry : 'not uploaded'}
																	</DocumentExpiryDate>
																</DocumentBlock>
															}
														/>
													)}
													{(filteredDocumentType.length === 0 ||
														filteredDocumentType.includes('Vehicle Insurance')) && (
														<Popup
															basic
															style={{ fontSize: '0.85em' }}
															content={
																vehicle.insuranceDocument !== null
																	? vehicle.insuranceDocument.expiry
																		? `Insurance expires on ${vehicle.insuranceDocument.expiry}`
																		: 'Open to view Insurance'
																	: 'Please upload Insurance'
															}
															trigger={
																<DocumentBlock
																	onClick={() => {
																		if (vehicle.insuranceDocument)
																			fetchDocumentLink(
																				vehicle.insuranceDocument.docId,
																				`${vehicle.registrationNumber.replaceAll(' ', '')} - Vehicle Insurance`
																			);
																	}}
																	color="#FDF0E9"
																	fontStyle={vehicle.insuranceDocument !== null ? 'normal' : 'italic'}
																	fontColor={vehicle.insuranceDocument !== null ? 'black' : '#909B9E'}>
																	<Icon name="shield alternate" style={{ marginBottom: '2%' }} link />
																	<DocumentName>
																		<span>Insurance</span>
																	</DocumentName>
																	<DocumentExpiryDate>
																		{vehicle.insuranceDocument !== null
																			? vehicle.insuranceDocument.expiry
																			: 'not uploaded'}
																	</DocumentExpiryDate>
																</DocumentBlock>
															}
														/>
													)}
													{(filteredDocumentType.length === 0 ||
														filteredDocumentType.includes('Vehicle Registration')) && (
														<Popup
															basic
															style={{ fontSize: '0.85em' }}
															content={
																vehicle.registrationDocument !== null
																	? vehicle.registrationDocument.expiry
																		? `Vehicle Registration expires on ${vehicle.registrationDocument.expiry}`
																		: 'Open to view Registration Document'
																	: 'Please upload Vehicle Registration'
															}
															trigger={
																<DocumentBlock
																	onClick={() => {
																		if (vehicle.registrationDocument)
																			fetchDocumentLink(
																				vehicle.registrationDocument.docId,
																				`${vehicle.registrationNumber.replaceAll(' ', '')} - Vehicle Registration`
																			);
																	}}
																	color="#D9D3FC"
																	fontStyle={vehicle.registrationDocument !== null ? 'normal' : 'italic'}
																	fontColor={vehicle.registrationDocument !== null ? 'black' : '#909B9E'}>
																	<Icon name="shield alternate" style={{ marginBottom: '2%' }} link />
																	<DocumentName>
																		<span>Registration</span>
																	</DocumentName>
																	<DocumentExpiryDate>
																		{vehicle.registrationDocument !== null
																			? vehicle.registrationDocument.expiry
																			: 'not uploaded'}
																	</DocumentExpiryDate>
																</DocumentBlock>
															}
														/>
													)}
													{(filteredDocumentType.length === 0 || filteredDocumentType.includes('PUC')) && (
														<Popup
															basic
															style={{ fontSize: '0.85em' }}
															content={
																vehicle.pucDocument !== null
																	? vehicle.pucDocument.expiry
																		? `PUC expires on ${vehicle.pucDocument.expiry}`
																		: 'Open to view PUC Document'
																	: 'Please upload PUC'
															}
															trigger={
																<DocumentBlock
																	onClick={() => {
																		if (vehicle.pucDocument)
																			fetchDocumentLink(
																				vehicle.pucDocument.docId,
																				`${vehicle.registrationNumber.replaceAll(' ', '')} - PUC`
																			);
																	}}
																	color="#E9EBFD"
																	fontStyle={vehicle.pucDocument !== null ? 'normal' : 'italic'}
																	fontColor={vehicle.pucDocument !== null ? 'black' : '#909B9E'}>
																	<Icon name="shield alternate" style={{ marginBottom: '2%' }} link />
																	<DocumentName>
																		<span>PUC</span>
																	</DocumentName>
																	<DocumentExpiryDate>
																		{vehicle.pucDocument !== null ? vehicle.pucDocument.expiry : 'not uploaded'}
																	</DocumentExpiryDate>
																</DocumentBlock>
															}
														/>
													)}
												</VerticalBlock>
											</Card>
										);
									})}
								</CardContainer>
								<CategoryFooter>
									<Expander />
									<PageSelector>
										<Dropdown
											fluid
											selection
											defaultValue={dataSizeLimit}
											disabled={loading || !vehicles?.length}
											options={PAGE_COUNT_OPTIONS}
											onChange={async (e, data: DropdownProps) => {
												if (data?.value) {
													const numericValue = Number(data.value); // Convert to number
													if (!isNaN(numericValue)) {
														// Check if it's a valid number
														handleDataSizeLimitChange(numericValue);
													}
												}
											}}
										/>
									</PageSelector>
									<Pagination
										activePage={pageNumber}
										disabled={loading || !vehicles?.length}
										ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
										firstItem={{ content: <Icon name="angle double left" />, icon: true }}
										lastItem={{ content: <Icon name="angle double right" />, icon: true }}
										nextItem={null}
										onPageChange={(event, data: PaginationProps) => {
											const inputValue = data.activePage; // Assuming data.activePage is of type string or number or undefined

											if (typeof inputValue === 'number') {
												// If it's already a number, you can pass it directly
												getFilteredDocuments(inputValue);
											} else if (typeof inputValue === 'string') {
												// If it's a string, attempt to parse it as a number
												const numericValue = parseInt(inputValue, 10); // or parseFloat(inputValue) for decimal numbers

												if (!isNaN(numericValue)) {
													// Check if parsing was successful
													getFilteredDocuments(numericValue);
												}
											}
										}}
										prevItem={null}
										totalPages={Math.ceil(count / dataSizeLimit)}
									/>
								</CategoryFooter>
							</>
						) : (
							<NoMatchFoundContainer>
								<Segment placeholder style={{ height: '100%', backgroundColor: `${THEME.COLORS.white}` }}>
									<Header icon>
										<img src={NoResult} alt={'no match'} />
										No Document Found
									</Header>
								</Segment>
							</NoMatchFoundContainer>
						)}
					</MainContent>

					{filterModalOpen && (
						<Modal size="small" open={filterModalOpen} onClose={() => closeFilterModal()}>
							<Modal.Header>Filter Documents</Modal.Header>
							<Modal.Content scrolling={true}>
								<Form>
									<Form.Group widths="equal">
										{isSuperAdmin ? (
											<Form.Field>
												<label>Organisations</label>
												<Dropdown
													placeholder="Choose..."
													fluid
													search
													multiple
													selection
													value={activeFilters.organisations}
													options={organisationsForFilter}
													onChange={async (e, data: DropdownProps) => {
														if (data?.value) {
															const organisationsArray = Array.isArray(data.value)
																? [...new Set(data.value.map((item) => Number(item)))]
																: [Number(data.value)];

															setActiveFilters({
																...activeFilters,
																organisations: organisationsArray,
															});
															const queryParams = `?dropdown=true&organisations[]=${organisationsArray.join(
																'&organisations[]='
															)}`;

															const vehicles = (await getVehicles(queryParams)) as VehiclesForFilter[];
															if (vehicles) setVehiclesForFilter(vehicles);
														}
													}}
												/>
											</Form.Field>
										) : (
											<Form.Field>
												<label>VehicleGroups</label>
												<Dropdown
													placeholder="Choose..."
													fluid
													search
													multiple
													selection
													value={activeFilters.vehicleGroups}
													options={vehiclesGroupsForFilter}
													onChange={async (e, data: DropdownProps) => {
														const vehicleGroupsArray = Array.isArray(data.value)
															? data.value.map((item) => Number(item))
															: [Number(data.value)];
														setActiveFilters({
															...activeFilters,
															vehicleGroups: vehicleGroupsArray,
														});
														const queryParams = `?dropdown=true&vehicleGroups[]=${(data.value as string[]).join(
															'&vehicleGroups[]='
														)}`;
														const response = (await getVehicles(queryParams)) as VehiclesForFilter[];
														setVehiclesForFilter(response);
													}}
												/>
											</Form.Field>
										)}

										<Form.Field>
											<label>Vehicle</label>
											<Dropdown
												placeholder="Choose ..."
												fluid
												search
												multiple
												selection
												value={activeFilters.vehicles}
												options={vehiclesForFilter}
												onChange={(e, { value }: DropdownProps) => {
													if (Array.isArray(value) && value.every((item) => typeof item === 'number')) {
														const numbers = (value || []).map(Number); // Ensure value is an array of numbers
														setActiveFilters({ ...activeFilters, vehicles: numbers });
													}
													// setActiveFilters({ ...activeFilters, vehicles: value });
												}}
											/>
										</Form.Field>
									</Form.Group>
									<Form.Group widths="equal">
										<Form.Field>
											<label>Document Type</label>
											<Dropdown
												placeholder="Choose ..."
												fluid
												search
												multiple
												selection
												value={activeFilters.documentType}
												options={DOCUMENTS_TYPE_OPTIONS}
												onChange={(e, { value }) => {
													if (Array.isArray(value) && value.every((item) => typeof item === 'string')) {
														const values = (value || []).map(String); // Ensure value is an array of strings
														setActiveFilters({ ...activeFilters, documentType: values });
													}
													/*setActiveFilters({
                                                        ...activeFilters,
                                                        documentType: value,
                                                    });*/
												}}
											/>
										</Form.Field>
										<Form.Field>
											<label>Is Expired</label>
											<Checkbox
												toggle
												checked={activeFilters.isExpired}
												onChange={(event, { checked }: CheckboxProps) => {
													if (checked && typeof checked === 'boolean')
														setActiveFilters({
															...activeFilters,
															isExpired: checked,
														});
												}}
											/>
										</Form.Field>
									</Form.Group>
									<Form.Group widths="equal">
										<Form.Field>
											<label>Expiry Start</label>
											<DateInput
												name="expiryStart"
												placeholder="Select"
												fluid
												closable
												disabled={false}
												value={activeFilters.expiryStartDate}
												dateFormat="DD-MM-YYYY"
												minDate={moment()}
												onChange={handleExpiryDateChange}
											/>
										</Form.Field>
										<Form.Field>
											<label>Expiry End</label>
											<DateInput
												name="expiryEnd"
												placeholder="Select"
												fluid
												closable
												disabled={!activeFilters.expiryStartDate}
												value={activeFilters.expiryEndDate}
												dateFormat="DD-MM-YYYY"
												minDate={moment()}
												onChange={handleExpiryDateChange}
											/>
										</Form.Field>
									</Form.Group>
								</Form>
							</Modal.Content>
							<Modal.Actions style={{ display: 'flex' }}>
								<Button
									onClick={resetFilterModal}
									disabled={
										activeFilters.organisations?.length === 0 &&
										activeFilters.vehicleGroups?.length === 0 &&
										activeFilters.vehicles?.length === 0 &&
										activeFilters.documentType?.length === 0 &&
										activeFilters.expiryEndDate === null &&
										activeFilters.isExpired === false
									}>
									Reset
								</Button>
								<Expander />
								<Button color="black" onClick={closeFilterModal}>
									Cancel
								</Button>
								<Button
									disabled={
										activeFilters.organisations?.length === 0 &&
										activeFilters.vehicleGroups?.length === 0 &&
										activeFilters.vehicles?.length === 0 &&
										activeFilters.documentType?.length === 0 &&
										activeFilters.expiryEndDate === null &&
										activeFilters.isExpired === false
									}
									positive
									onClick={() => {
										setFilters();
										setFilterCount(filterCount + 1);
										closeFilterModal();
									}}
									loading={loading}>
									Filter
								</Button>
							</Modal.Actions>
						</Modal>
					)}

					{preview &&
						previewSource &&
						(previewType === 'application/pdf' ? (
							<PdfView source={previewSource} filename={fileName} onClose={closePreviewModal} />
						) : (
							<ImageView visible={preview} source={previewSource} filename={fileName} onClose={closePreviewModal} />
						))}
				</MainContainer>
			)}
			{loading && !initialLoading && <TransitionLoader />}
		</Container>
	);
}

export default Documents;
