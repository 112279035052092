import styled from 'styled-components';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../../../../constants/constants';

export const Container = styled.section`
	width: 100%;
	height: 100%;

	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	background: #fafbfc;
`;

export const Content = styled.section`
	width: 100%;
	height: calc(100% - calc(${HEADER_HEIGHT} + ${FOOTER_HEIGHT}));

	overflow-y: auto;
`;

export const CardContainer = styled.section`
	box-sizing: border-box;
	padding: 0.75em 1em;

	.viewAddress {
		text-overflow: ellipsis;
		overflow: hidden;
		width: 12em;
		color: black;

		&:hover {
			color: blue;
			text-decoration: underline;
		}
	}
`;

export const Card = styled.section`
	width: 100%;

	margin: 0.25em 0;
	box-sizing: border-box;
	padding: 1em;

	border: 1px solid #ddd;
	border-radius: 10px;
	background: #fff;

	cursor: pointer;

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
	}
`;

export const Title = styled.section`
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;

	h4 {
		margin: 0;
	}
`;

export const BlockContainer = styled.section`
	width: 100%;
	display: flex;
	padding: 0.5em;
	justify-content: space-between;
`;

interface Blockprops {
	isDisabled?: boolean;
}

export const Block = styled.section<Blockprops>`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	font-size: 0.85em;

	color: ${(props) => props.isDisabled && '#aaa'};

	span {
		font-size: 1.2em;
		font-weight: bold;
		word-break: break-all;
	}
`;

export const Row = styled.section`
	width: 100%;
	min-width: 500px;

	display: flex;
	align-items: center;
	flex-wrap: wrap;

	margin: 0.25em 0;

	div {
		margin-right: 1em !important;
	}

	i.icon,
	i.icons {
		margin: 0 0.5em 0 1em;
	}

	a {
		width: 12em;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		color: black;

		&:hover {
			color: blue;
			text-decoration: underline;
		}
	}
`;

export const Expander = styled.section`
	flex-grow: 1;
`;

export const StoppageContainer = styled.section`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	span {
		font-size: 0.9em;
		font-weight: bold;
		color: ${(props) => props.theme.COLORS.inBetweenTripsRegNumLabel};
	}

	h4 {
		font-size: 1em;
		font-weight: bold;

		margin: 0.5em 0;

		color: ${(props) => props.theme.COLORS.inBetweenTripsRegNumLabel};
	}
`;

export const TripRouteContainer = styled.section`
	width: 100%;
	height: 100%;

	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
`;

export const PageSelector = styled.section`
	width: 6em;

	margin-right: 1em;

	border: none;
	border-radius: 5px;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0 1px 2px 0,
		rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
`;
