import React, { useEffect, useState } from 'react';
import { ASSET_VERIFICATION_API } from '../../constants/api';
import EmailVerified from './EmailVerified';
import EmailVerificationExpired from './EmailVerificationExpired';
import TrackNerdLoader from '../loader/Loader';
import styled from 'styled-components';
import axios from 'axios';
import { handleError } from '../../helper/view specific/messaging';

interface PropType {
	url: string;
}

const AssetVerification = (props: PropType) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [verified, setVerified] = useState<boolean>(false);
	const [assetType, setAssetType] = useState<number>(-1);

	useEffect(() => {
		const hash = new URLSearchParams(props.url).get('hash');
		if (hash !== null) verifyAsset(hash);
	}, []);

	const verifyAsset = (hash: string) => {
		axios
			.post(
				ASSET_VERIFICATION_API,
				{
					hash,
				},
				{}
			)
			.then((res) => {
				setLoading(false);
				setVerified(res?.data?.verified);
				setAssetType(res?.data?.assetType);
			})
			.catch((error) => {
				setLoading(false);
				handleError('AssetVerification.tsx => verifyAsset(): ', error);
			});
	};
	return (
		<Container>
			{loading ? (
				<TrackNerdLoader />
			) : verified ? (
				<EmailVerified assetType={assetType} />
			) : (
				<EmailVerificationExpired />
			)}
		</Container>
	);
};

export default AssetVerification;

export const Container = styled.section`
	width: 100%;
	height: calc(100vh);

	display: block;
	align-items: center;
	justify-content: center;

	position: relative;
`;
