interface uiState {
	freshDeskWidget: boolean;
	sidebarVisible: boolean;
	pageNumber: number;
	dataSizeLimit: number;
}

interface uiAction {
	type: string;
	payload?: object;
}

export default function ui(
	initialState: uiState = {
		freshDeskWidget: true,
		sidebarVisible: true,
		dataSizeLimit: 100,
		pageNumber: 1,
	},
	action: uiAction
) {
	switch (action.type) {
		case 'HIDE_FRESHDESK_WIDGET':
			return {
				...initialState,
				freshDeskWidget: false,
			};

		case 'SHOW_FRESHDESK_WIDGET':
			return {
				...initialState,
				freshDeskWidget: true,
			};
		case 'SHOW_SIDEBAR':
			return {
				...initialState,
				sidebarVisible: action.payload,
			};

		case 'SET_DATA_SIZE_LIMIT':
			return {
				...initialState,
				dataSizeLimit: action.payload,
			};

		case 'SET_PAGE_LIMIT':
			return {
				...initialState,
				pageNumber: action.payload,
			};
		default:
			return initialState;
	}
}
