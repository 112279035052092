import { isoToCustom } from '../utils/calendar';
import { User } from '../types/user';
import { Document } from '../types/document';

export interface DriverList {
	driverId: number;
	driverName: string;
	orgName: string;
	vehicleRegistrationNumber: string | null;
	driverMobileNumber: string | null;
	driverEmail: string | null;
	vehicleType: string | null;
	aadhaarDocument: Document | null;
	drivingLicenseDocument: Document | null;
}

export function createDriversDTO(response: User[]) {
	const driversList: DriverList[] = [];

	if (response !== undefined && response.length) {
		response.forEach((driver: User) => {
			let aadhaarDocument = null;
			let drivingLicenseDocument = null;

			//getting documents
			driver.documents.length > 0 &&
				driver.documents.forEach((document) => {
					const obj = {
						docId: document.id,
						docType: document.type,
						expiry: isoToCustom(document.expiry, 'DD-MM-YYYY'),
						docPath: document.path,
					};
					if (document.type === 'Aadhaar') aadhaarDocument = obj;
					else if (document.type === 'Driving License') drivingLicenseDocument = obj;
				});

			//driver objects array containing multiple documents
			driversList.push({
				driverName: driver.name,
				orgName: driver.organisation.name,
				vehicleRegistrationNumber: driver?.driverVehicle ? driver?.driverVehicle.registrationNumber : null,
				driverMobileNumber: driver?.phone ? driver?.phone : null,
				driverEmail: driver?.email ? driver?.email : null,
				driverId: driver.id,
				vehicleType: driver?.driverVehicle ? driver?.driverVehicle.type : null,
				aadhaarDocument: aadhaarDocument,
				drivingLicenseDocument: drivingLicenseDocument,
			});
		});
	}

	return driversList;
}
