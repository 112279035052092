import moment from 'moment';
import { Trips } from '../types/trip';

export interface StartIotData {
	totalOdometer: string | number;
	latitude: number;
	longitude: number;
	externalPowerVoltage: number;
}

export interface EndIotData {
	totalOdometer: string | number;
	latitude: number;
	longitude: number;
	externalPowerVoltage: number;
}

export interface TripDTO {
	id: number;
	status: number;
	startTime: string;
	endTime: string;
	startAddress: string;
	endAddress: string;
	distance: string;
	engineHours: string;
	movementHours: string;
	idleHours: string;
	averageSpeed: string;
	startIotData: StartIotData;
	endIotData: EndIotData;
	maxSpeed: string;
	odoDiffernce: string | number;
	vehicleId: number;
	regNum: string;
}

export function createTripsDTO(data: Trips[]): TripDTO[] | null {
	const trips: TripDTO[] = [];

	if (data !== undefined && data.length !== 0) {
		data.forEach((trip) => {
			if (trip.status === 3) {
				trips.push({
					id: trip.id,
					status: trip.status,
					startTime: trip.startTimestamp,
					endTime: trip.endTimestamp,
					startAddress: trip.startAddress,
					endAddress: trip.endAddress,
					distance: Number((trip.distance / 1000).toFixed(2)).toLocaleString(),
					engineHours:
						trip.engineHours !== null ? formatTime(trip.engineHours < 0 ? 0 : trip.engineHours) : trip.engineHours,
					movementHours:
						trip.movementHours !== null
							? formatTime(trip.movementHours < 0 ? 0 : trip.movementHours)
							: trip.movementHours,
					idleHours: trip.idleHours !== null ? formatTime(trip.idleHours < 0 ? 0 : trip.idleHours) : trip.idleHours,
					averageSpeed: trip.averageSpeed.toFixed(1),
					startIotData: {
						totalOdometer: trip?.startIotData?.totalOdometer
							? Number((trip.startIotData.totalOdometer / 1000).toFixed(1)).toLocaleString()
							: 0,
						latitude: trip?.startIotData?.latitude ? trip.startIotData.latitude : 0,
						longitude: trip?.startIotData?.longitude ? trip.startIotData.longitude : 0,
						externalPowerVoltage: trip?.startIotData?.externalPowerVoltage
							? parseFloat((trip.startIotData.externalPowerVoltage / 1000).toFixed(2))
							: 0,
					},
					endIotData: {
						totalOdometer: trip?.endIotData?.totalOdometer
							? Number((trip.endIotData.totalOdometer / 1000).toFixed(1)).toLocaleString()
							: 0,
						latitude: trip?.endIotData?.latitude ? trip.endIotData.latitude : 0,
						longitude: trip?.endIotData?.longitude ? trip.endIotData.longitude : 0,
						externalPowerVoltage: trip?.endIotData?.externalPowerVoltage
							? parseFloat((trip.endIotData.externalPowerVoltage / 1000).toFixed(2))
							: 0,
					},
					maxSpeed: trip.topSpeed.toFixed(1),
					odoDiffernce:
						trip?.startIotData?.totalOdometer && trip?.endIotData?.totalOdometer
							? ((trip?.endIotData?.totalOdometer - trip?.startIotData?.totalOdometer) / 1000).toFixed(1)
							: 'N/A',
					vehicleId: trip.vehicle.id,
					regNum: trip?.vehicle?.registrationNumber ? trip.vehicle.registrationNumber : '',
				});
			}
		});
	}

	return trips.length !== 0 ? trips : null;
}

function formatTime(seconds: number): string {
	const duration = moment.duration(seconds, 'seconds');
	return moment.utc(moment.duration(duration, 'seconds').asMilliseconds()).format('HH:mm:ss [s]');
}
