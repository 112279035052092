import { PermissionsAction, PermissionsState } from '../Type/rolePermissionType';

const initialState: PermissionsState = {
	permissions: {
		Vehicle: {
			create: false,
			read: false,
			manage: false,
			update: false,
			delete: false,
		},
		Document: {
			create: false,
			read: false,
			manage: false,
			update: false,
			delete: false,
		},
		Geofence: {
			create: false,
			read: false,
			manage: false,
			update: false,
			delete: false,
		},
		User: {
			create: false,
			read: false,
			manage: false,
			update: false,
			delete: false,
		},
		Organisation: {
			create: false,
			read: false,
			manage: false,
			update: false,
			delete: false,
		},
		Report: {
			create: false,
			read: false,
			manage: false,
			update: false,
			delete: false,
		},
	},
};
export default function rolePermissions(state = initialState, action: PermissionsAction) {
	switch (action.type) {
		case 'PERMISSIONS':
			return {
				...state,
				permissions: action.payload,
			};
		default:
			return state;
	}
}
