import styled from 'styled-components';

interface MainContainerProps {
	sidebarVisible: boolean;
}

export const MainContainer = styled.section<MainContainerProps>`
	width: ${(props) => (props.sidebarVisible ? 'calc(100% - 24em)' : '100%')};
	height: 100%;

	position: relative;

	.ui.menu {
		height: 3.25em;
		width: 100%;

		margin: 0;

		box-shadow: none;
		border-bottom: 1px solid #eee;
		border-radius: 0;
	}

	.ui.secondary.pointing.menu {
		border-bottom: 1px solid #eee;
		justify-content: ${(props) => !props.sidebarVisible && 'space-evenly'};
	}

	.ui.segment {
		height: calc(100% - 3.25em);
		width: 100%;

		margin: 0;
		padding: 0;

		box-shadow: none;
		border-radius: 0;
		//border: none;
	}
`;
