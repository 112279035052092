import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Container, ShortcutsText } from './ShortcutsStyles';
import Store from '../../../redux/store';
import { RootState } from '../../../redux/RootState';

const Shortcuts = () => {
	const { initialDate, finalDate, disableDatePicker } = useSelector((state: RootState) => state.dates);
	const handleToday = () => {
		Store.dispatch({
			type: 'SET_DATES',
			payload: {
				initialDate: moment().startOf('day').toISOString(),
				finalDate: moment().endOf('day').toISOString(),
			},
		});
		Store.dispatch({
			type: 'SET_PAGE_LIMIT',
			payload: 1,
		});
	};

	const handleYesterday = () => {
		Store.dispatch({
			type: 'SET_DATES',
			payload: {
				initialDate: moment().subtract(1, 'day').startOf('day').toISOString(),
				finalDate: moment().subtract(1, 'day').endOf('day').toISOString(),
			},
		});
		Store.dispatch({
			type: 'SET_PAGE_LIMIT',
			payload: 1,
		});
	};

	const handleLast3days = () => {
		Store.dispatch({
			type: 'SET_DATES',
			payload: {
				initialDate: moment().subtract(2, 'days').startOf('day').toISOString(),
				finalDate: moment().endOf('day').toISOString(),
			},
		});
		Store.dispatch({
			type: 'SET_PAGE_LIMIT',
			payload: 1,
		});
	};

	const handleLast7days = () => {
		Store.dispatch({
			type: 'SET_DATES',
			payload: {
				initialDate: moment().subtract(6, 'days').startOf('day').toISOString(),
				finalDate: moment().endOf('day').toISOString(),
			},
		});
		Store.dispatch({
			type: 'SET_PAGE_LIMIT',
			payload: 1,
		});
	};

	const getActivateShortcuts = () => {
		if (initialDate === moment().startOf('day').toISOString() && finalDate === moment().endOf('day').toISOString()) {
			return 'today';
		} else if (
			initialDate === moment().subtract(1, 'day').startOf('day').toISOString() &&
			finalDate === moment().subtract(1, 'day').endOf('day').toISOString()
		) {
			return 'yesterday';
		} else if (
			initialDate === moment().subtract(2, 'day').startOf('day').toISOString() &&
			finalDate === moment().endOf('day').toISOString()
		) {
			return 'last3days';
		} else if (
			initialDate === moment().subtract(6, 'days').startOf('day').toISOString() &&
			finalDate === moment().endOf('day').toISOString()
		) {
			return 'last7days';
		} else return '';
	};
	return (
		<Container>
			<ShortcutsText
				onClick={() => {
					if (!disableDatePicker) handleLast7days();
				}}
				active={getActivateShortcuts() === 'last7days'}>
				Last 7 Days
			</ShortcutsText>
			<ShortcutsText
				onClick={() => {
					if (!disableDatePicker) handleLast3days();
				}}
				active={getActivateShortcuts() === 'last3days'}>
				Last 3 Days
			</ShortcutsText>
			<ShortcutsText
				onClick={() => {
					if (!disableDatePicker) handleYesterday();
				}}
				active={getActivateShortcuts() === 'yesterday'}>
				Yesterday
			</ShortcutsText>
			<ShortcutsText
				onClick={() => {
					if (!disableDatePicker) handleToday();
				}}
				active={getActivateShortcuts() === 'today'}>
				Today
			</ShortcutsText>
		</Container>
	);
};

// Connect to Store
/*const mapStateToProps = (state) => ({
  initialDate: state.dates.initialDate,
  finalDate: state.dates.finalDate,
  disableDatePicker: state.dates.disableDatePicker
});*/

export default Shortcuts;
