import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Popup, SemanticICONS } from 'semantic-ui-react';

import { Container } from './DashboardNavListItemStyles';
import { THEME } from '../../constants/Theme';

interface PropType {
	urlPrefix?: string;
	exact?: boolean;
	iconName?: string;
	popupTitle?: string;
}

const DashboardNavListItem = (props: PropType) => {
	return (
		<Container>
			<Popup
				trigger={
					<NavLink
						to={`${props.urlPrefix}`}
						exact={!!props.exact}
						activeStyle={{
							color: THEME.COLORS.theme,
							backgroundColor: THEME.COLORS.activeDashboardLinkBackground,
							borderRadius: '5px',
						}}>
						<Icon name={(props.iconName as SemanticICONS) || 'question'} size="large" />
					</NavLink>
				}
				basic
				content={props.popupTitle || 'No Title'}
				position="right center"
				offset={[0, 10]}
				style={{ borderRadius: '5px' }}
			/>
		</Container>
	);
};

export default DashboardNavListItem;

export const DashboardNavListItemNoPermission = (props: PropType) => {
	return (
		<Container>
			<Popup
				trigger={<Icon name={(props.iconName as SemanticICONS) || 'question'} size="large" />}
				basic
				content={props.popupTitle || 'No Title'}
				position="right center"
				offset={[0, 10]}
				style={{ borderRadius: '5px', backgroundColor: 'red', color: 'white' }}
			/>
		</Container>
	);
};
