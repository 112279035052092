import { Libraries } from '@react-google-maps/api';

export const GOOGLE_MAPS_API_KEY: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
export const GOOGLE_MAPS_API_KEY_TRACKING_LINK: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_TRACKING_LINK || '';

// Google Map Options
export const GOOGLE_MAP_OPTIONS = {
	zoomControl: true,
	zoomControlOptions: { position: 7 },
	mapTypeControl: false,
	fullscreenControl: true,
	rotateControl: false,
	scaleControl: false,
	streetViewControl: false,
	controlSize: 28,
	maxZoom: 18,
};

// Google Map Libraries
export const GOOGLE_MAP_LIBRARIES: Libraries = ['drawing', 'places'];

// Google Map Styles
export const GOOGLE_MAP_DEFAULT_STYLES = {
	width: '100%',
	height: '100%',
};

export const GOOGLE_MAP_DEFAULT_CENTER = {
	lat: 23.098079,
	lng: 83.217008,
};

export const GOOGLE_MAP_DEFAULT_ZOOM = 5;

export const mapLibraries = (process.env.REACT_APP_GOOGLE_LIBS || '').split(',');
