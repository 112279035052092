import React from 'react';
import { Container } from './NoVehicleSelectionStyles';
import { Header, Icon, Segment } from 'semantic-ui-react';

import { THEME } from '../../../../constants/Theme';

const NoVehicleSelection = () => {
	return (
		<Container>
			<Segment placeholder style={{ height: '100%', backgroundColor: `${THEME.COLORS.white}` }}>
				<Header icon>
					<Icon name="truck" />
					Please select at least one vehicle.
				</Header>
			</Segment>
		</Container>
	);
};

export default NoVehicleSelection;
