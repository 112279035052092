import styled from 'styled-components';

// interface propsInterface{
//   top:string,
//   right: string,
//   left:string,
//   bgColor: string,
//   bgColoronhover:string
//   color:string
// }
interface EdgeButtonProps {
	title?: string;
	top?: string;
	left?: string;
	right?: string;
	bgColor?: string;
	color?: string;
	bgColoronhover?: string; // This is optional based on your usage
	onClick: () => void;
}

export const CheckboxAllContainer = styled.section`
	width: 100%;

	margin-top: 1em;
	box-sizing: border-box;
	padding: 0.5em 1em;

	display: flex;
	align-items: center;
`;

export const Expander = styled.section`
	flex-grow: 1;
`;

export const CheckboxContainer = styled.section`
	//margin-right: 1em;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Status = styled.section`
	font-size: 0.9em;
	font-weight: bold;
	color: ${(props) => props.color};
`;

export const EdgeButton = styled.section<EdgeButtonProps>`
	position: absolute;
	top: ${(props) => props.top};
	right: ${(props) => props.right};
	left: ${(props) => props.left};
	z-index: 1;
	cursor: pointer;
	background-color: ${(props) => props.bgColor} !important;
	color: ${(props) => props.color} !important;

	&:hover {
		background-color: ${(props) => props.bgColoronhover};
	}
`;
