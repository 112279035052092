import React, { useEffect, useState } from 'react';
import Status from './status/Status';
import Distance from './distance/Distance';
import { GraphsWrapper, MainContainer } from './OverviewStyles';
import TrackNerdLoader from '../../../components/loader/Loader';
import PerformanceGraph from './performance/PerformanceGraph'; // import { getData } from '../../../../helper/services/axios';
import EngineHour from './engineHour/EngineHour';
import EventCount from './eventCount/EventCount';

export interface mostRunVehicle {
	date: string;
	registrationNumber: string;
	totalFleetDistance: string;
	topVehicleDistance: number;
}

export interface mostIdleVehicle {
	date: string;
	registrationNumber: string;
	totalIdleHours: string;
	idleHours: number;
	movementHours: number;
	engineHours: number;
}

export interface mostEventVehicle {
	date: string;
	registrationNumber: string;
	mainPower: string;
	parking: string;
	sos: string;
	idling: string;
	overspeed: string;
	count: string;
}

export interface mostUsedVehicle {
	registrationNumber: string;
	distance: string;
}

export interface leastUsedVehicle {
	registrationNumber: string;
	distance: string;
}

export interface OverviewProps {
	graphData: {
		mostRunVehicles: mostRunVehicle[];
		mostIdleVehicles: mostIdleVehicle[];
		mostEventVehicles: mostEventVehicle[];
		mostUsedVehicles: mostUsedVehicle[];
		leastUsedVehicles: leastUsedVehicle[];
	};
}

export function Overview(props: OverviewProps) {
	const [loading, setLoading] = useState<boolean>(true);
	const [mvpData, setMvpData] = useState<mostRunVehicle[] | null>(null);
	const [performanceData, setPerformanceData] = useState<mostUsedVehicle[] | null>(null);
	const [idleVehiclesData, setIdleVehiclesData] = useState<mostIdleVehicle[] | null>(null);
	const [eventCountData, setEventCountData] = useState<mostEventVehicle[] | null>(null);

	useEffect(() => {
		setLoading(true);
		if (props.graphData) {
			setMvpData(props?.graphData['mostRunVehicles']);
			setIdleVehiclesData(props?.graphData['mostIdleVehicles']);
			setEventCountData(props?.graphData['mostEventVehicles']);
			setPerformanceData([
				...(props?.graphData['mostUsedVehicles'] || {}),
				...(props?.graphData['leastUsedVehicles'] || {}),
			]);
			setLoading(false);
		}
		// getData(DASHBOARD_SUMMARY_API)
		//   .then((res) => {
		//     setMvpData(res?.data.metadata["mostRunVehicles"]);
		//     setIdleVehiclesData(res?.data.metadata["mostIdleVehicles"]);
		//     setPerformanceData([...res.data.metadata["mostUsedVehicles"], ...res.data.metadata["leastUsedVehicles"]]);
		//     setLoading(false);
		//   })
		//   .catch((error) => {
		//     handleError(error);
		//     setLoading(false);
		//   });
	}, []);

	return (
		<MainContainer>
			{loading ? (
				<TrackNerdLoader />
			) : (
				<GraphsWrapper>
					<Status />
					{mvpData && <Distance data={mvpData} />}
					{performanceData && <PerformanceGraph data={performanceData} />}
					{idleVehiclesData && <EngineHour data={idleVehiclesData} />}
					{eventCountData && <EventCount data={eventCountData} />}
				</GraphsWrapper>
			)}
		</MainContainer>
	);
}

// export default Overview;
