import React, { useRef } from 'react';
import { GraphCard, GraphContainer, GraphHeader } from '../OverviewStyles'; // Theme
import { THEME } from '../../../../constants/Theme';
import { useSelector } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import { NavLink } from 'react-router-dom';
import { dispatch } from '../../../../helper/view specific/store';
import { VEHICLE_STATUS } from '../../../../constants/constants';
import { RootState } from '../../../../redux/RootState';

const Status = () => {
	const navLinkRef = useRef<HTMLAnchorElement | null>(null);

	const vehicleState = useSelector((state: RootState) => state.vehicle.counters);
	// const GeofencePermission = Store.getState().rolePermissions.permissions.Geofence;
	const GeofencePermission = useSelector((state: RootState) => state.rolePermissions.permissions.Geofence);

	return (
		<>
			<GraphContainer>
				<GraphCard>
					<GraphHeader>
						<h1>Fleet Status</h1>
					</GraphHeader>
					{/*<PieDesign>*/}
					<ResponsivePie
						startAngle={-90}
						margin={{ top: 20, right: 150, bottom: 20, left: 10 }}
						innerRadius={0.5}
						padAngle={0.7}
						cornerRadius={5}
						// enableRadialLabels={false}
						// enableSliceLabels={false}
						enableArcLabels={false}
						enableArcLinkLabels={false}
						colors={[
							THEME.COLORS.vehicleMoving,
							THEME.COLORS.vehicleIdle,
							THEME.COLORS.vehicleIgnitionOff,
							THEME.COLORS.vehicleNoDataHalfHour,
							THEME.COLORS.vehicleNoDataOneDay,
							THEME.COLORS.vehicleNoData,
						]}
						data={[
							{
								id: 'Moving',
								label: `${vehicleState.movingVehicles.toLocaleString()} (Moving within 60 mins)`,
								value: vehicleState.movingVehicles,
							},
							{
								id: 'Idle',
								label: `${vehicleState.idleVehicles.toLocaleString()} (Idle within 60 mins)`,
								value: vehicleState.idleVehicles,
							},
							{
								id: 'Ignition Off',
								label: `${vehicleState.stoppedVehicles.toLocaleString()} (Ignition Off within 60 mins)`,
								value: vehicleState.stoppedVehicles,
							},
							{
								id: 'No Data In Last 30 Min',
								label: `${vehicleState.noDataOneHourVehicles.toLocaleString()} (No Data In Last 60 mins)`,
								value: vehicleState.noDataOneHourVehicles,
							},
							{
								id: 'No Data In Last 24 Hours',
								label: `${vehicleState.noDataOneDayVehicles.toLocaleString()} (No Data In Last 24 hours)`,
								value: vehicleState.noDataOneDayVehicles,
							},
							{
								id: 'No Data',
								label: `${vehicleState.noDataVehicles.toLocaleString()} (No Data)`,
								value: vehicleState.noDataVehicles,
							},
						]}
						legends={[
							{
								anchor: 'right',
								direction: 'column',
								justify: false,
								translateX: 70,
								translateY: 0,
								itemsSpacing: 2,
								itemWidth: 120,
								itemHeight: 20,
								itemDirection: 'left-to-right',
								itemOpacity: 0.85,
								symbolSize: 15,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemOpacity: 1,
										},
									},
								],
							},
						]}
						isInteractive={true}
						onClick={(node) => {
							if (GeofencePermission.manage || GeofencePermission.read) {
								if (node.id === 'Moving') dispatch('SET_FILTER', VEHICLE_STATUS.Moving);
								else if (node.id === 'Idle') dispatch('SET_FILTER', VEHICLE_STATUS.Idle);
								else if (node.id === 'Ignition Off') dispatch('SET_FILTER', VEHICLE_STATUS.Stopped);
								else if (node.id === 'No Data In Last 30 Min') dispatch('SET_FILTER', VEHICLE_STATUS.NoDataOneHour);
								else if (node.id === 'No Data In Last 24 Hours') dispatch('SET_FILTER', VEHICLE_STATUS.NoDataOneDay);
								else if (node.id === 'No Data') dispatch('SET_FILTER', VEHICLE_STATUS.NoData);
								if (navLinkRef?.current) navLinkRef?.current.click();
							}
						}}
					/>
				</GraphCard>
			</GraphContainer>
			<NavLink
				ref={navLinkRef}
				to={{
					pathname: '/vehicles',
				}}
			/>
		</>
	);
};

// Connect to Store

export default Status;
