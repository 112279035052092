import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CheckboxTree, { Node } from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Icon, Label } from 'semantic-ui-react';

import './OrganisationWiseVehicleList.css';
import { AllVehiclesCheck, CardsContainer } from '../../STYLED/VehicleListStyles';
import { CheckboxContainer } from '../../../views/dashboard/vehicles/sidebar/SidebarStyles';
import { RootState } from '../../../redux/RootState';

interface PropType {
	checkedList: string[];
	handleAllVehiclesCheck: MouseEventHandler | undefined;
	isAllVehiclesCheck: string;
	allVehiclesList: string[];
	allVehicleColor: string;
	total: number;
	expandedList: string[];
	viewList: Node[];
	handleOnCheck: (total: string[], targetNode: CheckBoxTreeNode) => void;
	handleOnExpand: (total: string[]) => void;
	handleOnClick: (targetNode: CheckBoxTreeNode) => void;
}

export interface CheckBoxTreeNode {
	label: React.ReactNode;
	value: string;
	children?: Array<Node>;
	className?: string;
	disabled?: boolean;
	icon?: React.ReactNode;
	showCheckbox?: boolean;
	title?: string;
	checked: boolean;
	parent?: Node;
	isLeaf?: boolean;
}

const OrganisationWiseVehicleList = (props: PropType) => {
	const selectedVehicles: number[] = useSelector((state: RootState) => state.sidebar.selectedVehicles);

	const [eventchecked, setEventChecked] = useState<string[]>([]);

	const checkedList = props.checkedList;

	useEffect(() => {
		// Function to find the common value in checkedList from selectedVehicles
		const findCommonValue = () => {
			for (const item of checkedList) {
				const index = item.indexOf(String(selectedVehicles[0]));
				if (index === 0) {
					if (selectedVehicles.length === 1) {
						setEventChecked([item]);
						return; // Exit the loop once a match is found
					}
				}
			}
			setEventChecked([]); // If no match is found, set an empty array
		};

		findCommonValue();
	}, [checkedList, selectedVehicles]);

	return (
		<CardsContainer>
			<CheckboxContainer>
				<AllVehiclesCheck onClick={props.handleAllVehiclesCheck}>
					<span style={{ marginRight: '1em' }}>
						{props.isAllVehiclesCheck === 'all' ? (
							<Icon name="check square outline" />
						) : props.isAllVehiclesCheck === 'none' ? (
							<Icon name="square outline" />
						) : (
							<Icon name="minus square outline" />
						)}
					</span>
					<span style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
						<span>All Vehicles</span>
						<Label
							circular
							size="tiny"
							className={`labelColor-${props.allVehicleColor} allVehicleLabelColor`}
							style={{ color: 'white' }}>
							{props.total}
						</Label>
					</span>
				</AllVehiclesCheck>
			</CheckboxContainer>
			<CheckboxTree
				icons={{
					check: <Icon name="check square outline" />,
					uncheck: <Icon name="square outline" />,
					halfCheck: <Icon name="minus square outline" />,
					expandClose: <Icon name="triangle right" size="large" />,
					expandOpen: <Icon name="triangle down" size="large" />,
				}}
				showNodeIcon={false}
				nodes={props.viewList}
				checked={eventchecked.length > 0 ? eventchecked : props.checkedList}
				expanded={props.expandedList}
				onCheck={props.handleOnCheck}
				onExpand={props.handleOnExpand}
				onClick={props.handleOnClick}
				//expandOnClick={true}
				checkModel="leaf"
			/>
		</CardsContainer>
	);
};

export default OrganisationWiseVehicleList;
