import styled from 'styled-components';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../../../../constants/constants';
import { THEME } from '../../../../../constants/Theme';

export const Container = styled.section`
	width: 100%;
	height: 100%;

	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const TableContainer = styled.section`
	width: 100%;
	height: calc(100% - calc(${HEADER_HEIGHT} + ${FOOTER_HEIGHT}));
	z-index: 0;

	box-sizing: border-box;

	--ag-grid-size: 5px;
	--ag-cell-horizontal-padding: 10px;
	--ag-row-height: 40px;
	--ag-header-foreground-color: #000000;

	.pointer-cell {
		cursor: pointer;
	}

	.serial-cell {
		width: 2px !important;
	}

	a,
	.viewAddress {
		text-overflow: ellipsis;
		overflow: hidden;

		color: black;

		&:hover {
			color: blue;
			text-decoration: underline;
		}
	}

	.ui.table {
		border: none;
		border-top: none !important;
		border-bottom: 1px solid #efefef;
	}

	.ui.table thead tr:first-child > th {
		top: 0;

		box-sizing: border-box;
		border-top: 1px solid rgba(34, 36, 38, 0.15);
	}

	.ui.table thead tr:first-child > th:nth-child(6) {
		z-index: 0 !important;
	}

	.ui.table tbody tr td:nth-child(6) {
		a {
			text-overflow: ellipsis;
			overflow: hidden;

			color: black;

			&:hover {
				color: blue;
				text-decoration: underline;
			}
		}
	}

	.ui.dropdown {
		z-index: 10 !important;
	}

	overflow-y: auto;
`;

export const PageSelector = styled.section`
	width: 6em;

	margin-right: 1em;

	border: none;
	border-radius: 5px;
	box-shadow:
		rgb(60 64 67 / 30%) 0 1px 2px 0,
		rgb(60 64 67 / 15%) 0 2px 6px 2px;
`;

export const FlexContainer = styled.section`
	display: flex;
`;

export const NoMatchFoundContainer = styled.section`
	width: 100%;
	height: 100%;

	.header {
		color: ${THEME.COLORS.theme} !important;
	}

	.ui.icon {
		color: ${THEME.COLORS.theme} !important;
	}

	.ui.icon.header .icon {
		margin: 0 auto 0.45em !important;
	}
`;
