import React from 'react';
import LoginForm from './LoginForm';
import { Redirect } from 'react-router-dom';
import Particles from 'react-tsparticles';
import styled from 'styled-components';
import { ISourceOptions } from 'tsparticles';

import { ParticleOptions } from '../../constants/particle-options';

interface PropType {
	location: {
		pathname: string;
	};
}

const Auth = (props: PropType) => {
	if (props.location.pathname !== '/') return <Redirect to="/" />;

	return (
		<Container>
			<LoginForm />
			<Particles id="tsparticles" className="particle-container" options={ParticleOptions as ISourceOptions} />
		</Container>
	);
};

export default Auth;

export const Container = styled.section`
	width: 100%;
	height: 100%;
	min-height: 100vh;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;

	.particle-container {
		position: absolute !important;
		z-index: -1;
	}
`;
