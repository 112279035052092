import styled from 'styled-components';
import { THEME } from '../../../constants/Theme';

interface PropType {
	active: boolean;
}

export const Container = styled.section`
	width: 100%;

	margin-top: 0.5em;
	box-sizing: border-box;
	padding: 0 1em;

	display: flex;
	align-items: center;
	justify-content: space-around;
`;

export const ShortcutsText = styled.section<PropType>`
	font-size: 0.95em;
	font-weight: ${(props) => (props.active ? 'bold' : '')};

	color: ${(props) => (props.active ? THEME.COLORS.theme : 'black')};

	cursor: pointer;
`;
