import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import { ButtonProps } from 'semantic-ui-react/dist/commonjs/elements/Button/Button';

interface PropType {
	header: ReactElement;
	content: ReactElement;
	onClose: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void;
}

export default function FullScreenWrapper(props: PropType): ReactElement {
	return (
		<ContainerWrapper>
			<Container>
				<Header>
					{props.header && props.header}
					<BackButtonContainer>
						<Button circular color="red" icon="close" onClick={props.onClose} />
					</BackButtonContainer>
				</Header>
				{props.content}
			</Container>
		</ContainerWrapper>
	);
}

const ContainerWrapper = styled.section`
	width: 100%;
	height: 100%;

	box-sizing: border-box;
	padding: 1em;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;

	background: rgba(0, 0, 0, 0.75);
`;

const Container = styled.section`
	width: 100%;
	height: 100%;

	border-radius: 5px;
	background: #fff;
`;

const Header = styled.section`
	width: 100%;
	height: 3.5em;

	border-bottom: 1px solid #ddd;

	display: flex;
`;

const BackButtonContainer = styled.section`
	width: 100%;
	height: 100%;

	margin-right: 0.5em;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;
