import React, { useEffect, useRef, useState } from 'react';
import TrackNerdLoader from '../../../../components/loader/Loader';
import { GraphCard, GraphContainer, GraphHeader } from '../OverviewStyles';
import { ResponsiveBar } from '@nivo/bar';
import { THEME } from '../../../../constants/Theme';
import { NavLink } from 'react-router-dom';
import { dispatch, dispatchNoPayload } from '../../../../helper/view specific/store';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/RootState';
import { mostUsedVehicle } from '../Overview';

interface PerformanceGraphProps {
	data: mostUsedVehicle[];
}

interface GraphProps {
	Vehicle: string;
	Distance: string;
	registrationNumber: string;
}

const PerformanceGraph = (props: PerformanceGraphProps) => {
	const [graphData, setGraphData] = useState<GraphProps[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const navLinkRef = useRef<HTMLAnchorElement | null>(null);

	const GeofencePermission = useSelector((state: RootState) => state.rolePermissions.permissions.Geofence);

	useEffect(() => {
		formatData(props.data);
	}, []);

	const formatData = (reports: mostUsedVehicle[]) => {
		const tempData: GraphProps[] = [];
		reports.forEach((vehicle) => {
			tempData.push({
				Vehicle: vehicle.registrationNumber.replaceAll(' ', '').slice(0, 10),
				Distance: (parseInt(vehicle.distance) / 1000).toFixed(0),
				registrationNumber: vehicle.registrationNumber,
			});
		});

		setGraphData([...tempData]);
		setLoading(false);
	};

	const barData = graphData.map((item) => ({
		Vehicle: item.Vehicle,
		Distance: parseFloat(item.Distance),
		registrationNumber: item.registrationNumber,
	}));

	return (
		<GraphContainer>
			{loading ? (
				<TrackNerdLoader />
			) : barData.length ? (
				<GraphCard>
					<GraphHeader>
						<h1>Top Performers Vs Under Performers (3 Months)</h1>
					</GraphHeader>
					<ResponsiveBar
						data={barData}
						// width={630}
						// height={400}
						enableGridY={false}
						enableGridX={false}
						keys={['Distance']}
						indexBy="Vehicle"
						// layout="horizontal"
						margin={{ top: 20, right: 50, bottom: 100, left: 50 }}
						padding={0.3}
						valueScale={{ type: 'linear' }}
						indexScale={{ type: 'band', round: true }}
						borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
						axisTop={null}
						axisRight={null}
						enableLabel={true}
						// labelSkipWidth={12}
						// labelSkipHeight={12}
						labelTextColor="black"
						label={({ value }: { value: number | null }) => {
							if (value) return `${value.toLocaleString()} km`;
							else return '0 km';
						}}
						/*label={({ value }: { value: number | null }) => {
                            if (value) return <tspan y={-10}>{`${value.toLocaleString()} km`}</tspan>;
                            else return <tspan y={-10}>{'0 km'}</tspan>;
                        }}*/
						valueFormat={(value) => `${Number(value).toLocaleString()} kms`}
						axisBottom={{
							/*"onClick": (e:any) => {
                dispatch('SET_SEARCH_QUERY', e.target.textContent);
                navLinkRef.current.click();
              },*/
							tickRotation: -30,
							tickPadding: 5,
							tickSize: 10,
							// legendOffset: 40
							// format: (date) => `${moment(date, "DD-MM-YYYY").format("D MMM")}`
						}}
						axisLeft={null}
						// colors={[THEME.COLORS.vehicleMoving, THEME.COLORS.vehicleIdle]}
						colors={(e) => {
							if (e.index <= (barData.length - 1) / 2) return THEME.COLORS.vehicleMoving;
							else return THEME.COLORS.vehicleNoDataOneDay;
						}}
						onClick={(node) => {
							if (GeofencePermission.manage || GeofencePermission.read) {
								dispatch('SET_SEARCH_QUERY', node.data.registrationNumber);
								dispatch('SELECTED_TAB', 'Summary');
								dispatchNoPayload('MONTHLY_SUMMARY');
								dispatchNoPayload('SET_VEHICLE_SELECTED_FROM_GRAPH');
								dispatchNoPayload('ENABLE_THREE_MONTHS_DATA');
								if (navLinkRef?.current) navLinkRef?.current.click();
							}
						}}
						legends={[
							{
								data: [
									{ color: THEME.COLORS.vehicleMoving, id: 1, label: 'Top Performing' },
									{ color: THEME.COLORS.vehicleNoDataOneDay, id: 2, label: 'Under Performing' },
								],
								anchor: 'bottom',
								direction: 'row',
								justify: false,
								dataFrom: 'keys',
								translateX: 0,
								translateY: 90,
								itemsSpacing: 10,
								itemWidth: 100,
								itemHeight: 20,
								itemDirection: 'left-to-right',
								itemOpacity: 0.85,
								symbolSize: 15,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemOpacity: 1,
										},
									},
								],
							},
						]}
						animate={true}
						// motionStiffness={90}
						// motionDamping={15}
					/>
				</GraphCard>
			) : (
				<GraphCard>
					<GraphHeader>
						<h1>Top Performers Vs Under Performers (3 Months)</h1>
					</GraphHeader>
					<div
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							flex: 1,
						}}>
						<p>No Data!</p>
					</div>
				</GraphCard>
			)}
			<NavLink
				ref={navLinkRef}
				to={{
					pathname: '/vehicles',
				}}
			/>
		</GraphContainer>
	);
};

// Connect to Store

export default PerformanceGraph;
