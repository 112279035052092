import styled from 'styled-components';

import { FOOTER_HEIGHT } from '../../constants/constants';
import { THEME } from '../../constants/Theme';

interface CategoryFooterProps {
	borderRadius?: string;
}

export const CategoryFooter = styled.section<CategoryFooterProps>`
	width: 100%;
	height: ${FOOTER_HEIGHT};

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 0 1em;

	background: ${THEME.COLORS.footerBackground};
	border-top: 1px solid ${THEME.COLORS.footerBorder};
	border-bottom-left-radius: ${(props) => props.borderRadius || 0};
	border-bottom-right-radius: ${(props) => props.borderRadius || 0};

	.ui.menu {
		width: max-content !important;
		border: none;
		border-radius: 5px;
		box-shadow:
			rgba(60, 64, 67, 0.3) 0 1px 2px 0,
			rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
	}

	.ui.pagination.menu {
		justify-content: right;

		border: none;
		border-radius: 5px;
		box-shadow:
			rgba(60, 64, 67, 0.3) 0 1px 2px 0,
			rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
	}

	.ui.pagination.menu .active.item {
		color: #fff;
		background: ${(props) => props.theme.COLORS.theme};
	}
`;
