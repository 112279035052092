import { OrganisationData } from '../../dtos/vehicle';
import { Vehicle } from '../../types/vehicle';
import { VehicleGroup } from '../../types/vehicleGroup';
import { Organisation } from '../../types/organisation';

interface VehicleFilterCounter {
	movingVehicles: number;
	idleVehicles: number;
	stoppedVehicles: number;
	noDataOneHourVehicles: number;
	noDataOneDayVehicles: number;
	noDataVehicles: number;
	totalVehicles: number;
}

interface EventData {
	id: number;
	lat: number;
	lng: number;
}

export interface VehicleState {
	vehicles: Vehicle[];
	vehicleGroups: VehicleGroup[];
	organisations: Organisation[];
	vehicleDetails: Map<number, OrganisationData>;
	counters: VehicleFilterCounter;
	intervalId: number | null;
	tab: string;
	monthlySummary: boolean;
	eventData: EventData | null;
}

export interface VehicleAction {
	type: string;
	payload?:
		| Vehicle[]
		| VehicleGroup[]
		| Organisation[]
		| Map<number, OrganisationData>
		| VehicleFilterCounter
		| EventData
		| number
		| string
		| boolean;
}

export default function vehicle(
	initialState: VehicleState = {
		vehicles: [],
		// vehicleObjects: null,
		vehicleGroups: [],
		organisations: [],
		vehicleDetails: new Map(),

		counters: {
			movingVehicles: 0,
			idleVehicles: 0,
			stoppedVehicles: 0,
			noDataOneHourVehicles: 0,
			noDataOneDayVehicles: 0,
			noDataVehicles: 0,
			totalVehicles: 0,
		},

		intervalId: null,
		tab: 'Live',
		monthlySummary: false,
		eventData: null,
	},
	action: VehicleAction
) {
	switch (action.type) {
		case 'FEED_VEHICLES':
			return {
				...initialState,
				vehicles: action.payload,
			};

		/*case 'FEED_VEHICLE_OBJECTS':
            return {
                ...initialState,
                vehicleObjects: action.payload,
            };
*/
		case 'VEHICLES_DETAILS':
			return {
				...initialState,
				vehicleDetails: action.payload,
			};

		case 'FEED_VEHICLE_GROUPS':
			return {
				...initialState,
				vehicleGroups: action.payload,
			};

		case 'FEED_ORGANISATIONS':
			return {
				...initialState,
				organisations: action.payload,
			};

		case 'SET_COUNTERS':
			if (typeof action.payload === 'object' && action.payload !== null) {
				const payloadCounters = action.payload as VehicleFilterCounter;
				return {
					...initialState,
					counters: {
						movingVehicles: payloadCounters.movingVehicles,
						idleVehicles: payloadCounters.idleVehicles,
						stoppedVehicles: payloadCounters.stoppedVehicles,
						noDataOneHourVehicles: payloadCounters.noDataOneHourVehicles,
						noDataOneDayVehicles: payloadCounters.noDataOneDayVehicles,
						noDataVehicles: payloadCounters.noDataVehicles,
						totalVehicles: payloadCounters.totalVehicles,
					},
				};
			} else {
				return initialState;
			}
		case 'RESET_COUNTERS':
			return {
				...initialState,
				counters: {
					movingVehicles: 0,
					idleVehicles: 0,
					stoppedVehicles: 0,
					noDataOneHourVehicles: 0,
					noDataOneDayVehicles: 0,
					noDataVehicles: 0,
					totalVehicles: 0,
				},
			};

		case 'API_CALLS_INTERVAL_ID':
			return {
				...initialState,
				intervalId: action.payload,
			};

		case 'SELECTED_TAB':
			return {
				...initialState,
				tab: action.payload,
			};

		case 'MONTHLY_SUMMARY':
			return {
				...initialState,
				monthlySummary: true,
			};
		case 'DAILY_SUMMARY':
			return {
				...initialState,
				monthlySummary: false,
			};
		case 'EVENT_DATA':
			return {
				...initialState,
				eventData: action.payload,
			};
		default:
			return initialState;
	}
}
