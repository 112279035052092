import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
`;

export const SearchContainer = styled.section`
	width: 100%;

	box-sizing: border-box;
	padding: 1em;

	border-bottom: 1px solid ${(props) => props.theme.COLORS.border};
`;
