import { Icon, Popup } from 'semantic-ui-react';
import { CRM_BASE_URL, VEHICLES_API } from '../../constants/api';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/RootState';
import { getData } from '../../helper/services/axios';
import { Sim } from '../../types/sim';
import { handleError } from '../../helper/view specific/messaging';

interface PopUpProps {
	componentType: string;
	registrationNumber?: string;
	id?: number;
}

export default function PopUpBox(props: PopUpProps): ReactElement {
	const startDate = useSelector((state: RootState) => state.dates.initialDate);
	const finalDate = useSelector((state: RootState) => state.dates.finalDate);

	const registrationNumber = props.registrationNumber !== undefined ? props.registrationNumber : null;
	const id = props.id !== undefined ? props.id : null;

	function Vehicles() {
		const VehicleApi = (id: number | null, mainTab: string, tab: string) => {
			if (id) {
				getData(VEHICLES_API, `/${id}`)
					.then((response) => {
						const deviceID = response.data.device.imei;
						let simId;
						response.data.device.sims.forEach((sim: Sim) => {
							simId = sim.simNumber;
						});
						// window.location.href =`${CRM_BASE_URL}/${mainTab}/${tab}/${deviceID}`;
						if (tab === 'sims') {
							window.open(`${CRM_BASE_URL}${mainTab}/${tab}/${simId}`, '_blank');
						} else if (tab === 'logs') {
							window.open(`${CRM_BASE_URL}${mainTab}/${tab}/${deviceID}/${startDate}/${finalDate}`, '_blank');
						} else {
							window.open(`${CRM_BASE_URL}${mainTab}/${tab}/${deviceID}`, '_blank');
						}
					})
					.catch((error) => {
						handleError('SideBarNew2.tsx => VehicleApi()', error);
					});
			}
		};
		return (
			<Popup
				trigger={<Icon name="ellipsis vertical" color="black" />}
				on={'hover'}
				position={'bottom left'}
				hoverable={true}
				content={
					<div style={{ fontWeight: 'bold' }}>
						<span style={{ fontWeight: 'bold' }}>
							<a
								href={`${CRM_BASE_URL}operations/vehicles/${registrationNumber}`}
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: 'black' }}>
								Go to Edit
							</a>
						</span>
						<br />
						<span
							style={{ fontWeight: 'bold', cursor: 'pointer' }}
							onClick={() => VehicleApi(id, 'operations', 'sims')}>
							Go To Sims
						</span>
						<br />
						<span
							style={{ fontWeight: 'bold', cursor: 'pointer' }}
							onClick={() => {
								VehicleApi(id, 'diagnostic-and-logs', 'logs');
							}}>
							Go to Logs
						</span>
						<br />
						<span
							style={{ fontWeight: 'bold', cursor: 'pointer' }}
							onClick={() => {
								VehicleApi(id, 'operations', 'devices');
							}}>
							Go to Device
						</span>
					</div>
				}
			/>
		);
	}

	function VehicleGroups() {
		return (
			<Popup
				trigger={<Icon name="ellipsis vertical" color="black" />}
				on={'hover'}
				position={'bottom left'}
				hoverable={true}
				content={
					<div style={{ fontWeight: 'bold' }}>
						<span style={{ fontWeight: 'bold' }}>
							<a
								href={`${CRM_BASE_URL}operations/vehicle-groups/${name}`}
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: 'black' }}>
								Go to Edit
							</a>
						</span>
					</div>
				}
			/>
		);
	}

	function Organisations() {
		return (
			<Popup
				trigger={<Icon name="ellipsis vertical" color="black" />}
				on={'hover'}
				position={'bottom left'}
				hoverable={true}
				content={
					<div style={{ fontWeight: 'bold' }}>
						<span style={{ fontWeight: 'bold' }}>
							<a
								href={`${CRM_BASE_URL}operations/organisations/${name}`}
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: 'black' }}>
								Go to Edit
							</a>
							<br />
							<a
								href={`${CRM_BASE_URL}operations/vehicles/${name}`}
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: 'black' }}>
								All Vehicle
							</a>
							<br />
							<a
								href={`${CRM_BASE_URL}operations/vehicle-groups/${name}`}
								target="_blank"
								rel="noopener noreferrer"
								style={{ color: 'black' }}>
								Vehicle Groups
							</a>
						</span>
					</div>
				}
			/>
		);
	}

	switch (props.componentType) {
		case 'vehicle':
			return <Vehicles />;
		case 'vehicleGroup':
			return <VehicleGroups />;
		default:
			return <Organisations />;
	}
}
