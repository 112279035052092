export const StatusCode = {
	UnAuthorized: 401,
	NotFound: 404,
};

export const ConsignmentsStatus = {
	STARTED: 1,
	UNLOADING: 2,
	ENDED: 3,
	COMPLETED: 4,
};

// Vehicle Status
export const VEHICLE_STATES = {
	Active: 'Active',
	Trial: 'Trial',
	Breakdown: 'Breakdown',
	Paused: 'Paused',
};
