export const themeColor = '#1b3c71';

export const THEME = {
	// Colors
	COLORS: {
		theme: themeColor,
		accent: 'dodgerblue',

		loginFormBackground: 'whitesmoke',

		// Navbar
		activeDashboardLinkBackground: '#fff',

		// Links
		dashboardLinkColor: '#fff',

		// Calendar
		calendarContainerBackground: '#fff',
		calendarBackground: '#f5f5f5',
		calendarBackgroundOnHover: '#eee',
		calendarTimePickerBackground: '#eee',
		calendarBorderColor: '#ddd',
		calendarTextColor: '#555',

		headerBackground: '#f5f5f7',
		headerBorder: '#ddd',

		footerBackground: '#f5f5f7',
		footerBorder: '#ddd',

		positive: '#008D45',
		warning: '#D9A21B',
		danger: '#D24533',

		vehicleMoving: '#4aa96c',
		vehicleIdle: '#ffcc29',
		vehicleIgnitionOff: '#777777',
		vehicleNoDataHalfHour: '#ff8243',
		vehicleNoDataOneDay: '#e34234',
		vehicleNoData: '#000',
		vehiclespeedlimitData: '#D24533',
		// vehiclespeedlimitData: '#0D2453300',

		// Maintenance: 'rgb(27,60,113)',
		Breakdown: '#DB2828',
		Paused: '#F2711C',

		white: '#fff',

		overSpeed: '#DB2828',
		MainPower: '#4aa96c',
		Parking: '#ff8243',
		Idling: '#ffcc29',
		Geofence: '#26C6DA',
		SOS: '#1b3c71',
		Ac: '#EC407A',

		polylineTrack: '#5f779c',
		polylineTrackMoving: '#1ebc30',
		polylineTrackIdle: '#f4aa04',
		polylineTrackStopped: '#D24533',
		multiplePolyLinesTrack: [
			'#7986CB',
			'#AB47BC',
			'#26C6DA',
			'#26A69A',
			'#66BB6A',
			'#D4E157',
			'#78909C',
			'#EC407A',
			'#51cab3',
			'#5f779c',
			'#777777',
			'#1b3c71',
			'#D2691E',
			'#3FFF00',
			'#FF00FF',
			'#FF0800',
			'#EEDC82',
			'#FFFF00',
			'#E97451',
			'#3d0c02',
		],
		multiplePolyoverspeedLinesTrack: 'cd2217',
		// Battery Level
		batteryLevelGreen: '#1ebc30',
		batteryLevelYellow: '#ffcc29',
		batteryLevelOrange: '#ff8243',
		batteryLevelRed: '#e34234',

		polylineMarkerStroke: '#fff',
		polylineMarkerFill: '#1b3c71',

		border: '#eee',

		errorMessageTextColor: '#db2828',
		errorMessageBackground: '#ffe8e6',
		errorMessageBorderColor: '#db2828',

		successMessageTextColor: '#1ebc30',
		successMessageBackground: '#e5f9e7',
		successMessageBorderColor: '#1ebc30',

		infoMessageTextColor: '#51cab3',
		infoMessageBackground: '#1d1c1d',
		infoMessageBorderColor: '#1d1c1d',

		inBetweenTripsRegNumLabel: '#D24533',

		eventFalseTextColor: '#db2828',
		eventFalseBackground: '#ffe8e6',
		eventFalseBorderColor: '#db2828',

		eventTrueTextColor: '#1ebc30',
		eventTrueBackground: '#e5f9e7',
		eventTrueBorderColor: '#1ebc30',
	},
};
