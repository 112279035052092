import { Vehicle } from '../types/vehicle';
import { VehicleGroup } from '../types/vehicleGroup';
import { Organisation } from '../types/organisation';

interface VehiclesDTO {
	id: number;
	registrationNumber: string;
	/*device: string | null;
    organisation: {
        id: number | null;
        name: string | null;
    };
    user: {
        id: number | null;
        name: string;
        phone: string;
    };
    type: string;
    status: string;*/
}

export function createVehiclesDTO(data: Vehicle[]) {
	const vehicles: VehiclesDTO[] = [];

	if (data !== undefined && data.length !== 0) {
		data.forEach((vehicle) => {
			vehicles.push({
				id: vehicle.id,
				registrationNumber: vehicle.registrationNumber,
				/*device: vehicle?.device?.imei ? vehicle.device.imei : null,
                organisation: {
                    id: vehicle?.organisation?.id ? vehicle.organisation.id : null,
                    name: vehicle?.organisation?.name ? vehicle.organisation.name : null,
                },
                user: {
                    id: vehicle?.driver?.id ? vehicle.driver.id : null,
                    name: vehicle?.driver?.name ? vehicle.driver.name : '',
                    phone: vehicle?.driver?.phone ? vehicle.driver.phone : '',
                },
                type: vehicle?.type ? vehicle.type : 1,
                status: vehicle?.status ? vehicle.status : 1,

                fuelDataSource: vehicle.fuelDataSource,
                immobilizer: vehicle.immobilizer,*/
			});
		});
	}

	return vehicles;
}

export interface OrganisationData {
	vehicleGroup: Map<number, string>;
	org: Map<number, string>;
	data: Vehicle;
}

export interface SelectedOrganisation {
	id: number;
	name: string;
	vehicleGroups: SelectedGroup[];
}

export interface SelectedGroup {
	id: number;
	name: string;
	vehicles: Vehicle[];
}

export interface VehicleGroupData {
	vehicleGroup: Vehicle[];
	data: Vehicle;
}

interface VehicleGroupExport {
	id: number;
	name: string;
	vehicles: Vehicle[];
}

export function createOrganisationDTO(data: Organisation[]) {
	const organisationData = data;
	const selectedOrganisations: SelectedOrganisation[] = [];
	const vehicleList: Vehicle[] = [];
	const vehicles = new Map<number, OrganisationData>();

	if (organisationData.length) {
		organisationData.forEach((org) => {
			if (org.vehicleGroups.length !== 0) {
				const selectedGroups: SelectedGroup[] = [];
				org.vehicleGroups.forEach((group) => {
					const vehiclesInGroup: Vehicle[] = [];
					if (group.vehicles.length !== 0) {
						group.vehicles.forEach((vehicle) => {
							vehiclesInGroup.push(vehicle);
							if (!vehicles.has(vehicle.id)) {
								vehicleList.push(vehicle);

								const obj = vehicles.get(vehicle.id);
								// Update the vGroup and org properties

								if (obj) {
									obj.vehicleGroup.set(group.id, group.name);
									obj.org.set(org.id, group.name);
									obj.data = vehicle;
									vehicles.set(vehicle.id, obj);
								} else {
									const vehicleGroupLocal = new Map();
									const orgLocal = new Map();
									vehicles.set(vehicle.id, {
										vehicleGroup: vehicleGroupLocal.set(group.id, group.name),
										org: orgLocal.set(org.id, group.name),
										data: vehicle,
									});
								}
							}
						});
						if (vehiclesInGroup.length) {
							selectedGroups.push({ id: group.id, name: group.name, vehicles: vehiclesInGroup });
						}
					}
				});
				if (selectedGroups.length) {
					selectedOrganisations.push({ id: org.id, name: org.name, vehicleGroups: selectedGroups });
				}
			}
		});
	}

	return [selectedOrganisations, vehicles, vehicleList];
}

export function createVehicleGroupsDTONew(data: VehicleGroup[]) {
	const groupData = data;
	const vehicleList: Vehicle[] = [];
	const newGroupData: VehicleGroupExport[] = [];
	const vehicles = new Map<number, VehicleGroupData>();
	if (groupData.length) {
		groupData.forEach((group) => {
			const vehiclesInGroup: Vehicle[] = [];
			if (group.vehicles.length) {
				group.vehicles.forEach((vehicle) => {
					vehiclesInGroup.push(vehicle);
					if (!vehicles.has(vehicle.id)) {
						vehicles.set(vehicle.id, {
							vehicleGroup: vehiclesInGroup,
							data: vehicle,
						});
					}
					vehicleList.push(vehicle);
				});
				newGroupData.push({ id: group.id, name: group.name, vehicles: vehiclesInGroup });
			}
		});
	}
	return [newGroupData, vehicles, vehicleList];
}

/*export function createOrganisationDTONew(data) {
  let organisationData = data.organisations;
  let vehicleList = [];
  let selectedOrganisations = [];
  let vehicleObjects = {};
  if (organisationData.length) {
    organisationData.forEach((org) => {
      if (org.vehicleGroups.length !== 0) {
        let selectedGroups = [];
        org.vehicleGroups.forEach((group) => {
          let vehiclesInGroup = [];
          if (group.vehicles.length !== 0) {
            group.vehicles.forEach((vehicle) => {
              vehiclesInGroup.push(vehicle);
              if (!Object.hasOwn(vehicleObjects, [vehicle.id])) {
                vehicleObjects = { ...vehicleObjects, [vehicle.id]: vehicle };
                vehicleList.push(vehicle);
              }
            });
            if (vehiclesInGroup.length) {
              selectedGroups.push({ id: group.id, name: group.name, vehicles: vehiclesInGroup });
            }
          }
        });
        if (selectedGroups.length) {
          selectedOrganisations.push({ id: org.id, name: org.name, vehicleGroups: selectedGroups });
        }
      }
    });
  }
  return [selectedOrganisations, vehicleList, vehicleObjects];
}*/

//
// export function createVehicleGroupsDTO(data) {
//   let vehicleGroups = [];
//   let vehicleList = [];
//   let vehicleHashMap = [];
//   if (data && data.length) {
//     data.forEach((group) => {
//       if (group.vehicles?.length !== 0) {
//         vehicleGroups.push({
//           id: group.id,
//           name: group.name,
//           organisation: {
//             id: group.organisation?.id || null,
//             name: group.organisation?.name || '',
//           },
//           vehicles: group.vehicles
//             ? group.vehicles.map((vehicle) => {
//                 return {
//                   id: vehicle.id,
//                   registrationNumber: vehicle.registrationNumber,
//                   device: vehicle?.device?.imei ? vehicle.device.imei : null,
//                   user: {
//                     id: vehicle?.driver?.id ? vehicle.driver.id : null,
//                     name: vehicle?.driver?.name ? vehicle.driver.name : '',
//                     phone: vehicle?.driver?.phone ? vehicle.driver.phone : '',
//                   },
//                   type: vehicle?.type ? vehicle.type : 1,
//                   status: vehicle?.status ? vehicle.status : 1,
//                   fuelDataSource: vehicle.fuelDataSource,
//                   immobilizer: vehicle.immobilizer,
//                 };
//               })
//             : [],
//         });
//       }
//       vehicleGroups[vehicleGroups.length - 1].vehicles.forEach((vehicle) => {
//         if (!vehicleHashMap.includes(vehicle.id)) {
//           vehicleHashMap.push(vehicle.id);
//           vehicleList.push(vehicle);
//         }
//       });
//     });
//   }
//
//   return [vehicleGroups, vehicleList];
// }

/*export function createVehicleGroupsDTO(data: VehicleGroup[]) {
	const groupData = data;
	const vehicleList: Vehicle[] = [];
	const newGroupData: SelectedGroup[] = [];
	const vehicles = new Map();
	if (groupData.length) {
		groupData.forEach((group) => {
			const vehiclesInGroup: Vehicle[] = [];
			if (group.vehicles.length) {
				group.vehicles.forEach((vehicle) => {
					vehiclesInGroup.push(vehicle);
					if (!vehicles.has(vehicle.id)) {
						vehicles.set(vehicle.id, {
							vehicleGroup: vehiclesInGroup,
							data: vehicle,
						});
					}
					vehicleList.push(vehicle);
				});
				newGroupData.push({ id: group.id, name: group.name, vehicles: vehiclesInGroup });
			}
		});
	}
	return [newGroupData, vehicles, vehicleList];
}*/

/*
interface Vehicle{
	id: number,
	registrationNumber: string,
	device: string| null,
	organisation: {
		id: number | null,
		name: string | null,
	},
	user: {
		id: number | null,
		name: string | null,
		phone: string | '',
	},
	type: string | null,
	status: string | null,

	fuelDataSource: string,
	immobilizer: string,
}
export interface VehicleGroup {
	id: any;
	name: any;
	vehicles: Vehicle[];
}
export interface OrgGroup {
	id: any;
	name: any;
	vehicleGroups: VehicleGroup[];
}

export interface VehicleInfo {
	vehicleGroup:Map<any, any> | Vehicle[];
	org: Map<any, any>; // You can replace `any` with appropriate type
	data: Vehicle;
}
export function createVehiclesDTO(data: any[]):Vehicle[] {
	let vehicles: Vehicle[] = [];

	if (data !== undefined && data.length !== 0) {
		data.forEach((vehicle: any) => {
			vehicles.push({
				id: vehicle.id,
				registrationNumber: vehicle.registrationNumber,
				device: vehicle?.device?.imei ? vehicle.device.imei : null,
				organisation: {
					id: vehicle?.organisation?.id ? vehicle.organisation.id : null,
					name: vehicle?.organisation?.name ? vehicle.organisation.name : null,
				},
				user: {
					id: vehicle?.driver?.id ? vehicle.driver.id : null,
					name: vehicle?.driver?.name ? vehicle.driver.name : '',
					phone: vehicle?.driver?.phone ? vehicle.driver.phone : '',
				},
				type: vehicle?.type ? vehicle.type : 1,
				status: vehicle?.status ? vehicle.status : 1,

				fuelDataSource: vehicle.fuelDataSource,
				immobilizer: vehicle.immobilizer,
			});
		});
	}

	return vehicles;
}

export function createOrganisationDTO(data: any): [OrgGroup[], Map<any, VehicleInfo>, Vehicle[]] {
	let organisationData = data.organisations;
	let vehicleList: Vehicle[] = [];
	let selectedOrganisations: OrgGroup[] = [];

	let vehicles = new Map<any, VehicleInfo>();

	if (organisationData.length) {
		organisationData.forEach((org: any) => {
			if (org.vehicleGroups.length !== 0) {
				let selectedGroups: VehicleGroup[] = [];
				org.vehicleGroups.forEach((group: any) => {
					let vehiclesInGroup: Vehicle[] = [];
					if (group.vehicles.length !== 0) {
						group.vehicles.forEach((vehicle:any) => {
							vehiclesInGroup.push(vehicle);
							if (!vehicles.has(vehicle.id)) {
								vehicleList.push(vehicle);

								const obj = vehicles.get(vehicle.id);
								// Update the vGroup and org properties

								if (obj) {
									// obj.vehicleGroup.set(group.id, group.name);
									obj.org.set(org.id, group.name);
									obj.data = vehicle;
									vehicles.set(vehicle.id, obj);
								} else {
									const vehicleGroupLocal = new Map();
									const orgLocal = new Map();
									vehicles.set(vehicle.id, {
										vehicleGroup: vehicleGroupLocal.set(group.id, group.name),
										org: orgLocal.set(org.id, group.name),
										data: vehicle,
									});
								}
							}
						});
						if (vehiclesInGroup.length) {
							selectedGroups.push({ id: group.id, name: group.name, vehicles: vehiclesInGroup });
						}
					}
				});
				if (selectedGroups.length) {
					selectedOrganisations.push({ id: org.id, name: org.name, vehicleGroups: selectedGroups });
				}
			}
		});
	}

	return [selectedOrganisations, vehicles, vehicleList];
}

/!*export function createOrganisationDTONew(data) *!//!*{
	let organisationData = data.organisations;
	let vehicleList = [];
	let selectedOrganisations = [];
	let vehicleObjects = {};
	if (organisationData.length) {
		organisationData.forEach((org) => {
			if (org.vehicleGroups.length !== 0) {
				let selectedGroups = [];
				org.vehicleGroups.forEach((group) => {
					let vehiclesInGroup = [];
					if (group.vehicles.length !== 0) {
						group.vehicles.forEach((vehicle) => {
							vehiclesInGroup.push(vehicle);
							if (!Object.hasOwn(vehicleObjects, [vehicle.id])) {
								vehicleObjects = { ...vehicleObjects, [vehicle.id]: vehicle };
								vehicleList.push(vehicle);
							}
						});
						if (vehiclesInGroup.length) {
							selectedGroups.push({ id: group.id, name: group.name, vehicles: vehiclesInGroup });
						}
					}
				});
				if (selectedGroups.length) {
					selectedOrganisations.push({ id: org.id, name: org.name, vehicleGroups: selectedGroups });
				}
			}
		});
	}
	return [selectedOrganisations, vehicleList, vehicleObjects];
}*!/

//
// export function createVehicleGroupsDTO(data) {
//   let vehicleGroups = [];
//   let vehicleList = [];
//   let vehicleHashMap = [];
//   if (data && data.length) {
//     data.forEach((group) => {
//       if (group.vehicles?.length !== 0) {
//         vehicleGroups.push({
//           id: group.id,
//           name: group.name,
//           organisation: {
//             id: group.organisation?.id || null,
//             name: group.organisation?.name || '',
//           },
//           vehicles: group.vehicles
//             ? group.vehicles.map((vehicle) => {
//                 return {
//                   id: vehicle.id,
//                   registrationNumber: vehicle.registrationNumber,
//                   device: vehicle?.device?.imei ? vehicle.device.imei : null,
//                   user: {
//                     id: vehicle?.driver?.id ? vehicle.driver.id : null,
//                     name: vehicle?.driver?.name ? vehicle.driver.name : '',
//                     phone: vehicle?.driver?.phone ? vehicle.driver.phone : '',
//                   },
//                   type: vehicle?.type ? vehicle.type : 1,
//                   status: vehicle?.status ? vehicle.status : 1,
//                   fuelDataSource: vehicle.fuelDataSource,
//                   immobilizer: vehicle.immobilizer,
//                 };
//               })
//             : [],
//         });
//       }
//       vehicleGroups[vehicleGroups.length - 1].vehicles.forEach((vehicle) => {
//         if (!vehicleHashMap.includes(vehicle.id)) {
//           vehicleHashMap.push(vehicle.id);
//           vehicleList.push(vehicle);
//         }
//       });
//     });
//   }
//
//   return [vehicleGroups, vehicleList];
// }


*/
