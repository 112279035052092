import { VEHICLES_DOCUMENTS_API } from '../../../constants/api';
import { AllVehiclesAllDocs, createDocumentsDTO } from '../../../dtos/document';
import Store from '../../../redux/store';
import axios from 'axios';

export function fetchDocuments(params = '') {
	return axios(VEHICLES_DOCUMENTS_API + params, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
}

export async function loadDocument(param = '', activePage = 1) {
	Store.dispatch({
		type: 'DOCUMENT_LOADING',
	});

	let documentList: AllVehiclesAllDocs | undefined = undefined; // Initialize with undefined

	try {
		const response = await fetchDocuments(param);
		const documents = createDocumentsDTO(response?.data?.data, response?.data?.metadata);
		documentList = documents;

		Store.dispatch({
			type: 'FEED_DOCUMENTS',
			payload: documents,
		});
		Store.dispatch({
			type: 'DOCUMENTS_SET_PAGE_COUNT',
			payload: response?.data?.count || 0,
		});
		Store.dispatch({
			type: 'DOCUMENTS_SET_PAGE_NUMBER',
			payload: activePage,
		});

		if (document.getElementById('CardContainer')) {
			const CardContainer = document.getElementById('CardContainer');
			if (CardContainer !== null) CardContainer.scrollTo(0, 0);
		}

		Store.dispatch({
			type: 'DOCUMENT_LOADED',
		});

		return documentList;
	} catch (error) {
		Store.dispatch({
			type: 'DOCUMENT_LOADED',
		});
		throw error; // Re-throw the error to indicate failure
	}
}

// Search
export function searchDocument(query: string) {
	return `&search=${query
		.trim()
		.split('+')
		.map((searchTerm) => `${searchTerm}`)}`;
}
