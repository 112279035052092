import mixpanel from 'mixpanel-browser';

const environment = process.env.REACT_APP_BUILD_ENVIRONMENT;

export const initializeMixpanel = () => {
	// Do nothing if development environment.
	if (environment && !['staging', 'production'].includes(environment)) return;

	mixpanel.init('7946d1a0814376622c65227d681489e2', { debug: true });
	mixpanel.register_once({
		name: localStorage.getItem('name'),
		organisation: localStorage.getItem('organisationName'),
	});
	mixpanel.identify(localStorage.getItem('userId'));
};

export const resetMixpanel = () => {
	// Do nothing if development environment.
	if (environment && !['staging', 'production'].includes(environment)) return;

	mixpanel.reset();
};

export const trackMixpanelEvent = (eventName: string, obj = {}) => {
	// Do nothing if development environment.
	if (environment && !['staging', 'production'].includes(environment)) return;

	mixpanel.track(eventName, {
		...obj,
	});
};
