import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { VehicleLocation } from '../../types/location';

export interface Vehicle {
	id: string;
}

export const vehiclesAdapter = createEntityAdapter<VehicleLocation>({
	selectId: (vehicle) => vehicle.id,
});

const vehicleSlice = createSlice({
	name: 'vehicles',
	initialState: vehiclesAdapter.getInitialState(),
	reducers: {
		vehicleUpdate: vehiclesAdapter.upsertOne,
		vehicleMultiUpdate: vehiclesAdapter.upsertMany,
		removeAll: vehiclesAdapter.removeAll,
	},
});

export default vehicleSlice;

export type VehicleSliceState = ReturnType<typeof vehicleSlice.reducer>;
