import React from 'react';
import styled from 'styled-components';

import AppStoreImage from '../../assets/images/app-store.png';
import PlayStoreImage from '../../assets/images/play-store.png';

const VerificationPageFooter = () => {
	return (
		<Container>
			<InfoContainer>
				<a href="https://play.google.com/store/apps/details?id=com.tracknerd.lokkate" target="_blank" rel="noreferrer">
					<img src={PlayStoreImage} alt="Play Store" />
				</a>
				<a href="https://apps.apple.com/in/app/lokkate/id1546343210" target="_blank" rel="noreferrer">
					<img src={AppStoreImage} alt="App Store" />
				</a>
			</InfoContainer>
			<Phone>
				<span>Contact No.</span> : +91 7483055320
			</Phone>
			<Email>
				<span>Support Mail</span> : support@tracknerd.io
			</Email>
			<Address>
				<span>Address</span> : AIC-DSU Innovation Campus, Hosur Road, Near Kudlu Gate, Bengaluru, Karnataka 560068
			</Address>
		</Container>
	);
};

export default VerificationPageFooter;

export const Container = styled.section`
	width: 100%;

	margin-top: 5em;
`;

const Address = styled.section`
	text-align: center;
	padding: 0.5em 0 0 0;

	span {
		font-weight: bold;
	}
`;

const Phone = styled.section`
	text-align: center;
	padding: 0.5em 0 0 0;

	span {
		font-weight: bold;
	}
`;

const Email = styled.section`
	text-align: center;
	padding: 0.5em 0 0 0;

	span {
		font-weight: bold;
	}
`;

const InfoContainer = styled.section`
	display: flex;
	justify-content: center;
	margin-top: 1em;

	img {
		width: 150px;
		height: 4em;
	}

	a {
		padding: 5px;
	}
`;
