import axios from 'axios';

import { initializeApp } from 'firebase/app';
import { getDatabase, off, onValue, ref } from 'firebase/database';
import { getPerformance } from 'firebase/performance';
import { getMessaging, getToken, MessagePayload, onMessage } from 'firebase/messaging';

// import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import uniqueString from 'unique-string';
import moment from 'moment';
import { Database, DatabaseReference } from '@firebase/database';
import { FirebasePerformance } from '@firebase/performance/dist/src/public_types';
import { Messaging } from '@firebase/messaging';

import vehicleSlice from '../../redux/reducers/vehicleSlice';
import { VehicleLocation } from '../../types/location';
import { USER_DEVICES_API } from '../../constants/api';
import { FIREBASE_CONFIG, VEHICLE_STATUS } from '../../constants/constants';
import { generateInfoMessage, handleError } from '../view specific/messaging';
import Store from '../../redux/store';
import { getAuthHeaders } from '../../utils/auth';
// import { getElapsedTime } from '../../utils/calendar';
import { initializePushNotificationEnableStorage } from '../../utils/common';
import { OrganisationData } from '../../dtos/vehicle';
// import MessagePayload = firebase.messaging.MessagePayload;

export let database: Database;
export let performanceRef: FirebasePerformance;

let messaging: Messaging;

export function initializeFirebase() {
	// Only initialize if not already initialized.
	const app = initializeApp(FIREBASE_CONFIG);

	// Initialize Firebase Cloud Messaging and get a reference to the service
	messaging = getMessaging(app);

	// Initialize Realtime Database and get a reference to the service
	database = getDatabase(app);

	// intialize Performance Monitoring for web
	performanceRef = getPerformance(app);
}

// Realtime Database
export function connectToFirebase(vehicles: number[], allVehicleDetails: Map<number, OrganisationData>) {
	const vehiclePool: DatabaseReference[] = [];
	let vehicleRef: DatabaseReference;
	const vehicleState = Store.getState().vehicleSlice.entities;

	vehicles.forEach((vehicle) => {
		const vehicleDetail = allVehicleDetails.get(vehicle);
		const vehicleLocation: VehicleLocation = {
			id: vehicle,
			filterStatus: 'defaultFilterStatus',
			location: {
				status: 'defaultStatus',
				timestamp: '',
				vehicleType: 'defaultVehicleType',
				latitude: 0,
				longitude: 0,
				speed: 0,
				externalPowerVoltage: 0,
				totalOdometer: 0,
				gnssStatus: -1,
				relayStatus: false,
				ignitionStatus: true,
				soc: 0,
				dte: 0,
			},
		}; // Initialize as an empty object
		if (vehicleDetail && vehicle) {
			vehicleRef = ref(database, `/${vehicle + '-' + vehicleDetail.data?.registrationNumber}`);
			vehiclePool.push(vehicleRef);
			onValue(vehicleRef, (snapshot) => {
				if (snapshot.val()) {
					vehicleLocation.id = vehicle;
					vehicleLocation.filterStatus = getVehicleStatus(snapshot.val());

					if (snapshot.val()?.location) {
						vehicleLocation.location = {
							status: snapshot.val().location?.statusV2,
							timestamp: snapshot.val().location?.timestamp,
							vehicleType: snapshot.val().location?.vehicleType,
							latitude: 0,
							longitude: 0,
							speed: snapshot.val().location?.speed,
							externalPowerVoltage: snapshot.val().location?.externalPowerVoltage,
							totalOdometer: snapshot.val().location?.totalOdometer,
							gnssStatus: snapshot.val().location?.gnssStatus,
							relayStatus: snapshot.val().location?.relayStatus,
							ignitionStatus: snapshot.val().location?.ignitionStatus,
							soc: snapshot.val().location?.soc ? snapshot.val().location.soc : null,
							dte: snapshot.val().location?.dte ? snapshot.val().location.dte : null,
						};
					}

					const pastPdop = vehicleState[vehicle]?.location.gnssPDOP;
					const currentPdop = snapshot.val().location?.gnssPDOP || null;

					const latitude = snapshot.val().location?.latitude;
					const longitude = snapshot.val().location?.longitude;

					if (
						snapshot.val().location.ignitionStatus ||
						(!snapshot.val().location.ignitionStatus && currentPdop && pastPdop && currentPdop <= pastPdop) ||
						(!snapshot.val().location.ignitionStatus && !pastPdop)
					) {
						vehicleLocation.location.latitude = latitude;
						vehicleLocation.location.longitude = longitude;
					} else {
						const latitude = vehicleState[vehicle]?.location?.latitude;
						const longitude = vehicleState[vehicle]?.location?.longitude;
						vehicleLocation.location.latitude = latitude !== undefined ? latitude : 0;
						vehicleLocation.location.longitude = longitude !== undefined ? longitude : 0;
					}

					if (snapshot.val()?.relay) vehicleLocation.relay = snapshot.val().relay;
					if (snapshot.val()?.recalculation) vehicleLocation.recalculation = snapshot.val().recalculation;
					if (snapshot.val()?.ignition) vehicleLocation.relay = snapshot.val().relay;
					if (snapshot.val()?.wheellock) vehicleLocation.recalculation = snapshot.val().recalculation;
					Store.dispatch(vehicleSlice.actions.vehicleUpdate(vehicleLocation));
				}
			});
		}
	});
	Store.dispatch({
		type: 'FEED_VEHICLE_POOL',
		payload: {
			vehiclePool,
		},
	});
}

export function UnSubscribeVehicleFromFirebase(path: string) {
	const vehicleRef = ref(database, path);
	off(vehicleRef, 'value');
}

/*export function connectToFirebaseold(vehicles) {
	// Get previous data from Store.
	const storeIntervalId = Store.getState()['firebaseService'].intervalId;

	// const storeIgnitionReferencePool = Store.getState()["firebaseService"].ignitionReferencePool;
	// const storeMovementReferencePool = Store.getState()["firebaseService"].movementReferencePool;
	// const storeLocationReferencePool = Store.getState()["firebaseService"].locationReferencePool;
	// const storeTimestampReferencePool = Store.getState()["firebaseService"].timestampReferencePool;
	// const storeRelayReferencePool = Store.getState()["firebaseService"].relayReferencePool;
	// const storeBatteryReferencePool = Store.getState()["firebaseService"].batteryReferencePool;
	// const storeRecalculationReferencePool = Store.getState()["firebaseService"].recalculationReferencePool;
	// const storeRecalculationQueueReferencePool = Store.getState()["firebaseService"].recalculationQueueReferencePool;
	// const storeFuelReferencePool = Store.getState()["firebaseService"].fuelReferencePool;
	// const storeRemoteIgnitionReferencePool = Store.getState()["firebaseService"].remoteIgnitionReferencePool;
	// const storeWheelLockReferencePool = Store.getState()["firebaseService"].wheelLockReferencePool;

	// Clear Interval
	if (storeIntervalId) clearInterval(storeIntervalId);

	// Unsubscribe from Firebase
	// storeIgnitionReferencePool.forEach((ignitionReference) => {
	//   if (ignitionReference) ignitionReference.off();
	// });
	//
	// storeMovementReferencePool.forEach((movementReference) => {
	//   if (movementReference) movementReference.off();
	// });
	//
	// storeLocationReferencePool.forEach((locationReference) => {
	//   if (locationReference) locationReference.off();
	// });
	//
	// storeTimestampReferencePool.forEach((timestampReference) => {
	//   if (timestampReference) timestampReference.off();
	// });
	//
	// storeRelayReferencePool.forEach((relayReference) => {
	//   if (relayReference) relayReference.off();
	// });
	//
	// storeBatteryReferencePool.forEach((batteryReference) => {
	//   if (batteryReference) batteryReference.off();
	// });
	//
	// storeRecalculationReferencePool.forEach((recalculationReference) => {
	//   if (recalculationReference) recalculationReference.off();
	// });
	//
	// storeRecalculationQueueReferencePool.forEach((recalculationQueueReference) => {
	//   if (recalculationQueueReference) recalculationQueueReference.off();
	// });
	//
	// storeFuelReferencePool.forEach((fuelReference) => {
	//   if (fuelReference) fuelReference.off();
	// });
	//
	// storeRemoteIgnitionReferencePool.forEach((remoteIgnitionReference) => {
	//   if (remoteIgnitionReference) remoteIgnitionReference.off();
	// });
	//
	// storeWheelLockReferencePool.forEach((wheelLockReference) => {
	//   if (wheelLockReference) wheelLockReference.off();
	// });

	let ignitionReferencePool = [];
	// let movementReferencePool = [];
	let locationReferencePool = [];
	let relayReferencePool = [];
	let batteryReferencePool = [];
	let recalculationReferencePool = [];
	let recalculationQueueReferencePool = [];
	let keyLockReferencePool = [];

	let ignitionReference = null;
	// let movementReference = null;x
	let locationReference = null;
	let relayReference = null;
	let batteryReference = null;
	let recalculationReference = null;
	let recalculationQueueReference = null;
	let keyLockReference = null;

	let ignitionStatus = {};
	let locationStatus = {};
	let relayStatus = {};
	let batteryStatus = {};
	let recalculationStatus = {};
	let recalculationQueueStatus = {};
	let keyLockStatus = {};

	let movingVehicles = 0;
	let idleVehicles = 0;
	let stoppedVehicles = 0;
	let noDataOneDayVehicles = 0;
	let noDataOneHourVehicles = 0;
	let noDataVehicles = 0;
	let status;

	let shouldUpdate = false;
	vehicles.forEach((vehicle) => {
		ignitionReference = ref(database, `/${vehicle.id + '-' + vehicle.registrationNumber}/ignition`);
		ignitionReferencePool.push(ignitionReference);
		onValue(ignitionReference, (snapshot) => {
			if (snapshot.val() !== null) {
				ignitionStatus = {
					...ignitionStatus,
					[vehicle.id]: snapshot.val(),
				};
				shouldUpdate = true;
			}
		});

		locationReference = ref(database, `/${vehicle.id + '-' + vehicle.registrationNumber}/location`);
		locationReferencePool.push(locationReference);
		onValue(locationReference, (snapshot) => {
			if (snapshot.val() !== null) {
				locationStatus = {
					...locationStatus,
					[vehicle.id]: snapshot.val(),
				};
				shouldUpdate = true;
			}
		});

		relayReference = ref(database, `/${vehicle.id + '-' + vehicle.registrationNumber}/relay`);
		relayReferencePool.push(relayReference);
		onValue(relayReference, (snapshot) => {
			if (snapshot.val() !== null) {
				relayStatus = {
					...relayStatus,
					[vehicle.id]: snapshot.val(),
				};
				shouldUpdate = true;
			}
		});

		batteryReference = ref(database, `/${vehicle.id + '-' + vehicle.registrationNumber}/battery`);
		batteryReferencePool.push(batteryReference);
		onValue(batteryReference, (snapshot) => {
			if (snapshot.val() !== null) {
				batteryStatus = {
					...batteryStatus,
					[vehicle.id]: snapshot.val(),
				};
				shouldUpdate = true;
			}
		});

		recalculationReference = ref(database, `/${vehicle.id + '-' + vehicle.registrationNumber}/recalculation`);
		recalculationReferencePool.push(recalculationReference);
		onValue(recalculationReference, (snapshot) => {
			if (snapshot.val() !== null) {
				recalculationStatus = {
					...recalculationStatus,
					[vehicle.id]: snapshot.val(),
				};
				shouldUpdate = true;
			}
		});

		recalculationQueueReference = ref(database, `/${vehicle.id + '-' + vehicle.registrationNumber}/recalculationQueue`);
		recalculationQueueReferencePool.push(recalculationQueueReference);
		onValue(recalculationQueueReference, (snapshot) => {
			if (snapshot.val() !== null) {
				recalculationQueueStatus = {
					...recalculationQueueStatus,
					[vehicle.id]: snapshot.val(),
				};
				shouldUpdate = true;
			}
		});

		keyLockReference = ref(database, `/${vehicle.id + '-' + vehicle.registrationNumber}/keylock`);
		keyLockReferencePool.push(keyLockReference);
		onValue(keyLockReference, (snapshot) => {
			if (snapshot.val() !== null) {
				keyLockStatus = {
					...keyLockStatus,
					[vehicle.id]: snapshot.val(),
				};
				shouldUpdate = true;
			}
		});
	});

	vehicles.forEach((vehicle) => {
		status = getVehicleStatus(vehicle.id, locationStatus);

		if (status === VEHICLE_STATUS.Moving) ++movingVehicles;
		if (status === VEHICLE_STATUS.Idle) ++idleVehicles;
		if (status === VEHICLE_STATUS.Stopped) ++stoppedVehicles;
		if (status === VEHICLE_STATUS.NoDataOneHour) ++noDataOneDayVehicles;
		if (status === VEHICLE_STATUS.NoDataOneDay) ++noDataOneHourVehicles;
		if (status === VEHICLE_STATUS.NoData) ++noDataVehicles;
	});

	Store.dispatch({
		type: 'FEED_REFERENCE_POOL',
		payload: {
			ignitionReferencePool,
			// movementReferencePool,
			locationReferencePool,
			relayReferencePool,
			batteryReferencePool,
			recalculationReferencePool,
			recalculationQueueReferencePool,
			keyLockReferencePool,
		},
	});

	const intervalId = setInterval(() => {
		if (shouldUpdate) {
			vehicles.forEach((vehicle) => {
				status = getVehicleStatus(vehicle.id, locationStatus);

				if (status === VEHICLE_STATUS.Moving) ++movingVehicles;
				if (status === VEHICLE_STATUS.Idle) ++idleVehicles;
				if (status === VEHICLE_STATUS.Stopped) ++stoppedVehicles;
				if (status === VEHICLE_STATUS.NoDataOneHour) ++noDataOneHourVehicles;
				if (status === VEHICLE_STATUS.NoDataOneDay) ++noDataOneDayVehicles;
				if (status === VEHICLE_STATUS.NoData) ++noDataVehicles;
			});

			Store.dispatch({
				type: 'FEED_VEHICLE_DATA',
				payload: {
					ignitionStatus,
					locationStatus,
					relayStatus,
					batteryStatus,
					recalculationStatus,
					recalculationQueueStatus,
					keyLockStatus,
					movingVehicles,
					idleVehicles,
					stoppedVehicles,
					noDataOneHourVehicles,
					noDataOneDayVehicles,
					noDataVehicles,
					totalVehicles: vehicles?.length,
				},
			});
			shouldUpdate = false;
			movingVehicles = 0;
			idleVehicles = 0;
			stoppedVehicles = 0;
			noDataOneDayVehicles = 0;
			noDataOneHourVehicles = 0;
			noDataVehicles = 0;
		}
	}, 2000);

	Store.dispatch({ type: 'FEED_INTERVAL_ID', payload: { intervalId } });

	Store.dispatch({
		type: 'FEED_VEHICLE_DATA',
		payload: {
			ignitionStatus,
			locationStatus,
			relayStatus,
			batteryStatus,
			recalculationStatus,
			recalculationQueueStatus,
			keyLockStatus,
			movingVehicles,
			idleVehicles,
			stoppedVehicles,
			noDataOneHourVehicles,
			noDataOneDayVehicles,
			noDataVehicles,
			totalVehicles: vehicles?.length,
		},
	});
	movingVehicles = 0;
	idleVehicles = 0;
	stoppedVehicles = 0;
	noDataOneDayVehicles = 0;
	noDataOneHourVehicles = 0;
	noDataVehicles = 0;
}*/

/*function getVehicleStatus(vehicle, location) {
	let elapsedTime;
	if (location?.[vehicle] !== undefined) {
		elapsedTime = getElapsedTime(location[vehicle].timestamp, 'minutes');
		if (elapsedTime < 1440) {
			if (elapsedTime < 60) {
				if (location[vehicle].statusV2 !== undefined) {
					if (location[vehicle].statusV2 === 'Ignition off') {
						return VEHICLE_STATUS.Stopped;
					} else {
						if (location[vehicle].statusV2 === 'Moving') {
							return VEHICLE_STATUS.Moving;
						} else {
							return VEHICLE_STATUS.Idle;
						}
					}
				} else {
					return VEHICLE_STATUS.Stopped;
				}
			} else {
				return VEHICLE_STATUS.NoDataOneHour;
			}
		} else {
			return VEHICLE_STATUS.NoDataOneDay;
		}
	} else {
		return VEHICLE_STATUS.NoData;
	}
}*/

export const getVehicleStatus = (vehicle: VehicleLocation) => {
	if (!vehicle?.location?.timestamp) return VEHICLE_STATUS.NoData;
	if (moment().diff(moment(vehicle?.location?.timestamp), 'day') >= 1) return VEHICLE_STATUS.NoDataOneDay;
	if (moment().diff(moment(vehicle?.location?.timestamp), 'hour') >= 1) return VEHICLE_STATUS.NoDataOneHour;
	if (vehicle?.location?.statusV2 === 'Moving') return VEHICLE_STATUS.Moving;
	if (vehicle?.location?.statusV2 === 'Idle') return VEHICLE_STATUS.Idle;
	return VEHICLE_STATUS.Stopped;
};

// Push Notifications
export function initializePushNotificationService() {
	getToken(messaging, { vapidKey: process.env.REACT_APP_FCM_VALID_KEY })
		.then((token) => {
			Store.dispatch({ type: 'SET_NOTIFICATION_PERMISSIONS' });
			if (localStorage.getItem('pushToken')) {
				sendUpdatedTokenToServer(token).catch((error) => {
					handleError('firebase.ts => initializePushNotificationService(): ', error);
				});
			} else {
				sendTokenToServer(token).catch();
			}
		})
		.catch(() => {
			Store.dispatch({ type: 'CLEAR_NOTIFICATION_PERMISSIONS' });
			// handleError('firebase.ts => sendTokenToServer(): ', error);
		});
}

export async function sendTokenToServer(token: string) {
	let browserId = localStorage.getItem('browserId');
	if (!browserId) browserId = uniqueString();

	axios
		.post(
			USER_DEVICES_API,
			{
				deviceId: browserId,
				token,
			},
			getAuthHeaders()
		)
		.then(() => {
			localStorage.setItem('pushToken', token);
			localStorage.setItem('browserId', JSON.stringify(browserId));
			initializePushNotificationEnableStorage();
			subscribeToPushNotifications();
		})
		.catch((error) => {
			handleError('firebase.ts => sendTokenToServer(): ', error);
		});
}

export async function sendUpdatedTokenToServer(token: string) {
	const browserId = localStorage.getItem('browserId');
	if (!browserId) return;

	axios
		.patch(
			USER_DEVICES_API + `/${browserId}`,
			{
				token,
			},
			getAuthHeaders()
		)
		.then(() => {
			localStorage.setItem('pushToken', token);
			subscribeToPushNotifications();
		})
		.catch((error) => {
			handleError('firebase.ts => sendUpdatedTokenToServer(): ', error);
		});
}

/*export async function deleteTokenFromServer() {
  const browserId = localStorage.getItem('browserId');
  if (!browserId) return;

  axios.delete(USER_DEVICES_API + `/${browserId}`, getAuthHeaders()).catch((error) => {
  });
}*/

export async function subscribeToPushNotifications() {
	onMessage(messaging, (payload: MessagePayload) => {
		if (payload && payload?.notification) {
			const messageBody = payload.notification.body || 'Notification';
			generateInfoMessage(messageBody);
		}
	});
}

export function resetPushNotificationService() {
	if (localStorage.getItem('pushToken')) localStorage.removeItem('pushToken');
	if (localStorage.getItem('browserId')) localStorage.removeItem('browserId');
}

export async function getUserDeviceFromServer() {
	const browserId = localStorage.getItem('browserId');
	return axios(USER_DEVICES_API + `/${browserId}`, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
}
