import styled from 'styled-components';
import { THEME, themeColor } from '../../../../../constants/Theme';

interface blockProps {
	activeType?: boolean;
	top?: string;
}

export const Container = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	position: relative;
`;

export const ShouldAdjustMap = styled.section`
	position: absolute;
	top: 6em;
	right: 0;
	display: flex;
	flex-direction: column;

	.ui.icon.button {
		color: black !important;
		background-color: #fafafa !important;
	}
`;
export const ControlsContainer = styled.section`
	position: absolute;
	bottom: 2.2em;
	left: 1em;
	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const MapTypesContainer = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 0 0.5em;
`;

export const MapTypesLabels = styled.section<blockProps>`
	font-size: 0.85em;
	font-weight: bold;

	color: ${(props) => (props.activeType ? `${THEME.COLORS.theme}` : 'black')};
`;

export const MapTypeControls = styled.button<blockProps>`
	width: 3em;
	height: 3em;

	box-sizing: border-box;
	padding: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 8px;
	border: 2px solid ${(props) => (props.activeType ? `${THEME.COLORS.theme}` : 'white')};

	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
		border-radius: 8px;

		object-fit: none;
	}
`;

export const ActiveMapTypes = styled.button`
	width: 5em;
	height: 5em;

	box-sizing: border-box;
	padding: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 8px;
	border: 2px solid white;

	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
		border-radius: 8px;

		object-fit: contain;
	}
`;

export const EventsContainer = styled.section<blockProps>`
	position: absolute;
	top: ${(props) => (props.top ? props.top : '7em')};
	left: 1em;
	z-index: 10;

	box-sizing: border-box;
	padding: 0.5em;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 8px;
	box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
	background-color: #fff;
`;

export const AnimationControls = styled.section`
	position: absolute;
	bottom: 1em;
	left: 25%;
	background: white;
	padding: 0.5em;
	border-radius: 2em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 50%;
	box-shadow:
		0 4px 8px 0 rgba(0, 0, 0, 0.2),
		0 6px 20px 0 rgba(0, 0, 0, 0.19);

	.animationIcon {
		color: ${themeColor};
	}

	.animationIcon:hover {
		color: black;
	}

	.playPause {
		background-color: white;
		margin-right: 0.5em;
		border-radius: 150%;
	}

	.playPause:focus {
		background-color: white;
		//margin-right: 0.5em;
	}

	.playPause:hover {
		background-color: white;

		.animationIcon {
			color: black;
			transform: scale(1.5);
		}
	}

	.stop {
		background-color: white;
		margin-left: 0.5em;
		border-radius: 150%;
	}

	.stop:focus {
		background-color: white;
		//margin-right: 0.5em;
	}

	.stop:hover {
		background-color: white;

		.animationIcon {
			color: black;
			transform: scale(1.5);
		}
	}

	input {
		width: 80%;
	}
`;

export const SummaryContainer = styled.section<blockProps>`
	position: absolute;
	top: ${(props) => (props.top ? props.top : '1em')};
	left: 1.8em;
	z-index: 10;

	box-sizing: border-box;
	padding: 0.5em;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 8px;
	box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
	background-color: #fff;
`;

export const PathTypeInfoContainer = styled.section<blockProps>`
	position: absolute;
	top: 17em;
	right: 0.5em;
	z-index: 10;

	box-sizing: border-box;
	//padding: 0.5em;

	display: grid;
	grid-template-columns: repeat(1, 2fr);
	padding: 2px;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	grid-gap: 2px;

	border-radius: 8px;
	box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
	background-color: #fff;
`;

export const PathTypeInfoBlock = styled.section`
	display: flex;
	flex-direction: row;
	padding: 2px 10px;
	flex: 1;
	align-items: center;
	justify-content: space-between;

	font-size: 0.9em;
	font-weight: bold;

	span {
		margin-left: 2em;
	}
`;

interface HorizontalLinePathTypeInterface {
	color?: string;
	width?: string;
}

export const HorizontalLinePathTypeInfo = styled.hr<HorizontalLinePathTypeInterface>`
	color: ${(props) => (props.color ? props.color : THEME.COLORS.multiplePolyLinesTrack?.[0])};
	background-color: ${(props) => (props.color ? props.color : THEME.COLORS.multiplePolyLinesTrack?.[0])};
	border-color: ${(props) => (props.color ? props.color : THEME.COLORS.multiplePolyLinesTrack?.[0])};
	height: 2px;
	width: ${(props) => (props.width ? props.width : '75px')};
	border-radius: 5em;
`;

export const PathType = styled.span`
	width: 7.5em;
`;

export const RealtimeInfoWindow = styled.section`
	position: absolute;
	bottom: 5em;
	left: 37%;
	background: white;
	padding: 0.4em;
	border-radius: 1em;
	box-shadow:
		0 4px 8px 0 rgba(0, 0, 0, 0.2),
		0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const SummaryBlock = styled.section`
	display: flex;
	align-items: center;

	font-size: 0.9em;
	font-weight: bold;

	span {
		margin-left: 2em;
	}
`;

export const SummaryLabel = styled.section`
	display: flex;
	align-items: center;

	width: 1.5em;
`;

export const SummaryTimeBlock = styled.section`
	display: flex;
	align-items: center;

	font-size: 0.9em;
	font-weight: bold;

	span {
		margin-left: 0.5em;
	}
`;

/*export const CustomMarkers = styled.section`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
`;*/
export const EventLabel = styled.section`
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 0.9em;
	font-weight: bold;
`;

export const EventsBlock = styled.section`
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 0.9em;
	font-weight: bold;

	margin-top: 0.5em;
`;
