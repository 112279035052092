import React, { useEffect, useState } from 'react';

import { EXPORT_API } from '../../../constants/api';
import TrackNerdLoader from '../../../components/loader/Loader';
import { Expander } from '../../../components/STYLED/Expander';
import { CategoryFooter } from '../../../components/STYLED/CategoryFooter';
import { DRIVER_DOCUMENTS_TYPE_OPTIONS, PAGE_COUNT_OPTIONS } from '../../../constants/constants';
import { fetchOrganisations, handleDataSizeLimitChange } from '../../../utils/common';
import NoResult from '../../../assets/images/no-results.png';
import { loadDrivers, searchDriver } from './DriversMiddleware';
import { handleError } from '../../../helper/view specific/messaging';
import { useSelector } from 'react-redux';
import {
	Card,
	CardContainer,
	CategoryHeader,
	Container,
	DocumentBlock,
	DocumentName,
	DriverContact,
	DriverInfo,
	DriverName,
	Email,
	Filter,
	MainContainer,
	MainContent,
	NoMatchFoundContainer,
	OrgName,
	PageSelector,
	Phone,
	SearchContainer,
	VehicleChasis,
	VehicleChasisEmpty,
	VerticalBlock,
} from './DriversStyles';
import { Button, Dropdown, Form, Header, Icon, Input, Modal, Pagination, Popup, Segment } from 'semantic-ui-react';
import axios from 'axios';

import TransitionLoader from '../../../components/loader/TransitionLoader';
import PdfView from '../../../components/documentView/pdf-view/PdfView';
import ImageView from '../../../components/documentView/image-view/ImageView';
import CarImage from '../../../assets/images/vehicle-type-1.png';
import BikeImage from '../../../assets/images/vehicle-type-2.png';
import TruckImage from '../../../assets/images/vehicle-type-3.png';
import ConstructionImage from '../../../assets/images/vehicle-type-4.png';
import BusImage from '../../../assets/images/vehicle-type-5.png';
import GeneratorImg from '../../../assets/images/vehicle-type-6.png';
import ContainerImg from '../../../assets/images/vehicle-type-7.png';
import MiniTruck from '../../../assets/images/vehicle-type-8.png';
import AutoImg from '../../../assets/images/vehicle-type-9.png';
import { THEME } from '../../../constants/Theme';
import { RootState } from '../../../redux/RootState';
import { Organisation } from '../../../types/organisation';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { PaginationProps } from 'semantic-ui-react/dist/commonjs/addons/Pagination/Pagination';
import { DriverList } from '../../../dtos/driver';

interface OrganisationsForFilter {
	key: number;
	value: number;
	text: string;
}

interface ActiveFilters {
	organisations?: string[];
	vehicleGroups?: string[];
	vehicles?: number[];
	documentType?: string[];
	expiryStartDate?: string | null;
	expiryEndDate?: string | null;
	isExpired?: boolean;
}

function Drivers() {
	const [initialLoading, setInitialLoading] = useState(true);

	// adding driver
	const [drivers, setDrivers] = useState<DriverList[]>([]);

	//filters
	const [filterModalOpen, setFilterModalOpen] = useState(false);

	const [organisationsForFilter, setOrganisationsForFilter] = useState<OrganisationsForFilter[]>([]);
	const [filteredOrgs, setFilteredOrgs] = useState<string[]>([]);
	const [filteredDocumentType, setFilteredDocumentType] = useState<string[]>([]);

	const [searchQuery, setSearchQuery] = useState('');

	const [activeFilters, setActiveFilters] = useState<ActiveFilters>({
		organisations: [],
		vehicleGroups: [],
		vehicles: [],
		documentType: [],
		expiryStartDate: null,
		expiryEndDate: null,
		isExpired: false,
	});

	//file preview
	const [preview, setPreview] = useState(false);
	const [previewType, setPreviewType] = useState('application/pdf');
	const [previewSource, setPreviewSource] = useState('');

	const [filterCount, setFilterCount] = useState(0);
	const [fileName, setFileName] = useState<string>('');

	// const PermissionState = (Store.getState() as RootState).rolePermissions.permissions;

	const PermissionState = useSelector((state: RootState) => state.rolePermissions.permissions);
	const loadingState = useSelector((state: RootState) => state.driver.loading);
	const countState = useSelector((state: RootState) => state.driver.count);
	const dataSizeLimit = useSelector((state: RootState) => state.ui.dataSizeLimit);
	const pageNumberState = useSelector((state: RootState) => state.driver.pageNumber);

	useEffect(() => {
		getFilteredDocuments();
	}, [searchQuery, filterCount, dataSizeLimit]);

	const getFilteredDocuments = (activePage = 1) => {
		let query = '';

		if (searchQuery !== '') {
			query = query + searchDriver(searchQuery);
		}

		if (filteredOrgs.length) {
			query = query + `&organisations[]=${filteredOrgs.join('&organisations[]=')}`;
		}

		if (filteredDocumentType.length) {
			query = query + `&documentType[]=${filteredDocumentType.join('&documentType[]=')}`;
		}

		loadDrivers(query, dataSizeLimit, activePage).then((response) => {
			setDrivers(response);
			setInitialLoading(false);
		});
	};

	const getOrganisations = () => {
		fetchOrganisations()
			.then((response) => {
				const organisations = response?.data?.data.map((organisation: Organisation, index: number) => {
					return {
						key: index,
						value: organisation.id,
						text: organisation.name,
					};
				});
				setOrganisationsForFilter(organisations);
				/*const organisations = createOrganisationDTO(response?.data?.data);
                if (organisations !== undefined) {
                    organisations.map((organisation, index) => {
                        return {
                            key: index,
                            value: organisation.id,
                            text: organisation.name,
                        };
                    });

                    setOrganisationsForFilter(organisations);
                }*/
			})
			.catch((error) => {
				handleError('Drivers.tsx => getOrganisations()', error);
			});
	};

	function fetchDocumentLink(docId: number, fileName: string) {
		setInitialLoading(true);
		axios(EXPORT_API + `/documents/${docId}`, {
			responseType: 'arraybuffer',
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		})
			.then((response) => {
				setPreview(true);
				setPreviewType(response.headers['content-type']);
				setPreviewSource(response.data);

				setInitialLoading(false);
				const extension = response.headers['content-type'].split('/').at(-1);

				setFileName(`${fileName}.${extension}`);
			})
			.catch((error) => {
				handleError('Drivers.tsx => fetchDocumentLink()', error);
				setInitialLoading(false);
				setPreview(false);
			});
	}

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(e.target.value);
	};

	const closePreviewModal = () => {
		setPreview(false);
	};

	const openFilterModal = () => {
		setActiveFilters({ organisations: filteredOrgs, documentType: filteredDocumentType });
		setFilterModalOpen(true);
	};

	const closeFilterModal = () => {
		setActiveFilters({});
		setFilterModalOpen(false);
	};

	const resetFilterModal = () => {
		setActiveFilters({ organisations: [], documentType: [] });
		setFilterCount(0);
		setFilterModalOpen(true);
		setFilteredOrgs([]);
		setFilteredDocumentType([]);
	};

	const getVehicleIcon = (vehicleType: string) => {
		return vehicleType === 'Car'
			? CarImage
			: vehicleType === 'Bike'
			  ? BikeImage
			  : vehicleType === 'Truck'
			    ? TruckImage
			    : vehicleType === 'Construction Machinery'
			      ? ConstructionImage
			      : vehicleType === 'Bus'
			        ? BusImage
			        : vehicleType === 'Generator'
			          ? GeneratorImg
			          : vehicleType === 'Container'
			            ? ContainerImg
			            : vehicleType === 'Mini Truck'
			              ? MiniTruck
			              : vehicleType === 'Auto'
			                ? AutoImg
			                : CarImage;
	};

	const setFilters = () => {
		if (activeFilters.organisations) setFilteredOrgs(activeFilters.organisations);
		if (activeFilters.documentType) setFilteredDocumentType(activeFilters.documentType);
	};

	return (
		<Container>
			{!searchQuery && initialLoading ? (
				<TrackNerdLoader />
			) : (
				<MainContainer>
					<CategoryHeader>
						<SearchContainer>
							<Input
								value={searchQuery}
								style={{ width: '20em' }}
								{...(searchQuery && {
									icon: (
										<Icon
											name="times circle outline"
											link
											onClick={() => {
												setSearchQuery('');
											}}
										/>
									),
								})}
								placeholder="Search Vehicle/Organisation/Driver..."
								disabled={!searchQuery && loadingState}
								onChange={handleSearch}
							/>
						</SearchContainer>
						<Expander />
						<Filter>
							<Button
								icon
								disabled={!(PermissionState.Organisation.manage || PermissionState.Organisation.read)}
								primary={filterCount <= 0}
								style={{ backgroundColor: filterCount > 0 ? 'orange' : 'inherit', color: '#fff' }}
								// color={filterCount > 0 ? 'orange' : 'inherit'}
								onClick={() => {
									getOrganisations();
									openFilterModal();
								}}>
								<Icon name="filter" />
							</Button>
						</Filter>
					</CategoryHeader>
					<MainContent>
						{drivers !== null && drivers.length > 0 ? (
							<>
								<CardContainer id="CardContainer">
									{drivers.map((driver, index) => {
										const vehicleType = driver.vehicleType;
										return (
											<Card key={index}>
												<DriverInfo>
													<section style={{ width: '100%' }}>
														<DriverName>
															<span>{driver.driverName}</span>
														</DriverName>
														<OrgName>
															<span>{driver.orgName}</span>
														</OrgName>
														{driver.vehicleRegistrationNumber !== null ? (
															<VehicleChasis>
																<span>{driver.vehicleRegistrationNumber}</span>
																<img src={getVehicleIcon(vehicleType ? vehicleType : '')} alt="" />
															</VehicleChasis>
														) : (
															<VehicleChasisEmpty>
																<span style={{ fontStyle: 'italic', fontSize: '0.75em' }}>No Vehicle attached!</span>
																<img src={getVehicleIcon(vehicleType ? vehicleType : '')} alt="" />
															</VehicleChasisEmpty>
														)}
														<DriverContact>
															<Phone>
																<Icon name="call" size="small" style={{}} />
																<span>
																	{driver.driverMobileNumber !== null ? driver.driverMobileNumber : 'No Phone attached'}
																</span>
															</Phone>
															<Email>
																<Icon name="mail" size="small" style={{}} />
																<span>{driver.driverEmail !== null ? driver.driverEmail : 'No Email attached'}</span>
															</Email>
														</DriverContact>
													</section>
												</DriverInfo>

												<VerticalBlock>
													{(filteredDocumentType.length === 0 || filteredDocumentType.includes('Aadhaar')) && (
														<Popup
															basic
															style={{ fontSize: '0.85em' }}
															content={
																driver.aadhaarDocument !== null ? 'CLick to view the Aadhaar' : 'Please upload Aadhaar'
															}
															trigger={
																<DocumentBlock
																	onClick={() => {
																		if (driver?.aadhaarDocument)
																			fetchDocumentLink(
																				driver?.aadhaarDocument.docId,
																				`${driver.driverName} - Aadhaar`
																			);
																	}}
																	color="#E5F6FD"
																	fontStyle={driver.aadhaarDocument !== null ? 'normal' : 'italic'}
																	fontColor={driver.aadhaarDocument !== null ? 'black' : '#909B9E'}>
																	<Icon name="address card" style={{ marginBottom: '2%' }} link />
																	<DocumentName>
																		<span>Aadhaar</span>
																	</DocumentName>
																	<span> {driver.aadhaarDocument !== null ? 'N.A' : 'not uploaded'}</span>
																</DocumentBlock>
															}
														/>
													)}

													{(filteredDocumentType.length === 0 || filteredDocumentType.includes('Driving License')) && (
														<Popup
															basic
															style={{ fontSize: '0.85em' }}
															content={
																driver.drivingLicenseDocument !== null
																	? `Driving License expires on ${driver.drivingLicenseDocument?.expiry}`
																	: 'Please upload Driving License'
															}
															trigger={
																<DocumentBlock
																	onClick={() => {
																		if (driver?.drivingLicenseDocument)
																			fetchDocumentLink(
																				driver?.drivingLicenseDocument.docId,
																				`${driver.driverName} - Driving Licence`
																			);
																	}}
																	color="#E2F6E9"
																	fontStyle={driver.drivingLicenseDocument !== null ? 'normal' : 'italic'}
																	fontColor={driver.drivingLicenseDocument !== null ? 'black' : '#909B9E'}>
																	<Icon name="address card" style={{ marginBottom: '2%' }} link />
																	<DocumentName>
																		<span>License</span>
																	</DocumentName>
																	<span>
																		{driver.drivingLicenseDocument !== null
																			? driver.drivingLicenseDocument?.expiry
																			: 'not uploaded'}
																	</span>
																</DocumentBlock>
															}
														/>
													)}
												</VerticalBlock>
											</Card>
										);
									})}
								</CardContainer>
								<CategoryFooter>
									<Expander />
									<PageSelector>
										<Dropdown
											fluid
											selection
											defaultValue={dataSizeLimit}
											disabled={loadingState || !drivers?.length}
											options={PAGE_COUNT_OPTIONS}
											onChange={(e, data: DropdownProps) => {
												if (data?.value && typeof data.value === 'number') {
													handleDataSizeLimitChange(data.value);
												}
											}}
										/>
									</PageSelector>
									<Pagination
										activePage={pageNumberState}
										disabled={loadingState || !drivers?.length}
										ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
										firstItem={{ content: <Icon name="angle double left" />, icon: true }}
										lastItem={{ content: <Icon name="angle double right" />, icon: true }}
										nextItem={null}
										onPageChange={(event, data: PaginationProps) => {
											const inputValue = data.activePage; // Assuming data.activePage is of type string or number or undefined

											if (typeof inputValue === 'number') {
												// If it's already a number, you can pass it directly
												getFilteredDocuments(inputValue);
											} else if (typeof inputValue === 'string') {
												// If it's a string, attempt to parse it as a number
												const numericValue = parseInt(inputValue, 10); // or parseFloat(inputValue) for decimal numbers

												if (!isNaN(numericValue)) {
													// Check if parsing was successful
													getFilteredDocuments(numericValue);
												}
											}
										}}
										prevItem={null}
										totalPages={Math.ceil(countState / dataSizeLimit)}
									/>
								</CategoryFooter>
							</>
						) : (
							<NoMatchFoundContainer>
								<Segment placeholder style={{ height: '100%', backgroundColor: `${THEME.COLORS.white}` }}>
									<Header icon>
										<img src={NoResult} alt={'no match'} />
										No Driver Found
									</Header>
								</Segment>
							</NoMatchFoundContainer>
						)}
					</MainContent>

					{filterModalOpen && (
						<Modal size="small" open={filterModalOpen} onClose={() => closeFilterModal()}>
							<Modal.Header>Filter Drivers</Modal.Header>
							<Modal.Content scrolling={true} style={{ minHeight: '15em' }}>
								<Form>
									<Form.Group widths="equal">
										<Form.Field>
											<label>Organisations</label>
											<Dropdown
												placeholder="Choose..."
												fluid
												search
												multiple
												selection
												value={activeFilters.organisations}
												options={organisationsForFilter}
												onChange={(e, data: DropdownProps) => {
													const organisationsArray = Array.isArray(data.value)
														? data.value.map((item) => String(item))
														: [String(data.value)];
													setActiveFilters({
														...activeFilters,
														organisations: organisationsArray,
													});
												}}
											/>
										</Form.Field>
										<Form.Field>
											<label>Document Type</label>
											<Dropdown
												placeholder="Choose ..."
												fluid
												search
												multiple
												selection
												value={activeFilters.documentType}
												options={DRIVER_DOCUMENTS_TYPE_OPTIONS}
												onChange={(e, data: DropdownProps) => {
													const documentTypes = Array.isArray(data.value)
														? data.value.map((item) => String(item))
														: [String(data.value)];
													setActiveFilters({
														...activeFilters,
														documentType: documentTypes,
													});
												}}
											/>
										</Form.Field>
									</Form.Group>
								</Form>
							</Modal.Content>
							<Modal.Actions style={{ display: 'flex' }}>
								<Button
									onClick={resetFilterModal}
									disabled={activeFilters.organisations?.length === 0 && activeFilters.documentType?.length === 0}>
									Reset
								</Button>
								<Expander />
								<Button color="black" onClick={closeFilterModal}>
									Cancel
								</Button>
								<Button
									disabled={activeFilters.organisations?.length === 0 && activeFilters.documentType?.length === 0}
									positive
									onClick={() => {
										setFilters();
										setFilterCount(filterCount + 1);
										closeFilterModal();
									}}
									loading={loadingState}>
									Filter
								</Button>
							</Modal.Actions>
						</Modal>
					)}

					{preview &&
						(previewType === 'application/pdf' ? (
							<PdfView source={previewSource} filename={fileName} onClose={closePreviewModal} />
						) : (
							<ImageView visible={preview} source={previewSource} filename={fileName} onClose={closePreviewModal} />
						))}
				</MainContainer>
			)}

			{loadingState && !initialLoading && <TransitionLoader />}
		</Container>
	);
}

export default Drivers;
