import Store from '../../../../../redux/store';
import { SHARE_TRACKING_LINK_API, VEHICLES_API } from '../../../../../constants/api';
import { postData } from '../../../../../helper/services/axios';

export function openTrackingLinkModal() {
	Store.dispatch({
		type: 'OPEN_TRACKING_LINK_MODAL',
	});
}

export function closeTrackingLinkModal() {
	Store.dispatch({
		type: 'CLOSE_TRACKING_LINK_MODAL',
	});
}

export function openImmobilizerModal() {
	Store.dispatch({
		type: 'OPEN_IMMOBILIZER_MODAL',
	});
}

export function closeImmobilizerModal() {
	Store.dispatch({
		type: 'CLOSE_IMMOBILIZER_MODAL',
	});
}

export function openMobilizerModal() {
	Store.dispatch({
		type: 'OPEN_MOBILIZER_MODAL',
	});
}

export function closeMobilizerModal() {
	Store.dispatch({
		type: 'CLOSE_MOBILIZER_MODAL',
	});
}

export function openTrackingLinkViewModal() {
	Store.dispatch({
		type: 'OPEN_TRACKING_LINK_VIEW_MODAL',
	});
}

export function closeTrackingLinkViewModal() {
	Store.dispatch({
		type: 'CLOSE_TRACKING_LINK_VIEW_MODAL',
	});
}

export interface ShareTrackingLink {
	vehicles: { id: number }[];
	phone?: string[];
	email?: string[];
	expiry?: string;
	type?: string;
}

export function shareTrackingLinkRequest(data: ShareTrackingLink) {
	Store.dispatch({
		type: 'BUTTON_LOADING',
	});
	return postData(SHARE_TRACKING_LINK_API, '', data);
}

export interface VehicleProcessCommand {
	status: boolean;
	action: string;
}

export function vehicleProcessCommands(id: number, data: VehicleProcessCommand) {
	Store.dispatch({
		type: 'BUTTON_LOADING',
	});
	return postData(VEHICLES_API, `/${id}/process-commands`, data);
}
