import React from 'react';
import { Header, Icon, Segment, SemanticICONS } from 'semantic-ui-react';

import { THEME } from '../../../constants/Theme';
import { Container } from './PlaceHolderStyles';

interface PropType {
	iconName?: SemanticICONS;
	content?: string;
}

const PlaceHolder = (props: PropType) => {
	return (
		<Container>
			<Segment placeholder style={{ height: '100%', backgroundColor: `${THEME.COLORS.white}` }}>
				<Header icon>
					<Icon name={props.iconName} />
					{props.content}
				</Header>
			</Segment>
		</Container>
	);
};

export default PlaceHolder;
