import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Form, Icon, Input } from 'semantic-ui-react';

import { ANDROID_APP_LINK, IOS_APP_LINK } from '../../constants/constants';
import LogoImage from '../../assets/images/tracknerd-logo.png';
import PlayStoreImage from '../../assets/images/play-store.png';
import AppStoreImage from '../../assets/images/app-store.png';
import Store from '../../redux/store';
import { THEME } from '../../constants/Theme';
import { getOTP, login, verifyOTP } from '../../helper/view specific/auth';
import { RootState } from '../../redux/RootState';

const LoginForm = () => {
	const { loading, sendingOTP, otpSent, verifyingOTP } = useSelector((state: RootState) => state.auth);

	// State
	const [username, setUsername] = useState<string>('');
	const [otp, setOtp] = useState<string | null>(null);
	const [counter, setCounter] = useState<number | null>(null);
	const [withPassword, setWithPassword] = useState<boolean>(true);
	const [password, setPassword] = useState<string>('');
	const [passwordVisible, setPasswordVisible] = useState<boolean | null>(null);

	useEffect(() => {
		if (counter && counter > 0) {
			setTimeout(() => {
				setCounter(counter - 1);
			}, 1000);
		}
	}, [counter]);

	useEffect(() => {
		if (otpSent) {
			setCounter(15);
		}
	}, [sendingOTP, otpSent]);

	useEffect(() => {
		if (withPassword) {
			Store.dispatch({ type: 'AUTH_SEND_OTP_REJECT' });
		}
	}, [withPassword]);

	// Unmount
	useEffect(
		() => () => {
			Store.dispatch({ type: 'AUTH_RESET' });
		},
		[]
	);

	return (
		<Container>
			<LogoContainer>
				<img src={LogoImage} alt="Logo" />
			</LogoContainer>
			<Form>
				<Form.Field>
					<label>{withPassword ? 'Email or Phone' : 'Mobile Number'}</label>
					{withPassword ? (
						<Input
							type="text"
							placeholder="Email or Phone"
							value={username}
							onChange={(e) => {
								setUsername(e.target.value);
							}}
						/>
					) : (
						<Input
							type="text"
							placeholder="Phone"
							maxLength={10}
							value={username}
							onChange={(e) => {
								setUsername(e.target.value);
							}}
						/>
					)}
				</Form.Field>
				{otpSent && !withPassword && (
					<Form.Field>
						<label>OTP</label>
						<Input
							type="text"
							placeholder="OTP"
							maxLength={6}
							onChange={(e) => {
								setOtp(e.target.value);
							}}
						/>
					</Form.Field>
				)}
				{withPassword && (
					<Form.Field>
						<label>Password</label>
						<Input
							type={passwordVisible ? 'text' : 'password'}
							placeholder="Secret Password"
							icon={
								<Icon
									name={passwordVisible ? 'eye' : 'eye slash'}
									link
									color={passwordVisible ? 'blue' : 'grey'}
									onClick={() => setPasswordVisible(!passwordVisible)}
								/>
							}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
						/>
					</Form.Field>
				)}
				<ButtonContainer>
					<Button
						primary
						fluid
						type="submit"
						loading={withPassword ? loading : !otpSent ? sendingOTP : verifyingOTP}
						onClick={() => {
							if (withPassword) {
								login(username, password);
							} else {
								if (!otpSent) {
									getOTP(username).catch();
								} else {
									if (otp) verifyOTP(username, otp).catch();
								}
							}
						}}>
						{withPassword ? 'Login' : !otpSent ? 'Send OTP' : 'Verify'}
					</Button>
					{otpSent && !withPassword && (
						<Button
							primary
							fluid
							type="submit"
							style={{ margin: 0 }}
							loading={loading}
							disabled={counter === 0}
							onClick={() => {
								getOTP(username).catch();
							}}>
							{`Resend OTP${counter ? ` (${counter})` : ''}`}
						</Button>
					)}
				</ButtonContainer>

				<LoginWithPassword
					onClick={() => {
						setUsername('');
						setWithPassword(!withPassword);
					}}>
					{withPassword ? 'Login with OTP' : 'Login with Password'}
				</LoginWithPassword>
				<AppLinksContainer>
					<a href={ANDROID_APP_LINK} target="_blank" rel="noreferrer">
						<img src={PlayStoreImage} alt="Play Store" />
					</a>
					<a href={IOS_APP_LINK} target="_blank" rel="noreferrer">
						<img src={AppStoreImage} alt="App Store" />
					</a>
				</AppLinksContainer>
			</Form>
		</Container>
	);
};

export default LoginForm;

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 3em;

	background: #fff;
	border-radius: 10px;

	.ui.button:disabled,
	.ui.buttons .disabled.button,
	.ui.disabled.active.button,
	.ui.disabled.button,
	.ui.disabled.button:hover {
		margin-top: 1em;
	}
`;

export const LogoContainer = styled.section`
	width: 100%;

	img {
		height: 3em;
	}

	margin-bottom: 3em;
	box-sizing: border-box;

	display: flex;
	justify-content: center;
`;

export const ButtonContainer = styled.section`
	width: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
`;

export const LoginWithPassword = styled.section`
	width: 100%;
	display: flex;
	justify-content: center;
	cursor: pointer;
	margin-top: 0.5em;

	span {
		font-size: 1em;
		font-weight: bold;
	}

	&:hover {
		color: ${THEME.COLORS.theme};
	}
`;

export const AppLinksContainer = styled.section`
	width: 100%;

	margin-top: 0.5em;
	display: flex;
	justify-content: space-around;

	box-sizing: border-box;

	img {
		width: 11.5em;
		height: 4em;
	}
`;
