import { isoToCustom } from '../utils/calendar';
import { Vehicle } from '../types/vehicle';
import { Document } from '../types/document';

export interface AllVehiclesAllDocs {
	vehicleList: VehicleDocs[];
	expiryCounters: DocCountsFromApiResponse;
}

export interface DocCountsFromApiResponse {
	roadTaxExpiring: number;
	vehicleFitnessExpiring: number;
	vehicleInsuranceExpiring: number;
	vehicleRegistrationExpiring: number;
	pucExpiring: number;
}

export interface VehicleDocs {
	org: string;
	vehicleId: number;
	registrationNumber: string;
	type: string;
	roadTaxDocument: Document | null;
	fitnessDocument: Document | null;
	insuranceDocument: Document | null;
	registrationDocument: Document | null;
	pucDocument: Document | null;
}

export function createDocumentsDTO(response: Vehicle[], metaData: DocCountsFromApiResponse): AllVehiclesAllDocs {
	const vehiclesObject: AllVehiclesAllDocs = {
		vehicleList: [],
		expiryCounters: {
			roadTaxExpiring: 0,
			vehicleFitnessExpiring: 0,
			vehicleRegistrationExpiring: 0,
			vehicleInsuranceExpiring: 0,
			pucExpiring: 0,
		},
	};

	if (response !== undefined && response.length) {
		const vehiclesList: VehicleDocs[] = [];
		response.forEach((vehicle) => {
			let roadTaxDocument = null;
			let fitnessDocument = null;
			let insuranceDocument = null;
			let registrationDocument = null;
			let pucDocument = null;

			//getting documents
			vehicle?.documents &&
				vehicle.documents.forEach((document) => {
					const obj = {
						docId: document.id,
						docType: document.type,
						expiry: document.expiry == null ? 'no date' : isoToCustom(document.expiry, 'DD-MM-YYYY'),
						docPath: document.path,
					};
					if (document.type === 'Road Tax') roadTaxDocument = obj;
					else if (document.type === 'Vehicle Fitness') fitnessDocument = obj;
					else if (document.type === 'Vehicle Insurance') insuranceDocument = obj;
					else if (document.type === 'Vehicle Registration') registrationDocument = obj;
					else if (document.type === 'PUC') pucDocument = obj;
				});

			//vehicle objects array containing multiple documents
			vehiclesList.push({
				org: vehicle.organisation.name,
				vehicleId: vehicle.id,
				registrationNumber: vehicle.registrationNumber,
				type: vehicle.type,
				roadTaxDocument: roadTaxDocument,
				fitnessDocument: fitnessDocument,
				insuranceDocument: insuranceDocument,
				registrationDocument: registrationDocument,
				pucDocument: pucDocument,
			});
		});

		vehiclesObject.vehicleList = vehiclesList;
	} else {
		vehiclesObject.vehicleList = [];
	}

	vehiclesObject.expiryCounters = {
		roadTaxExpiring: metaData.roadTaxExpiring,
		vehicleFitnessExpiring: metaData.vehicleFitnessExpiring,
		vehicleRegistrationExpiring: metaData.vehicleRegistrationExpiring,
		vehicleInsuranceExpiring: metaData.vehicleInsuranceExpiring,
		pucExpiring: metaData.pucExpiring,
	};

	return vehiclesObject;
}
