import { configureStore } from '@reduxjs/toolkit';
import RootReducer from './rootReducer';

const Store = configureStore({
	reducer: RootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}),
	devTools: true,
});

export default Store;
// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof Store.getState>;
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof Store.dispatch;
// export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
