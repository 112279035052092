import auth from './reducers/auth';
import dates from './reducers/calendar';
import document from './reducers/document';
import driver from './reducers/driver';
// import firebaseService from './reducers/firebase';
import geofence from './reducers/geofence';
import location from './reducers/location';
import sidebar from './reducers/sidebar';
import tab from './reducers/tab';
import vehicle from './reducers/vehicle';
import ui from './reducers/ui';
import { combineReducers } from 'redux';
import vehicleSlice from './reducers/vehicleSlice';
import rolePermissions from './reducers/rolePermissions';
import reduceApi from './reducers/reduceApi';

export default combineReducers({
	auth,
	dates,
	document,
	driver,
	// firebaseService,
	geofence,
	location,
	// notification,
	sidebar,
	tab,
	vehicle,
	ui,
	rolePermissions,
	reduceApi,
	vehicleSlice: vehicleSlice.reducer,
});
