import React from 'react';
import { useSelector } from 'react-redux';

import DashboardNavListItem, { DashboardNavListItemNoPermission } from '../../mainNavigation/DashboardNavListItem';
import { Container } from './DashboardNavListStyles';
import Store from '../../../redux/store';
import { EdgeButton } from '../../../views/dashboard/vehicles/sidebar/SidebarStyles';
import { RootState } from '../../../redux/RootState';

interface PropType {
	urlPrefix: string;
}

const DashboardNavList = (props: PropType) => {
	const permissionState = useSelector((state: RootState) => state.rolePermissions.permissions);
	const sidebarData = useSelector((state: RootState) => state.ui.sidebarVisible);

	const handleSidebar = () => {
		Store.dispatch({
			type: 'SHOW_SIDEBAR',
			payload: !sidebarData,
		});
	};

	return (
		<>
			{!sidebarData && (
				<EdgeButton
					title={'Expand'}
					top={'145px'}
					left={'40px'}
					bgColor={'#fff'}
					color={'#1b3c71'}
					className="circular ui icon button"
					onClick={handleSidebar}>
					<i className="angle right icon"></i>
				</EdgeButton>
			)}
			<Container>
				<DashboardNavListItem urlPrefix={props.urlPrefix} exact={true} iconName="home" popupTitle="Dashboard" />
				{permissionState.Geofence && (permissionState.Geofence.manage || permissionState.Geofence.read) ? (
					<>
						<DashboardNavListItem urlPrefix={`${props.urlPrefix}vehicles`} iconName="truck" popupTitle="Vehicles" />
						<DashboardNavListItem urlPrefix={`${props.urlPrefix}geofences`} iconName="map" popupTitle="Geofences" />
					</>
				) : (
					<>
						<DashboardNavListItemNoPermission
							iconName="red truck"
							popupTitle="Apologies, but access to this feature is limited to specific user roles."
						/>
						<DashboardNavListItemNoPermission
							iconName="red map"
							popupTitle="Apologies, but access to this feature is limited to specific user roles."
						/>
					</>
				)}

				{permissionState.Document.manage || permissionState.Document.read ? (
					<DashboardNavListItem urlPrefix={`${props.urlPrefix}documents`} iconName="folder" popupTitle="Documents" />
				) : (
					<DashboardNavListItemNoPermission
						iconName="red folder"
						popupTitle="Apologies, but access to this feature is limited to specific user roles."
					/>
				)}
				{permissionState.User.manage || permissionState.User.read ? (
					<DashboardNavListItem urlPrefix={`${props.urlPrefix}drivers`} iconName="user" popupTitle="Drivers" />
				) : (
					<DashboardNavListItemNoPermission
						iconName="red user"
						popupTitle="Apologies, but access to this feature is limited to specific user roles."
					/>
				)}
				{/*<DashboardNavListItem*/}
				{/*  urlPrefix={`${props.urlPrefix}notifications`}*/}
				{/*  iconName="bell"*/}
				{/*  popupTitle="Notifications"*/}
				{/*/>*/}
				{/*<DashboardNavListItem urlPrefix={`${props.urlPrefix}delivery`} iconName="dolly" popupTitle="Delivery" />*/}
			</Container>
		</>
	);
};

export default DashboardNavList;
