import React, { useEffect } from 'react';
import Auth from './components/auth/Auth';
import Dashboard from './views/dashboard/Dashboard';
import { handleUserSession } from './helper/view specific/auth';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { RootState } from './redux/RootState';
import { isNewLiveTrackingLink, isOldLiveTrackingLink } from './helper/services/common';
import Tracker from './views/tracker/Tracker'; // import TrackPLayBack from "./views/components/trackPlayBack/TrackPlayBack";
// import TrackPLayBack from "./views/components/trackPlayBack/TrackPlayBack";

const AppGateway = () => {
	useEffect(() => {
		handleUserSession();
	}, []);

	if (isOldLiveTrackingLink()) {
		return <Tracker url={window.location.pathname} />;
	} else if (isNewLiveTrackingLink()) {
		return <Tracker url={window.location.pathname} />;
	} else {
		if (localStorage.getItem('token')) {
			return (
				<>
					<BrowserRouter>
						<Switch>
							<Route path="/" component={Dashboard} />
						</Switch>
					</BrowserRouter>
				</>
			);
		} else {
			return (
				<>
					<BrowserRouter>
						<Switch>
							<Route path="/" component={Auth} />
						</Switch>
					</BrowserRouter>
				</>
			);
		}
	}
};

// Connect to Store
const mapStateToProps = (state: RootState) => ({
	loggedIn: state.auth.loggedIn,
});

export default connect(mapStateToProps)(AppGateway);
