export interface DocumentState {
	loading: boolean;
	documents: [];
	count: number;
	pageNumber: number;
	// Used for Search
	documentsBackup: [];
	activeDocument: [];
}

interface DocumentAction {
	type: string;
	payload?: object;
}

export default function document(
	initialState: DocumentState = {
		loading: true,
		documents: [],
		count: 0,
		pageNumber: 1,
		// Used for Search
		documentsBackup: [],
		activeDocument: [],
	},
	action: DocumentAction
) {
	switch (action.type) {
		case 'FEED_DOCUMENTS':
			return {
				...initialState,
				documents: action.payload,
				documentsBackup: action.payload,
				loading: false,
			};

		case 'DOCUMENT_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'DOCUMENT_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'DOCUMENTS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'DOCUMENTS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'SET_ACTIVE_DOCUMENT':
			return {
				...initialState,
				activeDocument: action.payload,
			};

		case 'RESET_ACTIVE_DOCUMENT':
			return {
				...initialState,
				activeDocument: null,
			};

		default:
			return initialState;
	}
}
