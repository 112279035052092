// Base URL
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CRM_BASE_URL = process.env.REACT_APP_CRM_BASE_URL;

// End Points
export const LOGIN_API = BASE_URL + 'v1/auth/login';
export const CHANGE_PASSWORD_API = BASE_URL + 'v1/users';
export const GET_OTP_API = BASE_URL + 'v1/auth/login/otp';
export const VERIFY_OTP_API = BASE_URL + 'v1/auth/login/otp';
export const LOGOUT_API = BASE_URL + 'v1/users/logout';
export const VEHICLES_API = BASE_URL + 'v1/vehicles';
export const GEOCODE_API = BASE_URL + 'v1/geocode';
export const SHARE_TRACKING_LINK_API = BASE_URL + 'v1/link';
export const TRIP_HISTORY_API = BASE_URL + 'v1/trips';
export const TRACK_API = BASE_URL + 'v1/vehicles/track';
export const EVENTS_API = BASE_URL + 'v1/events';
export const SUMMARY_API = BASE_URL + 'v1/summary';
export const EXPORT_API = BASE_URL + 'v1/export';
export const DASHBOARD_SUMMARY_API = BASE_URL + 'v2/dashboard/summary';
export const GEOFENCE_API = BASE_URL + 'v1/geofences';
export const ORGANISATIONS_API = BASE_URL + 'v1/organisations';
export const VEHICLES_GROUPS = BASE_URL + 'v1/vehicle-groups';
export const USERS_API = BASE_URL + 'v1/users';
export const BATTERY_RECHARGE_SUMMARY = BASE_URL + 'v1/battery-recharge-summary';
export const VEHICLES_DOCUMENTS_API = BASE_URL + 'v1/vehicles/documents';
export const USER_DEVICES_API = BASE_URL + 'v1/user-devices';
export const ASSET_VERIFICATION_API = BASE_URL + 'v1/verified-assets/verify';
export const VERIFIED_ASSETS_API = BASE_URL + 'v1/verified-assets';
export const VEHICLE_FLEET_STATUS = BASE_URL + 'v1/dashboard/fleet-status';

export const CONSIGNMENTS_API = BASE_URL + 'v1/consignments';
export const FUEL_REPORT_API = BASE_URL + 'v1/vehicles/fuel';
export const EVENTS_TYPES_API = BASE_URL + 'v1/events/types';
export const NOTIFICATIONS_API = BASE_URL + 'v1/notifications';
export const DRIVERS_API = BASE_URL + 'v1/drivers';
export const DELIVERY_API = BASE_URL + 'v1/sms-report/delivery';
export const SMS_REPORT_API = BASE_URL + 'v1/sms-report';
export const LIVE_TRACKING_LINK_API = BASE_URL + 'v1/link/live';
export const TRACK_TRACKING_LINK_API = BASE_URL + 'v1/link/track';
export const VEHICLES_TYPES_API = BASE_URL + 'v1/vehicles/types';
export const VEHICLE_GROUPS_API = BASE_URL + 'v1/vehicle-groups';
export const BATTERY_API = BASE_URL + 'v1/vehicles/battery';

// export const SHARE_TRACKING_LINK_API = BASE_URL + 'v1/vehicles/track/link/share';
// export const TRACKING_LINK_API = BASE_URL + 'v1/vehicles/track/link';
