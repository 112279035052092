import { ASSET_VERIFICATION_LINK_URL, NEW_LIVE_LINK_URL, OLD_LIVE_LINK_URL } from '../../constants/constants';

export const isOldLiveTrackingLink = () => {
	const path = window.location.pathname.split('/');
	return `/${path[1]}/${path[2]}` === OLD_LIVE_LINK_URL;
};
export const isNewLiveTrackingLink = () => {
	const path = window.location.pathname.split('/');
	return `/${path[1]}/${path[2]}` === NEW_LIVE_LINK_URL;
};

export const isVerificationLink = () => {
	return `/${window.location.pathname.split('/')[1]}` === ASSET_VERIFICATION_LINK_URL;
};

export const isValid = (url: string) => {
	const liveLink = url.match(/live\/link\/(.*)$/);
	const trackLink = url.match(/track\/link\/(.*)$/);

	const isOldUrl = liveLink ? liveLink[1] : '';
	const isNewUrl = trackLink ? trackLink[1] : '';

	return isOldUrl.length ? isOldUrl : isNewUrl;
};
