import React, { useEffect, useRef, useState } from 'react';
import TrackNerdLoader from '../../../../../components/loader/Loader';
import PlaceHolder from '../../../../../components/placeHolder/dynamic/PlaceHolder';
import { CategoryHeader } from '../../../../../components/STYLED/CategoryHeader';
import { CategoryFooter } from '../../../../../components/STYLED/CategoryFooter';
import { Expander } from '../../../../../components/STYLED/Expander';
import {
	EXPORT_FILE_EXTENSION,
	PAGE_COUNT_OPTIONS,
	SUMMARY_INTERVAL_DROPDOWN_OPTIONS,
} from '../../../../../constants/constants';
import { createSummaryDTO, SummaryDTO } from '../../../../../dtos/summary';
import NoVehicleSelection from '../../../../../components/placeHolder/static/noVehicleSelection/NoVehicleSelection';
import { dispatch, dispatchNoPayload } from '../../../../../helper/view specific/store';
import { useSelector } from 'react-redux';
import Store from '../../../../../redux/store';
import { Container, PageSelector, TableContainer } from './SummaryStyles';
import { Button, Checkbox, Dropdown, Icon, Pagination, Popup, Table } from 'semantic-ui-react';
import { handleError } from '../../../../../helper/view specific/messaging';
import 'jspdf-autotable';
import { THEME } from '../../../../../constants/Theme';
// import TransitionLoader from '../../../../common/loader/TransitionLoader';
import { trackMixpanelEvent } from '../../../../../helper/services/mixpanel';
import { EXPORT_API, SUMMARY_API } from '../../../../../constants/api';
import { exportPostData, postData } from '../../../../../helper/services/axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
// import { FlexContainer } from '../refueling/RefuelingStyles';
import { handleDataSizeLimitChange } from '../../../../../utils/common';
import './TableStayle.css';
import { RootState } from '../../../../../redux/RootState';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';

const Summary = () => {
	const selectedVehiclesNumbers = useSelector((state: RootState) => state.sidebar.selectedVehiclesNumbers);
	const selectedVehiclesFromSidebar = useSelector((state: RootState) => state.sidebar.selectedVehicles);
	const initialDateState = useSelector((state: RootState) => state.dates.initialDate);
	const finalDateState = useSelector((state: RootState) => state.dates.finalDate);
	const dataSizeLimitState = useSelector((state: RootState) => state.ui.dataSizeLimit);
	const activePage = useSelector((state: RootState) => state.ui.pageNumber);
	const summaryDataStore = useSelector((state: RootState) => state.reduceApi.summaryDataStore);
	const monthlySummary = useSelector((state: RootState) => state.vehicle.monthlySummary);
	const selectedThreeMonthsDataFromGraph = useSelector((state: RootState) => state.dates.threeMonthData);

	const dataSize = useRef(0);

	const [initialLoading, setInitialLoading] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [summary, setSummary] = useState<SummaryDTO[][]>([]);
	const [activeSort, setActiveSort] = useState<string>('vehicle');
	const [sortOrder, setSortOrder] = useState<string>('desc');
	const [fileExtension, setFileExtension] = useState<string | string[]>('pdf');
	const [openFieldsMenu, setOpenFieldsMenu] = useState<boolean>(false);
	const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
	const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
	const [sortDirections, setSortDirections] = useState<{
		[columnName: string]: string;
	}>({});
	// const [intervalValue, setIntervalValue] = useState<string | string[]>(monthlySummary ? 'monthly' : 'daily');
	const [intervalValue, setIntervalValue] = useState<string | string[]>(summaryDataStore.report);

	const firstRenderRef = useRef(true);

	const columnOptions = [
		'First Ignition',
		'First Address',
		'Distance(KM)',
		'Avg Speed(KM/H)',
		'Top Speed(KM/H)',
		'Start Odometer(KM)',
		// 'Odo Difference(KM)',
		'End Odometer(KM)',
		'Engine Operation Time',
		'Movement Time',
		'Idle Time',
		'Last Ignition',
		'Last Address',
		'First GeoFence Event',
		'Last GeoFence Event',
		'Trip Count',
		'OverSpeed Count',
		'Driver',
		'Vehicle Groups',
	];

	//Sort
	useEffect(() => {
		const str = localStorage.getItem('summaryColumns');
		const parsedArray = str && JSON.parse(str);
		if (str) {
			setVisibleColumns([...parsedArray]);
		} else {
			setVisibleColumns([
				'Serial',
				'Vehicle',
				'Date',
				'First Ignition',
				'First Address',
				'Distance(KM)',
				'Avg Speed(KM/H)',
				'Top Speed(KM/H)',
				'Start Odometer(KM)',
				// 'Odo Difference(KM)',
				'End Odometer(KM)',
				'Engine Operation Time',
				'Movement Time',
				'Idle Time',
				'Last Ignition',
				'Last Address',
				'First GeoFence Event',
				'Last GeoFence Event',
				'Trip Count',
				'OverSpeed Count',
				'Driver',
				'Vehicle Groups',
			]);
		}
		Store.dispatch({
			type: 'DISABLE_TIMEPICKER',
			payload: true,
		});
		dispatch('SET_SIDEBAR_CONFIG', {
			showCalendar: true,
			multiSelectAllowed: true,
		});

		trackMixpanelEvent('summary_report', {
			vehicles: selectedVehiclesNumbers,
			startTime: initialDateState,
			endTime: finalDateState,
		});

		Store.dispatch({
			type: 'SELECTED_TAB',
			payload: 'Summary',
		});

		if (monthlySummary) setIntervalValue('monthly');
		else setIntervalValue('daily');

		if (summaryDataStore?.total) {
			dataSize.current = summaryDataStore?.total;
			getFilterEventsRedux();
		} else {
			Store.dispatch({
				type: 'SET_PAGE_LIMIT',
				payload: 1,
			});
		}

		return () => {
			dispatchNoPayload('RESTORE_SIDEBAR_CONFIG');
			dispatchNoPayload('DAILY_SUMMARY');
			Store.dispatch({
				type: 'DISABLE_TIMEPICKER',
				payload: false,
			});
			Store.dispatch({
				type: 'DISABLE_DATEPICKER',
				payload: false,
			});
			Store.dispatch({
				type: 'SELECTED_TAB',
				payload: 'Live',
			});
		};
	}, []);

	useEffect(() => {
		if (intervalValue === 'monthly') {
			trackMixpanelEvent('summary_monthly_report', {
				vehicles: selectedVehiclesNumbers,
				intervalValue: intervalValue, //setIntervalValue('monthly'),
			});
			Store.dispatch({
				type: 'DISABLE_DATEPICKER',
				payload: true,
			});
		} else {
			Store.dispatch({
				type: 'DISABLE_DATEPICKER',
				payload: false,
			});
		}

		if (!firstRenderRef.current) {
			if (selectedVehiclesFromSidebar && selectedVehiclesFromSidebar.length) {
				getFilterEvents(activePage);
			}
		} else {
			if (selectedVehiclesFromSidebar && selectedVehiclesFromSidebar.length) {
				if (!summaryDataStore.total) {
					getFilterEvents(1);
				}
			}
			firstRenderRef.current = false;
		}

		if (selectedVehiclesFromSidebar.length === 0) {
			setInitialLoading(false);
		}
	}, [
		selectedVehiclesNumbers,
		selectedVehiclesFromSidebar,
		initialDateState,
		finalDateState,
		dataSizeLimitState,
		intervalValue,
		activeSort,
		sortOrder,
	]);

	const getFilterEventsRedux = () => {
		setSummary(summaryDataStore.data);
		Store.dispatch({
			type: 'SET_PAGE_LIMIT',
			payload: summaryDataStore.activePage,
		});
	};

	interface summaryParam {
		sortBy: string;
		orderBy: string;
		pagenumber: number;
	}

	const getFilterEvents = (pageNumber: number) => {
		const params = {
			orderBy: sortOrder,
			sortBy: activeSort,
			pagenumber: pageNumber,
		};
		fetchData(params);
	};

	const fetchData = (params: summaryParam) => {
		let startTime = initialDateState;

		setLoading(true);
		setInitialLoading(true);

		const vehiclesParams = selectedVehiclesFromSidebar.map((vehicle) => vehicle);
		if (intervalValue === 'monthly') {
			if (selectedThreeMonthsDataFromGraph) {
				startTime = moment().subtract(3, 'months').startOf('month').toISOString();
			} else {
				startTime = moment(initialDateState).subtract(11, 'months').startOf('month').toISOString();
			}
		} else {
			if (selectedThreeMonthsDataFromGraph) dispatchNoPayload('DISABLE_THREE_MONTHS_DATA');
		}
		const endTime: string = finalDateState;
		const requestBody = {
			vehicleIds: vehiclesParams,
			startTime: startTime,
			endTime: endTime,
			pagecount: dataSizeLimitState,
			interval: intervalValue,
			...params,
		};

		postData(SUMMARY_API, '', requestBody)
			.then((response) => {
				let summaryDTOdata: SummaryDTO[][];
				dataSize.current = response?.data?.count || 0;
				if (activeSort === 'vehicle') {
					summaryDTOdata = createSummaryDTO(response?.data?.data, 'vehicle');
				} else if (activeSort === 'date') {
					summaryDTOdata = createSummaryDTO(response?.data?.data, 'date');
				} else {
					summaryDTOdata = createSummaryDTO(response?.data?.data, '');
				}
				const summaryReduxData = {
					total: response.data.count,
					data: summaryDTOdata,
					activePage: params.pagenumber,
					report: intervalValue,
				};
				setSummary(summaryDTOdata);
				if (summaryDTOdata !== null) dispatch('SUMMARY_DATA_STORE', summaryReduxData);

				setLoading(false);
				setInitialLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				setInitialLoading(false);
				handleError('Summary.tsx => postData()', error);
			});
	};

	// const sort = (param) => {
	// 	let activeSortTemp;
	// 	let sortOrderTemp;
	// 	if (activeSort !== param) {
	// 		activeSortTemp = param;
	// 		sortOrderTemp = 'asc';
	// 	} else {
	// 		if (sortOrder === 'desc') {
	// 			activeSortTemp = param;
	// 			sortOrderTemp = 'asc';
	// 		} else {
	// 			activeSortTemp = param;
	// 			sortOrderTemp = 'desc';
	// 		}
	// 	}
	//
	// 	setActiveSort(activeSortTemp);
	// 	setSortOrder(sortOrderTemp);
	// };

	const sort = (param: string, currentSortOrder: string) => {
		let activeSortTemp;
		let sortOrderTemp;

		switch (param) {
			case 'Vehicle':
				param = 'vehicle';
				break;
			case 'Date':
				param = 'date';
				break;
			case 'Distance(KM)':
				param = 'distance';
				break;
			case 'Avg Speed(KM/H)':
				param = 'averageSpeed';
				break;
			case 'Top Speed(KM/H)':
				param = 'topSpeed';
				break;
			case 'Engine Operation Time':
				param = 'engineHours';
				break;
			case 'Movement Time':
				param = 'movementHours';
				break;
			case 'Idle Time':
				param = 'idleHours';
				break;
			case 'First Ignition':
				param = 'firstIgnitionOn';
				break;
			case 'Last Ignition':
				param = 'lastIgnitionOff';
				break;
			case 'Start Odometer(KM)':
				param = 'startOdometer';
				break;
			case 'End Odometer(KM)':
				param = 'endOdometer';
				break;
			case 'Vehicle Groups':
				param = 'vehicleGroups';
				break;
			default:
				break;
		}

		if (activeSort !== param || currentSortOrder === undefined) {
			activeSortTemp = param;
			sortOrderTemp = 'asc';
		} else {
			if (currentSortOrder === 'desc') {
				activeSortTemp = param;
				sortOrderTemp = 'asc';
			} else {
				activeSortTemp = param;
				sortOrderTemp = 'desc';
			}
		}

		setActiveSort(activeSortTemp);
		setSortOrder(sortOrderTemp);
	};

	// const ListItem = ({ field }) => {
	// 	return (
	// 		<List.Item>
	// 			<div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', padding: '0 1em' }}>
	// 				<span style={{ paddingRight: '5em' }}>{field}</span>
	// 				<Checkbox
	// 					checked={selectedColumns.includes(field)}
	// 					onClick={(e, { checked }) => {
	// 						handleSelectColumn(checked, field);
	// 					}}
	// 				/>
	// 			</div>
	// 		</List.Item>
	// 	);
	// };

	/*const handleSelectColumn = (checked, name) => {
        try {
            let selectedRowTemp = selectedColumns;

            if (checked) {
                selectedRowTemp.push(name);
            } else {
                selectedRowTemp = selectedRowTemp.filter((columnName) => columnName !== name);
            }
            setSelectedColumns([...selectedRowTemp]);
        } catch (error) {
            datadogLogs.logger.error('Summary handleSelectColumn Error ', {}, error);
        }
    };*/

	// formatExportData(data) {
	//   let formatExcelData = formatExcelExport(data);
	//   let formatedData = {};
	//
	//   formatExcelData.forEach((data) => {
	//     if (!formatedData[data.Vehicle]) {
	//       formatedData[data.Vehicle] = {
	//         [data.Date]: {
	//           [`Vehicle`]: data.Vehicle,
	//           [`Date`]: data.Date,
	//           [`First Ignition ON`]: data['First Ignition ON'],
	//           [`Distance (KM)`]: data['Distance (KM)'],
	//           [`AverageSpeed (KM/H)`]: data[`AverageSpeed (KM/H)`],
	//           [`Top Speed (KM/H)`]: data[`Top Speed (KM/H)`],
	//           [`EngineHours`]: data.EngineHours,
	//           [`MovementHours`]: data.MovementHours,
	//           [`IdleHours`]: data.IdleHours,
	//           [`Last Ignition OFF`]: data['Last Ignition OFF'],
	//           /*[`Start Fuel (L)`]: data[`Start Fuel (L)`],
	//           [`End Fuel (L)`]: data[`End Fuel (L)`],
	//           [`Refueling (L)`]: data[`Refueling (L)`],
	//           [`Draining (L)`]: data[`Draining (L)`],
	//           [`Consumption (L)`]: data[`Consumption (L)`],
	//           [`Consumption During Movement (L)`]: data[`Consumption During Movement (L)`],
	//           [`Mileage (KM/L)`]: data[`Mileage (KM/L)`],
	//           [`Mileage During Movement (KM/L)`]: data[`Mileage During Movement (KM/L)`],*/
	//         },
	//       };
	//     } else {
	//       formatedData[data.Vehicle] = {
	//         ...formatedData[data.Vehicle],
	//         [data.Date]: {
	//           [`Vehicle`]: data.Vehicle,
	//           [`Date`]: data.Date,
	//           [`First Ignition ON`]: data['First Ignition ON'],
	//           [`Distance (KM)`]: data['Distance (KM)'],
	//           [`AverageSpeed (KM/H)`]: data[`AverageSpeed (KM/H)`],
	//           [`Top Speed (KM/H)`]: data[`Top Speed (KM/H)`],
	//           [`EngineHours`]: data.EngineHours,
	//           [`MovementHours`]: data.MovementHours,
	//           [`IdleHours`]: data.IdleHours,
	//           [`Last Ignition OFF`]: data['Last Ignition OFF'],
	//           /*[`Start Fuel (L)`]: data[`Start Fuel (L)`],
	//           [`End Fuel (L)`]: data[`End Fuel (L)`],
	//           [`Refueling (L)`]: data[`Refueling (L)`],
	//           [`Draining (L)`]: data[`Draining (L)`],
	//           [`Consumption (L)`]: data[`Consumption (L)`],
	//           [`Consumption During Movement (L)`]: data[`Consumption During Movement (L)`],
	//           [`Mileage (KM/L)`]: data[`Mileage (KM/L)`],
	//           [`Mileage During Movement (KM/L)`]: data[`Mileage During Movement (KM/L)`],*/
	//         },
	//       };
	//     }
	//   });
	//
	//   let formatedExportData = [];
	//   let distance = 0;
	//   let maxSpeed = 0;
	//   let avgSpeed = 0;
	//   let engineHour = '00:00:00';
	//   let movementHour = '00:00:00';
	//   let idleHour = '00:00:00';
	//   /* let startFuel = 0;
	//   let endFuel = 0;
	//   let refueling = 0;
	//   let draining = 0;
	//   let consumption = 0;
	//   let consumptionDuringMovement = 0;
	//   let mileage = 0;
	//   let mileageDuringMovement = 0;*/
	//
	//   Object.keys(formatedData).forEach((vehicle, index) => {
	//     Object.keys(formatedData[vehicle])
	//       .sort((start, end) => {
	//         return moment(start, 'DD-MM-YYYY') - moment(end, 'DD-MM-YYYY');
	//       })
	//       .forEach((date) => {
	//         distance += Number(formatedData[vehicle][date]['Distance (KM)']);
	//         avgSpeed += Number(formatedData[vehicle][date]['AverageSpeed (KM/H)']);
	//         if (Number(formatedData[vehicle][date]['Top Speed (KM/H)']) > maxSpeed) {
	//           maxSpeed = Number(formatedData[vehicle][date]['Top Speed (KM/H)']);
	//         }
	//         engineHour = moment.duration(engineHour).add(moment.duration(formatedData[vehicle][date].EngineHours));
	//         movementHour = moment.duration(movementHour).add(moment.duration(formatedData[vehicle][date].MovementHours));
	//         idleHour = moment.duration(idleHour).add(moment.duration(formatedData[vehicle][date].IdleHours));
	//         /*startFuel += formatedData[vehicle][date]['Start Fuel (L)']
	//           ? Number(formatedData[vehicle][date]['Start Fuel (L)'])
	//           : 0;
	//         endFuel += formatedData[vehicle][date]['End Fuel (L)']
	//           ? Number(formatedData[vehicle][date]['End Fuel (L)'])
	//           : 0;
	//         refueling += formatedData[vehicle][date]['Refueling (L)']
	//           ? Number(formatedData[vehicle][date]['Refueling (L)'])
	//           : 0;
	//         draining += formatedData[vehicle][date]['Draining (L)']
	//           ? Number(formatedData[vehicle][date]['Draining (L)'])
	//           : 0;
	//         consumption += formatedData[vehicle][date]['Consumption (L)']
	//           ? Number(formatedData[vehicle][date]['Consumption (L)'])
	//           : 0;
	//         consumptionDuringMovement += formatedData[vehicle][date]['Consumption During Movement (L)']
	//           ? Number(formatedData[vehicle][date]['Consumption During Movement (L)'])
	//           : 0;
	//         mileage += formatedData[vehicle][date]['Mileage (KM/L)']
	//           ? Number(formatedData[vehicle][date]['Mileage (KM/L)'])
	//           : 0;
	//         mileageDuringMovement += formatedData[vehicle][date]['Mileage During Movement (KM/L)']
	//           ? Number(formatedData[vehicle][date]['Mileage During Movement (KM/L)'])
	//           : 0;*/
	//
	//         formatedExportData.push(formatedData[vehicle][date]);
	//       });
	//
	//     formatedExportData.push({
	//       Vehicle: 'Total',
	//       Date: Object.keys(formatedData[vehicle]).length + ' Days',
	//       'First Ignition ON': '',
	//       'Distance (KM)': distance.toFixed(2),
	//       'AverageSpeed (KM/H)': (avgSpeed / Object.keys(formatedData[vehicle]).length).toFixed(2),
	//       'Top Speed (KM/H)': maxSpeed,
	//       EngineHours: Math.floor(engineHour.asHours()) + moment.utc(engineHour.asMilliseconds()).format(':mm:ss'),
	//       MovementHours: Math.floor(movementHour.asHours()) + moment.utc(movementHour.asMilliseconds()).format(':mm:ss'),
	//       IdleHours: Math.floor(idleHour.asHours()) + moment.utc(idleHour.asMilliseconds()).format(':mm:ss'),
	//       'Last Ignition OFF': '',
	//       /*'Start Fuel (L)': startFuel.toFixed(2),
	//       'End Fuel (L)': endFuel.toFixed(2),
	//       'Refueling (L)': refueling.toFixed(2),
	//       'Draining (L)': draining.toFixed(2),
	//       'Consumption (L)': consumption.toFixed(2),
	//       'Consumption During Movement (L)': consumptionDuringMovement.toFixed(2),
	//       'Mileage (KM/L)': mileage.toFixed(2),
	//       'Mileage During Movement (KM/L)': mileageDuringMovement.toFixed(2),*/
	//     });
	//
	//     if (index !== Object.keys(formatedData).length - 1) {
	//       formatedExportData.push({
	//         Vehicle: '',
	//         Date: '',
	//         'First Ignition ON': '',
	//         'Distance (KM)': '',
	//         'AverageSpeed (KM/H)': '',
	//         'Top Speed (KM/H)': '',
	//         EngineHours: '',
	//         MovementHours: '',
	//         IdleHours: '',
	//         'Last Ignition OFF': '',
	//         /*'Start Fuel (L)': '',
	//         'End Fuel (L)': '',
	//         'Refueling (L)': '',
	//         'Draining (L)': '',
	//         'Consumption (L)': '',
	//         'Consumption During Movement (L)': '',
	//         'Mileage (KM/L)': '',
	//         'Mileage During Movement (KM/L)': '',*/
	//       });
	//     }
	//
	//     distance = 0;
	//     maxSpeed = 0;
	//     avgSpeed = 0;
	//     engineHour = '00:00:00';
	//     movementHour = '00:00:00';
	//     idleHour = '00:00:00';
	//     /*  startFuel = 0;
	//     endFuel = 0;
	//     refueling = 0;
	//     draining = 0;
	//     consumption = 0;
	//     consumptionDuringMovement = 0;
	//     mileage = 0;
	//     mileageDuringMovement = 0;*/
	//   });
	//
	//   return formatedExportData;
	// }
	//
	// convertToPdf(data) {
	//   const doc = new jsPDF({orientation: 'l', unit: 'mm', format: 'a3'});
	//
	//   let col = [
	//     'Vehicle',
	//     'Date',
	//     'First Ignition ON',
	//     'Distance (KM)',
	//     'AverageSpeed (KM/H)',
	//     'Top Speed (KM/H)',
	//     'EngineHours',
	//     'MovementHours',
	//     'IdleHours',
	//     'Last Ignition OFF',
	//   ];
	//   let rows = [];
	//
	//   let formated = formatExportData(data);
	//   formated.forEach((summary) => {
	//     let temp = [];
	//     Object.keys(summary).forEach((key, index) => {
	//       if (col[index] === key) temp[index] = summary[key];
	//     });
	//     rows.push(temp);
	//   });
	//
	//   let header = function (data) {
	//     doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	//   };
	//
	//   doc.autoTable(col, rows, {
	//     margin: {top: 25},
	//     beforePageContent: header,
	//     theme: 'grid',
	//     didParseCell: function (data) {
	//       let rows = data.table.body;
	//       rows.forEach((row) => {
	//         if (row.raw.includes('Total')) {
	//           if (data.row.index === row.index) {
	//             data.cell.STYLED.fontStyle = 'bold';
	//             data.cell.STYLED.fontSize = 11;
	//           }
	//         }
	//       });
	//     },
	//   });
	//
	//   doc.save(`${!fileName ? 'Summary' : fileName}`);
	// }
	//
	// export() {
	//
	//   const {summary, selectedRows} = state;
	//
	//   if (!selectAll) {
	//
	//     let summaryToExport = [];
	//     summary.forEach((summary) => {
	//       if (selectedRows.includes(summary.id)) {
	//         summaryToExport.push(summary);
	//       }
	//     });
	//
	//     if (fileExtension === 'excel') {
	//       exportToExcel(
	//         formatExportData(summaryToExport),
	//         `${!fileName ? 'Summary' : fileName}`,
	//         FileHeader
	//       );
	//     } else {
	//       convertToPdf(summaryToExport);
	//     }
	//   } else {
	//     exportAll(getFilteredQuery());
	//   }
	//
	//   closeDownloadFileModal();
	// }
	//
	// handleSelectAll(checked) {
	//   let selectedRow = new Set();
	//
	//   if (checked) {
	//     summary.forEach((summary) => selectedRow.add(summary.id));
	//   }
	//
	//   setState({
	//     selectedRows: selectedRow,
	//     anyRowSelected: selectedRow.size && true,
	//   });
	// }

	const exportAll = () => {
		// let params = `?vehicles=${selectedVehiclesFromSidebar.join(',')}`;
		let startTime = initialDateState;
		const endTime = finalDateState;

		trackMixpanelEvent('export_summery_report', {
			vehicles: selectedVehiclesNumbers,
			export: 'files_export',
		});

		if (intervalValue === 'monthly')
			startTime = moment(initialDateState).subtract(11, 'months').startOf('month').toISOString();

		const body = {
			vehicleIds: selectedVehiclesFromSidebar,
			startTime: startTime,
			endTime: endTime,
			fileType: fileExtension,
			sortBy: activeSort,
			orderBy: sortOrder,
			interval: intervalValue,
		};
		exportPostData(EXPORT_API, '/summary', body)
			.then((response) => {
				const contentDisposition = response.headers['content-disposition'];
				const matches = /filename="([^"]+)"/.exec(contentDisposition);
				if (matches) {
					const fileName = matches[1];
					fileDownload(response.data, fileName);
				}
			})
			.catch((error) => {
				handleError('Summary.tsx => exportAll() => exportPostData()', error);
			});
	};

	const toggleSelection = (checked: boolean | undefined, name: string) => {
		let selectedRowTemp = selectedColumns;

		if (checked) {
			selectedRowTemp.push(name);
		} else {
			selectedRowTemp = selectedRowTemp.filter((columnName) => columnName !== name);
		}
		setSelectedColumns([...selectedRowTemp]);
	};

	//draggable columns
	const [columnOrder, setColumnOrder] = useState([
		'Serial',
		'Vehicle',
		'Date',
		'First Ignition',
		'First Address',
		'Distance(KM)',
		'Avg Speed(KM/H)',
		'Top Speed(KM/H)',
		'Start Odometer(KM)',
		// 'Odo Difference(KM)',
		'End Odometer(KM)',
		'Engine Operation Time',
		'Movement Time',
		'Idle Time',
		'Last Ignition',
		'Last Address',
		'First GeoFence Event',
		'Last GeoFence Event',
		'Trip Count',
		'OverSpeed Count',
		'Driver',
		'Vehicle Groups',
	]);

	const fixedColumns = ['Serial', 'Vehicle', 'Date'];

	useEffect(() => {
		// Load column preference from local storage

		const savedColumnOrder = localStorage.getItem('columnOrder');
		if (savedColumnOrder) {
			setColumnOrder(JSON.parse(savedColumnOrder));
		} else {
			localStorage.setItem('columnOrder', JSON.stringify(columnOrder));
		}
		// renderTableCell();
	}, []);

	const handleDragStart = (e: React.DragEvent, columnName: string) => {
		// Only allow dragging for non-fixed columns
		if (!fixedColumns.includes(columnName)) {
			e.dataTransfer.effectAllowed = 'move';
			e.dataTransfer.setData('text/plain', columnName);
		}
	};

	const handleDragOver = (e: React.DragEvent) => {
		e.preventDefault();
		e.dataTransfer.dropEffect = 'move';
	};

	const handleDrop = (e: React.DragEvent, targetIndex: number) => {
		e.preventDefault();
		const columnName = e.dataTransfer.getData('text/plain');

		// Only allow reordering for non-fixed columns
		if (!fixedColumns.includes(columnOrder[targetIndex])) {
			const updatedOrder = [...columnOrder];
			const sourceIndex = updatedOrder.indexOf(columnName);
			updatedOrder.splice(sourceIndex, 1);
			updatedOrder.splice(targetIndex, 0, columnName);
			setColumnOrder(updatedOrder);

			// Save column preference to local storage
			localStorage.setItem('columnOrder', JSON.stringify(updatedOrder));
		}
	};

	const isoToCustom = (isoDate: string, format: string) => {
		return moment(isoDate).format(format);
	};

	const renderTableCell = (day: SummaryDTO, columnName: string, index: number) => {
		switch (columnName) {
			case 'Serial':
				return visibleColumns.includes('Serial') ? index : '-';
			case 'Vehicle':
				return visibleColumns.includes('Vehicle') ? day.vehicle?.registrationNumber || '-' : '-';
			case 'Date':
				return visibleColumns.includes('Date')
					? intervalValue === 'monthly'
						? moment(day.date, 'DD-MM-YYYY').format('MMM YY')
						: day.date
					: '-';
			case 'First Ignition':
				return visibleColumns.includes('First Ignition')
					? day.firstIgnitionOn
						? isoToCustom(day.firstIgnitionOn, 'hh:mm:ss A')
						: '-'
					: null;
			case 'First Address':
				return visibleColumns.includes('First Address') ? day.firstIgnitionAddress : '-';
			case 'Distance(KM)':
				return visibleColumns.includes('Distance(KM)')
					? typeof day.distance !== 'string'
						? Number(day.distance?.toFixed(2)).toLocaleString()
						: 0
					: 'typeIssue';
			case 'Avg Speed(KM/H)':
				return visibleColumns.includes('Avg Speed(KM/H)') ? day.averageSpeed : '-';
			case 'Top Speed(KM/H)':
				return visibleColumns.includes('Top Speed(KM/H)') ? day.maxSpeed : '-';
			case 'Start Odometer(KM)':
				return visibleColumns.includes('Start Odometer(KM)') ? day.startOdometer : '-';
			// case 'Odo Difference(KM)':
			// 	return visibleColumns.includes('Odo Difference(KM)') ? day.odometerDifference : '-';
			case 'End Odometer(KM)':
				return visibleColumns.includes('End Odometer(KM)') ? day.endOdometer : '-';
			case 'Engine Operation Time':
				return visibleColumns.includes('Engine Operation Time') ? day.engineHoursView : '-';
			case 'Movement Time':
				return visibleColumns.includes('Movement Time') ? day.movementHoursView : '-';
			case 'Idle Time':
				return visibleColumns.includes('Idle Time') ? day.idleHoursView : '-';
			case 'Last Ignition':
				return visibleColumns.includes('Last Ignition')
					? day.lastIgnitionOff
						? isoToCustom(day.lastIgnitionOff, 'hh:mm:ss A')
						: '-'
					: null;
			case 'Last Address':
				return visibleColumns.includes('Last Address') ? day.lastIgnitionAddress : '-';
			case 'First GeoFence Event':
				return visibleColumns.includes('First GeoFence Event') ? day.firstGeofenceEvent : '-';
			case 'Last GeoFence Event':
				return visibleColumns.includes('Last GeoFence Event') ? day.lastGeofenceEvent : '-';
			case 'Trip Count':
				return visibleColumns.includes('Trip Count') ? day.tripCount?.toLocaleString() : '-';
			case 'OverSpeed Count':
				return visibleColumns.includes('OverSpeed Count') ? day.overSpeedingCount?.toLocaleString() : '-';
			case 'Driver':
				return visibleColumns.includes('Driver') ? day.driver : '-';
			case 'Vehicle Groups':
				return visibleColumns.includes('Vehicle Groups') ? day.vehicleGroups : '-';
			default:
				return '-';
		}
	};

	const toggleSort = (columnName: string) => {
		setSortDirections((prevSortDirections: { [columnName: string]: string }) => ({
			...prevSortDirections,
			[columnName]: prevSortDirections[columnName] === 'asc' ? 'desc' : 'asc',
		}));
		sort(columnName, sortDirections[columnName]);
	};

	const highlightOperationAndMovementHours = (columnName: string, data: SummaryDTO) => {
		if (
			(columnName === 'Engine Operation Time' || columnName === 'Movement Time') &&
			((data?.engineHoursView !== undefined &&
				data?.movementHoursView !== undefined &&
				moment(data?.engineHoursView, 'HH:mm:ss') < moment(data?.movementHoursView, 'HH:mm:ss')) ||
				data?.engineHoursView === '00.00.00')
		) {
			return true;
		} else {
			return false;
		}
	};

	const getTableData = (whichTabelCell: string, data: SummaryDTO, index: number) => {
		switch (whichTabelCell) {
			case 'Serial':
				return 'Total';
			case 'Vehicle':
				return activeSort === 'vehicle' ? data?.vehicle?.registrationNumber : '-';
			case 'Date':
				return activeSort === 'date' ? data?.date : '-';
			case 'First Ignition':
				return '-';
			case 'First Address':
				return '-';
			case 'Top Speed(KM/H)':
				return data?.maxSpeed;
			case 'Engine Operation Time':
				return data?.engineOperationTime;
			case 'Movement Time':
				return data?.movementHours;
			case 'Idle Time':
				return data?.idleHours;
			case 'Last Ignition':
				return '-';
			case 'OverSpeed Count':
				return data?.overSpeedCount;
			default:
				return renderTableCell(data, whichTabelCell, index) || '-';
		}
	};

	return (
		<Container>
			<div style={{ display: 'flex', width: '100%' }}>
				<CategoryHeader>
					<Button primary disabled={loading} icon title="Refresh" onClick={() => getFilterEvents(activePage)}>
						<Icon name="refresh" />
					</Button>
					<Expander />
					<div style={{ marginRight: '1em' }}>
						<Button.Group primary>
							<Button disabled={!selectedVehiclesFromSidebar?.length} primary>
								{`${intervalValue === 'daily' ? 'Daily Report' : 'Monthly Report'}`}
							</Button>
							<Dropdown
								className="button icon"
								style={{ background: '#ffffff', color: '#1b3c71' }}
								disabled={!selectedVehiclesFromSidebar?.length}
								floating
								trigger={<></>}
								options={SUMMARY_INTERVAL_DROPDOWN_OPTIONS}
								onChange={(e, { value }) => {
									if (typeof value === 'string') {
										setIntervalValue(value);
									}
								}}
							/>
						</Button.Group>
					</div>
					<div style={{ marginRight: '1em' }}>
						<Button.Group primary>
							<Button disabled={!summary?.length} primary onClick={exportAll}>
								Export
							</Button>
							<Dropdown
								className="button icon"
								style={{ background: '#ffffff', color: '#1b3c71' }}
								disabled={!summary?.length}
								floating
								trigger={<></>}
								options={EXPORT_FILE_EXTENSION}
								defaultValue={'pdf'}
								onChange={(e, { value }: DropdownProps) => {
									if (typeof value === 'string') setFileExtension(value);
								}}
							/>
						</Button.Group>
					</div>
					<Button.Group>
						<Button
							icon="setting"
							primary
							title={'Choose Custom Fields'}
							style={{ marginRight: '1em' }}
							disabled={loading || !selectedVehiclesFromSidebar.length}
							onClick={() => {
								if (!openFieldsMenu) setOpenFieldsMenu(true);
								else setOpenFieldsMenu(false);
								setSelectedColumns([...visibleColumns]);
							}}
						/>
						{openFieldsMenu && (
							<Dropdown open={openFieldsMenu} style={{ width: 'fit-content', marginTop: '2em' }}>
								<Dropdown.Menu style={{ maxHeight: '400px', overflowY: 'auto' }}>
									<Dropdown.Item
										style={{
											textAlign: 'center',
											background: '#FFFFFF',
											position: 'sticky',
											top: 0,
											zIndex: 1,
										}}>
										<h3 style={{ background: '#FFFFFF', padding: '5px 5px' }}>Select Column Fields</h3>
									</Dropdown.Item>
									<Dropdown.Divider />
									{columnOptions.map((column, index) => {
										return (
											<Dropdown.Item key={index + column + Math.random() * 100}>
												<Checkbox
													checked={selectedColumns.includes(column)}
													label={column}
													onClick={(e, { checked }) => {
														toggleSelection(checked, column);
													}}
												/>
											</Dropdown.Item>
										);
									})}
									<Dropdown.Item
										style={{
											textAlign: 'center',
											background: '#FFFFFF',
											position: 'sticky',
											bottom: 0,
											zIndex: 2,
										}}>
										<Button
											color="black"
											style={{ marginRight: '5px' }}
											onClick={() => {
												setOpenFieldsMenu(false);
												setSelectedColumns([]);
											}}>
											Cancel
										</Button>
										<Button
											primary
											// disabled={!tempFilters.events.length}
											onClick={() => {
												setVisibleColumns([...selectedColumns]);
												// const jsonArray = JSON.stringify(selectedColumns);
												if (Object.keys(selectedColumns).length)
													localStorage.setItem('summaryColumns', JSON.stringify(selectedColumns));
												setOpenFieldsMenu(false);
												setSelectedColumns([]);
											}}>
											Apply
										</Button>
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						)}
					</Button.Group>
				</CategoryHeader>
			</div>
			<TableContainer id="TableContainer">
				{initialLoading ? (
					<TrackNerdLoader />
				) : selectedVehiclesFromSidebar.length !== 0 ? (
					summary && summary.length !== 0 ? (
						<Table celled compact selectable striped className="fixed_headers ui tablet stackable table">
							<Table.Header>
								<Table.Row>
									{columnOrder.map(
										(columnName, columnIndex) =>
											visibleColumns.includes(columnName) && (
												<Table.HeaderCell
													key={columnName}
													singleLine
													textAlign="center"
													style={{
														cursor: ![
															'Serial',
															'First Address',
															'Last Address',
															'First GeoFence Event',
															'Last GeoFence Event',
															'Trip Count',
															// 'Odo Difference(KM)',
															'OverSpeed Count',
															'Driver',
														].includes(columnName)
															? 'pointer'
															: 'default',
														color: activeSort === columnName ? THEME.COLORS.theme : '',
														width:
															columnName === 'Serial'
																? '6rem'
																: columnName === 'Vehicle'
																  ? '10rem'
																  : columnName === 'Date'
																    ? '8rem'
																    : '14rem',
													}}
													draggable={!fixedColumns.includes(columnName)}
													onDragStart={(e: React.DragEvent) => handleDragStart(e, columnName)}
													onDragOver={(e: React.DragEvent) => handleDragOver(e)}
													onDrop={(e: React.DragEvent) => handleDrop(e, columnIndex)}>
													<div
														style={{
															display: 'flex',
															margin: 'auto',
															justifyContent: 'center',
														}}
														onClick={() =>
															![
																'Serial',
																'First Address',
																'Last Address',
																'First GeoFence Event',
																'Last GeoFence Event',
																'Trip Count',
																// 'Odo Difference(KM)',
																'OverSpeed Count',
																'Driver',
															].includes(columnName) && toggleSort(columnName)
														}>
														{![
															'Serial',
															'First Address',
															'Last Address',
															'First GeoFence Event',
															'Last GeoFence Event',
															'Trip Count',
															// 'Odo Difference(KM)',
															'OverSpeed Count',
															'Driver',
														].includes(columnName) && (
															<Icon
																name={sortDirections[columnName] === 'asc' ? 'sort alphabet down' : 'sort alphabet up'}
															/>
														)}
														{columnName}
													</div>
												</Table.HeaderCell>
											)
									)}
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{summary.map((sortBys, parentIndex) => {
									return (
										<>
											{sortBys.map((day, index) => {
												if (index !== sortBys.length - 1) {
													return (
														<Table.Row
															key={index}
															draggable={!fixedColumns.includes('Serial')}
															// onDragStart={(e: any) => handleDragStart(e, index)}
															// onDragOver={(e: any) => handleDragOver(e)}
															// onDrop={(e: any) => handleDrop(e, index)}
														>
															{columnOrder.map(
																(columnName) =>
																	visibleColumns.includes(columnName) && (
																		/*<Table.Cell
                                                                            key={columnName}
                                                                            textAlign={columnName === 'Vehicle' ? 'left' : 'center'}
                                                                            style={{
                                                                                color: highlightOperationAndMovementHours(columnName, day),
                                                                            }}>
                                                                            {renderTableCell(
                                                                                day,
                                                                                columnName,
                                                                                sortBys.length <= 2
                                                                                    ? dataSizeLimitState * (activePage - 1) + 1 + parentIndex
                                                                                    : index + 1
                                                                            )}
                                                                        </Table.Cell>*/

																		<Popup
																			key={columnName}
																			style={{ zIndex: '10' }}
																			disabled={!highlightOperationAndMovementHours(columnName, day)}
																			trigger={
																				<Table.Cell
																					key={columnName}
																					textAlign={columnName === 'Vehicle' ? 'left' : 'center'}
																					style={{
																						color: highlightOperationAndMovementHours(columnName, day)
																							? 'orange'
																							: 'black',
																					}}>
																					{renderTableCell(
																						day,
																						columnName,
																						sortBys.length <= 2
																							? dataSizeLimitState * (activePage - 1) + 1 + parentIndex
																							: index + 1
																					)}
																				</Table.Cell>
																			}
																			on={'hover'}
																			position={'bottom center'}
																			hoverable={true}
																			content={
																				<div style={{ fontWeight: 'bold' }}>
																					<Icon name={'exclamation circle'} color={'orange'} />
																					{day.engineHours === '00.00.00' ? (
																						<span>No ignition data!</span>
																					) : (
																						<span>Loose wiring of ignition source!</span>
																					)}
																				</div>
																			}
																		/>
																	)
															)}
														</Table.Row>
													);
												} else if (sortBys.length > 2) {
													return (
														(activeSort === 'date' || activeSort === 'vehicle') && (
															<Table.Row
																style={{ fontWeight: 'bold' }}
																key={index}
																draggable={!fixedColumns.includes('Serial')}
																// onDragStart={(e:any) => handleDragStart(e, index)}
																// onDragOver={(e: any) => handleDragOver(e)}
																// onDrop={(e: any) => handleDrop(e, index)}
															>
																{columnOrder.map(
																	(columnName) =>
																		visibleColumns.includes(columnName) && (
																			<Table.Cell
																				key={columnName}
																				textAlign={columnName === 'Vehicle' ? 'left' : 'center'}>
																				{getTableData(columnName, day, index)}
																			</Table.Cell>
																		)
																)}
															</Table.Row>
														)
													);
												}
											})}
										</>
									);
									// }
								})}
							</Table.Body>
						</Table>
					) : (
						<PlaceHolder content={'No Data'} iconName={'cloud'} />
					)
				) : (
					<NoVehicleSelection />
				)}
			</TableContainer>
			<CategoryFooter>
				<Expander />
				<PageSelector>
					<Dropdown
						fluid
						selection
						disabled={loading || !summary?.length}
						defaultValue={dataSizeLimitState}
						options={PAGE_COUNT_OPTIONS}
						onChange={(e, { value }: DropdownProps) => {
							if (typeof value === 'number') handleDataSizeLimitChange(value);
						}}
					/>
				</PageSelector>
				<Pagination
					activePage={activePage}
					disabled={loading || !summary?.length}
					ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
					firstItem={{ content: <Icon name="angle double left" />, icon: true }}
					lastItem={{ content: <Icon name="angle double right" />, icon: true }}
					nextItem={null}
					onPageChange={(e, { activePage }) => {
						if (typeof activePage === 'number') {
							Store.dispatch({
								type: 'SET_PAGE_LIMIT',
								payload: activePage,
							});
							getFilterEvents(activePage);
						}
						setTimeout(() => {
							const tableContainer = document.getElementById('TableContainer');
							if (tableContainer !== null) {
								tableContainer.scrollTo(0, 0);
							}
						}, 500);
					}}
					prevItem={null}
					totalPages={Math.ceil(dataSize.current / dataSizeLimitState)}
				/>
			</CategoryFooter>

			{/*{openFieldsMenu && (
				<Modal
					as="list"
					style={{ width: 'fit-content' }}
					open={openFieldsMenu}
					onClose={() => {
						setOpenFieldsMenu(false);
						setSelectedColumns([]);
					}}>
					<Modal.Header>Select Column Fields</Modal.Header>
					<Modal.Content>
						<List vertical>
							<ListItem field={'First Ignition On'} />
							<ListItem field={'First Address'} />
							<ListItem field={'Distance'} />
							<ListItem field={'Avg Speed'} />
							<ListItem field={'Top Speed'} />
							<ListItem field={'Start Odometer'} />
							<ListItem field={'End Odometer'} />
							<ListItem field={'Engine Operation Time'} />
							<ListItem field={'Movement Time'} />
							<ListItem field={'Idle Time'} />
							<ListItem field={'Last Ignition Off'} />
							<ListItem field={'Last Address'} />
							<ListItem field={'First GeoFence Event'} />
							<ListItem field={'Last GeoFence Event'} />
							<ListItem field={'Trip Count'} />
							<ListItem field={'OverSpeed Count'} />
							<ListItem field={'Driver'} />
							<ListItem field={'Vehicle Groups'} />
						</List>
					</Modal.Content>
					<Modal.Actions>
						<FlexContainer>
							<Button
								color="black"
								onClick={() => {
									setOpenFieldsMenu(false);
									setSelectedColumns([]);
								}}>
								Cancel
							</Button>
							<Expander />
							<Button
								primary
								// disabled={!tempFilters.events.length}
								onClick={() => {
									setVisibleColumns([...selectedColumns]);
									const jsonArray = JSON.stringify(selectedColumns);
									localStorage.setItem('summaryColumns', jsonArray);
									setOpenFieldsMenu(false);
									setSelectedColumns([]);
								}}>
								Apply
							</Button>
						</FlexContainer>
					</Modal.Actions>
				</Modal>
			)}

			{loading && !initialLoading && <TransitionLoader />}

			{/*{downloadFileModal && (*/}
			{/*  <Modal onClose={closeDownloadFileModal} open={downloadFileModal} size="tiny">*/}
			{/*    <Modal.Header>Download File</Modal.Header>*/}
			{/*    <Modal.Content>*/}
			{/*      <Form>*/}
			{/*        <Form.Group>*/}
			{/*          <Form.Field width={10}>*/}
			{/*            <label>File Name</label>*/}
			{/*            <Input*/}
			{/*              autoFocus*/}
			{/*              value={fileName}*/}
			{/*              onChange={(event) => {*/}
			{/*                setState({fileName: event.target.value});*/}
			{/*              }}*/}
			{/*            />*/}
			{/*          </Form.Field>*/}
			{/*          <Form.Field width={6}>*/}
			{/*            <label>File Type</label>*/}
			{/*            <Dropdown*/}
			{/*              fluid*/}
			{/*              search*/}
			{/*              selection*/}
			{/*              value={fileExtension}*/}
			{/*              options={extension}*/}
			{/*              onChange={(event, {value}) =>*/}
			{/*                setState({*/}
			{/*                  fileExtension: value,*/}
			{/*                })*/}
			{/*              }*/}
			{/*            />*/}
			{/*          </Form.Field>*/}
			{/*        </Form.Group>*/}
			{/*      </Form>*/}
			{/*    </Modal.Content>*/}
			{/*    <Modal.Actions>*/}
			{/*      <Button color="black" onClick={closeDownloadFileModal}>*/}
			{/*        Cancel*/}
			{/*      </Button>*/}
			{/*      <Button primary onClick={export}>*/}
			{/*        Download*/}
			{/*      </Button>*/}
			{/*    </Modal.Actions>*/}
			{/*  </Modal>*/}
			{/*)}*/}
		</Container>
	);
};

export default Summary;
