import moment from 'moment';

export interface DatesState {
	initialDate: string;
	finalDate: string;
	initialDateTime: string;
	finalDateTime: string;
	disableTimePicker: boolean;
	disableDatePicker: boolean;
	calendarVisible: boolean;
	track7Days: boolean;
	threeMonthData: boolean;
}

interface DatesAction {
	type: string;
	payload?: string | boolean | SetDatesPayload | SetInitialDatesPayload;
}

interface SetDatesPayload {
	initialDate: string;
	finalDate: string;
}

interface SetInitialDatesPayload {
	initialDateTime: string;
	finalDateTime: string;
}

export default function dates(
	initialState: DatesState = {
		initialDate: moment().startOf('day').toISOString(),
		finalDate: moment().endOf('day').toISOString(),
		initialDateTime: moment().startOf('day').format('HH:mm:ss:SSS'),
		finalDateTime: moment().endOf('day').format('HH:mm:ss:SSS'),
		disableTimePicker: false,
		disableDatePicker: false,
		calendarVisible: false,
		track7Days: false,
		threeMonthData: false,
	},
	action: DatesAction
) {
	switch (action.type) {
		case 'SET_DATES': {
			const payload = action.payload as SetDatesPayload;
			return {
				...initialState,
				initialDate: payload.initialDate,
				finalDate: payload.finalDate,
			};
		}
		case 'RESET_DATES':
			return {
				...initialState,
				initialDate: moment().startOf('day').toISOString(),
				finalDate: moment().endOf('day').toISOString(),
			};
		case 'DISABLE_DATEPICKER':
			return {
				...initialState,
				disableDatePicker: action.payload,
			};

		case 'TRACK_7_DAYS':
			return {
				...initialState,
				track7Days: action.payload,
			};

		case 'DISABLE_TIMEPICKER':
			return {
				...initialState,
				disableTimePicker: action.payload,
			};

		case 'SET_DATE_TIME': {
			const payload = action.payload as SetInitialDatesPayload;
			return {
				...initialState,
				initialDateTime: payload.initialDateTime,
				finalDateTime: payload.finalDateTime,
			};
		}

		case 'RESET_DATE_TIME':
			return {
				...initialState,
				initialDateTime: moment().startOf('day').format('HH:mm:ss:SSS'),
				finalDateTime: moment().endOf('day').format('HH:mm:ss:SSS'),
			};

		case 'CLEAR_DATES':
			return {
				...initialState,
				initialDate: null,
				finalDate: null,
			};

		case 'SHOW_CALENDAR':
			return {
				...initialState,
				calendarVisible: true,
			};

		case 'HIDE_CALENDAR':
			return {
				...initialState,
				calendarVisible: false,
			};

		case 'ENABLE_THREE_MONTHS_DATA':
			return {
				...initialState,
				threeMonthData: true,
			};

		case 'DISABLE_THREE_MONTHS_DATA':
			return {
				...initialState,
				threeMonthData: false,
			};

		default:
			return initialState;
	}
}
