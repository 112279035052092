import moment from 'moment';

export function isoToHumanReadable(timestamp: string): string {
	return moment(timestamp).format('DD-MM-YYYY, hh:mm:ss A');
}

export function firebaseTimestampToPretty(timestamp: string): string {
	return timestamp
		? moment(timestamp).isSame(moment(), 'day')
			? isoToCustom(timestamp, 'hh:mm a')
			: isoToCustom(timestamp, 'DD-MM-YYYY, hh:mm a')
		: '';
}

export function isoToCustom(timestamp: string, format: string): string {
	return moment(timestamp).format(format);
}

export function hoursToHMS(hours: number): string {
	return moment.utc(moment.duration(hours, 'hours').asMilliseconds()).format('HH:mm:ss');
}

export function secondsToHrs(span: number): string {
	const duration = moment.duration(span, 'seconds');
	return moment.utc(duration.asMilliseconds()).format('H[h] m[m]');
}

export function secondsToHrsEvents(span: number) {
	const duration = moment.duration(span, 'seconds');
	return moment.utc(duration.asMilliseconds()).format('H[h] m[m] s[s]');
}

export function hoursToHoursMinutes(hours: string): string {
	const duration = moment.duration(hours, 'hours');
	return moment.utc(duration.asMilliseconds()).format('H[h] m[m]');
}

export function hoursToHHMM(hours: number): string {
	const totalHours = Math.floor(hours);
	const remainingMinutes = Math.round((hours - totalHours) * 60);
	return `${totalHours}h ${remainingMinutes}m`;
}

export function getDiff(startTime: string, endTime: string): string {
	const start = moment(startTime);
	const end = moment(endTime);

	const duration = moment.duration(end.diff(start, 'seconds'), 'seconds');
	return moment.utc(moment.duration(duration, 'seconds').asMilliseconds()).format('HH:mm:ss [s]');
}

export function getDiffInDays(startTime: string, endTime: string): number {
	const start = moment(startTime);
	const end = moment(endTime);

	return moment.duration(end.diff(start, 'days'), 'days').asDays();
}

export function getDifference(timestamp1: string, timestamp2: string): string {
	const t1 = moment(timestamp1);
	const t2 = moment(timestamp2);

	if (t2.diff(t1, 'seconds') <= 60) {
		return `${t2.diff(t1, 'seconds')} sec`;
	} else if (t2.diff(t1, 'minutes') <= 60) {
		return `${t2.diff(t1, 'minutes')} min`;
	} else if (t2.diff(t1, 'hours') <= 24) {
		return `${t2.diff(t1, 'hours')} hr`;
	} else {
		return `${t2.diff(t1, 'days')} day`;
	}
}

export function getElapsedTime(timestamp: string, unit: string): number {
	const t1 = moment(timestamp);
	const t2 = moment();

	switch (unit) {
		case 'hours':
			return t2.diff(t1, 'hours');

		case 'minutes':
			return t2.diff(t1, 'minutes');

		default:
			return t2.diff(t1, 'seconds');
	}
}

export function convertSecondsToDuration(seconds: number): string {
	const duration: moment.Duration = moment.duration(seconds, 'seconds');
	const hours = duration.asHours();
	const days = Math.floor(hours / 24);
	const remainingHours = hours % 24;
	const minutes = duration.minutes();
	return days > 0
		? `${days}d ${Math.floor(remainingHours)}h ${minutes}m ${duration.seconds()}s`
		: `${Math.floor(hours)}h ${minutes}m ${duration.seconds()}s`;
}
