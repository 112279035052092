import React, { useEffect, useRef, useState } from 'react';
import { GraphCard, GraphContainer, GraphHeader } from '../OverviewStyles';
import { ResponsiveBar } from '@nivo/bar';
import { THEME } from '../../../../constants/Theme';
import moment from 'moment';
import { dispatch, dispatchNoPayload } from '../../../../helper/view specific/store';
import { NavLink } from 'react-router-dom';
import TrackNerdLoader from '../../../../components/loader/Loader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/RootState';
import { mostEventVehicle } from '../Overview';

interface EventCountProps {
	data: mostEventVehicle[];
}

interface GraphProps {
	Date: string;
	'Main Power': string;
	Parking: string;
	SOS: string;
	OverSpeed: string;
	Idling: string;
	Count: string;
	Vehicle: string;
}

const EventCount = (props: EventCountProps) => {
	const [graphData, setGraphData] = useState<GraphProps[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const navLinkRef = useRef<HTMLAnchorElement | null>(null);

	const GeofencePermission = useSelector((state: RootState) => state.rolePermissions.permissions.Geofence);

	useEffect(() => {
		formatData(props.data);
	}, []);

	const formatData = (reports: mostEventVehicle[]) => {
		const tempData: GraphProps[] = [];
		const formattedData: Record<string, Partial<mostEventVehicle>> = {};
		reports.forEach((day) => {
			formattedData[day.date] = {
				['date']: day.date ? moment(day.date).format('DD-MM-YYYY') : '',
				mainPower: day.mainPower ?? '',
				parking: day.parking ?? '',
				sos: day.sos ?? '',
				idling: day.idling ?? '',
				overspeed: day.overspeed ?? '',
				count: day.count ?? '',
				registrationNumber: day.registrationNumber ?? '',
			};
		});
		Object.keys(formattedData)
			.sort((start, end) => {
				const startTime = moment(start, 'YYYY-MM-DD');
				const endTime = moment(end, 'YYYY-MM-DD');
				return startTime.diff(endTime);
			})
			.forEach((data) => {
				if (formattedData[data]) {
					tempData.push({
						Date: formattedData[data].date ?? '',
						'Main Power': formattedData[data].mainPower ?? '',
						Parking: formattedData[data].parking ?? '',
						SOS: formattedData[data].sos ?? '',
						OverSpeed: formattedData[data].overspeed ?? '',
						Idling: formattedData[data].idling ?? '',
						Count: formattedData[data].count ?? '',
						Vehicle: formattedData[data].registrationNumber ?? '',
					});
				}
			});
		setGraphData([...tempData]);
		setLoading(false);
	};

	const barData = graphData.map((item) => ({
		Date: item.Date,
		'Main Power': item['Main Power'],
		Parking: item.Parking,
		SOS: item.SOS,
		OverSpeed: item.OverSpeed,
		Idling: item.Idling,
		Count: item.Count,
		Vehicle: item.Vehicle,
	}));

	return (
		<GraphContainer>
			{loading ? (
				<TrackNerdLoader />
			) : barData.length ? (
				<GraphCard>
					<GraphHeader>
						<h1>Events Counts</h1>
					</GraphHeader>
					<ResponsiveBar
						data={barData}
						// width={630}
						// height={400}
						enableGridY={false}
						enableGridX={false}
						keys={['Main Power', 'Parking', 'SOS', 'OverSpeed', 'Idling']}
						colors={[
							THEME.COLORS.MainPower,
							THEME.COLORS.Parking,
							THEME.COLORS.SOS,
							THEME.COLORS.overSpeed,
							THEME.COLORS.Idling,
						]}
						indexBy="Date"
						margin={{ top: 50, right: 50, bottom: 80, left: 50 }}
						padding={0.3}
						valueScale={{ type: 'linear' }}
						indexScale={{ type: 'band', round: true }}
						borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
						axisTop={null}
						axisRight={null}
						enableLabel={true}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor="black"
						label={(e) => {
							return `${e.value}`;
						}}
						/*  tooltipFormat={(value) => {
                const hours = Math.trunc(value);
                const minutes = (value - hours) * 60;
                // const seconds = (minutes % 1) * 60;

                return `${Math.trunc(hours)}h:${Math.trunc(minutes)}m`;
              }}*/
						axisBottom={{
							// enable: false,
							tickRotation: -45,
							format: (date) => `${moment(date, 'DD-MM-YYYY').format('D MMM')}`,
							tickPadding: 5,
							legendOffset: 40,
						}}
						axisLeft={null}
						legends={[
							{
								dataFrom: 'keys',
								anchor: 'bottom',
								direction: 'row',
								justify: false,
								translateX: 0,
								translateY: 70,
								// itemsSpacing: 5,
								// itemWidth: 90,
								itemsSpacing: 18,
								itemWidth: 100,
								itemHeight: 20,
								itemDirection: 'left-to-right',
								itemOpacity: 0.85,
								symbolSize: 15,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemOpacity: 1,
										},
									},
								],
							},
						]}
						onClick={(node) => {
							if (GeofencePermission.manage || GeofencePermission.read) {
								dispatch('SET_SEARCH_QUERY', node.data['Vehicle']);
								dispatchNoPayload('SET_VEHICLE_SELECTED_FROM_GRAPH');
								dispatch('SELECTED_TAB', 'Events');
								dispatch('SET_DATES', {
									initialDate: moment(node.data.Date, 'DD-MM-YYYY').startOf('day').toISOString(),
									finalDate: moment(node.data.Date, 'DD-MM-YYYY').endOf('day').toISOString(),
								});
								if (navLinkRef?.current) navLinkRef?.current.click();
							}
						}}
						tooltip={(e) => {
							const offsetX = -20;
							const offsetY = 5;
							const x = window.screenX + offsetX;
							const y = window.screenY + offsetY;
							return (
								<div
									style={{
										padding: '12px',
										color: 'black',
										background: 'white',
										display: 'flex',
										flexDirection: 'column',
										boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
										position: 'absolute',
										right: x + 'px',
										bottom: y + 'px',
										width: '200px',
									}}>
									<div style={{ justifyContent: 'left' }}>
										<strong>{'Event Count'}</strong>
									</div>
									<div style={{ justifyContent: 'left' }}>Vehicle: {e.data.Vehicle}</div>
									<div style={{ justifyContent: 'left' }}>Counts: {e.data.Count}</div>
									<div style={{ justifyContent: 'left' }}>Main Power: {e.data['Main Power']}</div>
									<div style={{ justifyContent: 'left' }}>Parking: {e.data['Parking']}</div>
									<div style={{ justifyContent: 'left' }}>OverSpeed: {e.data['OverSpeed']}</div>
									<div style={{ justifyContent: 'left' }}>Idling: {e.data['Idling']}</div>
									{/*<div style={{ justifyContent: 'left' }}>Geofence: {e.data['Geofence']}</div>*/}
									{/*<div style={{ justifyContent: 'left' }}>Ac: {e.data['Ac']}</div>*/}
									<div style={{ justifyContent: 'left' }}>SOS: {e.data['SOS']}</div>
								</div>
							);
						}}
						animate={true}
						// motionStiffness={90}
						// motionDamping={15}
					/>
				</GraphCard>
			) : (
				<GraphCard>
					<GraphHeader>
						<h1>Events Counts</h1>
					</GraphHeader>
					<div
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							flex: 1,
						}}>
						<p>No Data!</p>
					</div>
				</GraphCard>
			)}
			<NavLink
				ref={navLinkRef}
				to={{
					pathname: '/vehicles',
				}}
			/>
		</GraphContainer>
	);
};

// Connect to Store

export default EventCount;
