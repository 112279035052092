import { GEOFENCE_API } from '../../../constants/api';
import { createGeoFenceDTO, createSingleGeoFenceDTO, GeoFenceDTOData } from '../../../dtos/geofence';
import Store from '../../../redux/store';
import { handleError } from '../../../helper/view specific/messaging';
import axios from 'axios';
import { Geofence } from '../../../types/geofence';

export function fetchGeoFences(params = '') {
	return axios(GEOFENCE_API + params, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
}

export function loadGeoFences(param = '', search = false) {
	fetchGeoFences(`?pagecount=1000${param}`)
		.then((response) => {
			const geoFences = createGeoFenceDTO(response?.data?.data);

			if (search) {
				Store.dispatch({
					type: 'FEED_SEARCHED_GEO_FENCES',
					payload: geoFences,
				});
				setActiveGeoFence(geoFences ? geoFences[0] : null);
			} else {
				Store.dispatch({
					type: 'FEED_GEO_FENCES',
					payload: geoFences,
				});
				setActiveGeoFence(geoFences ? geoFences[0] : null);
			}
		})
		.catch((error) => {
			handleError('GeofencesMiddleware.tsx => loadGeoFences()', error);
		});
}

export function setActiveGeoFence(geoFence: GeoFenceDTOData | null) {
	Store.dispatch({
		type: 'SET_ACTIVE_GEO_FENCE',
		payload: geoFence,
	});
}

export function resetActiveGeoFence() {
	Store.dispatch({
		type: 'RESET_ACTIVE_GEO_FENCE',
	});
}

export interface addGeofence {
	name?: string;
	latitude: number;
	longitude: number;
	radius: number;
	organisation?: {
		id: number | number[];
	};
}

export function addGeoFenceMiddleware(data: addGeofence) {
	return axios.post(GEOFENCE_API, data, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
}

export function appendGeoFence(data: Geofence) {
	const geoFence = createSingleGeoFenceDTO(data);
	Store.dispatch({
		type: 'APPEND_GEO_FENCE',
		payload: geoFence,
	});
	setActiveGeoFence(geoFence);
}

export function updateGeoFenceMiddleWare(id: number, data: addGeofence) {
	return axios.patch(GEOFENCE_API + `/${id}`, data, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
}

export function amendGeoFence(geofence: Geofence) {
	return amendGeoFenceInStore(createSingleGeoFenceDTO(geofence));
}

export function amendGeoFenceInStore(geoFence: GeoFenceDTOData) {
	setActiveGeoFence(geoFence);
	Store.dispatch({
		type: 'UPDATE_GEO_FENCE',
		payload: geoFence,
	});
}

export function removeGeoFenceMiddleware(id: number) {
	return axios.delete(GEOFENCE_API + `/${id}`, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
}
