import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export const initializeDataDog = () => {
	datadogRum.init({
		applicationId: '8c4e9a12-490d-4f0c-b2cf-829cef9d478a',
		clientToken: 'pubc1ff3d2bc4be90e674492bf373acc9d0',
		site: 'datadoghq.eu',
		service: 'lokkateweb',
		env: process.env.REACT_APP_BUILD_ENVIRONMENT,
		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 100,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		// trackViewsManually: true,
		defaultPrivacyLevel: 'allow',
	});

	datadogRum.startSessionReplayRecording();
};
datadogLogs.init({
	clientToken: 'pubc1ff3d2bc4be90e674492bf373acc9d0',
	site: 'datadoghq.eu',
	env: process.env.REACT_APP_BUILD_ENVIRONMENT,
	service: 'lokkateweb',
	forwardErrorsToLogs: true,
	sessionSampleRate: 100,
});

const id: string | null = localStorage.getItem('userId');
const name: string | null = localStorage.getItem('name');
const phone: string | null = localStorage.getItem('phone');
const email: string | null = localStorage.getItem('email');

if (id && name && phone && email) {
	datadogRum.setUser({ id: id, name: name, phone: phone, email: email });
	datadogLogs.setUser({ id: id, name: name, phone: phone, email: email });

	datadogRum.getUser();
	datadogLogs.getUser();
}
