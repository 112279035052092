import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Popup, Segment, Table } from 'semantic-ui-react';
import { Circle, GoogleMap, InfoWindow, Marker, Polyline, TrafficLayer, useJsApiLoader } from '@react-google-maps/api';
import { getBounds } from 'geolib';

import PlaceHolder from '../../../../../../components/placeHolder/dynamic/PlaceHolder';
import TrackNerdLoader from '../../../../../../components/loader/Loader';
import FullScreenWrapper from '../../../../../../components/vehicles/fullScreenWrapper/FullScreenWrapper';
import CustomInfoWindow from '../../../../../../components/vehicles/info-window/CustomInfoWindow';
import {
	GOOGLE_MAP_DEFAULT_CENTER,
	GOOGLE_MAP_DEFAULT_STYLES,
	GOOGLE_MAP_DEFAULT_ZOOM,
	GOOGLE_MAP_LIBRARIES,
	GOOGLE_MAP_OPTIONS,
	GOOGLE_MAPS_API_KEY,
} from '../../../../../../constants/google';
import { createTripRouteDTO, TripRouteData } from '../../../../../../dtos/tripRoute';
import { createGeoFenceDTO, GeoFenceDTOData } from '../../../../../../dtos/geofence';
import StartMarkerImage from '../../../../../../assets/images/start-point-image.png';
import HybridImage from '../../../../../../assets/images/hybrid-image.png';
import RoadmapImage from '../../../../../../assets/images/roadmap-image.png';
import TrafficImage from '../../../../../../assets/images/traffic-image.png';
import { fetchGeoFences } from '../../../../geoFences/GeoFencesMiddleware';
import {
	ActiveMapTypes,
	Container,
	ControlsContainer,
	Header,
	MapContainer,
	MapTypeControls,
	MapTypesContainer,
	MapTypesLabels,
	RightContainer,
	ShouldAdjustMap,
	TableContainer,
} from './TripRouteStyles';
import { isoToHumanReadable } from '../../../../../../utils/calendar';
import { handleError } from '../../../../../../helper/view specific/messaging';
import {
	AddressLink,
	Coordinates,
	FormatTableCell,
	getVehicleIcon,
	setMapType,
	setTrafficView,
} from '../../../../../../utils/common';
import { getData } from '../../../../../../helper/services/axios';
import { GEOCODE_API, TRIP_HISTORY_API } from '../../../../../../constants/api';
import { THEME } from '../../../../../../constants/Theme';
import { RootState } from '../../../../../../redux/RootState';
import { EndIotData, StartIotData } from '../../../../../../dtos/trip';
import { getDistance } from '../../../../../../dtos/track';

interface TripsProps {
	closeTripRouteView: () => void;
	payload: {
		vehicleId: number;
		id: number;
		regNum: string;
		startAddress: string;
		startIotData: StartIotData;
		endIotData: EndIotData;
		startTime: string;
		endTime: string;
		distance: string;
		endAddress: string;
		engineHours: string;
		movementHours: string;
		idleHours: string;
		averageSpeed: string;
		maxSpeed: string;
	};
}

interface CoordinatesShort {
	lat: number;
	lng: number;
}

const scaledSize: google.maps.Size = {
	width: 42,
	height: 42,
	equals: function (): boolean {
		throw new Error('Function not implemented.');
	},
};
const anchor: google.maps.Point = {
	x: 16,
	y: 16,
	equals: function (): boolean {
		throw new Error('Function not implemented.');
	},
};

const infoWindowPixelOffset: google.maps.Size = {
	width: 0,
	height: -24,
	equals: function (): boolean {
		throw new Error('Function not implemented.');
	},
};

const TripRoute = (props: TripsProps) => {
	let bounds;
	const vehicleId = props.payload.vehicleId;

	const vehicleDetails = useSelector((state: RootState) => state.vehicle.vehicleDetails);
	const shownGeoFences = useSelector((state: RootState) => state.auth.shownGeoFences);
	const mapType = useSelector((state: RootState) => state.location.mapType);
	const trafficOn = useSelector((state: RootState) => state.location.traffic);

	const [path, setPath] = useState<TripRouteData[]>([]);
	const [boundsArray, setBoundsArray] = useState<Coordinates[]>([]);
	const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
	const [showInfoWindow, setShowInfoWindow] = useState<boolean>(false);
	const [infoWindowPosition, setInfoWindowPosition] = useState<CoordinatesShort | null>(null);
	const [infoWindowContent, setInfoWindowContent] = useState<TripRouteData | null>(null);
	const [shouldAdjustMap, setShouldAdjustMap] = useState<boolean>(false);
	const [googleAddress, setGoogleAddress] = useState<string>('Fetching...');
	const [loading, setLoading] = useState(true);
	const [geoFences, setGeoFences] = useState<GeoFenceDTOData[] | null>(null);

	useEffect(() => {
		fetchData();
		fetchGeoFence();
		document.addEventListener('keydown', escFunction, false);

		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, []);

	const fetchGeoFence = () => {
		fetchGeoFences('?pagecount=1000').then((response) => setGeoFences(createGeoFenceDTO(response?.data?.data)));
	};

	const fetchData = () => {
		getData(TRIP_HISTORY_API, `/${props.payload.id}`)
			.then((response) => {
				const path = createTripRouteDTO(response.data);
				if (path) {
					const boundsArray = path.map((location) => {
						return {
							latitude: location.lat,
							longitude: location.lng,
						};
					});

					setPath(path);
					setBoundsArray(boundsArray);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				handleError('TripRoute.tsx => fetchData()', error);
				setLoading(false);
			});
	};

	const setBounds = (map: google.maps.Map) => {
		bounds = getBounds(boundsArray);
		map.fitBounds({
			north: bounds.maxLat,
			south: bounds.minLat,
			east: bounds.maxLng,
			west: bounds.minLng,
		});
	};

	const setAddress = (lat: number, lng: number) => {
		getData(GEOCODE_API, `?latitude=${lat}&longitude=${lng}`)
			.then((response) => {
				if (response?.data?.address) {
					setGoogleAddress(response.data.address);
				}
			})
			.catch((error) => {
				handleError('TripRoute.tsx => setAddress()', error);
			});
	};

	const getClosestCoordinate = (lat: number, lng: number) => {
		let distance = null;
		let closestDistance = 99999999;
		let closestPointIndex: number | null = null;

		for (let i = 0; i < path.length - 1; i++) {
			distance = getDistance(path[i].lat, path[i].lng, lat, lng);

			if (distance < closestDistance) {
				closestDistance = distance;
				closestPointIndex = i;
			}
		}
		if (closestPointIndex !== null) {
			setInfoWindowPosition({
				lat: path[closestPointIndex].lat,
				lng: path[closestPointIndex].lng,
			});
			setInfoWindowContent(path[closestPointIndex]);
			setShowInfoWindow(true);

			setAddress(path[closestPointIndex].lat, path[closestPointIndex].lng);
		}
	};

	const escFunction = (event: KeyboardEvent) => {
		if (event.keyCode === 27) {
			props.closeTripRouteView();
		}
	};

	const setMapTypeLocal = (value: string) => {
		if (value !== mapType) {
			setMapType(value);
		}
	};

	const getPopupInfo = () => (
		<MapTypesContainer>
			<MapTypeControls activeType={trafficOn} onClick={() => setTrafficView(!trafficOn)}>
				<img src={TrafficImage} alt="Traffic" />
			</MapTypeControls>
			<MapTypesLabels activeType={trafficOn}>Traffic</MapTypesLabels>
		</MapTypesContainer>
	);

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: GOOGLE_MAPS_API_KEY,
		libraries: GOOGLE_MAP_LIBRARIES,
	});

	if (isLoaded) {
		const vehicleType = vehicleDetails.get(vehicleId)?.data?.type;
		return (
			<>
				<FullScreenWrapper
					onClose={props.closeTripRouteView}
					header={<Header>{props.payload.regNum}</Header>}
					content={
						<Container>
							{loading ? (
								<TrackNerdLoader />
							) : path ? (
								<>
									<MapContainer>
										<GoogleMap
											mapContainerStyle={GOOGLE_MAP_DEFAULT_STYLES}
											center={GOOGLE_MAP_DEFAULT_CENTER}
											zoom={GOOGLE_MAP_DEFAULT_ZOOM}
											mapTypeId={mapType}
											onDrag={() => {
												if (!shouldAdjustMap) {
													setShouldAdjustMap((prevState) => !prevState);
												}
											}}
											onZoomChanged={() => {
												if (!shouldAdjustMap) {
													setShouldAdjustMap((prevState) => !prevState);
												}
											}}
											options={{
												...GOOGLE_MAP_OPTIONS,
												mapTypeId: mapType,
											}}
											onLoad={(map) => {
												setBounds(map);
												setMapInstance(map);
											}}
											onUnmount={() => setMapInstance(null)}
											onClick={() => {
												setShowInfoWindow(false);
											}}>
											{trafficOn && <TrafficLayer />}

											<>
												{shouldAdjustMap && (
													<ShouldAdjustMap>
														<Button
															icon="crosshairs"
															onClick={() => {
																if (mapInstance) setBounds(mapInstance);
																setShouldAdjustMap(false);
															}}
														/>
													</ShouldAdjustMap>
												)}
												<Marker
													position={path[0]}
													zIndex={0}
													options={{
														icon: {
															url: StartMarkerImage,
															scaledSize: scaledSize,
															anchor: anchor,
														},
													}}
													onClick={() => {
														setInfoWindowPosition({
															lat: path[0].lat,
															lng: path[0].lng,
														});
														setInfoWindowContent(path[0]);
														setShowInfoWindow(true);
														setAddress(path[0].lat, path[0].lng);
													}}
												/>
												{path.length >= 2 && (
													<>
														<Polyline
															path={path}
															options={{
																strokeColor: THEME.COLORS.polylineTrack,
																strokeWeight: 5,
																icons: [
																	{
																		icon: {
																			path: 'M -2,2 0,-4 2,2 0,0 z',
																			strokeColor: THEME.COLORS.polylineMarkerStroke,
																			strokeWeight: 2,
																			fillColor: THEME.COLORS.polylineMarkerFill,
																			fillOpacity: 1,
																		},
																		offset: '5%',
																		repeat: '200px',
																	},
																],
															}}
															onClick={(e) => {
																if (e.latLng !== null) getClosestCoordinate(e.latLng.lat(), e.latLng.lng());
															}}
														/>
														<Marker
															position={path[path.length - 1]}
															zIndex={1}
															icon={{
																url: getVehicleIcon(vehicleType || ''),
																scaledSize: scaledSize,
																anchor: anchor,
															}}
															onClick={() => {
																setInfoWindowPosition({
																	lat: path[path.length - 1].lat,
																	lng: path[path.length - 1].lng,
																});
																setInfoWindowContent(path[path.length - 1]);
																setShowInfoWindow(true);
																setAddress(path[path.length - 1].lat, path[path.length - 1].lng);
															}}
														/>
													</>
												)}
												{showInfoWindow && infoWindowPosition && (
													<InfoWindow
														position={{
															lat: infoWindowPosition.lat,
															lng: infoWindowPosition.lng,
														}}
														options={{
															pixelOffset: infoWindowPixelOffset,
														}}>
														<CustomInfoWindow
															id={vehicleId}
															coordinates={{
																latitude: infoWindowPosition.lat,
																longitude: infoWindowPosition.lng,
															}}
															RegistrationNo={false}
															content={infoWindowContent}
															VehicleState={false}
															googleAddress={googleAddress}
															shareTrackingLink={false}
															immobilizer={false}
															recalculation={false}
															relayIconShow={false}
														/>
													</InfoWindow>
												)}
											</>

											{shownGeoFences &&
												geoFences &&
												geoFences.map((geofence, index) => {
													return (
														<Circle
															key={index}
															center={{
																lat: geofence.latitude,
																lng: geofence.longitude,
															}}
															radius={geofence.radius}
															options={{
																strokeColor: 'dodgerblue',
																strokeWeight: 2,
																fillColor: 'dodgerblue',
																fillOpacity: 0.1,
															}}
														/>
													);
												})}

											<ControlsContainer>
												<Popup
													content={getPopupInfo()}
													position="right center"
													offset={[0, 5]}
													basic
													on="hover"
													hoverable
													style={{
														width: '5em',
														height: '5em',
														padding: '0',
														paddingTop: '6px',
														borderRadius: '8px',
														zIndex: '99',
														border: '10px solid blue',
													}}
													trigger={
														<ActiveMapTypes
															activeType={mapType}
															onClick={() => setMapTypeLocal(`${mapType === 'roadmap' ? 'hybrid' : 'roadmap'}`)}>
															{mapType === 'roadmap' ? (
																<img src={HybridImage} alt="Hybrid" />
															) : (
																<img src={RoadmapImage} alt="RoadMap" />
															)}
														</ActiveMapTypes>
													}
												/>
											</ControlsContainer>
										</GoogleMap>
									</MapContainer>
									<RightContainer>
										{props.payload && (
											<TableContainer>
												<Table celled compact selectable striped unstackable>
													<Table.Header>
														<Table.Row>
															<Table.HeaderCell singleLine collapsing textAlign="left">
																Particulars
															</Table.HeaderCell>
															<Table.HeaderCell singleLine collapsing textAlign="left">
																Value
															</Table.HeaderCell>
														</Table.Row>
													</Table.Header>
													<Table.Body>
														<Table.Row>
															<Table.Cell singleLine textAlign="left">
																Vehicle
															</Table.Cell>
															<Table.Cell textAlign="left">{props.payload.regNum}</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell singleLine textAlign="left">
																Start
															</Table.Cell>
															<Table.Cell textAlign="left">
																{props.payload.startAddress?.length ? (
																	<>
																		<AddressLink
																			value={props.payload.startAddress}
																			coordinates={props.payload.startIotData}
																			infoWindow={false}
																		/>{' '}
																		at {props.payload.startTime ? isoToHumanReadable(props.payload.startTime) : ''}
																	</>
																) : (
																	<FormatTableCell
																		iotData={props.payload.startIotData}
																		text="View Start Address"
																		timeStamp={` at ${
																			props.payload.startTime ? isoToHumanReadable(props.payload.startTime) : ''
																		}`}
																	/>
																)}
															</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell singleLine textAlign="left">
																End
															</Table.Cell>
															<Table.Cell textAlign="left">
																{props.payload.endAddress?.length ? (
																	<>
																		<AddressLink
																			value={props.payload.endAddress}
																			coordinates={props.payload.endIotData}
																			infoWindow={false}
																		/>{' '}
																		at {props.payload.endTime ? isoToHumanReadable(props.payload.endTime) : ''}
																	</>
																) : (
																	<FormatTableCell
																		iotData={props.payload.endIotData}
																		text="View End Address"
																		timeStamp={` at ${
																			props.payload.endTime ? isoToHumanReadable(props.payload.endTime) : ''
																		}`}
																	/>
																)}
															</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell singleLine textAlign="left">
																Distance
															</Table.Cell>
															<Table.Cell textAlign="left">
																{props.payload.distance ? `${props.payload.distance} km` : ''}
															</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell singleLine textAlign="left">
																Avg Speed
															</Table.Cell>
															<Table.Cell textAlign="left">
																{props.payload.averageSpeed ? `${props.payload.averageSpeed} km/h` : ''}
															</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell singleLine textAlign="left">
																Top Speed
															</Table.Cell>
															<Table.Cell textAlign="left">
																{props.payload.maxSpeed ? `${props.payload.maxSpeed} km/h` : ''}
															</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell singleLine textAlign="left">
																Engine Hours
															</Table.Cell>
															<Table.Cell textAlign="left">{props.payload.engineHours}</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell singleLine textAlign="left">
																Movement Hours
															</Table.Cell>
															<Table.Cell textAlign="left">{props.payload.movementHours}</Table.Cell>
														</Table.Row>
														<Table.Row title="fddsf">
															<Table.Cell singleLine textAlign="left">
																Idle Hours
															</Table.Cell>
															<Table.Cell textAlign="left">{props.payload.idleHours}</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell singleLine textAlign="left">
																Start Odometer
															</Table.Cell>
															<Table.Cell textAlign="left">
																{props.payload.startIotData.totalOdometer
																	? props.payload.startIotData.totalOdometer
																	: 'N/A'}
															</Table.Cell>
														</Table.Row>
														<Table.Row>
															<Table.Cell singleLine textAlign="left">
																End Odometer
															</Table.Cell>
															<Table.Cell textAlign="left">
																{props.payload.endIotData.totalOdometer
																	? props.payload.endIotData.totalOdometer
																	: 'N/A'}
															</Table.Cell>
														</Table.Row>
													</Table.Body>
												</Table>
											</TableContainer>
										)}
									</RightContainer>
								</>
							) : (
								<Segment style={{ width: '100%', height: '100%' }}>
									<PlaceHolder />
								</Segment>
							)}
						</Container>
					}
				/>
			</>
		);
	} else return <TrackNerdLoader />;
};

export default TripRoute;
