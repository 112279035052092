import moment from 'moment';
import { Location } from '../../types/location';

export const updateVehicleStatus = (data: Location) => {
	const timestamp = data?.timestamp;
	const status = data?.status;
	if (status === 'Moving') {
		// Movement true
		if (moment().diff(moment(timestamp), 'minutes') <= 10) return status;
	}

	if (status === 'Idle') {
		// Ignition true and Movement false
		if (moment().diff(moment(timestamp), 'minutes') <= 10) return status;
	}

	// Ignition false
	if (status === 'Ignition off') if (moment().diff(moment(timestamp), 'minutes') <= 70) return status;

	if (status === 'GPS not fixed') {
		return status;
	}

	return 'No data since';
};
