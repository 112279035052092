export interface AuthInterface {
	loading: boolean;
	loggedIn: boolean;
	shownGeoFences: boolean;
	pushNotificationsEnabled: boolean;
	sendingOTP: boolean;
	otpSent: boolean;
	verifyingOTP: boolean;
	otpVerified: boolean;
}

export default function auth(
	initialState: AuthInterface = {
		loading: false,
		loggedIn: false,
		shownGeoFences: false,
		pushNotificationsEnabled: false,
		sendingOTP: false,
		otpSent: false,
		verifyingOTP: false,
		otpVerified: false,
	},
	action: { type: string; payload: object }
) {
	switch (action.type) {
		case 'LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'LOGIN':
			return {
				...initialState,
				loggedIn: true,
			};

		case 'LOGOUT':
			return {
				...initialState,
				loggedIn: false,
			};

		case 'AUTH_SEND_OTP_REQUEST':
			return {
				...initialState,
				sendingOTP: true,
			};

		case 'AUTH_SEND_OTP_RESOLVE':
			return {
				...initialState,
				sendingOTP: false,
				otpSent: true,
			};

		case 'AUTH_SEND_OTP_REJECT':
			return {
				...initialState,
				sendingOTP: false,
				otpSent: false,
			};

		case 'AUTH_VERIFY_OTP_REQUEST':
			return {
				...initialState,
				verifyingOTP: true,
			};

		case 'AUTH_VERIFY_OTP_RESOLVE':
			return {
				...initialState,
				verifyingOTP: false,
				otpVerified: true,
			};

		case 'AUTH_VERIFY_OTP_REJECT':
			return {
				...initialState,
				verifyingOTP: false,
				otpVerified: false,
			};

		case 'ENABLE_SHOWN_GEOFENCES':
			return {
				...initialState,
				shownGeoFences: true,
			};

		case 'DISABLE_SHOWN_GEOFENCES':
			return {
				...initialState,
				shownGeoFences: false,
			};

		case 'ENABLE_PUSH_NOTIFICATIONS':
			return {
				...initialState,
				pushNotificationsEnabled: true,
			};

		case 'DISABLE_PUSH_NOTIFICATIONS':
			return {
				...initialState,
				pushNotificationsEnabled: false,
			};

		case 'AUTH_RESET':
			return {
				...initialState,
				loading: false,
				sendingOTP: false,
				otpSent: false,
				verifyingOTP: false,
				otpVerified: false,
			};

		default:
			return initialState;
	}
}
