import { convertSecondsToDuration, isoToCustom } from '../utils/calendar';
import moment from 'moment';

interface SummaryFromBackEnd {
	distance: number;
	averageSpeed: number;
	topSpeed: number;
	engineHours: number;
	movementHours: number;
	idleHours: number;
	startOdometer: number;
	odometerDifference: number;
	endOdometer: number;
	tripCount: number;
	overSpeedingCount: number;
	id: number;
	date: string;
	interval: string;
	firstIgnitionOn: string;
	firstIgnitionAddress: string;
	lastIgnitionOff: string;
	lastIgnitionAddress: string;
	firstGeofenceEvent: null;
	lastGeofenceEvent: null;
	driver: null;
	vehicleGroups: string;
	version: number;
	createdDate: string;
	modifiedDate: string;
	vehicle: {
		createdDate: string;
		modifiedDate: string;
		deletedDate: null;
		id: number;
		registrationNumber: string;
		type: string;
		make: string;
		model: null;
		chassisNumber: null;
		sensors: null;
		odometerReading: number;
		installationDate: string;
		trialEndDate: string;
		note: null;
		status: string;
		speedLimit: number;
		idlingDuration: number;
		parkingDuration: number;
		isFMSVehicle: false;
	};
}

export interface SummaryDTO {
	id?: number;
	date: string;
	distance: number | string;
	engineHours?: string | number;
	movementHours: string | number;
	idleHours: string | number;
	engineHoursView?: string;
	engineOperationTime?: string;
	movementHoursView?: string;
	idleHoursView?: string;
	averageSpeed: number | string;
	maxSpeed?: string | null;
	// topSpeed?: number | null;
	firstIgnitionOn?: string;
	firstIgnitionAddress?: string;
	lastIgnitionOff?: string;
	lastIgnitionAddress?: string;
	startOdometer?: number | string;
	odometerDifference?: number | string;
	endOdometer?: number | string;
	overSpeedingCount?: number;
	firstGeofenceEvent?: string;
	lastGeofenceEvent?: string;
	tripCount: number;
	overSpeedCount?: number;
	driver?: string;
	vehicleGroups?: string;
	vehicle: {
		registrationNumber: string;
	};
}

export function createSummaryDTO(data: SummaryFromBackEnd[], sort: string) {
	let summary: SummaryDTO[] = [];
	let summaryArray: SummaryDTO[][] = [];

	if (data && data.length) {
		summary = data.map((day) => {
			return {
				id: day?.id,
				date: isoToCustom(day.date, 'DD-MM-YYYY'),
				distance: day.distance !== 0 ? day.distance / 1000 : '0.0',
				engineHours: day.engineHours !== 0 ? day.engineHours : '00.00.00',
				movementHours: day.movementHours !== 0 ? day.movementHours : '00.00.00',
				idleHours: day.idleHours !== 0 ? day.idleHours : '00.00.00',
				engineHoursView: day.engineHours !== undefined || 0 ? convertSecondsToDuration(day.engineHours) : '00.00.00',
				movementHoursView:
					day.movementHours !== undefined || 0 ? convertSecondsToDuration(day.movementHours) : '00.00.00',
				idleHoursView: day.idleHours !== undefined || 0 ? convertSecondsToDuration(day.idleHours) : '00.00.00',
				averageSpeed: day?.averageSpeed !== undefined ? day.averageSpeed.toFixed(1) : '-',
				maxSpeed: day?.topSpeed !== undefined ? day.topSpeed.toFixed(1) : '-',
				firstIgnitionOn: day?.firstIgnitionOn !== undefined ? day.firstIgnitionOn : '-',
				firstIgnitionAddress: day?.firstIgnitionAddress ? day.firstIgnitionAddress : '-',
				lastIgnitionOff: day?.lastIgnitionOff !== undefined ? day.lastIgnitionOff : '-',
				lastIgnitionAddress: day?.lastIgnitionAddress ? day.lastIgnitionAddress : '-',
				startOdometer: day?.startOdometer ? Number((day.startOdometer / 1000).toFixed(1)).toLocaleString() : 'N/A',
				odometerDifference: day?.odometerDifference
					? Number((day.odometerDifference / 1000).toFixed(1))?.toLocaleString()
					: 0,
				endOdometer: day?.endOdometer ? Number((day.endOdometer / 1000).toFixed(1))?.toLocaleString() : 'N/A',
				overSpeedingCount: day?.overSpeedingCount !== undefined ? day.overSpeedingCount : 0,
				firstGeofenceEvent: day?.firstGeofenceEvent ? day.firstGeofenceEvent : '-',
				lastGeofenceEvent: day?.lastGeofenceEvent ? day.lastGeofenceEvent : '-',
				tripCount: day?.tripCount,
				driver: day?.driver ? day.driver : '-',
				vehicleGroups: day?.vehicleGroups ? day.vehicleGroups : '-',
				vehicle: {
					registrationNumber: day?.vehicle?.registrationNumber || '',
				},
			};
		});
	}

	if (sort === 'date') {
		summaryArray = summary.reduce((result: SummaryDTO[][], item) => {
			const index = result.findIndex((summary) => summary[0].date === item.date);

			if (index !== -1) {
				result[index].push(item);
			} else {
				result.push([item]);
			}
			return result;
		}, []);
	} else if (sort === 'vehicle') {
		summaryArray = summary.reduce((result: SummaryDTO[][], item) => {
			const index = result.findIndex(
				(summary) => summary[0].vehicle.registrationNumber === item.vehicle.registrationNumber
			);

			if (index !== -1) {
				result[index].push(item);
			} else {
				result.push([item]);
			}
			return result;
		}, []);
	} else {
		summaryArray = summary.reduce((result: SummaryDTO[][], item) => {
			const index = result.findIndex((summary) => summary[0] !== item);
			if (index !== -1) {
				result[index].push(item);
			} else {
				result.push([item]);
			}
			return result;
		}, []);
	}
	summaryArray.forEach((group) => {
		const obj = {
			date: '',
			firstIgnitionOn: '',
			firstIgnitionAddress: '',
			lastIgnitionOff: '',
			lastIgnitionAddress: '',
			distance: 0,
			averageSpeed: 0,
			maxSpeed: '',
			tripCount: 0,
			overSpeedCount: 0,
			movementHours: '0h 0m',
			engineOperationTime: '',
			idleHours: '',
			vehicle: {
				registrationNumber: '',
			},
		};
		const date = group.map((date) => date.date);
		const reg = group.map((reg) => reg.vehicle.registrationNumber);
		const totalDistance = group.reduce(
			(sum, item) => sum + (typeof item.distance === 'string' ? parseFloat(item.distance) : item.distance),
			0
		);
		const topSpeed = group.reduce((maxSpeed, item) => {
			const speed = item.maxSpeed ? parseFloat(item.maxSpeed) : 0;
			return Math.max(maxSpeed, speed);
		}, 0);
		const tripCount = group.reduce((sum, item) => sum + (item.tripCount || 0), 0);
		const overSpeedCount = group.reduce((sum, item) => sum + (item.overSpeedingCount || 0), 0);

		//Total Movement Duration Hours Calculation
		const momentDuration = group.reduce(
			(total, time) => total.add(moment.duration(time.movementHours, 'seconds')),
			moment.duration(0)
		);

		const totalMovementHours = momentDuration.asHours();
		const movementTime = convertSecondsToDuration(momentDuration.asSeconds());

		const overallAvg = totalDistance / totalMovementHours;

		//Total Engine Duration Hours Calculation
		const engineDuration = group.reduce(
			(total, time) => total.add(moment.duration(time.engineHours, 'seconds')),
			moment.duration(0)
		);

		const engineOperationTime = convertSecondsToDuration(engineDuration.asSeconds());

		//Idle Duration Hours Calculation
		const TotalIdleTime = Number(engineDuration.asSeconds()) - Number(momentDuration.asSeconds());
		const idlingDurationTime = convertSecondsToDuration(Number(TotalIdleTime));

		obj.date = date[0];
		obj.vehicle.registrationNumber = reg[0];
		obj.distance = Number(totalDistance.toFixed(3));
		obj.averageSpeed = Number(overallAvg.toFixed(1));
		obj.maxSpeed = topSpeed.toFixed(1);
		obj.tripCount = tripCount;
		obj.overSpeedCount = overSpeedCount;
		obj.movementHours = movementTime;
		obj.engineOperationTime = engineOperationTime;
		obj.idleHours = idlingDurationTime;

		group.push(obj);
	});

	return summaryArray;
}
