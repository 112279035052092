//React imports
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'; //Styles imports
import { MarkerInfo, MarkerInfoRegNo, MarkerInfoRow } from '../../components/STYLED/InfoWindow';
import { InfoCardStatus, LastUpdate } from '../../components/vehicles/info-window/CustomInfoWindowStyles';
import { Container, Header, Logo, MapContainer } from './TrackerStyles';
import LogoImage from '../../assets/images/tracknerd-logo.png';
import TrackNerdLoader from '../../components/loader/Loader';
import { THEME } from '../../constants/Theme';
import InfoPage from './info/InfoPage'; //Common function imports
import { updateVehicleStatus } from '../../helper/view specific/vehicleStatus';
import { handleError } from '../../helper/view specific/messaging';
import { isoToHumanReadable } from '../../utils/calendar';
import { eraseCookie, getCookie, getVehicleIcon, isCookieExpired, setCookie } from '../../utils/common'; //Api imports
import { LIVE_TRACKING_LINK_API } from '../../constants/api';
import { database } from '../../helper/services/firebase';
import { onValue, ref } from 'firebase/database';
import { isEmpty } from 'lodash';
import axios from 'axios'; //Mixpanel imports
import { trackMixpanelEvent } from '../../helper/services/mixpanel'; //Datadogs imports
import ReactMapGL, {
	FullscreenControl,
	GeolocateControl,
	Layer,
	MapRef,
	Marker,
	MarkerProps,
	NavigationControl,
	Popup,
	ScaleControl,
	Source,
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css'; //Geolib imports to setbound
import { getBounds } from 'geolib';
import { ShouldAdjustMapMapbox } from '../dashboard/vehicles/main/location/LocationStyles';
import { Button } from 'semantic-ui-react';
import { isValid } from '../../helper/services/common';
import { Location } from '../../types/location';
import { VehicleDetailsForShareTrackingLink } from '../../types/vehicle';
import { MarkerEvent } from 'react-map-gl/dist/esm/types';
import { Helmet } from 'react-helmet';

interface PropType {
	url: string;
}

interface LocationStatus {
	[vehicleId: string]: Location;
}

interface ActiveMarkerProps {
	vehicleId: number;
	vehicle: VehicleDetailsForShareTrackingLink;
}

const baseUrl = process.env.REACT_APP_TRACKING_CANONICAL_URL;
const canonicalUrl = `${baseUrl}/live/link`;

const Tracker = (props: PropType) => {
	const [loading, setLoading] = useState(true);
	const [hasLinkExpired, setHasLinkExpired] = useState<boolean | null>(null);
	const [wrongURl, setWrongURL] = useState<boolean>(false);
	const [noVehicles, setNoVehicles] = useState<boolean | null>(null);
	const [locationStatus, setLocationStatus] = useState<LocationStatus>({});
	const [polyLines, setPolyLines] = useState<GlobalPolyline>({});
	const [activeMarker, setActiveMarker] = useState<ActiveMarkerProps | null>(null);
	const markerClicked: MutableRefObject<{
		isActive: boolean;
		vehicleId: number | null;
	}> = useRef({
		isActive: false,
		vehicleId: null,
	});

	const mapRef = useRef<MapRef | null>(null);

	let globalPolyline: GlobalPolyline = {};

	// Will run whenever this component will mount
	useEffect(() => {
		getData();
	}, []);

	// // Will run whenever this props.url changes
	// useEffect(() => {
	// 	checkLinkExpire();
	// }, [props.url]);

	const getData = async () => {
		try {
			const checkCookies = getCookie(isValid(props.url));
			const vehicleDataCookie = checkCookies && JSON.parse(checkCookies);

			if (checkCookies && vehicleDataCookie) {
				// Check if the cookie has expired before proceeding
				if (vehicleDataCookie && isCookieExpired(vehicleDataCookie)) {
					setHasLinkExpired(true);
					setLoading(false);
					eraseCookie(isValid(props.url));
					return; // Exit early since the cookie has expired
				}

				if (vehicleDataCookie) {
					subscribeToFirebase(vehicleDataCookie?.vehicle);
					setLoading(false);
					setHasLinkExpired(false);
				}
			} else if (!vehicleDataCookie) {
				try {
					const response = await getUrlInfo(isValid(props.url));

					if (isEmpty(response?.data)) {
						setLoading(false);
						setNoVehicles(true);
					} else {
						setHasLinkExpired(false);
						if (response?.data) {
							for (const data of response.data) {
								const cookieName = isValid(props.url);
								const cookieValue = JSON.stringify(data);
								const expirationDate = data.expiryDate;
								setCookie(cookieName, cookieValue, expirationDate);
								subscribeToFirebase(data.vehicle);
							}
						}
					}
				} catch (error) {
					setLoading(false);
					// if (error?.response && error?.response?.data) setHasLinkExpired(error?.response?.data?.statusCode !== 406);
					eraseCookie(isValid(props.url));
				}
			}
			trackMixpanelEvent('Share_Tracking_Link_Open');
		} catch (error) {
			// console.log('error', error);
			// datadogLogs.logger.error(`${activeMarker?.vehicle?.registrationNumber} Tracker Error`, {}, error);
		}
	};

	// Function to check URL is valid or not

	// Function to check URL information
	const getUrlInfo = (urlHash: string) => {
		return axios.get(LIVE_TRACKING_LINK_API + `/${urlHash}`).catch((error) => {
			const statusCode = error?.response?.data?.statusCode;
			if (statusCode == 410) {
				setHasLinkExpired(true);
			} else if (statusCode == 404 || statusCode == 406) {
				setWrongURL(true);
			} else {
				setHasLinkExpired(false);
				setWrongURL(false);
			}
			handleError('Tracker.tsx => getUrlInfo(): ', error);
		});
	};

	// // Function to check whether the URL is correct or not
	// const checkLinkExpire = () => {
	// 	const vehicleDataCookie = getCookie(isValid(props.url));
	// 	console.log('vehicleDataCookie', vehicleDataCookie);
	// 	console.log('props.url', props.url);
	// 	if (vehicleDataCookie == null) {
	// 		setWrongURL((prev) => !prev);
	// 	} else {
	// 		setWrongURL(false);
	// 	}
	// };

	interface Coordinates {
		lat: number;
		lng: number;
	}

	interface GlobalPolyline {
		[vehicleId: number]: {
			polylineCoordinates: Coordinates[];
			image: string; // Assuming 'image' is a string, adjust the type accordingly
			vehicle?: VehicleDetailsForShareTrackingLink;
			// Other properties of the polyline specific to a vehicle
		};
	}

	// Function to subscribe to firebase and get real time data
	const subscribeToFirebase = (vehicle: VehicleDetailsForShareTrackingLink) => {
		const reference = ref(database, `/${vehicle.id}-${vehicle.registrationNumber}/location`);
		onValue(reference, (snapshot) => {
			if (snapshot.val()) {
				if (globalPolyline?.[vehicle.id]) {
					globalPolyline = {
						...globalPolyline,
						[vehicle.id]: {
							...globalPolyline[vehicle.id],
							polylineCoordinates: [
								...globalPolyline[vehicle.id].polylineCoordinates,
								{
									lat: snapshot.val().latitude,
									lng: snapshot.val().longitude,
								},
							],
							image: getVehicleIcon(snapshot.val().vehicleType),
							vehicle: vehicle,
						},
					};
				} else {
					globalPolyline = {
						...globalPolyline,
						[vehicle.id]: {
							polylineCoordinates: [
								{
									lat: snapshot.val().latitude,
									lng: snapshot.val().longitude,
								},
							],
							image: getVehicleIcon(snapshot.val().vehicleType),
						},
					};
				}
			}

			const localLocationStatus = locationStatus;

			const locationObj: Location = {
				status: snapshot.val()?.statusV2,
				timestamp: snapshot.val()?.timestamp,
				vehicleType: snapshot.val()?.vehicleType,
				latitude: snapshot.val()?.latitude,
				longitude: snapshot.val()?.longitude,
				speed: snapshot.val()?.speed,
				externalPowerVoltage: snapshot.val()?.externalPowerVoltage,
				totalOdometer: snapshot.val()?.totalOdometer,
				gnssStatus: snapshot.val()?.gnssStatus,
				soc: snapshot.val()?.soc,
				dte: snapshot.val()?.dte,
				vehicle: vehicle,
			};

			locationObj.status = updateVehicleStatus(locationObj);
			localLocationStatus[vehicle.id] = locationObj;

			setTimeout(() => {
				setLocationStatus(localLocationStatus);
				if (polyLines) setPolyLines(globalPolyline);
				setLoading(false);
			}, 1);

			setBounds(globalPolyline);
		});
	};

	// Function to set the view when more than 1 vehicle is selected
	const setBounds = (globalPolyline: GlobalPolyline) => {
		if (!markerClicked.current || !markerClicked.current?.isActive) {
			let bounndArray: Coordinates[] = [];
			Object.values(globalPolyline).map((item) => {
				bounndArray = bounndArray.concat(item.polylineCoordinates);
			});
			const bounds = getBounds(bounndArray);
			mapRef?.current?.fitBounds(
				[
					[bounds.minLng, bounds.minLat],
					[bounds.maxLng, bounds.maxLat],
				],
				{
					padding: 40,
					duration: 1000,
				}
			);

			if (markerClicked.current) {
				markerClicked.current = {
					isActive: true,
					vehicleId: null,
				}; // Set isActive to true
			}
		}
	};

	const [viewState, setViewState] = useState({
		longitude: 78.9629,
		latitude: 20.5937,
		zoom: 3.5,
		maxZoom: 15,
	});

	// Function to subscribe to firebase and get real time data
	const getInfoData = (vehicleId: number) => {
		const vehicleDetails = locationStatus[vehicleId].vehicle;
		if (vehicleDetails !== undefined) {
			// Onclick of marker we will get vehicle id and will set in the below state
			setActiveMarker({ vehicle: vehicleDetails, vehicleId });
		}

		// Set markerClicked to true
		markerClicked.current = {
			isActive: true,
			vehicleId,
		};

		//Onclick of marker we will set the view of the map to updated lat,lng
		const polylineCoordinates = polyLines[vehicleId]?.polylineCoordinates;
		const viewdata = polylineCoordinates[polylineCoordinates.length - 1];

		mapRef.current?.flyTo({ center: [viewdata.lng, viewdata.lat], zoom: 18, duration: 2000 });
	};

	useEffect(() => {
		let lat;
		let lng;

		// const vehicleCount = Object.keys(locationStatus);

		if (!markerClicked.current.isActive) {
			Object.keys(polyLines).map((id) => {
				const { polylineCoordinates } = polyLines[parseInt(id)];
				lat = polylineCoordinates[polylineCoordinates.length - 1].lat;
				lng = polylineCoordinates[polylineCoordinates.length - 1].lng;
			});
			mapRef.current?.flyTo({ center: [lng, lat], zoom: 18, duration: 2000 });
		} else {
			const vehicleId = markerClicked.current.vehicleId;
			if (vehicleId) {
				const { polylineCoordinates } = polyLines[vehicleId];
				lat = polylineCoordinates[polylineCoordinates.length - 1].lat;
				lng = polylineCoordinates[polylineCoordinates.length - 1].lng;
				mapRef.current?.flyTo({ center: [lng, lat], zoom: 18, duration: 2000 });
			}
		}
	}, [polyLines]);

	function rencenterMarker() {
		let lat;
		let lng;

		Object.keys(polyLines).map((id) => {
			const { polylineCoordinates } = polyLines[parseInt(id)];
			lat = polylineCoordinates[polylineCoordinates.length - 1].lat;
			lng = polylineCoordinates[polylineCoordinates.length - 1].lng;
		});
		mapRef.current?.flyTo({ center: [lng, lat], zoom: 18, duration: 2000 });
	}

	// async function getcurrentAddress(lat, lng) {
	// 	let accessToken = process.env.REACT_APP_MAPBOX_API_KEY;
	// 	try {
	// 		const response = await axios.get(
	// 			`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${accessToken}`
	// 		);
	//
	// 		if (response.data.features.length > 0) {
	// 			const address = response.data.features[0].place_name;
	// 			// return address;
	// 			setlastAddress(address);
	// 		} else {
	// 			return 'Address not found';
	// 		}
	// 	} catch (error) {
	// 		return 'Error fetching address';
	// 	}
	// }

	// To get start address
	// const getReverseGeocode = async (longitude, latitude) => {
	// 	let accessToken = process.env.REACT_APP_MAPBOX_API_KEY;
	// 	try {
	// 		const response = await axios.get(
	// 			`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}`
	// 		);
	//
	// 		if (response.data.features.length > 0) {
	// 			const address = response.data.features[0].place_name;
	// 			return address;
	// 		} else {
	// 			return 'Address not found';
	// 		}
	// 	} catch (error) {
	// 		return 'Error fetching address';
	// 	}
	// };

	// const getstartaddress = async (vehicleId) => {
	// 	const longitude = polyLines[vehicleId].polylineCoordinates[0].lng;
	// 	const latitude = polyLines[vehicleId].polylineCoordinates[0].lat;
	//
	// 	const address = await getReverseGeocode(longitude, latitude);
	//
	// 	const data = {
	// 		address,
	// 		longitude,
	// 		latitude,
	// 	};
	// 	setstartaddress(data);
	// };

	return (
		<>
			<Helmet>
				<link rel="canonical" href={canonicalUrl} />
			</Helmet>
			<Container>
				<Header>
					<Logo>
						<img src={LogoImage} alt="TrackNerdGPS" />
					</Logo>
				</Header>
				<MapContainer>
					{loading ? (
						<TrackNerdLoader />
					) : hasLinkExpired ? (
						<InfoPage hasLinkExpired={hasLinkExpired} noVehicles={noVehicles} />
					) : wrongURl ? (
						<InfoPage hasWrongURL={wrongURl} noVehicles={noVehicles} />
					) : (
						<ReactMapGL
							ref={mapRef}
							{...viewState}
							onMove={(evt) => {
								if (evt?.viewState) {
									const view = {
										latitude: evt.viewState.latitude,
										longitude: evt.viewState.longitude,
										zoom: evt.viewState.zoom,
										maxZoom: 15,
									};
									setViewState(view);
								}
							}}
							// pitch={60}
							// bearing={-60}
							// initialViewState={viewState}
							mapStyle="mapbox://styles/mapbox/streets-v12"
							mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}>
							<GeolocateControl />
							<FullscreenControl />
							<NavigationControl />
							<ScaleControl />
							{/*Recenter button*/}
							<ShouldAdjustMapMapbox title={'Re-center marker'}>
								<Button icon="crosshairs" onClick={() => rencenterMarker()} />
							</ShouldAdjustMapMapbox>

							{/* Markers for each vehicle */}
							{Object.keys(locationStatus).length &&
								Object.keys(polyLines).map((vehicle) => {
									const vehicleId = parseInt(vehicle);
									const { image, polylineCoordinates } = polyLines[vehicleId];
									return (
										<Marker
											// onDrag={(evt) => {
											// 	console.log(evt.viewState);
											// 	// setViewState(evt.viewState);
											// }}
											// // onMove={(evt) => {
											// // 	console.log(evt.viewState);
											// // 	setViewState(evt.viewState);
											// // }}
											key={vehicleId}
											longitude={polylineCoordinates[polylineCoordinates.length - 1].lng}
											latitude={polylineCoordinates[polylineCoordinates.length - 1].lat}
											onClick={(e: MarkerEvent<MarkerProps, MouseEvent>) => {
												e.originalEvent.stopPropagation();
												getInfoData(vehicleId);
											}}>
											<div className="custom-marker" style={{ cursor: 'pointer' }}>
												<img src={image} alt={`Vehicle ${vehicleId}`} width={30} height={30} />
											</div>
										</Marker>
									);
								})}

							{/*/To show a marker at the starting point from where user started to track the vehilce /*/}
							{/*{Object.keys(polyLines).map((vehicleId) => {*/}
							{/*	const { polylineCoordinates } = polyLines[vehicleId];*/}

							{/*	if (polylineCoordinates.length > 1 && showstartmarker) {*/}
							{/*		return (*/}
							{/*			<div key={vehicleId}>*/}
							{/*				<Marker*/}
							{/*					longitude={polylineCoordinates[0].lng}*/}
							{/*					latitude={polylineCoordinates[0].lat}*/}
							{/*					onClick={(e) => {*/}
							{/*						e.originalEvent.stopPropagation();*/}
							{/*						getstartaddress(vehicleId);*/}
							{/*					}}>*/}
							{/*					<div className="custom-marker" style={{ cursor: 'pointer' }}>*/}
							{/*						<img*/}
							{/*							width="30"*/}
							{/*							height="30"*/}
							{/*							src="https://img.icons8.com/3d-fluency/94/map-pin.png"*/}
							{/*							alt="map-pin"*/}
							{/*						/>*/}
							{/*					</div>*/}
							{/*				</Marker>*/}
							{/*			</div>*/}
							{/*		);*/}
							{/*	} else {*/}
							{/*		return null;*/}
							{/*	}*/}
							{/*})}*/}

							{/* Render polylines */}
							{Object.keys(polyLines).map((vehicle, index) => {
								const vehicleId = parseInt(vehicle);
								const { polylineCoordinates } = polyLines[vehicleId];

								// Create a GeoJSON object from the polylineCoordinates
								const geojson = {
									type: 'Feature',
									geometry: {
										type: 'LineString',
										coordinates: polylineCoordinates.map((point) => [point.lng, point.lat]),
									},
								};

								return (
									<Source key={vehicleId} id={`polyline-source-${vehicleId}`} type="geojson" data={geojson}>
										{/* Add arrow at the start point */}
										<Layer
											id={`polyline-layer-${vehicleId}`}
											type="line"
											layout={{
												'line-join': 'round',
												'line-cap': 'round',
											}}
											paint={{
												'line-color': THEME.COLORS.multiplePolyLinesTrack[index % 7], // Set the line color
												'line-width': 4, // Set the line width
											}}
										/>
									</Source>
								);
							})}

							{/*To render infowindow on click on any marker*/}
							{locationStatus && activeMarker && (
								<Popup
									style={{ maxWidth: '22em', minWidth: '18em' }}
									longitude={Number(locationStatus[activeMarker.vehicleId]?.longitude)}
									latitude={Number(locationStatus[activeMarker.vehicleId]?.latitude)}
									onClose={() => {
										setActiveMarker(null);
										markerClicked.current.isActive = false;
									}}>
									<MarkerInfo>
										<MarkerInfoRegNo>{activeMarker.vehicle.registrationNumber}</MarkerInfoRegNo>

										<InfoCardStatus
											paddingBottom="0.5em"
											color={
												locationStatus[activeMarker.vehicleId]?.status === 'GPS not fixed'
													? THEME.COLORS.vehicleNoDataHalfHour
													: locationStatus[activeMarker.vehicleId]?.status === 'No data since'
													  ? THEME.COLORS.vehicleNoDataHalfHour
													  : locationStatus[activeMarker.vehicleId]?.status === 'Moving'
													    ? THEME.COLORS.vehicleMoving
													    : locationStatus[activeMarker.vehicleId]?.status === 'Idle'
													      ? THEME.COLORS.vehicleIdle
													      : locationStatus[activeMarker.vehicleId]?.status === 'Ignition off'
													        ? THEME.COLORS.vehicleIgnitionOff
													        : THEME.COLORS.vehicleNoDataHalfHour
											}>
											{locationStatus[activeMarker.vehicleId]?.status !== 'No data since' &&
												locationStatus[activeMarker.vehicleId]?.status}
										</InfoCardStatus>

										{/*<Address>*/}
										{/*	{getcurrentAddress(*/}
										{/*		Number(locationStatus[activeMarker.vehicleId]?.longitude),*/}
										{/*		Number(locationStatus[activeMarker.vehicleId]?.latitude)*/}
										{/*	)}*/}
										{/*</Address>*/}

										{locationStatus[activeMarker.vehicleId]?.totalOdometer !== undefined && (
											<MarkerInfoRow>
												<span>
													Odometer{' '}
													{locationStatus[activeMarker.vehicleId]?.totalOdometer === undefined
														? 0
														: (Number(locationStatus[activeMarker.vehicleId]?.totalOdometer) / 1000).toFixed(2)}{' '}
													km
												</span>
											</MarkerInfoRow>
										)}

										<MarkerInfoRow>
											<span>
												Speed{' '}
												{locationStatus[activeMarker.vehicleId]?.speed === undefined
													? 0.0
													: Number(locationStatus[activeMarker.vehicleId]?.speed).toFixed(2)}{' '}
												KM/h
											</span>
										</MarkerInfoRow>

										{locationStatus[activeMarker.vehicleId]?.externalPowerVoltage !== undefined && (
											<MarkerInfoRow>
												<span>
													Battery{' '}
													{locationStatus[activeMarker.vehicleId]?.externalPowerVoltage === undefined
														? 0
														: locationStatus[activeMarker.vehicleId]?.externalPowerVoltage / 1000}{' '}
													Volts
												</span>
											</MarkerInfoRow>
										)}
										{locationStatus[activeMarker.vehicleId]?.status === 'No data since' ? (
											<LastUpdate style={{ color: THEME.COLORS.vehicleNoDataHalfHour }}>
												Last data {isoToHumanReadable(locationStatus[activeMarker.vehicleId]?.timestamp)}
											</LastUpdate>
										) : (
											<LastUpdate>{isoToHumanReadable(locationStatus[activeMarker.vehicleId]?.timestamp)}</LastUpdate>
										)}
									</MarkerInfo>
								</Popup>
							)}

							{/*To show the infowindow containing address from where user started to track the vehicle*/}
							{/*{startaddress && (*/}
							{/*	<Popup*/}
							{/*		anchor="top"*/}
							{/*		longitude={startaddress.longitude}*/}
							{/*		latitude={startaddress.latitude}*/}
							{/*		onClose={() => setstartaddress(null)}>*/}
							{/*		<MarkerInfo>*/}
							{/*			<MarkerInfoRow>*/}
							{/*				<span>{startaddress.address}</span>*/}
							{/*			</MarkerInfoRow>*/}
							{/*		</MarkerInfo>*/}
							{/*	</Popup>*/}
							{/*)}*/}
						</ReactMapGL>
					)}
				</MapContainer>
			</Container>
		</>
	);
};

export default Tracker;
