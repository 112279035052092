/*

import React, { useState, useEffect, useRef } from 'react';

// Configs
import {
  addGeoFenceMiddleware,
  amendGeoFence,
  appendGeoFence,
  removeGeoFenceMiddleware,
  loadGeoFences,
  updateGeoFenceMiddleWare,
} from '../GeoFencesMiddleware';

import { connect } from 'react-redux';
import { Block, Controls, ControlsContainer, HorizontalBlock, MainContainer, SearchBoxContainer } from './MainStyles';
import { Button, Dropdown, Icon, Input, Modal } from 'semantic-views-react';
import {
  Circle,
  DrawingManager,
  GoogleMap,
  StandaloneSearchBox,
  TrafficLayer,
  useJsApiLoader,
} from '@react-google-maps/api';
import { getBounds, getBoundsOfDistance } from 'geolib';
import { handleError, handleSuccess } from '../../../../../helper/view specific/messaging';
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAP_LIBRARIES,
  GOOGLE_MAP_DEFAULT_STYLES,
  GOOGLE_MAP_DEFAULT_CENTER,
  GOOGLE_MAP_DEFAULT_ZOOM,
  GOOGLE_MAP_OPTIONS,
} from '../../../../../constants/constants';
import { fetchOrganisations } from '../../../../../utils/common';
import TrackNerdLoader from '../../../common/loader/Loader';
import Store from "../../../../../redux/store";
import {datadogLogs} from "@datadog/browser-logs";


const Main = (props) => {

  const circleOptions = {
    strokeColor: 'dodgerblue',
    strokeWeight: 2,
    fillColor: 'dodgerblue',
    fillOpacity: 0.1,
  };
  const editableCircleOptions = {
    ...circleOptions,
    editable: true,
    draggable: true,
  };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAP_LIBRARIES,
  });

  let name = '';
  let searchBoxInstance = useRef(null);
  let searchInputRef = React.createRef();
  let geoFenceCircleInstance = useRef(null);
  let circleInstance = useRef(null);
  let drawableInstance = useRef(null);

  let mapInstance = useRef(null);

  const [geoName, setGeoName] = useState('');
  const [canvasMode, setCanvasMode] = useState(false);
  const [circleDrawn, setCircleDrawn] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [adding, setAdding] = useState(false);
  const [organisation, setOrganisation] = useState('');
  const [deleting, setDeleting] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const PermissionState = Store.getState().rolePermissions.permissions;

  useEffect(() => {
    try {
      if (searchInputRef.current && searchInputRef.current?.inputRef?.current) {
        searchInputRef.current.inputRef.current.value = '';
      }
      if (props.activeGeoFence?.radius) setBounds();

      cleanUpAddOperation();
      cleanUpUpdateOperation();
    }  catch(error){
      datadogLogs.logger.error("UseEffect 1 Geofence Error", {}, error);
    }
  }, [props.activeGeoFence]);

  const moveToLocation = () => {
    try {
      let place = searchBoxInstance.current.getPlaces();
      let bounds = getBounds([
        {
          latitude: place[0].geometry.viewport.getSouthWest().lat(),
          longitude: place[0].geometry.viewport.getSouthWest().lng(),
        },
        {
          latitude: place[0].geometry.viewport.getNorthEast().lat(),
          longitude: place[0].geometry.viewport.getNorthEast().lng(),
        },
      ]);

      if (place && mapInstance?.current) {
        mapInstance?.current.fitBounds({
          north: bounds.maxLat,
          south: bounds.minLat,
          east: bounds.maxLng,
          west: bounds.minLng,
        });
      }
    }  catch(error){
      datadogLogs.logger.error("Geofence Move To Location Error", {}, error);
    }
  };

  const setBounds = () => {
    try {
      if (mapInstance?.current) {
        let bounds = getBoundsOfDistance(
          {
            lat: props.activeGeoFence?.latitude,
            lng: props.activeGeoFence?.longitude,
          },
          props.activeGeoFence?.radius
        );

        setTimeout(() => {
          bounds = getBounds(bounds);
          mapInstance.current.fitBounds({
            north: bounds.maxLat,
            south: bounds.minLat,
            east: bounds.maxLng,
            west: bounds.minLng,
          });
        }, 1);
      }
    }  catch(error){
      datadogLogs.logger.error("Geofence SetBounds Error", {}, error);
    }
  };

  const addGeoFence = () => {
    setAdding(true);

    let payload = {
      name: geoName.trim(),
      latitude: circleInstance.current.center.lat(),
      longitude: circleInstance.current.center.lng(),
      radius: Math.round(circleInstance.current.radius),
      organisation: {
        id: organisation,
      },
    };

    addGeoFenceMiddleware(payload)
      .then((response) => {
        if (response?.data?.id) {
          appendGeoFence(response.data);
        }
        cleanUpAddOperation();
        handleSuccess(response);
      })
      .catch((error) => {
        datadogLogs.logger.error("Geofence Add Geofence Error", {}, error);
        cleanUpAddOperation();
        handleError(error);
      });
  };

  const enableCanvasMode = () => {
    setCanvasMode(true);
  };

  // const disableCanvasMode = () => {
  // 	setCanvasMode(false);
  // 	setCircleDrawn(false);
  // };

  const circleDrawComplete = (circle) => {
    try {
      circleInstance.current = circle;
      setCircleDrawn(true);
    }  catch(error){
      datadogLogs.logger.error("Geofence circleDrawComplete Error", {}, error);
    }
  };

  const removeCircle = () => {
    try {
      if (circleInstance.current) circleInstance.current.setMap(null);
    }  catch(error){
      datadogLogs.logger.error("Geofence Remove Circle Error", {}, error);
    }
  };

  const cleanUpAddOperation = () => {
    try {
      removeCircle();
      setGeoName('');
      setCanvasMode(false);
      setCircleDrawn(false);
      setAdding(false);
      setOrganisation('');
    }  catch(error){
      datadogLogs.logger.error("Geofence cleanUpAdd Operation Error", {}, error);
    }
  };

  const removeGeoFence = () => {
    setDeleting(true);
    removeGeoFenceMiddleware(props.activeGeoFence.id)
      .then((response) => {
        loadGeoFences();
        handleSuccess(response);
        cleanUpDeleteOperation();
      })
      .catch((error) => {
        datadogLogs.logger.error("Geofence  RemoveGeofence Error", {}, error);
        handleError(error);
        setDeleting(false);
      });
  };

  const cleanUpDeleteOperation = () => {
    try {
      hideDeleteModal();
      setDeleting(false);
    }  catch(error){
      datadogLogs.logger.error("Geofence cleanUpDeleteOperation Error", {}, error);
    }
  };

  const showDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const hideDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const updateGeoFence = () => {
    setUpdating(true);
    let payload = {
      latitude: geoFenceCircleInstance?.current.center.lat(),
      longitude: geoFenceCircleInstance?.current.center.lng(),
      radius: Math.round(geoFenceCircleInstance?.current.radius),
    };
    if (name !== '') {
      payload = { ...payload, name: name };
    }

    updateGeoFenceMiddleWare(props.activeGeoFence.id, payload)
      .then((response) => {
        amendGeoFence(response.data);
        cleanUpUpdateOperation();
        handleSuccess(response);
      })
      .catch((error) => {
        datadogLogs.logger.error("Geofence Update Geofence Error", {}, error);
        cleanUpUpdateOperation();
        handleError(error);
      });
  };

  const enableEditMode = () => {
    setEditMode(true);
  };



  const cleanUpUpdateOperation = () => {
    try {
      setEditMode(false);
      setUpdating(false);

      name = '';

      if (geoFenceCircleInstance && geoFenceCircleInstance.current) {
        geoFenceCircleInstance.current.setEditable(false);
        geoFenceCircleInstance.current.setDraggable(false);
        if (props.activeGeoFence?.radius) setBounds();
        if (props.activeGeoFence) {
          geoFenceCircleInstance.current.setRadius(props.activeGeoFence.radius);
        }
      }
    }  catch(error){
      datadogLogs.logger.error("Geofence CleanUpdateOperation Error", {}, error);
    }
  };


  if (loadError) {
    handleError(loadError);
  }

  if (isLoaded) {
    return (
      <MainContainer>
        <GoogleMap
          mapContainerStyle={GOOGLE_MAP_DEFAULT_STYLES}
          center={GOOGLE_MAP_DEFAULT_CENTER}
          zoom={GOOGLE_MAP_DEFAULT_ZOOM}
          mapTypeId={props.mapType}
          options={{
            ...GOOGLE_MAP_OPTIONS,

          }}
          onLoad={(map) => {
            mapInstance.current = map;
            // setMapInstance(map);
          }}>
          {props.traffic && <TrafficLayer/>}
          <SearchBoxContainer>
            <StandaloneSearchBox
              onLoad={(instance) => (searchBoxInstance.current = instance)}
              onPlacesChanged={moveToLocation}>
              <Input
                placeholder="Search places"
                ref={(ref) => (searchInputRef = ref)}
                {...(searchQuery && {
                  icon: (
                    <Icon
                      name="times circle outline"
                      link
                      onClick={() => {
                        setSearchQuery('');
                        searchInputRef.inputRef.current.value = '';
                        loadGeoFences('', true);
                      }}
                    />
                  ),
                })}
                // style={{ width: '100%' }}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </StandaloneSearchBox>
          </SearchBoxContainer>
          {canvasMode
            ? !circleDrawn && (
            <DrawingManager
              onLoad={(drawable) => (drawableInstance.current = drawable)}
              drawingMode="circle"
              options={{
                drawingControl: false,
                circleOptions: editableCircleOptions,
              }}
              onCircleComplete={circleDrawComplete}
            />
          )
            : props.activeGeoFence && (
            <Circle
              onLoad={(circle) => (geoFenceCircleInstance.current = circle)}
              ref={(ref) => (geoFenceCircleInstance = ref)}
              center={{
                lat: props.activeGeoFence.latitude,
                lng: props.activeGeoFence.longitude,
              }}
              radius={props.activeGeoFence.radius}
              options={circleOptions}
            />
          )}
        </GoogleMap>
        <ControlsContainer>
          <Controls>
            {canvasMode && (
              <Block>
                <HorizontalBlock>
                  <Block>
                    <Input placeholder="Name" onChange={(e) => setGeoName(e.target.value)}/>
                  </Block>
                  <Block>
                    <Dropdown
                      search
                      selection
                      placeholder="Organisation"
                      options={organisations}
                      value={organisation}
                      onChange={(e, {value}) => setOrganisation(value)}
                    />
                  </Block>
                </HorizontalBlock>
                <HorizontalBlock>
                  <Button onClick={cleanUpAddOperation}>Cancel</Button>
                  <Block>
                    <Button
                      primary
                      disabled={!(circleDrawn && geoName !== '' && organisation !== '')}
                      loading={adding}
                      onClick={addGeoFence}>
                      Add
                    </Button>
                  </Block>
                </HorizontalBlock>
              </Block>
            )}
            {editMode && (
              <>
                <Block>
                  <Input placeholder={props.activeGeoFence.name}
                         onChange={(e) => (name = e.target.value)}/>
                </Block>
                <Block>
                  <Button primary onClick={updateGeoFence} loading={updating}>
                    Update
                  </Button>
                </Block>
                <Block>
                  <Button onClick={cleanUpUpdateOperation}>Cancel</Button>
                </Block>
              </>
            )}
            {!canvasMode && !editMode && (
              <>
                {(PermissionState.Organisation.manage || PermissionState.Organisation.read)  && (
                  <>
                    {props.activeGeoFence && (
                      <>
                        <Block>
                          <Button
                            primary
                            disabled={!(PermissionState.Geofence.manage || PermissionState.Geofence.update)}
                            onClick={() => {
                              enableEditMode();
                              if (geoFenceCircleInstance && geoFenceCircleInstance.current) {
                                geoFenceCircleInstance.current.setEditable(true);
                                geoFenceCircleInstance.current.setDraggable(true);
                              }
                            }}>
                            {' '}
                            Edit
                          </Button>
                          <Button negative onClick={showDeleteModal} disabled={!(PermissionState.Geofence.manage || PermissionState.Geofence.delete)}>
                            Delete
                          </Button>
                        </Block>
                        <Block>
                          <span>||</span>
                        </Block>
                      </>
                    )}

                    <Block>
                      <Button
                        color="teal"
                        disabled={!(PermissionState.Geofence.manage || PermissionState.Geofence.create)}
                        onClick={() => {
                          enableCanvasMode();
                          fetchOrganisations()
                            .then((response) => {
                              let temp = response?.data?.data.map((org, index) => {
                                return {
                                  key: index,
                                  text: org.name,
                                  value: org.id,
                                };
                              });
                              setOrganisations(temp);
                            })
                            .catch((error) => handleError(error));
                        }}>
                        New
                      </Button>
                    </Block>
                  </>
                )}
              </>
            )}
          </Controls>
        </ControlsContainer>
        {deleteModalOpen && (
          <Modal size="tiny" onClose={cleanUpDeleteOperation} open={deleteModalOpen}>
            <Modal.Header>Delete</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <p>Are you sure you want to delete this geofence ?</p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={cleanUpDeleteOperation}>
                Cancel
              </Button>
              <Button negative onClick={removeGeoFence} loading={deleting}>
                Delete
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </MainContainer>
    );
  } else return <TrackNerdLoader/>;
};

// Connect to Store

const mapStateToProps = (state) => ({
  activeGeoFence: state.geofence.activeGeoFence,
  mapType: state.location.mapType,
  traffic: state.location.traffic,
});

export default connect(mapStateToProps)(Main);
*/

import React, { LegacyRef, useEffect, useRef, useState } from 'react';

// Configs
import {
	addGeofence,
	addGeoFenceMiddleware,
	amendGeoFence,
	appendGeoFence,
	loadGeoFences,
	removeGeoFenceMiddleware,
	updateGeoFenceMiddleWare,
} from '../GeoFencesMiddleware';

import { useSelector } from 'react-redux';
import { Block, Controls, ControlsContainer, HorizontalBlock, MainContainer, SearchBoxContainer } from './MainStyles';
import { Button, Dropdown, Icon, Input, Modal } from 'semantic-ui-react';
import {
	Circle,
	DrawingManager,
	GoogleMap,
	StandaloneSearchBox,
	TrafficLayer,
	useJsApiLoader,
} from '@react-google-maps/api';
import { getBounds, getBoundsOfDistance } from 'geolib';
import { handleError, handleSuccess } from '../../../../helper/view specific/messaging';
import {
	GOOGLE_MAP_DEFAULT_CENTER,
	GOOGLE_MAP_DEFAULT_STYLES,
	GOOGLE_MAP_DEFAULT_ZOOM,
	GOOGLE_MAP_LIBRARIES,
	GOOGLE_MAP_OPTIONS,
	GOOGLE_MAPS_API_KEY,
} from '../../../../constants/google';
import { fetchOrganisations } from '../../../../utils/common';
import TrackNerdLoader from '../../../../components/loader/Loader';
import { RootState } from '../../../../redux/RootState';
import { Organisation } from '../../../../types/organisation';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';

// interface Props {
// Other props...
// }

interface Org {
	key: number;
	text: string;
	value: number;
}

const Main = () => {
	const circleOptions = {
		strokeColor: 'dodgerblue',
		strokeWeight: 2,
		fillColor: 'dodgerblue',
		fillOpacity: 0.1,
	};
	const editableCircleOptions = {
		...circleOptions,
		editable: true,
		draggable: true,
	};

	const { isLoaded, loadError } = useJsApiLoader({
		googleMapsApiKey: GOOGLE_MAPS_API_KEY,
		libraries: GOOGLE_MAP_LIBRARIES,
	});

	let name = '';
	const searchBoxInstance = useRef<google.maps.places.SearchBox | null>(null);
	const searchInputRef: LegacyRef<Input> = useRef(null);
	const geoFenceCircleInstance = useRef<google.maps.Circle | null>(null);
	const circleInstance = useRef<google.maps.Circle | null>(null);
	const drawableInstance = useRef<google.maps.drawing.DrawingManager | null>(null);

	const mapInstance = useRef<google.maps.Map | null>(null);

	const [geoName, setGeoName] = useState<string>('');
	const [canvasMode, setCanvasMode] = useState<boolean>(false);
	const [circleDrawn, setCircleDrawn] = useState<boolean>(false);
	const [organisations, setOrganisations] = useState<Org[]>([]);
	const [adding, setAdding] = useState<boolean>(false);
	const [organisation, setOrganisation] = useState<number | number[]>(-1);
	const [deleting, setDeleting] = useState<boolean>(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
	const [editMode, setEditMode] = useState<boolean>(false);
	const [updating, setUpdating] = useState<boolean>(false);

	const [searchQuery, setSearchQuery] = useState<string>('');

	const PermissionState = useSelector((state: RootState) => state.rolePermissions.permissions);

	const { activeGeoFence } = useSelector((state: RootState) => state.geofence);
	const { mapType, traffic } = useSelector((state: RootState) => state.location);

	useEffect(() => {
		if (searchInputRef.current) {
			const inputElement = searchInputRef.current as unknown as HTMLInputElement;
			inputElement.value = '';
			// searchInputRef.current.value = '';
		}
		if (activeGeoFence?.radius) setBounds();

		cleanUpAddOperation();
		cleanUpUpdateOperation();
	}, [activeGeoFence]);

	const moveToLocation = () => {
		const place = searchBoxInstance?.current?.getPlaces();
		if (place) {
			const viewPort = place[0]?.geometry?.viewport;
			if (viewPort) {
				const bounds = getBounds([
					{
						latitude: viewPort.getSouthWest().lat(),
						longitude: viewPort.getSouthWest().lng(),
					},
					{
						latitude: viewPort.getNorthEast().lat(),
						longitude: viewPort.getNorthEast().lng(),
					},
				]);

				if (place && mapInstance?.current) {
					mapInstance?.current.fitBounds({
						north: bounds.maxLat,
						south: bounds.minLat,
						east: bounds.maxLng,
						west: bounds.minLng,
					});
				}
			}
		}
	};

	const setBounds = () => {
		if (activeGeoFence?.latitude && activeGeoFence?.longitude) {
			const coordinates = {
				lat: activeGeoFence?.latitude,
				lng: activeGeoFence?.longitude,
			};
			if (mapInstance?.current !== undefined) {
				const boundsArray = getBoundsOfDistance(coordinates, activeGeoFence?.radius);

				setTimeout(() => {
					const bounds = getBounds(boundsArray);

					if (mapInstance?.current) {
						mapInstance.current.fitBounds({
							north: bounds.maxLat,
							south: bounds.minLat,
							east: bounds.maxLng,
							west: bounds.minLng,
						});
					}
				}, 1);
			}
		}
	};

	const addGeoFence = () => {
		setAdding(true);
		const latitude = circleInstance?.current?.getCenter()?.lat();
		const longitude = circleInstance?.current?.getCenter()?.lng();
		const radius = circleInstance?.current?.getRadius();

		if (circleInstance?.current && latitude && longitude && radius) {
			const payload: addGeofence = {
				name: geoName.trim(),
				latitude: latitude,
				longitude: longitude,
				radius: Math.round(radius),
				organisation: {
					id: organisation,
				},
			};

			addGeoFenceMiddleware(payload)
				.then((response) => {
					if (response?.data?.id) {
						appendGeoFence(response.data);
					}
					cleanUpAddOperation();
					handleSuccess(response);
				})
				.catch((error) => {
					handleError('Main.tsx => addGeoFence()', error);
					cleanUpAddOperation();
				});
		}
	};

	const enableCanvasMode = () => {
		setCanvasMode(true);
	};

	// const disableCanvasMode = () => {
	// 	setCanvasMode(false);
	// 	setCircleDrawn(false);
	// };

	const circleDrawComplete = (circle: google.maps.Circle) => {
		if (circleInstance.current === null) {
			circleInstance.current = circle;
		}
		setCircleDrawn(true);
	};

	const removeCircle = () => {
		if (circleInstance.current) {
			circleInstance.current.setMap(null);
			circleInstance.current = null;
		}
	};

	const cleanUpAddOperation = () => {
		removeCircle();
		setGeoName('');
		setCanvasMode(false);
		setCircleDrawn(false);
		setAdding(false);
		setOrganisation(-1);
	};

	const removeGeoFence = () => {
		setDeleting(true);
		if (activeGeoFence) {
			removeGeoFenceMiddleware(activeGeoFence.id)
				.then((response) => {
					loadGeoFences();
					handleSuccess(response);
					cleanUpDeleteOperation();
				})
				.catch((error) => {
					handleError('Main.tsx => removeGeoFence()', error);
					setDeleting(false);
				});
		}
	};

	const cleanUpDeleteOperation = () => {
		hideDeleteModal();
		setDeleting(false);
	};

	const showDeleteModal = () => {
		setDeleteModalOpen(true);
	};

	const hideDeleteModal = () => {
		setDeleteModalOpen(false);
	};

	const updateGeoFence = () => {
		interface GeoFencePayload {
			latitude: number;
			longitude: number;
			radius: number;
			name?: string; // Make the 'name' property optional
		}

		setUpdating(true);

		const latitide = geoFenceCircleInstance?.current?.getCenter()?.lat();
		const longitude = geoFenceCircleInstance?.current?.getCenter()?.lng();
		const radius = geoFenceCircleInstance?.current?.getRadius();

		if (latitide && longitude && radius) {
			let payload: GeoFencePayload = {
				latitude: latitide,
				longitude: longitude,
				radius: radius,
			};
			if (name !== '') {
				payload = { ...payload, name: name };
			}

			activeGeoFence &&
				updateGeoFenceMiddleWare(activeGeoFence.id, payload)
					.then((response) => {
						amendGeoFence(response.data);
						cleanUpUpdateOperation();
						handleSuccess(response);
					})
					.catch((error) => {
						handleError('Main.tsx => updateGeoFence() => updateGeoFenceMiddleWare()', error);
						cleanUpUpdateOperation();
					});
		}
	};

	const enableEditMode = () => {
		setEditMode(true);
	};

	const cleanUpUpdateOperation = () => {
		setEditMode(false);
		setUpdating(false);

		name = '';

		if (geoFenceCircleInstance && geoFenceCircleInstance.current) {
			geoFenceCircleInstance.current.setEditable(false);
			geoFenceCircleInstance.current.setDraggable(false);
			if (activeGeoFence?.radius) setBounds();
			if (activeGeoFence) {
				geoFenceCircleInstance.current.setRadius(activeGeoFence.radius);
			}
		}
	};

	if (loadError) {
		// handleError('Main.tsx => updateGeoFence() => updateGeoFenceMiddleWare()', loadError);
	}

	if (isLoaded) {
		return (
			<MainContainer>
				<GoogleMap
					mapContainerStyle={GOOGLE_MAP_DEFAULT_STYLES}
					center={GOOGLE_MAP_DEFAULT_CENTER}
					zoom={GOOGLE_MAP_DEFAULT_ZOOM}
					mapTypeId={mapType}
					options={{
						...GOOGLE_MAP_OPTIONS,
					}}
					onLoad={(map) => {
						mapInstance.current = map;
						// setMapInstance(map);
					}}>
					{traffic && <TrafficLayer />}
					<SearchBoxContainer>
						<StandaloneSearchBox
							onLoad={(instance) => (searchBoxInstance.current = instance)}
							onPlacesChanged={moveToLocation}>
							<Input
								placeholder="Search places"
								ref={searchInputRef}
								{...(searchQuery && {
									icon: (
										<Icon
											name="times circle outline"
											link
											onClick={() => {
												setSearchQuery('');
												if (searchInputRef?.current) {
													const inputElement = searchInputRef.current as unknown as HTMLInputElement;
													inputElement.value = '';
												}
												loadGeoFences('', true);
											}}
										/>
									),
								})}
								// style={{ width: '100%' }}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
						</StandaloneSearchBox>
					</SearchBoxContainer>
					{canvasMode
						? !circleDrawn && (
								<DrawingManager
									onLoad={(drawable) => (drawableInstance.current = drawable)}
									drawingMode={window.google.maps.drawing.OverlayType.CIRCLE}
									options={{
										drawingControl: false,
										circleOptions: editableCircleOptions,
									}}
									onCircleComplete={circleDrawComplete}
								/>
						  )
						: activeGeoFence && (
								<Circle
									onLoad={(circle) => (geoFenceCircleInstance.current = circle)}
									// ref={geoFenceCircleInstance}
									center={{
										lat: activeGeoFence.latitude,
										lng: activeGeoFence.longitude,
									}}
									radius={activeGeoFence.radius}
									options={circleOptions}
								/>
						  )}
				</GoogleMap>
				<ControlsContainer>
					<Controls>
						{canvasMode && (
							<Block>
								<HorizontalBlock>
									<Block>
										<Input placeholder="Name" onChange={(e) => setGeoName(e.target.value)} />
									</Block>
									<Block>
										<Dropdown
											search
											selection
											placeholder="Organisation"
											options={organisations}
											value={organisation}
											onChange={async (e, data: DropdownProps) => {
												if (data?.value) {
													const numericValue = Number(data.value); // Convert to number
													if (!isNaN(numericValue)) {
														// Check if it's a valid number
														setOrganisation(numericValue);
													}
												}
											}}

											/*onChange={async (e, data: DropdownProps) => {
                                                if (data?.value) {
                                                    const numericValue = Number(data.value); // Convert to number
                                                    if (!isNaN(numericValue)) {
                                                        // Check if it's a valid number
                                                        setOrganisation(numericValue);
                                                    }
                                                }
                                            }}*/
										/>
									</Block>
								</HorizontalBlock>
								<HorizontalBlock>
									<Button onClick={cleanUpAddOperation}>Cancel</Button>
									<Block>
										<Button
											primary
											disabled={!(circleDrawn && geoName !== '' && organisation !== -1)}
											loading={adding}
											onClick={addGeoFence}>
											Add
										</Button>
									</Block>
								</HorizontalBlock>
							</Block>
						)}
						{editMode && (
							<>
								<Block>
									<Input
										placeholder={activeGeoFence ? activeGeoFence.name : ''}
										onChange={(e) => (name = e.target.value)}
									/>
								</Block>
								<Block>
									<Button primary onClick={updateGeoFence} loading={updating}>
										Update
									</Button>
								</Block>
								<Block>
									<Button onClick={cleanUpUpdateOperation}>Cancel</Button>
								</Block>
							</>
						)}
						{!canvasMode && !editMode && (
							<>
								{(PermissionState.Organisation.manage || PermissionState.Organisation.read) && (
									<>
										{activeGeoFence && (
											<>
												<Block>
													<Button
														primary
														disabled={!(PermissionState.Geofence.manage || PermissionState.Geofence.update)}
														onClick={() => {
															enableEditMode();
															if (geoFenceCircleInstance && geoFenceCircleInstance.current) {
																geoFenceCircleInstance.current.setEditable(true);
																geoFenceCircleInstance.current.setDraggable(true);
															}
														}}>
														{' '}
														Edit
													</Button>
													<Button
														negative
														onClick={showDeleteModal}
														disabled={!(PermissionState.Geofence.manage || PermissionState.Geofence.delete)}>
														Delete
													</Button>
												</Block>
												<Block>
													<span>||</span>
												</Block>
											</>
										)}

										<Block>
											<Button
												color="teal"
												disabled={!(PermissionState.Geofence.manage || PermissionState.Geofence.create)}
												onClick={() => {
													enableCanvasMode();
													fetchOrganisations()
														.then((response) => {
															const temp = response?.data?.data.map((org: Organisation, index: number) => {
																return {
																	key: index,
																	text: org.name,
																	value: org.id,
																};
															});
															setOrganisations(temp);
														})
														.catch((error) => handleError('Main.tsx => fetchOrganisations()', error));
												}}>
												New
											</Button>
										</Block>
									</>
								)}
							</>
						)}
					</Controls>
				</ControlsContainer>
				{deleteModalOpen && (
					<Modal size="tiny" onClose={cleanUpDeleteOperation} open={deleteModalOpen}>
						<Modal.Header>Delete</Modal.Header>
						<Modal.Content>
							<Modal.Description>
								<p>Are you sure you want to delete this geofence ?</p>
							</Modal.Description>
						</Modal.Content>
						<Modal.Actions>
							<Button color="black" onClick={cleanUpDeleteOperation}>
								Cancel
							</Button>
							<Button negative onClick={removeGeoFence} loading={deleting}>
								Delete
							</Button>
						</Modal.Actions>
					</Modal>
				)}
			</MainContainer>
		);
	} else return <TrackNerdLoader />;
};

// Connect to Store

export default Main;
