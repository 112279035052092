import React, { useEffect, useState } from 'react'; // Configs
import {
	GOOGLE_MAP_DEFAULT_CENTER,
	GOOGLE_MAP_DEFAULT_STYLES,
	GOOGLE_MAP_DEFAULT_ZOOM,
	GOOGLE_MAP_LIBRARIES,
	GOOGLE_MAP_OPTIONS,
	GOOGLE_MAPS_API_KEY,
} from '../../../../../constants/google';
import { createGeoFenceDTO, GeoFenceDTOData } from '../../../../../dtos/geofence';
import HybridImage from '../../../../../assets/images/hybrid-image.png';
import RoadmapImage from '../../../../../assets/images/roadmap-image.png';
import TrafficImage from '../../../../../assets/images/traffic-image.png';
import {
	closeImmobilizerModal,
	closeMobilizerModal,
	closeTrackingLinkModal,
	closeTrackingLinkViewModal,
	openImmobilizerModal,
	openMobilizerModal,
	openTrackingLinkViewModal,
	shareTrackingLinkRequest,
	VehicleProcessCommand,
	vehicleProcessCommands,
} from './LocationMiddleware';
import { fetchGeoFences } from '../../../geoFences/GeoFencesMiddleware';
import { useSelector } from 'react-redux';
import {
	ActiveMapTypes,
	Container,
	ControlsContainer,
	MapTypeControls,
	MapTypesContainer,
	MapTypesLabels,
	Placeholder,
	ShouldAdjustMap,
} from './LocationStyles';
import { Button, Dropdown, Form, Input, Modal, Popup, Transition } from 'semantic-ui-react';
import Store from '../../../../../redux/store'; // Theme
import { THEME } from '../../../../../constants/Theme';
import { getVehicleIcon, setMapType, setTrafficView } from '../../../../../utils/common';
import { handleError } from '../../../../../helper/view specific/messaging';
import { IsEmailValid, IsMobileNumberValid } from '../../../../../utils/validation';
import { Circle, GoogleMap, InfoWindow, Marker, Polyline, TrafficLayer, useJsApiLoader } from '@react-google-maps/api';
import { getBounds, getDistance } from 'geolib';
import moment, { DurationInputArg1, DurationInputArg2 } from 'moment';
import CustomInfoWindow from '../../../../../components/vehicles/info-window/CustomInfoWindow';
import { GEOCODE_API, VEHICLES_API } from '../../../../../constants/api';
import { trackMixpanelEvent } from '../../../../../helper/services/mixpanel';
import { getData } from '../../../../../helper/services/axios';
import TrackNerdLoader from '../../../../../components/loader/Loader';
import { connectToFirebase } from '../../../../../helper/services/firebase';
import { RootState } from '../../../../../redux/RootState';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { Vehicle } from '../../../../../types/vehicle';
import { Location as LocationObj, VehicleLocation } from '../../../../../types/location';
import { Dictionary } from '@reduxjs/toolkit';

interface VehicleLocationCoordinates {
	lat: number;
	lng: number;
}

interface ShareTrackingDropDownOptions {
	key: string;
	text: string;
	value: string;
}

/*interface VehicleLocationWithLocation extends VehicleLocation {
	location: LocationObj;
}*/

const scaledSize: google.maps.Size = {
	width: 50,
	height: 50,
	equals: function (): boolean {
		throw new Error('Function not implemented.');
	},
};

const anchor: google.maps.Point = {
	x: 25,
	y: 25,
	equals: function (): boolean {
		throw new Error('Function not implemented.');
	},
};
const pixelOffset: google.maps.Size = {
	width: 0,
	height: -24,
	equals: function (): boolean {
		throw new Error('Function not implemented.');
	},
};

const EXPIRY_UNIT_OF_TIME_OPTIONS = [
	{ key: 'days', text: 'Days', value: 'days' },
	{ key: 'weeks', text: 'Weeks', value: 'weeks' },
	// Add more options as needed
];

const Location = () => {
	let prevCoordinates: {
		latitude: number;
		longitude: number;
	} | null = null;
	const [trackingLink, setTrackingLink] = useState('');
	const [vehicleId, setVehicleId] = useState<number | null>(null);
	const [polyLines, setPolyLines] = useState<{
		[key: string]: VehicleLocationCoordinates[];
	}>({});
	const [mapInstance, setMapInstance] = useState<google.maps.Map>();
	const [infoWindowOpen, setInfoWindowOpen] = useState<boolean>(false);
	const [activeMarker, setActiveMarker] = useState<number | null>(null);
	const [googleAddress, setGoogleAddress] = useState<string>('Fetching...');
	const [distanceFromLastAddress, setDistanceFromLastAddress] = useState<number | null>(null);
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [selectedEmailPhone, setSelectedEmailPhone] = useState<string[]>([]);
	const [emailsPhonesOptions, setEmailsPhonesOptions] = useState<ShareTrackingDropDownOptions[]>([]);
	const [quantityOfTime, setQuantityOfTime] = useState<string>('1');
	const [unitOfTime, setUnitOfTime] = useState<string>('days');
	const [geoFences, setGeoFences] = useState<GeoFenceDTOData[] | null>(null);
	const [dropdownVehicles, setDropdownVehicles] = useState([]);
	const [selectedVehicles, setSelectedVehicles] = useState<number[]>([]);
	const [openKeyLockModal, setOpenKeyLockModal] = useState<boolean>(false);
	const [openKeyUnlockModal, setOpenKeyUnlockModal] = useState<boolean>(false);
	const [openIgnitionOffModal, setOpenIgnitionOffModal] = useState<boolean>(false);
	const [openIgnitionOnModal, setOpenIgnitionOnModal] = useState<boolean>(false);
	const [openWheellockModal, setOpenWheellockModal] = useState(false);
	const [wheelLockOnClicked, setWheelLockOnClicked] = useState<boolean | null>(null);
	const [shouldAdjustMap, setShouldAdjustMap] = useState(false);

	const vehicleState = useSelector((state: RootState) => state.vehicleSlice.entities);
	const selectedVehiclesNumbers = useSelector((state: RootState) => state.sidebar.selectedVehiclesNumbers);
	const selectedVehiclesFromSidebar = useSelector((state: RootState) => state.sidebar.selectedVehicles);
	const vehicleDetails = useSelector((state: RootState) => state.vehicle.vehicleDetails);
	const {
		trackingLinkModalOpen,
		immobilizerModalOpen,
		mobilizerModalOpen,
		trackingLinkViewModelOpen,
		buttonLoading,
		mapType,
		traffic,
	} = useSelector((state: RootState) => state.location);
	const shownGeoFences = useSelector((state: RootState) => state.auth.shownGeoFences);

	useEffect(() => {
		Store.dispatch({
			type: 'SELECTED_TAB',
			payload: 'Live',
		});
	}, []);

	useEffect(() => {
		Store.dispatch({
			type: 'DISABLE_DATEPICKER',
			payload: true,
		});
		Store.dispatch({
			type: 'DISABLE_TIMEPICKER',
			payload: true,
		});

		// fetchGeoFence();
		if (selectedVehiclesFromSidebar.length) {
			if (selectedVehiclesFromSidebar.length === 1) {
				setActiveInfoWindow(selectedVehiclesFromSidebar[0]);
			} else {
				setActiveMarker(null);
				setInfoWindowOpen(false);
			}
		} else {
			setActiveMarker(null);
			setInfoWindowOpen(false);
		}

		setBounds();

		trackMixpanelEvent('live_location', {
			vehicles: selectedVehiclesNumbers,
		});

		if (selectedVehiclesFromSidebar.length <= 20) connectToFirebase(selectedVehiclesFromSidebar, vehicleDetails);

		return () => {
			Store.dispatch({
				type: 'DISABLE_DATEPICKER',
				payload: false,
			});
			Store.dispatch({
				type: 'DISABLE_TIMEPICKER',
				payload: false,
			});
		};
	}, [selectedVehiclesFromSidebar, mapInstance]);

	useEffect(() => {
		updatePolyline();
	}, [vehicleState, selectedVehiclesFromSidebar]);

	useEffect(() => {
		if (shownGeoFences) {
			fetchGeoFence();
		}
	}, [shownGeoFences]);

	const fetchGeoFence = (): void => {
		fetchGeoFences('?pagecount=1000')
			.then((response) => {
				setGeoFences(createGeoFenceDTO(response?.data?.data));
			})
			.catch((error) => {
				handleError('Location.tsx => fetchGeofence()', error);
			});
	};

	const updatePolyline = () => {
		if (selectedVehiclesFromSidebar.length > 0) {
			selectedVehiclesFromSidebar.forEach((vehicle) => {
				if (vehicleState[vehicle]?.location?.latitude) {
					if (polyLines?.[vehicle]) {
						setPolyLines((prevState) => ({
							...prevState,
							[vehicle]: [
								...prevState[vehicle],
								{
									lat: vehicleState[vehicle]?.location?.latitude,
									lng: vehicleState[vehicle]?.location?.longitude,
								},
							],
						}));
					} else {
						setPolyLines((prevState) => ({
							...prevState,
							[vehicle]: [
								{
									lat: vehicleState[vehicle]?.location?.latitude,
									lng: vehicleState[vehicle]?.location?.longitude,
								},
							],
						}));
					}
				}
			});

			if (infoWindowOpen && activeMarker && Object.hasOwn(vehicleState, activeMarker) && prevCoordinates) {
				const activeVehicleState = vehicleState[activeMarker];
				if (activeVehicleState && activeVehicleState.location !== undefined) {
					const currCoordinates = {
						latitude: activeVehicleState.location.latitude,
						longitude: activeVehicleState.location.longitude,
					};
					const distance = getDistance(prevCoordinates, currCoordinates);

					if (distance > 10) {
						setDistanceFromLastAddress(distance);
					}
				}
			}
		}
	};

	const setBounds = () => {
		if (!mapInstance) return;
		const boundsArray: LocationObj[] = [];
		let bounds;
		selectedVehiclesFromSidebar.forEach((key: number) => {
			const activeVehicleState = vehicleState[key];
			if (activeVehicleState && activeVehicleState.location !== undefined) {
				boundsArray.push(activeVehicleState.location);
			}
		});

		if (boundsArray.length === 1) {
			mapInstance.setCenter({
				lat: boundsArray[0]?.latitude,
				lng: boundsArray[0]?.longitude,
			});
			mapInstance.setZoom(18);
		}

		if (boundsArray.length > 1) {
			bounds = getBounds(boundsArray);
			mapInstance.fitBounds(
				{
					north: bounds.maxLat,
					south: bounds.minLat,
					east: bounds.maxLng,
					west: bounds.minLng,
				},
				128
			);
		}
	};

	const setMapTypeLocal = (value: string) => {
		if (value !== mapType) {
			setMapType(value);
		}
	};

	const setActiveInfoWindow = (vehicle: number) => {
		const activeVehicleState = vehicleState[vehicle];
		if (activeVehicleState && activeVehicleState.location !== undefined) {
			const coordinates = {
				latitude: activeVehicleState?.location?.latitude,
				longitude: activeVehicleState?.location?.longitude,
			};

			setActiveMarker(vehicle);
			setGoogleAddress('Fetching...');
			setDistanceFromLastAddress(null);
			setInfoWindowOpen(true);

			setTimeout(() => setAddress(coordinates.latitude, coordinates.longitude), 1);
		}
	};

	const setAddress = (lat: number, lng: number) => {
		if (lat && lng) {
			prevCoordinates = {
				latitude: lat,
				longitude: lng,
			};

			getData(GEOCODE_API, `?latitude=${lat}&longitude=${lng}`)
				.then((response) => {
					if (response?.data?.address) {
						setGoogleAddress(response.data.address);
						setDistanceFromLastAddress(null);
					}
				})
				.catch((error) => {
					handleError('Location.tsx => setAddress()', error);
				});
		}
	};

	const refreshAddress = () => {
		if (activeMarker) {
			const activeVehicleState = vehicleState[activeMarker];
			if (activeVehicleState && activeVehicleState.location !== undefined) {
				setAddress(activeVehicleState.location?.latitude, activeVehicleState.location?.longitude);
			}
		}
	};

	const shareTrackingLink = () => {
		// logSelectContentEvent('click', 'Share Tracking Link', 'shareTrackingLink');

		setTrackingLink('');

		let query: {
			vehicles: {
				id: number;
			}[];
			phone?: string[];
			email?: string[];
			expiry?: string;
			type?: string;
		} = {
			vehicles: selectedVehicles.map((vehicle) => {
				return { id: Number(vehicle) };
			}),
		};
		if (selectedEmailPhone.length) {
			const phones: string[] = [];
			const emails: string[] = [];
			selectedEmailPhone.forEach((value) => {
				if (IsMobileNumberValid(value)) {
					phones.push(value);
				} else if (IsEmailValid(value)) {
					emails.push(value);
				}
			});

			if (phones.length) {
				query = {
					...query,
					phone: phones,
				};
			}
			if (emails.length) {
				query = {
					...query,
					email: emails,
				};
			}
		}

		query = {
			...query,
			expiry: moment()
				.add(parseInt(quantityOfTime) as DurationInputArg1, unitOfTime as DurationInputArg2)
				.toISOString(),
			type: 'Live',
		};
		query = {
			...query,
			type: 'Live',
		};

		shareTrackingLinkRequest(query)
			.then((response) => {
				setTrackingLink(response.data);
				cleanShareTrackingLinkOperation();
				openTrackingLinkViewModal();
				Store.dispatch({
					type: 'BUTTON_LOADED',
				});
			})
			.catch((error) => {
				handleError('Location.tsx => shareTrackingLink() => shareTrackingLinkRequest()', error);
				Store.dispatch({
					type: 'BUTTON_LOADED',
				});
			});
	};

	const toggleCommands = (action: string, status: boolean) => {
		const query: VehicleProcessCommand = {
			status: status,
			action: action,
		};

		vehicleId &&
			vehicleProcessCommands(vehicleId, query)
				.then(() => {
					Store.dispatch({
						type: 'BUTTON_LOADED',
					});
					setVehicleId(null);
					closeImmobilizerModal();
					closeMobilizerModal();
					setOpenKeyLockModal(false);
					setOpenKeyUnlockModal(false);
					setOpenIgnitionOnModal(false);
					setOpenIgnitionOffModal(false);
					setOpenWheellockModal(false);
				})
				.catch((error) => {
					handleError('Location.tsx => toggleCommands()', error);
					closeImmobilizerModal();
					closeMobilizerModal();
					setOpenKeyLockModal(false);
					setOpenKeyUnlockModal(false);
					setOpenIgnitionOnModal(false);
					setOpenIgnitionOffModal(false);
					setOpenWheellockModal(false);
					Store.dispatch({
						type: 'BUTTON_LOADED',
					});
				});
	};

	const cleanShareTrackingLinkOperation = () => {
		setSearchQuery('');
		setSelectedEmailPhone([]);
		setEmailsPhonesOptions([]);
		// setQuantityOfTime('1');
		setUnitOfTime('days');
		Store.dispatch({ type: 'RESET_SELECTED_DATE' });
	};

	const getPopupInfo = () => (
		<MapTypesContainer>
			<MapTypeControls activeType={traffic} onClick={() => setTrafficView(!traffic)}>
				<img src={TrafficImage} alt="Traffic" />
			</MapTypeControls>
			<MapTypesLabels activeType={traffic}>Traffic</MapTypesLabels>
		</MapTypesContainer>
	);

	const checkLocationDataAvailable = (selectedVehicles: number[], data: Dictionary<VehicleLocation>) => {
		let IsDataAvailable = false;
		selectedVehicles.forEach((id: number) => {
			if (data[id]?.location !== undefined) {
				IsDataAvailable = true;
			}
		});

		return IsDataAvailable;
	};

	// Get Vehicle List for dropdown
	const getVehiclesforDropdown = () => {
		getData(VEHICLES_API, '?dropdown=true')
			.then((res) => {
				setDropdownVehicles(
					res.data.data.map((vehicle: Vehicle, index: number) => {
						return { key: index, value: vehicle.id, text: vehicle.registrationNumber };
					})
				);
				setSelectedVehicles([selectedVehiclesFromSidebar[0]]);
			})
			.catch((error) => {
				handleError('Location.tsx => getVehiclesforDropdown()', error);
			});
	};

	const { isLoaded, loadError } = useJsApiLoader({
		googleMapsApiKey: GOOGLE_MAPS_API_KEY,
		libraries: GOOGLE_MAP_LIBRARIES,
	});

	/*	const VehicleInfoWindow = () => {
            if (activeMarker) {
                const activeVehicleState = vehicleState?.[activeMarker]?.location;
                if (activeMarker && infoWindowOpen && Object.hasOwn(vehicleState, activeMarker) && activeVehicleState)
                    return (
                        <InfoWindow
                            position={{
                                lat: activeVehicleState?.latitude,
                                lng: activeVehicleState?.longitude,
                            }}
                            options={{
                                pixelOffset: pixelOffset,
                            }}
                            onCloseClick={() => {
                                setInfoWindowOpen(false);
                            }}>
                            <CustomInfoWindow
                                id={activeMarker}
                                onVehicleImmobilize={() => {
                                    openImmobilizerModal();
                                    setVehicleId(activeMarker);
                                }}
                                onVehicleMobilize={() => {
                                    openMobilizerModal();
                                    setVehicleId(activeMarker);
                                }}
                                onVehicleKeyLockClick={() => {
                                    setOpenKeyLockModal(true);
                                    setVehicleId(activeMarker);
                                }}
                                onVehicleKeyUnlockClick={() => {
                                    setOpenKeyUnlockModal(true);
                                    setVehicleId(activeMarker);
                                }}
                                onIgnitionOnClick={() => {
                                    setOpenIgnitionOnModal(true);
                                    setVehicleId(activeMarker);
                                }}
                                onIgnitionOffClick={() => {
                                    setOpenIgnitionOffModal(true);
                                    setVehicleId(activeMarker);
                                }}
                                liveStatus
                                // driver={true}
                                liveAddress={true}
                                liveOdometer={true}
                                liveSpeed
                                liveBattery
                                liveTimestampUpdate
                                distanceFromLastAddress={distanceFromLastAddress}
                                googleAddress={googleAddress}
                                onRefreshAddress={refreshAddress}
                                distance={false}
                                relayIconShow={true}
                                liveFuel={true}
                                getVehiclesforDropdown={getVehiclesforDropdown}
                            />
                        </InfoWindow>
                    );
            } else return <></>;
        };*/

	/*const MarkerGroup = () => {
        if (selectedVehiclesFromSidebar) {
            return (
                <MarkerClusterer>
                    {() => {
                        return <MarkerGroupClusterer />;
                        // return <MarkerGroupClusterer clusterer={clusterer} />;
                    }}
                </MarkerClusterer>
            );
        } else return <></>;
    };
*/

	/*
        const MarkerGroupClusterer = () => {
            if (selectedVehiclesFromSidebar) {
                return selectedVehiclesFromSidebar.map((key, index) => {
                    const location = vehicleState[key]?.location;
                    const vehicleType = vehicleDetails.get(key)?.data?.type;

                    if (vehicleType && location && Object.hasOwn(vehicleState, key)) {
                        const coordinates = {
                            lat: location.latitude,
                            lng: location.longitude,
                        };
                        return (
                            <Marker
                                key={index}
                                position={coordinates}
                                icon={{
                                    url: getVehicleIcon(vehicleType),
                                    scaledSize: scaledSize,
                                    anchor: anchor,
                                    rotation: 180,
                                }}
                                // clusterer={props.clusterer}
                                onClick={() => {
                                    if (activeMarker !== key) {
                                        setActiveMarker(key);
                                        setGoogleAddress('Fetching...');
                                        setDistanceFromLastAddress(null);
                                        setInfoWindowOpen(true);
                                        setAddress(coordinates?.lat, coordinates?.lng);
                                    } else {
                                        if (!infoWindowOpen) {
                                            setInfoWindowOpen(true);
                                            setGoogleAddress('Fetching...');
                                            setDistanceFromLastAddress(null);
                                            setAddress(coordinates?.lat, coordinates?.lng);
                                        }
                                    }
                                }}
                            />
                        );
                    }
                });
            }
        };
    */

	function calculateAccuracyCircle(
		maxPDOP: number,
		minPDOP: number,
		maxSatellites: number,
		minSatellites: number,
		currentPDOP: number,
		currentSatellites: number
	) {
		function calculateRadius(pdop: number, satellites: number) {
			return (
				(((200 * (pdop - minPDOP)) / (maxPDOP - minPDOP)) * (maxSatellites - minSatellites)) /
				(satellites - minSatellites)
			);
		}

		return calculateRadius(currentPDOP, currentSatellites);
		// return accuracyCircleRadius;
	}

	const AccuracyCircle = () => {
		//constants value
		const maxPDOP = 4;
		const minPDOP = 0;
		const maxSatellites = 24;
		const minSatellites = 0;

		const vehicleLocation = activeMarker ? vehicleState[activeMarker]?.location : null;

		if (vehicleLocation) {
			//current value
			const currentPDOP = vehicleLocation?.gnssPDOP;
			const currentSatellites = vehicleLocation?.satellites;
			const currentVehicleStatus = activeMarker ? vehicleState[activeMarker]?.location?.ignitionStatus : '';

			//state to maintains steps wise update of data
			const [radius, setRadius] = useState(0);
			const [targetRadius, setTargetRadius] = useState(0);
			const stepSize = 1;

			//whenever currentPop and currentSatellites value changes we are updating the state of TargetRadius
			useEffect(() => {
				if (currentPDOP && currentSatellites) {
					const newRadius = calculateAccuracyCircle(
						maxPDOP,
						minPDOP,
						maxSatellites,
						minSatellites,
						currentPDOP,
						currentSatellites
					);

					setTargetRadius(newRadius);
				}
			}, [currentPDOP, currentSatellites]);

			//logic to create smooth transition
			useEffect(() => {
				const updateRadius = () => {
					if (radius !== targetRadius) {
						if (Math.abs(targetRadius - radius) < stepSize) {
							setRadius(targetRadius);
						} else {
							if (targetRadius > radius) {
								setRadius(radius + stepSize);
							} else {
								setRadius(radius - stepSize);
							}
						}
					}
				};

				const intervalId = setInterval(updateRadius, 200);

				return () => clearInterval(intervalId);
			}, [radius, targetRadius]);

			if (
				currentPDOP &&
				currentSatellites &&
				radius !== 0 &&
				!currentVehicleStatus &&
				selectedVehiclesFromSidebar.length === 1
			) {
				return (
					<Circle
						center={{
							lat: vehicleLocation?.latitude,
							lng: vehicleLocation?.longitude,
						}}
						radius={radius}
						options={{
							strokeColor: '#ff8243',
							strokeWeight: radius === targetRadius ? 2 : 0,
							fillColor: '#ff8243',
							fillOpacity: 0.2,
						}}
					/>
				);
			} else {
				return <></>;
			}
		}
	};

	if (loadError) {
		// handleError(loadError);
	}
	if (isLoaded) {
		return (
			<Container>
				<>
					<GoogleMap
						mapContainerStyle={GOOGLE_MAP_DEFAULT_STYLES}
						center={GOOGLE_MAP_DEFAULT_CENTER}
						zoom={GOOGLE_MAP_DEFAULT_ZOOM}
						mapTypeId={mapType}
						options={{
							...GOOGLE_MAP_OPTIONS,
						}}
						onDrag={() => {
							if (!shouldAdjustMap) {
								setShouldAdjustMap(!shouldAdjustMap);
							}
						}}
						onZoomChanged={() => {
							if (!shouldAdjustMap) {
								setShouldAdjustMap(!shouldAdjustMap);
							}
						}}
						onLoad={(map) => {
							setMapInstance(map);
						}}
						onClick={() => {
							if (activeMarker) {
								setInfoWindowOpen(false);
							}
						}}>
						{traffic && <TrafficLayer />}
						{shouldAdjustMap && (
							<ShouldAdjustMap>
								<Button
									icon="crosshairs"
									onClick={() => {
										setBounds();
									}}
								/>
							</ShouldAdjustMap>
						)}
						{selectedVehiclesFromSidebar !== null &&
							selectedVehiclesFromSidebar.map((key, index) => {
								const location = vehicleState[key]?.location;
								const vehicleType = vehicleDetails.get(key)?.data?.type;

								if (vehicleType && location && Object.hasOwn(vehicleState, key)) {
									const coordinates = {
										lat: location.latitude,
										lng: location.longitude,
									};
									return (
										<Marker
											key={index}
											position={coordinates}
											icon={{
												url: getVehicleIcon(vehicleType),
												scaledSize: scaledSize,
												anchor: anchor,
												rotation: 180,
											}}
											// clusterer={props.clusterer}
											onClick={() => {
												if (activeMarker !== key) {
													setActiveMarker(key);
													setGoogleAddress('Fetching...');
													setDistanceFromLastAddress(null);
													setInfoWindowOpen(true);
													setAddress(coordinates?.lat, coordinates?.lng);
												} else {
													if (!infoWindowOpen) {
														setInfoWindowOpen(true);
														setGoogleAddress('Fetching...');
														setDistanceFromLastAddress(null);
														setAddress(coordinates?.lat, coordinates?.lng);
													}
												}
											}}
										/>
									);
								} else return <></>;
							})}

						{/*	<MarkerClusterer>
							{(clusterer) => {
								return (
									selectedVehiclesFromSidebar !== null &&
									selectedVehiclesFromSidebar.map((key: number, index: number) => {
										const vehicleIcon = vehicleDetails.has(key) ? vehicleDetails.get(key).data.type : '';
										return (
											Object.hasOwn(vehicleState, key) && (
												<Marker
													key={index}
													position={{
														lat: parseFloat(vehicleState[key].location?.latitude),
														lng: parseFloat(vehicleState[key].location?.longitude),
													}}
													icon={{
														url: getVehicleIcon(vehicleIcon),
														scaledSize: scaledSize,
														anchor: anchor,
														rotation: 180,
													}}
													clusterer={clusterer}
													onClick={() => {
														if (activeMarker !== key) {
															setActiveMarker(key);
															setGoogleAddress('Fetching...');
															setDistanceFromLastAddress(null);
															setInfoWindowOpen(true);
															setAddress(
																parseFloat(vehicleState[key].location?.latitude),
																parseFloat(vehicleState[key].location?.longitude)
															);
														} else {
															if (!infoWindowOpen) {
																setInfoWindowOpen(true);
																setGoogleAddress('Fetching...');
																setDistanceFromLastAddress(null);
																setAddress(
																	parseFloat(vehicleState[key].location?.latitude),
																	parseFloat(vehicleState[key].location?.longitude)
																);
															}
														}
													}}
												/>
											)
										);
									})
								);
							}}
						</MarkerClusterer>*/}
						{/*<MarkerGroup />*/}

						{/*<VehicleInfoWindow />*/}
						{activeMarker ? (
							(() => {
								const activeVehicleState = vehicleState?.[activeMarker]?.location;

								if (
									activeMarker &&
									infoWindowOpen &&
									// Object.hasOwnProperty(vehicleState, activeMarker) &&
									activeVehicleState
								) {
									return (
										<InfoWindow
											position={{
												lat: activeVehicleState?.latitude,
												lng: activeVehicleState?.longitude,
											}}
											options={{
												pixelOffset: pixelOffset,
											}}
											onCloseClick={() => {
												setInfoWindowOpen(false);
											}}>
											<CustomInfoWindow
												id={activeMarker}
												coordinates={{
													latitude: activeVehicleState?.latitude,
													longitude: activeVehicleState?.longitude,
												}}
												onVehicleImmobilize={() => {
													openImmobilizerModal();
													setVehicleId(activeMarker);
												}}
												onVehicleMobilize={() => {
													openMobilizerModal();
													setVehicleId(activeMarker);
												}}
												onVehicleKeyLockClick={() => {
													setOpenKeyLockModal(true);
													setVehicleId(activeMarker);
												}}
												onVehicleKeyUnlockClick={() => {
													setOpenKeyUnlockModal(true);
													setVehicleId(activeMarker);
												}}
												onIgnitionOnClick={() => {
													setOpenIgnitionOnModal(true);
													setVehicleId(activeMarker);
												}}
												onIgnitionOffClick={() => {
													setOpenIgnitionOffModal(true);
													setVehicleId(activeMarker);
												}}
												onWheelLockOnClick={() => {
													setOpenWheellockModal(true);
													setWheelLockOnClicked(true);
													setVehicleId(activeMarker);
												}}
												onWheelLockOffClick={() => {
													setOpenWheellockModal(true);
													setWheelLockOnClicked(false);
													setVehicleId(activeMarker);
												}}
												liveStatus
												// driver={true}
												liveAddress={true}
												liveOdometer={true}
												liveSpeed
												liveBattery
												liveTimestampUpdate
												distanceFromLastAddress={distanceFromLastAddress}
												googleAddress={googleAddress}
												onRefreshAddress={refreshAddress}
												distance={false}
												relayIconShow={true}
												liveFuel={true}
												getVehiclesforDropdown={getVehiclesforDropdown}
											/>
										</InfoWindow>
									);
								} else {
									return <></>;
								}
							})()
						) : (
							<></>
						)}

						{selectedVehiclesFromSidebar &&
							selectedVehiclesFromSidebar.map((vehicle: number, index: number) => {
								const coordinates = polyLines[vehicle];
								if (!coordinates) {
									return null; // or handle the case when coordinates are undefined
								}
								const polylineCoordinates = coordinates.length > 1 ? coordinates.slice(1) : [];
								return (
									<Polyline
										key={index}
										path={polylineCoordinates}
										options={{
											strokeColor: THEME.COLORS.multiplePolyLinesTrack[index % 7],
											strokeWeight: 4,
											icons: [
												{
													icon: {
														path: 'M -2,2 0,-4 2,2 0,0 z',
														strokeColor: THEME.COLORS.polylineMarkerStroke,
														strokeWeight: 2,
														fillColor: THEME.COLORS.polylineMarkerFill,
														fillOpacity: 1,
													},
													offset: '5%',
													repeat: '200px',
												},
											],
										}}
									/>
								);
							})}

						{shownGeoFences &&
							geoFences &&
							geoFences.map((geofence, index: number) => {
								return (
									<Circle
										key={index}
										center={{
											lat: geofence.latitude,
											lng: geofence.longitude,
										}}
										radius={geofence.radius}
										options={{
											strokeColor: 'dodgerblue',
											strokeWeight: 2,
											fillColor: 'dodgerblue',
											fillOpacity: 0.1,
										}}
									/>
								);
							})}

						<AccuracyCircle />

						<ControlsContainer>
							<Popup
								content={getPopupInfo()}
								position="right center"
								offset={[0, 5]}
								basic
								on="hover"
								hoverable
								style={{
									width: '5em',
									height: '5em',
									padding: '0',
									paddingTop: '6px',
									borderRadius: '8px',
								}}
								trigger={
									<ActiveMapTypes
										// activeType={mapType}
										onClick={() => setMapTypeLocal(`${mapType === 'roadmap' ? 'hybrid' : 'roadmap'}`)}>
										{mapType === 'roadmap' ? (
											<img src={HybridImage} alt="Hybrid" />
										) : (
											<img src={RoadmapImage} alt="RoadMap" />
										)}
									</ActiveMapTypes>
								}
							/>
						</ControlsContainer>
					</GoogleMap>
				</>

				<Transition
					visible={!selectedVehiclesFromSidebar || selectedVehiclesFromSidebar.length === 0}
					animation="scale"
					duration={500}>
					<Placeholder>
						<span>Please Select at least 1 vehicle.</span>
					</Placeholder>
				</Transition>

				{/*No Data*/}
				<Transition
					visible={
						selectedVehiclesFromSidebar.length !== 0 &&
						!checkLocationDataAvailable(selectedVehiclesFromSidebar, vehicleState)
					}
					animation="scale"
					duration={500}>
					<Placeholder>
						<span>No Data</span>
					</Placeholder>
				</Transition>

				{/*Share Tracking Link Modal*/}
				{trackingLinkModalOpen && (
					<Modal
						size="small"
						open={trackingLinkModalOpen}
						onClose={() => {
							closeTrackingLinkModal();
							cleanShareTrackingLinkOperation();
						}}>
						<Modal.Header>Share Tracking Link</Modal.Header>
						<Modal.Content>
							<Form>
								<Form.Group widths="equal">
									<Form.Field disabled={trackingLinkViewModelOpen}>
										<label>Phone/Email</label>
										<Dropdown
											placeholder="Add Multiple by hitting space"
											multiple
											search
											selection
											options={emailsPhonesOptions}
											value={selectedEmailPhone}
											allowAdditions
											closeOnChange={true}
											onAddItem={(event, data) => {
												const value = data.value as string;

												if (value && value.trim()) {
													const trimmedValue = value.trim();

													// Check if the value already exists in selectedEmailPhone
													if (!selectedEmailPhone.includes(trimmedValue)) {
														setEmailsPhonesOptions([
															...emailsPhonesOptions,
															{
																key: trimmedValue,
																text: trimmedValue,
																value: trimmedValue,
															},
														]);
														setSelectedEmailPhone([...selectedEmailPhone, trimmedValue]);
													}

													setSearchQuery('');
												}
											}}
											searchQuery={searchQuery}
											onSearchChange={(event, data) => {
												setSearchQuery(data.searchQuery);
											}}
											onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
												if (event.charCode === 32 && searchQuery.trim()) {
													setEmailsPhonesOptions([
														...emailsPhonesOptions,
														{
															key: searchQuery.trim(),
															text: searchQuery.trim(),
															value: searchQuery.trim(),
														},
													]);
													setSelectedEmailPhone([...selectedEmailPhone, searchQuery.trim()]);
													setSearchQuery('');
												}
											}}
											/*onBlur={() => {
                                                if (searchQuery.trim()) {
                                                    setEmailsPhonesOptions([
                                                        ...emailsPhonesOptions,
                                                        {
                                                            key: searchQuery.trim(),
                                                            text: searchQuery.trim(),
                                                            value: searchQuery.trim(),
                                                        },
                                                    ]);
                                                    setSelectedEmailPhone([...selectedEmailPhone, searchQuery.trim()]);
                                                    setSearchQuery('');
                                                } else {
                                                    setSearchQuery('');
                                                }
                                            }}*/
											/*	onBlur={(event) => {
                                                    // Check if the related target (where the focus is going) is within the dropdown
                                                    const dropdownElement = event.currentTarget;
                                                    const isWithinDropdown = dropdownElement.contains(event.relatedTarget);

                                                    if (!isWithinDropdown && searchQuery.trim()) {
                                                        setEmailsPhonesOptions([
                                                            ...emailsPhonesOptions,
                                                            {
                                                                key: searchQuery.trim(),
                                                                text: searchQuery.trim(),
                                                                value: searchQuery.trim(),
                                                            },
                                                        ]);
                                                        setSelectedEmailPhone([...selectedEmailPhone, searchQuery.trim()]);
                                                        setSearchQuery('');
                                                    } else {
                                                        setSearchQuery('');
                                                    }
                                                }}*/
											onChange={(event, { value }) => {
												setSelectedEmailPhone(value as string[]);
											}}
											renderLabel={(label) => {
												const valueAsString = String(label.value);
												return {
													color: IsMobileNumberValid(valueAsString) || IsEmailValid(valueAsString) ? 'green' : 'red',
													content: `${valueAsString}`,
													icon: IsMobileNumberValid(valueAsString)
														? 'phone'
														: IsEmailValid(valueAsString)
														  ? 'at'
														  : 'ban',
													border: '1px solid red',
												};
											}}
										/>
									</Form.Field>
								</Form.Group>
								<Form.Group widths="equal">
									<Form.Field disabled={trackingLinkViewModelOpen}>
										<label>Quantity of Time</label>
										<Input value={quantityOfTime} onChange={(e) => setQuantityOfTime(e.target.value)} />
									</Form.Field>
									<Form.Field disabled={trackingLinkViewModelOpen}>
										<label>Unit of Time</label>
										<Dropdown
											selection
											value={unitOfTime}
											options={EXPIRY_UNIT_OF_TIME_OPTIONS}
											onChange={(e, data: DropdownProps) => {
												if (data?.value) {
													if (EXPIRY_UNIT_OF_TIME_OPTIONS.some((option) => option.value === data.value)) {
														setUnitOfTime(data.value as string); // Cast data.value to string
													}
												}
											}}
										/>
									</Form.Field>
								</Form.Group>

								<Form.Group widths="equal">
									<Form.Field disabled={trackingLinkViewModelOpen}>
										<label>Select Vehicles</label>
										<Dropdown
											placeholder="Select Vehicles"
											fluid
											search
											selection
											multiple
											value={selectedVehicles}
											options={dropdownVehicles}
											onChange={(e, data: DropdownProps) => {
												if (data?.value) {
													setSelectedVehicles(
														Array.isArray(data.value)
															? [...new Set(data.value.map((item) => Number(item)))]
															: [Number(data.value)]
													);
												}
											}}
										/>
									</Form.Field>
								</Form.Group>
								{trackingLinkViewModelOpen && (
									<Form.Group widths="equal">
										<Form.Field>
											<label> Your Tracking Link Is </label>
											<Input
												action={{
													color: 'teal',
													labelPosition: 'right',
													icon: 'copy',
													content: 'Copy',
													onClick: () => navigator.clipboard.writeText(trackingLink),
												}}
												value={trackingLink}
											/>
										</Form.Field>
									</Form.Group>
								)}
							</Form>
						</Modal.Content>

						<Modal.Actions>
							{trackingLinkViewModelOpen ? (
								<>
									<Button
										primary
										onClick={() => {
											closeTrackingLinkModal();
											closeTrackingLinkViewModal();
											cleanShareTrackingLinkOperation();
										}}>
										Close
									</Button>
								</>
							) : (
								<>
									<Button
										disabled={trackingLinkViewModelOpen}
										color="black"
										onClick={() => {
											closeTrackingLinkModal();
											cleanShareTrackingLinkOperation();
										}}>
										Cancel
									</Button>
									<Button
										disabled={trackingLinkViewModelOpen}
										primary
										onClick={shareTrackingLink}
										loading={buttonLoading}>
										Share
									</Button>
								</>
							)}
						</Modal.Actions>
					</Modal>
				)}

				{/*Relay Modal*/}
				{immobilizerModalOpen && (
					<Modal size="small" open={immobilizerModalOpen} onClose={closeImmobilizerModal}>
						<Modal.Header>Immobilize Vehicle</Modal.Header>
						<Modal.Content>
							<p>Are you sure you want to immobilize this vehicle ?</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="black" onClick={closeImmobilizerModal}>
								No
							</Button>
							<Button negative onClick={() => toggleCommands('relay', true)} loading={buttonLoading}>
								Yes
							</Button>
						</Modal.Actions>
					</Modal>
				)}

				{/*Mobilizer Modal*/}
				{mobilizerModalOpen && (
					<Modal size="tiny" open={mobilizerModalOpen} onClose={closeMobilizerModal}>
						<Modal.Header>Mobilize Vehicle</Modal.Header>
						<Modal.Content>
							<p>Are you sure you want to mobilize this vehicle ?</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="black" onClick={closeMobilizerModal}>
								No
							</Button>
							<Button primary onClick={() => toggleCommands('relay', false)} loading={buttonLoading}>
								Yes
							</Button>
						</Modal.Actions>
					</Modal>
				)}
				{openKeyLockModal && (
					<Modal size="small" open={openKeyLockModal} onClose={() => setOpenKeyLockModal(false)}>
						<Modal.Header>Lock Vehicle&apos;s Key</Modal.Header>
						<Modal.Content>
							<p>Are you sure you want to Lock this Vehicle&apos;s Key?</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="black" onClick={() => setOpenKeyLockModal(false)}>
								No
							</Button>
							<Button negative onClick={() => toggleCommands('keylock', true)}>
								Yes
							</Button>
						</Modal.Actions>
					</Modal>
				)}
				{openKeyUnlockModal && (
					<Modal size="small" open={openKeyUnlockModal} onClose={() => setOpenKeyUnlockModal(false)}>
						<Modal.Header>Unlock Vehicle&apos;s Key</Modal.Header>
						<Modal.Content>
							<p>Are you sure you want to unlock this Vehicle&apos;s key?</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="black" onClick={() => setOpenKeyUnlockModal(false)}>
								No
							</Button>
							<Button negative onClick={() => toggleCommands('keylock', false)}>
								Yes
							</Button>
						</Modal.Actions>
					</Modal>
				)}

				{openIgnitionOnModal && (
					<Modal size="small" open={openIgnitionOnModal} onClose={() => setOpenIgnitionOnModal(false)}>
						<Modal.Header>Turn Ignition On</Modal.Header>
						<Modal.Content>
							<p>Are you sure you want to turn on this vehicle&apos;s ignition?</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="black" onClick={() => setOpenIgnitionOnModal(false)}>
								No
							</Button>
							<Button negative onClick={() => toggleCommands('ignition', true)}>
								Yes
							</Button>
						</Modal.Actions>
					</Modal>
				)}

				{openIgnitionOffModal && (
					<Modal size="small" open={openIgnitionOffModal} onClose={() => setOpenIgnitionOffModal(false)}>
						<Modal.Header>Turn Ignition Off</Modal.Header>
						<Modal.Content>
							<p>Are you sure you want to turn off this vehicle&apos;s ignition?</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="black" onClick={() => setOpenIgnitionOffModal(false)}>
								No
							</Button>
							<Button negative onClick={() => toggleCommands('ignition', false)}>
								Yes
							</Button>
						</Modal.Actions>
					</Modal>
				)}

				{openWheellockModal && (
					<Modal
						size="small"
						open={openWheellockModal}
						onClose={() => setOpenWheellockModal(false)}
						style={{ zIndex: '11' }}>
						<Modal.Header>{`Turn Wheel lock ${wheelLockOnClicked ? 'on' : 'off'}`}</Modal.Header>
						<Modal.Content>
							<p>{`Are you sure you want to ${
								wheelLockOnClicked ? 'turn on' : 'turn off'
							} this vehicle's wheel lock?`}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button
								color="black"
								onClick={() => {
									setOpenWheellockModal(false);
								}}>
								No
							</Button>
							<Button
								negative
								onClick={() => {
									wheelLockOnClicked ? toggleCommands('wheellock', true) : toggleCommands('wheellock', false);
								}}>
								Yes
							</Button>
						</Modal.Actions>
					</Modal>
				)}

				{/* props.trackingLinkViewModelOpen && (
						<Modal size="tiny" open={props.trackingLinkViewModelOpen} onClose={closeTrackingLinkViewModal}>
							<Modal.Header>Tracking Link</Modal.Header>
							<Modal.Content>
								<p style={{wordBreak: 'break-word'}}>{trackingLink}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button
									positive
									onClick={() => {
										navigator.clipboard.writeText(trackingLink);
									}}>
									Copy
								</Button>
								<Button primary onClick={closeTrackingLinkViewModal}>
									Close
								</Button>
							</Modal.Actions>
						</Modal>
					)*/}
			</Container>
		);
	} else return <TrackNerdLoader />;
};
export default Location;
