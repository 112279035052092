import React, { useEffect, useState } from 'react';
import Location from './location/Location';
import Trips from './trips/Trips';
import Track from './track/Track';
// import Power from "./power/Power";
import VehicleEvents from './events/VehicleEvents';
import Summary from './summary/Summary';
import { MainContainer } from './MainStyles';
import { Tab } from 'semantic-ui-react';
import BatteryRecharge from './battery/BatteryRecharge';
// import Power2 from './power2/Power2';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/RootState';
import { TabProps } from 'semantic-ui-react/dist/commonjs/modules/Tab/Tab';
// import {dispatch} from "../../../../../helper/view specific/store";

const findIndex = (tabName: string): number => {
	switch (tabName) {
		case 'Live':
			return 0;
		case 'Trips':
			return 1;
		case 'Track':
			return 2;
		case 'Events':
			return 3;
		case 'Summary':
			return 4;
		case 'Battery':
			return 5;
		default:
			return 0;
	}
};

function Main() {
	const tab = useSelector((state: RootState) => state.vehicle.tab);

	const [localState, setLocalState] = useState(findIndex(tab));

	useEffect(() => {
		const tabIndex = findIndex(tab);
		setLocalState(tabIndex);
	}, [tab]);

	const panes = [
		{
			menuItem: {
				key: 'Live',
				icon: 'map marker alternate',
				content: 'Live',
			},
			render: () => (
				<Tab.Pane attached={false}>
					<Location />
				</Tab.Pane>
			),
		},

		{
			menuItem: {
				key: 'Trips',
				icon: 'flag checkered',
				content: 'Trips',
			},
			render: () => (
				<Tab.Pane attached={false}>
					<Trips />
				</Tab.Pane>
			),
		},
		{
			menuItem: {
				key: 'Track',
				icon: 'map marker alternate',
				content: 'Track',
			},
			render: () => (
				<Tab.Pane attached={false}>
					<Track />
				</Tab.Pane>
			),
		},
		// {
		//   menuItem: {
		//     key: "Fuel Report",
		//     icon: "tint",
		//     content: "Fuel"
		//   },
		//   render: () => (
		//     <Tab.Pane attached={false}>
		//       <Fuel />
		//     </Tab.Pane>
		//   )
		// }
		// {
		//   menuItem: {
		//     key: 'Refuel & Drain',
		//     icon: 'tint',
		//     content: 'Refuel & Drain',
		//   },
		//   render: () => (
		//     <Tab.Pane attached={false}>
		//       <Refueling />
		//     </Tab.Pane>
		//   ),
		// },
		// {
		//   menuItem: {
		//     key: 'Consignments',
		//     icon: 'dolly flatbed',
		//     content: 'Consignments',
		//   },
		//   render: () => (
		//     <Tab.Pane attached={false}>
		//       <Consignments />
		//     </Tab.Pane>
		//   ),
		// },
	];
	//

	/*	if (process.env.REACT_APP_BUILD_ENVIRONMENT === 'development') {
            panes.push({
                menuItem: {
                    key: 'Power',
                    icon: 'battery half',
                    content: 'Power',
                },
                render: () => (
                    <Tab.Pane attached={false}>
                        <Power2 />
                    </Tab.Pane>
                ),
            });
        }*/

	panes.push(
		{
			menuItem: {
				key: 'Events',
				icon: 'info circle',
				content: 'Events',
			},
			render: () => (
				<Tab.Pane attached={false}>
					<VehicleEvents />
				</Tab.Pane>
			),
		},
		{
			menuItem: {
				key: 'Summary',
				icon: 'chart bar',
				content: 'Summary',
			},
			render: () => (
				<Tab.Pane attached={false}>
					<Summary />
				</Tab.Pane>
			),
		},
		{
			menuItem: {
				key: 'Battery Analytics',
				icon: 'battery full',
				content: 'Battery Analytics',
			},
			render: () => (
				<Tab.Pane attached={false}>
					<BatteryRecharge />
				</Tab.Pane>
			),
		}
	);

	const handleTabChange = (event: React.MouseEvent<HTMLDivElement>, data: TabProps) => {
		const newIndex = data.activeIndex;
		if (typeof newIndex === 'number') setLocalState(newIndex);
		// dispatch("SELECTED_TAB",`${data.panes[data.activeIndex].menuItem.key}`);
	};

	const sidebarVisible = useSelector((state: RootState) => state.ui.sidebarVisible);

	return (
		<MainContainer sidebarVisible={sidebarVisible}>
			<Tab
				onTabChange={handleTabChange}
				// defaultActiveIndex={localState}
				activeIndex={localState}
				// activeIndex={findIndex(tab)}
				menu={{
					pointing: true,
					secondary: true,
				}}
				panes={panes}
				style={{ height: '100%', width: '100%' }}
			/>
		</MainContainer>
	);
}

export default Main;
