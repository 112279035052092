import styled from 'styled-components';

export const MainContainer = styled.section`
	flex-grow: 1;
	height: 100%;

	position: relative;

	.ui.menu {
		height: 3.25em;

		margin: 0;

		box-shadow: none;
		border-bottom: 1px solid ${(props) => props.theme.COLORS.border};
		border-radius: 0;
	}

	.ui.segment {
		height: calc(100% - 3.25em);

		margin: 0;
		padding: 0;

		box-shadow: none;
		border-radius: 0;
		border: none;
	}
`;

export const ControlsContainer = styled.section`
	position: absolute;
	top: 1em;
	right: 1em;
	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Controls = styled.section`
	box-sizing: border-box;
	padding: 0.25em;

	display: flex;
	align-items: center;

	background: #fff;
	border-radius: 5px;
	box-shadow: rgb(0 0 0 / 16%) 0 1px 4px;
`;

export const SearchBoxContainer = styled.section`
	position: absolute;
	top: 1em;
	left: 1em;
	z-index: 10;

	box-sizing: border-box;
	padding: 0.5em;

	background: #fff;
	border-radius: 5px;
	box-shadow: rgb(0 0 0 / 16%) 0 1px 4px;

	input {
		width: 24em;
	}
`;

export const Block = styled.section`
	margin: 0.25em;
`;

export const HorizontalBlock = styled.section`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
