import React, { useEffect, useRef, useState } from 'react';
import { postData } from '../../../../../helper/services/axios';
import { BATTERY_RECHARGE_SUMMARY, EXPORT_API } from '../../../../../constants/api';
import { handleError } from '../../../../../helper/view specific/messaging';
import { createBatteryRechargeDTO } from '../../../../../dtos/batteryRecharge';
import { Button, Dropdown, Icon, Pagination, Table } from 'semantic-ui-react';
import { Expander } from '../../../../../components/STYLED/Expander';
import { EXPORT_FILE_EXTENSION, PAGE_COUNT_OPTIONS } from '../../../../../constants/constants';
import TrackNerdLoader from '../../../../../components/loader/Loader';
import { Container, PageSelector, TableContainer } from '../summary/SummaryStyles';
import { THEME } from '../../../../../constants/Theme';
import PlaceHolder from '../../../../../components/placeHolder/dynamic/PlaceHolder';
import NoVehicleSelection from '../../../../../components/placeHolder/static/noVehicleSelection/NoVehicleSelection';
import { CategoryFooter } from '../../../../../components/STYLED/CategoryFooter';
import { handleDataSizeLimitChange } from '../../../../../utils/common';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import fileDownload from 'js-file-download';
import { CategoryHeader } from '../../../../../components/STYLED/CategoryHeader';
import Store from '../../../../../redux/store';
import { trackMixpanelEvent } from '../../../../../helper/services/mixpanel';
import { RootState } from '../../../../../redux/RootState';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { BatteryRechargeData } from '../../../../../types/batteryRecharge';
import { dispatch, dispatchNoPayload } from '../../../../../helper/view specific/store';

export default function BatteryRecharge() {
	const selectedVehicles = useSelector((state: RootState) => state.sidebar.selectedVehicles);
	const selectedVehiclesNumbers = useSelector((state: RootState) => state.sidebar.selectedVehiclesNumbers);
	const selectedVehiclesFromSidebar = useSelector((state: RootState) => state.sidebar.selectedVehicles);
	const initialDateState = useSelector((state: RootState) => state.dates.initialDate);
	const finalDateState = useSelector((state: RootState) => state.dates.finalDate);
	const dataSizeLimitState = useSelector((state: RootState) => state.ui.dataSizeLimit);

	const initialDate = useSelector((state: RootState) => state.dates.initialDate);
	const finalDate = useSelector((state: RootState) => state.dates.finalDate);
	const dataSizeLimit = useSelector((state: RootState) => state.ui.dataSizeLimit);
	const activePage = useSelector((state: RootState) => state.ui.pageNumber);
	const batteryDataStore = useSelector((state: RootState) => state.reduceApi.batteryDataStore);

	const dataSize = useRef(0);
	const firstRenderRef = useRef(true);

	const [loading, setLoading] = useState<boolean>(false);
	const [initialLoading, setInitialLoading] = useState<boolean>(false);
	const [rechargeSummary, setRechargeSummary] = useState<BatteryRechargeData[]>([]);
	const [activeSort, setActiveSort] = useState<string>('timestamp');
	const [sortOrder, setSortOrder] = useState<string>('desc');
	const [fileExtension, setFileExtension] = useState<string>('pdf');

	useEffect(() => {
		Store.dispatch({
			type: 'SELECTED_TAB',
			payload: 'Summary',
		});

		if (batteryDataStore?.total) {
			dataSize.current = batteryDataStore?.total;
			getReduxData();
		} else {
			Store.dispatch({
				type: 'SET_PAGE_LIMIT',
				payload: 1,
			});
		}

		return () => {
			dispatchNoPayload('RESTORE_SIDEBAR_CONFIG');
			Store.dispatch({
				type: 'SELECTED_TAB',
				payload: 'Live',
			});
		};
	}, []);

	useEffect(() => {
		if (selectedVehicles && selectedVehicles.length) {
			fetchData(activePage);
			trackMixpanelEvent('battery_recharge_report', {
				vehicles: selectedVehiclesNumbers,
				startTime: initialDate,
				endTime: finalDate,
			});
		}

		if (!firstRenderRef.current) {
			if (selectedVehiclesFromSidebar && selectedVehiclesFromSidebar.length) {
				fetchData(activePage);
			}
		} else {
			if (selectedVehiclesFromSidebar && selectedVehiclesFromSidebar.length) {
				if (!batteryDataStore.total) {
					fetchData(1);
				}
			}
			firstRenderRef.current = false;
		}

		if (selectedVehiclesFromSidebar.length === 0) {
			setInitialLoading(false);
		}
	}, [
		selectedVehiclesNumbers,
		selectedVehiclesFromSidebar,
		initialDateState,
		finalDateState,
		dataSizeLimitState,
		activeSort,
		sortOrder,
	]);

	const getReduxData = () => {
		setRechargeSummary(batteryDataStore.data);
		Store.dispatch({
			type: 'SET_PAGE_LIMIT',
			payload: batteryDataStore.activePage,
		});
	};

	const fetchData = (pageNumber: number) => {
		setLoading(true);
		setInitialLoading(true);
		// let query = `${params}&pagecount=${dataSizeLimit}&pagenumber=${activePage}`;
		const endTime = finalDate;
		const startTime = initialDate;
		const requestBody = {
			vehicleIds: selectedVehicles,
			startTime: startTime,
			endTime: endTime,
			pagecount: dataSizeLimit,
			pagenumber: pageNumber,
		};

		postData(BATTERY_RECHARGE_SUMMARY, '', requestBody)
			.then((response) => {
				dataSize.current = response?.data?.count || 0;
				const batteryDTOData = createBatteryRechargeDTO(response.data.data);

				const batteryReduxData = {
					total: response.data.count,
					data: batteryDTOData,
					activePage: pageNumber,
				};
				setRechargeSummary(batteryDTOData);
				if (batteryDTOData !== null) dispatch('BATTERY_DATA_STORE', batteryReduxData);

				setLoading(false);
				setInitialLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				setInitialLoading(false);
				handleError('BatteryRecharge.tsx => fetchData()', error);
			});
	};

	const sort = (param: string) => {
		let activeSortTemp;
		let sortOrderTemp;
		if (activeSort !== param) {
			activeSortTemp = param;
			sortOrderTemp = 'asc';
		} else {
			if (sortOrder === 'desc') {
				activeSortTemp = param;
				sortOrderTemp = 'asc';
			} else {
				activeSortTemp = param;
				sortOrderTemp = 'desc';
			}
		}

		setActiveSort(activeSortTemp);
		setSortOrder(sortOrderTemp);
	};

	const exportAll = () => {
		// let query = getFilteredData();

		const endTime = finalDate;
		const startTime = initialDate;
		// query += `&fileType=${fileExtension}`;
		const body = {
			vehicleIds: selectedVehicles,
			startTime: startTime,
			endTime: endTime,
			fileType: fileExtension,
			sortBy: activeSort,
			orderBy: sortOrder,
		};

		trackMixpanelEvent('export_battery_recharge', {
			vehicles: selectedVehiclesNumbers,
			export: 'files_export',
		});

		postData(EXPORT_API, '/battery-recharge-summary', body)
			.then((response) => {
				fileDownload(
					response.data,
					`BatteryRechargeSummary_${moment(initialDate).format('DD-MM-YYYY')}_${moment(initialDate).format(
						'DD-MM-YYYY'
					)}.${fileExtension}`
				);
			})
			.catch((error) => {
				handleError('BatteryRecharge.tsx => exportAll()', error);
			});
	};

	return (
		<Container>
			<CategoryHeader>
				<Button
					primary
					disabled={loading}
					icon
					title="Refresh"
					onClick={() => {
						fetchData(activePage);
					}}>
					<Icon name="refresh" />
				</Button>
				<Expander />
				<Button.Group primary>
					<Button disabled={!rechargeSummary?.length} primary onClick={exportAll}>
						Export
					</Button>
					<Dropdown
						className="button icon"
						style={{ background: '#ffffff', color: '#1b3c71' }}
						disabled={!rechargeSummary?.length}
						floating
						trigger={<></>}
						options={EXPORT_FILE_EXTENSION}
						defaultValue={'pdf'}
						onChange={(e, { value }: DropdownProps) => {
							if (typeof value === 'string') setFileExtension(value);
						}}
					/>
				</Button.Group>
			</CategoryHeader>
			<TableContainer id="TableContainer">
				{initialLoading ? (
					<TrackNerdLoader />
				) : selectedVehiclesFromSidebar.length !== 0 ? (
					rechargeSummary && rechargeSummary.length !== 0 ? (
						<Table celled compact selectable striped unstackable>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell singleLine collapsing textAlign="center">
										Serial
									</Table.HeaderCell>
									<Table.HeaderCell
										singleLine
										textAlign="left"
										onClick={() => sort('vehicle')}
										style={{
											cursor: 'pointer',
											color: activeSort === 'vehicle' ? THEME.COLORS.theme : '',
											minWidth: '10em',
										}}>
										{activeSort === 'vehicle' ? (
											sortOrder === 'asc' ? (
												<Icon name="sort alphabet down" />
											) : (
												<Icon name="sort alphabet up" />
											)
										) : (
											<Icon name="sort" />
										)}
										Vehicle
									</Table.HeaderCell>
									<Table.HeaderCell
										singleLine
										textAlign="center"
										onClick={() => sort('timestamp')}
										style={{
											cursor: 'pointer',
											color: activeSort === 'timestamp' ? THEME.COLORS.theme : '',
										}}>
										{activeSort === 'timestamp' ? (
											sortOrder === 'asc' ? (
												<Icon name="sort alphabet down" />
											) : (
												<Icon name="sort alphabet up" />
											)
										) : (
											<Icon name="sort" />
										)}
										Time
									</Table.HeaderCell>
									<Table.HeaderCell
										singleLine
										textAlign="center"
										title={'Charge'}
										onClick={() => sort('chargedSoc')}
										style={{
											cursor: 'pointer',
											color: activeSort === 'chargedSoc' ? THEME.COLORS.theme : '',
										}}>
										{activeSort === 'chargedSoc' ? (
											sortOrder === 'asc' ? (
												<Icon name="sort alphabet down" />
											) : (
												<Icon name="sort alphabet up" />
											)
										) : (
											<Icon name="sort" />
										)}
										Charge
									</Table.HeaderCell>
									<Table.HeaderCell
										singleLine
										textAlign="center"
										title={'Final State of Charge'}
										onClick={() => sort('totalSoc')}
										style={{
											cursor: 'pointer',
											color: activeSort === 'totalSoc' ? THEME.COLORS.theme : '',
										}}>
										{activeSort === 'totalSoc' ? (
											sortOrder === 'asc' ? (
												<Icon name="sort alphabet down" />
											) : (
												<Icon name="sort alphabet up" />
											)
										) : (
											<Icon name="sort" />
										)}
										Final SOC
									</Table.HeaderCell>
									<Table.HeaderCell
										singleLine
										textAlign="center"
										onClick={() => sort('typeOfCharge')}
										title={'Type of Charge'}
										style={{
											cursor: 'pointer',
											color: activeSort === 'typeOfCharge' ? THEME.COLORS.theme : '',
										}}>
										{activeSort === 'typeOfCharge' ? (
											sortOrder === 'asc' ? (
												<Icon name="sort alphabet down" />
											) : (
												<Icon name="sort alphabet up" />
											)
										) : (
											<Icon name="sort" />
										)}
										Type of Charging
									</Table.HeaderCell>
									<Table.HeaderCell
										singleLine
										textAlign="center"
										title={'Charge duration in hh:mm:ss'}
										onClick={() => sort('chargeDuration')}
										style={{
											cursor: 'pointer',
											color: activeSort === 'chargeDuration' ? THEME.COLORS.theme : '',
										}}>
										{activeSort === 'chargeDuration' ? (
											sortOrder === 'asc' ? (
												<Icon name="sort alphabet down" />
											) : (
												<Icon name="sort alphabet up" />
											)
										) : (
											<Icon name="sort" />
										)}
										Charge Duration
									</Table.HeaderCell>
									<Table.HeaderCell
										singleLine
										textAlign="center"
										onClick={() => sort('energyDrawn')}
										title={'Energy Drawn'}
										style={{
											cursor: 'pointer',
											color: activeSort === 'energyDrawn' ? THEME.COLORS.theme : '',
										}}>
										{activeSort === 'energyDrawn' ? (
											sortOrder === 'asc' ? (
												<Icon name="sort alphabet down" />
											) : (
												<Icon name="sort alphabet up" />
											)
										) : (
											<Icon name="sort" />
										)}
										Energy Drawn
									</Table.HeaderCell>
									<Table.HeaderCell
										singleLine
										textAlign="center"
										title={'Charge Start Time'}
										onClick={() => sort('chargeStartTime')}
										style={{
											cursor: 'pointer',
											color: activeSort === 'chargeStartTime' ? THEME.COLORS.theme : '',
										}}>
										{activeSort === 'chargeStartTime' ? (
											sortOrder === 'asc' ? (
												<Icon name="sort alphabet down" />
											) : (
												<Icon name="sort alphabet up" />
											)
										) : (
											<Icon name="sort" />
										)}
										Charge Start Time
									</Table.HeaderCell>
									<Table.HeaderCell
										singleLine
										textAlign="center"
										title={'Charge End Time'}
										onClick={() => sort('chargeEndTime')}
										style={{
											cursor: 'pointer',
											color: activeSort === 'chargeEndTime' ? THEME.COLORS.theme : '',
										}}>
										{activeSort === 'chargeEndTime' ? (
											sortOrder === 'asc' ? (
												<Icon name="sort alphabet down" />
											) : (
												<Icon name="sort alphabet up" />
											)
										) : (
											<Icon name="sort" />
										)}
										Charge End Time
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{rechargeSummary.map((data, index) => {
									return (
										<Table.Row key={index}>
											<Table.Cell singleLine textAlign="center">
												{index + 1 + (activePage - 1) * dataSizeLimit}
											</Table.Cell>
											<Table.Cell
												title={data.registrationNumber}
												singleLine
												textAlign="left"
												style={{ minWidth: '10em' }}>
												{data.registrationNumber}
											</Table.Cell>
											<Table.Cell title={data.timestamp} singleLine textAlign="center">
												{data.timestamp}
											</Table.Cell>
											<Table.Cell singleLine textAlign="right">
												{data.chargedSoc} %
											</Table.Cell>
											<Table.Cell singleLine textAlign="right">
												{data.totalSoc} %
											</Table.Cell>
											<Table.Cell singleLine textAlign="right">
												{data.typeOfCharge}
											</Table.Cell>
											<Table.Cell singleLine textAlign="right">
												{data.chargeDuration}
											</Table.Cell>
											<Table.Cell singleLine textAlign="right">
												{data.energyDrawn} KWh
											</Table.Cell>
											<Table.Cell singleLine textAlign="right">
												{data.chargeStartTime}
											</Table.Cell>
											<Table.Cell singleLine textAlign="right">
												{data.chargeEndTime}
											</Table.Cell>
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
					) : (
						<PlaceHolder content={'No Data'} iconName={'cloud'} />
					)
				) : (
					<NoVehicleSelection />
				)}
			</TableContainer>
			<Expander />
			<CategoryFooter>
				<Expander />
				<PageSelector>
					<Dropdown
						fluid
						selection
						disabled={loading || !rechargeSummary?.length}
						defaultValue={dataSizeLimit}
						options={PAGE_COUNT_OPTIONS}
						onChange={async (e, data: DropdownProps) => {
							if (data?.value) {
								const numericValue = Number(data.value); // Convert to number
								if (!isNaN(numericValue)) {
									// Check if it's a valid number
									handleDataSizeLimitChange(numericValue);
								}
							}
						}}
					/>
				</PageSelector>
				<Pagination
					activePage={activePage}
					disabled={loading || !rechargeSummary?.length}
					ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
					firstItem={{ content: <Icon name="angle double left" />, icon: true }}
					lastItem={{ content: <Icon name="angle double right" />, icon: true }}
					nextItem={null}
					onPageChange={(e, { activePage }) => {
						if (typeof activePage === 'number') {
							Store.dispatch({
								type: 'SET_PAGE_LIMIT',
								payload: activePage,
							});
							fetchData(activePage);
						}
					}}
					prevItem={null}
					totalPages={Math.ceil(dataSize.current / dataSizeLimit)}
				/>
			</CategoryFooter>
		</Container>
	);
}
