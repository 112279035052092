import Store from '../../../redux/store';

import { USERS_API } from '../../../constants/api';
import { createDriversDTO, DriverList } from '../../../dtos/driver';
import { handleError } from '../../../helper/view specific/messaging';
import { getData } from '../../../helper/services/axios';

// export function fetchDrivers(params = "") {
//   return axios(USERS_API + params, {
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("token"),
//       "Content-Type": "application/json"
//     }
//   });
// }

// export function fetchUsers(params = "") {
//   return axios(USERS_API + params, {
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("token"),
//       "Content-Type": "application/json"
//     }
//   });
// }

export async function loadDrivers(param = '', dataSizeLimit = 25, pageNumber = 1) {
	let drivers: DriverList[] = [];
	Store.dispatch({
		type: 'DRIVERS_LOADING',
	});
	await getData(USERS_API, `?type[]=Driver&pagecount=${dataSizeLimit}&pagenumber=${pageNumber}${param}`)
		.then((response) => {
			drivers = createDriversDTO(response?.data?.data);
			Store.dispatch({
				type: 'FEED_DRIVERS',
				payload: drivers,
			});
			Store.dispatch({
				type: 'DRIVERS_SET_PAGE_COUNT',
				payload: response?.data?.count || 0,
			});
			Store.dispatch({
				type: 'DRIVERS_SET_PAGE_NUMBER',
				payload: pageNumber,
			});
			if (document.getElementById('CardContainer')) {
				const CardContainer = document.getElementById('CardContainer');
				if (CardContainer !== null) CardContainer.scrollTo(0, 0);
			}
		})
		.catch((error) => {
			Store.dispatch({
				type: 'DRIVERS_LOADED',
			});
			handleError('DriversMiddleware.tsx => loadDrivers()', error);
		});

	return drivers;
}

// Search
export function searchDriver(query: string) {
	return `&search=${query
		.trim()
		.split('+')
		.map((searchTerm) => `${searchTerm}`)}`;
}

// export function addDriver(query) {
//   return axios.post(USERS_API, query, {
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("token"),
//       "Content-Type": "application/json"
//     }
//   });
// }

// export function updateDriver(id, query) {
//   return axios.patch(USERS_API + `/${id}`, query, {
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("token"),
//       "Content-Type": "application/json"
//     }
//   });
// }

// export function assignVehicle(id, payload) {
//   return axios.patch(USERS_API + `/${id}`, payload, {
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("token"),
//       "Content-Type": "application/json"
//     }
//   });
// }

// export function removeVehicle(id) {
//   return axios.delete(USERS_API + `/${id}/vehicle`, {
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("token"),
//       "Content-Type": "application/json"
//     }
//   });
// }

// export function assignUser(id, payload) {
//   return axios.patch(DRIVERS_API + `/${id}/map-user`, payload, {
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("token"),
//       "Content-Type": "application/json"
//     }
//   });
// }

// export function removeUser(id) {
//   return axios.patch(
//     DRIVERS_API + `/${id}/unmap-user`,
//     {},
//     {
//       headers: {
//         Authorization: "Bearer " + localStorage.getItem("token"),
//         "Content-Type": "application/json"
//       }
//     }
//   );
// }

// export function deleteDriver(id) {
//   return axios.delete(DRIVERS_API + `/${id}`, {
//     headers: {
//       Authorization: "Bearer " + localStorage.getItem("token"),
//       "Content-Type": "application/json"
//     }
//   });
// }
