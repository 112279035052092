import { HEADER_HEIGHT } from '../../../../../../constants/constants';
import styled from 'styled-components';
import { THEME } from '../../../../../../constants/Theme';

interface styleProps {
	activeType: boolean;
}

interface ActiveMapTypesInterface {
	activeType?: string;
}

export const Container = styled.section`
	width: 100%;
	height: calc(100% - ${HEADER_HEIGHT});
	display: flex;

	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
`;

export const MapContainer = styled.section`
	flex-grow: 1;
	height: 100%;

	position: relative;

	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;

	div {
		border-bottom-left-radius: 5px;
	}
`;

export const RightContainer = styled.section`
	width: 30%;
	height: 100%;

	border-left: 1px solid #ddd;
`;

export const TableContainer = styled.section`
	width: 100%;
	height: 100%;

	box-sizing: border-box;
	margin-right: 0;

	overflow-y: auto;

	a,
	.viewAddress {
		text-overflow: ellipsis;
		overflow: hidden;

		color: black;

		&:hover {
			color: blue;
			text-decoration: underline;
		}
	}

	.ui.table th:first-child {
		width: 1em !important;
	}
`;

export const ControlsContainer = styled.section`
	position: absolute;
	bottom: 2.2em;
	left: 1em;
	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const MapTypesContainer = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 0 0.5em;
`;

export const MapTypesLabels = styled.section<styleProps>`
	font-size: 0.85em;
	font-weight: bold;

	color: ${(props) => (props.activeType ? `${THEME.COLORS.theme}` : 'black')};
`;

export const MapTypeControls = styled.button<styleProps>`
	width: 3em;
	height: 3em;

	box-sizing: border-box;
	padding: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 8px;
	border: 2px solid ${(props) => (props.activeType ? `${THEME.COLORS.theme}` : 'white')};

	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
		border-radius: 8px;

		object-fit: none;
	}
`;

export const ActiveMapTypes = styled.button<ActiveMapTypesInterface>`
	width: 5em;
	height: 5em;

	box-sizing: border-box;
	padding: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 8px;
	border: 2px solid white;

	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
		border-radius: 8px;

		object-fit: contain;
	}
`;

export const ShouldAdjustMap = styled.section`
	position: absolute;
	top: 9em;
	right: 0;

	.ui.icon.button {
		color: black !important;
		background-color: #fafafa !important;
	}
`;

export const Header = styled.section`
	width: 100%;

	display: flex;
	justify-content: flex-start;
	align-items: center;

	margin-left: 1em;
	font-weight: 700;
	font-size: 1.4em;
	color: ${THEME.COLORS.theme};
`;
