import styled from 'styled-components';

interface SidebarContainerProps {
	sidebarVisible?: boolean;
	onMouseDown?: () => void;
}

export const SidebarContainer = styled.section<SidebarContainerProps>`
	height: 100%;
	width: 24em;

	position: relative;
	z-index: 2;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	border-right: 1px solid ${(props) => props.theme.COLORS.border};

	transition: width 0.1s ease;
`;

export const ResizableHandle = styled.div`
	cursor: e-resize;
	width: 3px;
	height: 100%;
	background-color: #1b3c71;
	position: absolute;
	top: 0;
	right: -2.5px;
	opacity: 0;

	&:hover {
		opacity: 1;
	}
`;

export const MainContainer = styled.section`
	width: calc(100% - 24em);
	height: 100%;
	transition: all 0.25s;

	position: relative;

	.ui.menu {
		height: 3.25em;

		margin: 0;

		box-shadow: none;
		border-bottom: 1px solid ${(props) => props.theme.COLORS.border};
		border-radius: 0;
	}

	.ui.segment {
		height: calc(100% - 3.25em);

		margin: 0;
		padding: 0;

		box-shadow: none;
		border-radius: 0;
		border: none;
	}
`;
