// For AlphaNumeric
// export function IsAlphaNumeric(params) {
//   if (params !== null) return params ? /^[ a-z 0-9 ]+$/i.test(params) : true;
//   else return true;
// }
//
// // For Alphabetic
// export function IsAlphabetic(params) {
//   if (params !== null) return params ? /^[ a-z ]+$/i.test(params) : true;
//   else return true;
// }

// For Mobile Number
export function IsMobileNumberValid(params: string): boolean {
	if (params !== null) return params ? params.toString().length === 10 && /^-?\d+$/.test(params) : true;
	else return true;
}

// For Aadhaar Number
// export function IsValidAadhaar(params) {
//   if (params !== null) return params ? /^\d{4}\d{4}\d{4}$/.test(params) : true;
//   else return true;
// }

// For Driving License
// export function IsValidLicense(params) {
//   if (params !== null) return params ? /^[a-zA-Z]{2}-\d\d-(19\d\d|20[01][0-9])-\d{7}$/.test(params) : true;
//   else return true;
// }

// For Email
export function IsEmailValid(params: string): boolean {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return params ? re.test(String(params).toLowerCase()) : true;
}
