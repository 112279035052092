import React, { useEffect, useMemo, useRef, useState } from 'react';
import fileDownload from 'js-file-download';
import { useSelector } from 'react-redux';
import { Button, Dropdown, Form, Header, Icon, Modal, Pagination, Segment } from 'semantic-ui-react';
import 'jspdf-autotable';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { GridReadyEvent, SortChangedEvent } from 'ag-grid-community/dist/lib/events';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';

import './VehicleEventsStyles';
import { CategoryHeader } from '../../../../../components/STYLED/CategoryHeader';
import { CategoryFooter } from '../../../../../components/STYLED/CategoryFooter';
import { Expander } from '../../../../../components/STYLED/Expander';

import {
	EVENTS_FILTER_DROPDOWN_OPTIONS,
	EXPORT_FILE_EXTENSION,
	PAGE_COUNT_OPTIONS,
} from '../../../../../constants/constants';
import NoVehicleSelection from '../../../../../components/placeHolder/static/noVehicleSelection/NoVehicleSelection';
import { Container, FlexContainer, NoMatchFoundContainer, PageSelector, TableContainer } from './VehicleEventsStyles';
import { handleError } from '../../../../../helper/view specific/messaging';
import { dispatch, dispatchNoPayload } from '../../../../../helper/view specific/store';
import { convertSecondsToDuration, isoToHumanReadable } from '../../../../../utils/calendar';
import {
	AddressLink,
	FormatTableCell,
	getLocalStorage,
	handleDataSizeLimitChange,
	setLocalStorage,
} from '../../../../../utils/common';
import TransitionLoader from '../../../../../components/loader/TransitionLoader';
import { trackMixpanelEvent } from '../../../../../helper/services/mixpanel';
import { exportPostData, postData } from '../../../../../helper/services/axios';
import { EVENTS_API, EXPORT_API } from '../../../../../constants/api';
import Store from '../../../../../redux/store';
import './EventTable.css'; //ag-grid imports
import { THEME } from '../../../../../constants/Theme';
import { RootState } from '../../../../../redux/RootState';
import PlaceHolder from '../../../../../components/placeHolder/dynamic/PlaceHolder';
import { EventDTOInterface, IotEventsData } from '../../../../../types/event';
import moment from 'moment';

interface TableRowHeaders {
	Serial: number;
	Vehicle: string;
	VehicleID: number;
	vehicleEventID: number;
	'Start Time': string | null;
	Events: string;
	endtime: string | null;
	startAddress: string | null;
	endAddress: string | null;
	startIotData: IotEventsData | null;
	endIotData: IotEventsData | null;
	duration: string | null;
	value: string | null;
}

// Define an interface for the specific structure of 'params'
interface CellParams {
	data: TableRowHeaders; // Assumes TableRowHeaders is the correct type for your rowData
	value?: string;
}

export const statusCellRenderer = (params: CellParams) => {
	return (
		<a
			style={{ cursor: 'pointer' }}
			onClick={() => {
				dispatch('SELECTED_TAB', 'Track');
				dispatch('SET_SELECTED_VEHICLES', [params.data.VehicleID]);
				dispatch('EVENT_DATA', {
					id: params.data.vehicleEventID,
					lat: params.data.startIotData?.latitude,
					lng: params.data.startIotData?.longitude,
				});
				Store.dispatch({
					type: 'SET_DATES',
					payload: {
						initialDate: moment(params.data['Start Time']).startOf('day').toISOString(),
						finalDate: moment(params.data['Start Time']).endOf('day').toISOString(),
					},
				});
			}}>
			{params.data.value ? (
				<>
					{params.value} ({params.value === 'OverSpeed' ? `${params.data.value} Km/hr` : params.data.value})
				</>
			) : (
				params.value
			)}
		</a>
	);
};

// Use the defined interface as the type for 'params'
const startAddressCellRender = (params: CellParams) => {
	const data = params.data;
	const value = data.startAddress;
	const iotData = data.startIotData;

	if (iotData) {
		const coordinates = {
			latitude: iotData.latitude,
			longitude: iotData.longitude,
		};
		if (value || coordinates) {
			return (
				<>
					{value && value.length ? (
						<AddressLink value={value} coordinates={coordinates} infoWindow={false} />
					) : (
						<FormatTableCell iotData={iotData} text="View Address" />
					)}
				</>
			);
		}
	} else {
		return (
			<>
				<span>-</span>
			</>
		);
	}
};

const endAddressCellRender = (params: CellParams) => {
	const data = params.data;
	const value = data.endAddress;

	const iotData = data.endIotData;
	if (iotData) {
		const coordinates = {
			latitude: iotData?.latitude,
			longitude: iotData?.longitude,
		};
		if (value || coordinates) {
			return (
				<>
					{value && value.length ? (
						<AddressLink value={value} coordinates={coordinates} infoWindow={false} />
					) : (
						<FormatTableCell iotData={iotData} text="View Address" />
					)}
				</>
			);
		}
	} else {
		return (
			<>
				<span>-</span>
			</>
		);
	}
};

const VehicleEvents = () => {
	const { selectedVehiclesNumbers, selectedVehicles } = useSelector((state: RootState) => state.sidebar);
	const { initialDate, finalDate } = useSelector((state: RootState) => state.dates);
	const dataSizeLimit = useSelector((state: RootState) => state.ui.dataSizeLimit);
	const eventDataStore = useSelector((state: RootState) => state.reduceApi.eventDataStore);
	const activePage = useSelector((state: RootState) => state.ui.pageNumber);

	const dataSize = useRef(0);

	const [initialLoading, setInitialLoading] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [vehicleEvents, setVehicleEvents] = useState<EventDTOInterface[]>([]);
	const [vehicleEventsBackup, setVehicleEventsBackup] = useState<EventDTOInterface[]>([]);
	const [tempFilters, setTempFilters] = useState({ events: [] as string[] }); // Initialize events as an empty string array
	const [filters, setFilters] = useState({
		events: [] as string[],
	});
	const [isFilterActive, setIsFilterActive] = useState(false);
	const [activeSort, setActiveSort] = useState('startTime');
	const [sortOrder, setSortOrder] = useState('desc');
	const [fileExtension, setFileExtension] = useState('pdf');
	const [filterModalOpen, setFilterModalOpen] = useState(false);
	const [rowData, setRowData] = useState<TableRowHeaders[]>([]);
	const [gridApi, setGridApi] = useState<GridReadyEvent | null>(null);
	const [disableExportButton, setDisableExportButton] = useState(false);

	const firstRenderRef = useRef(true);

	const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
	const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

	//ag-grid
	const columnDefsBase = [
		{
			field: 'Serial',
			headerName: 'Serial',
			colId: 'serialnum',
			lockPosition: true,
			flex: 2,
			// width: 60,
			// autoWidth: true,
			cellStyle: () => ({
				borderRightColor: '#e2e2e2',
				textAlign: 'center', // Set the text alignment to center
			}),
		},
		{
			field: 'Vehicle',
			colId: 'VehicleNum',
			sortable: true,
			// autoWidth: true,
			// suppressSizeToFit: true,
			// width: 120,
			flex: 3,
			cellStyle: { borderRightColor: '#e2e2e2' },
		},
		{
			field: 'Events',
			headerName: 'Events',
			colId: 'events',
			sortable: true,
			flex: 3,
			// width: 100,
			cellRenderer: statusCellRenderer,
			cellStyle: { borderRightColor: '#e2e2e2' },
		},
		{
			field: 'Start Time',
			headerName: 'Start Time',
			colId: 'startTime',
			sortable: true,
			flex: 5,
			// width: 160,
			// cellStyle: { 'borderRightColor': '#e2e2e2', textAlign: 'center' },
			cellStyle: { borderRightColor: '#e2e2e2' },
		},
		{
			field: 'startAddress',
			headerName: 'Start Address',
			// width: 200,
			colId: 'startAddress',
			flex: 7,
			cellRenderer: startAddressCellRender,
			cellStyle: { borderRightColor: '#e2e2e2' },
		},
		{
			field: 'endtime',
			headerName: 'End Time',
			colId: 'endTime',
			sortable: true,
			flex: 5,
			cellStyle: { borderRightColor: '#e2e2e2' },
			// cellRenderer: endtimeRenderer,
			// width: 200,
		},
		/*{
            field: 'Status',
            headerName: 'Status',
            width: 150,
            colId: 'status',
            sortable: true,
            cellRenderer: statusCellRenderer,
        },*/

		{
			field: 'endAddress',
			headerName: 'End Address',
			// width: 200,
			colId: 'endAddress',
			flex: 7,
			cellRenderer: endAddressCellRender,
			cellStyle: { borderRightColor: '#e2e2e2' },
		},
		{
			field: 'duration',
			headerName: 'Duration',
			// width: 100,
			colId: 'duration',
			flex: 3,
			cellStyle: { borderRightColor: '#e2e2e2' },
		},
	];

	const [columnDefs] = useState(columnDefsBase);

	useEffect(() => {
		dispatch('SET_SIDEBAR_CONFIG', {
			showCalendar: true,
			multiSelectAllowed: true,
		});

		trackMixpanelEvent('event_report', {
			vehicles: selectedVehiclesNumbers,
			startTime: initialDate,
			endTime: finalDate,
		});

		Store.dispatch({
			type: 'SELECTED_TAB',
			payload: 'Events',
		});

		if (eventDataStore?.total) {
			dataSize.current = eventDataStore?.total;
			fetchFilteredEventsRedux();
		} else {
			Store.dispatch({
				type: 'SET_PAGE_LIMIT',
				payload: 1,
			});
		}

		return () => {
			dispatchNoPayload('RESTORE_SIDEBAR_CONFIG');
			Store.dispatch({
				type: 'SELECTED_TAB',
				payload: 'Live',
			});
		};
	}, []);

	useEffect(() => {
		const difference = moment(finalDate).diff(moment(initialDate), 'months');

		setDisableExportButton(() => difference >= 1);

		if (!firstRenderRef.current) {
			if (selectedVehicles && selectedVehicles.length) {
				fetchFilteredEvents(activePage);
			}
		} else {
			if (selectedVehicles && selectedVehicles.length) {
				if (!eventDataStore.total) fetchFilteredEvents(1);
			}
			firstRenderRef.current = false;
		}

		if (selectedVehicles.length === 0) {
			setInitialLoading(false);
		}
	}, [
		selectedVehiclesNumbers,
		selectedVehicles,
		initialDate,
		finalDate,
		dataSizeLimit,
		filters,
		activeSort,
		sortOrder,
	]);

	interface eventParam {
		sortBy: string;
		orderBy: string;
		type?: string[];
		pagenumber: number;
	}

	const fetchData = (params: eventParam) => {
		setLoading(true);
		setInitialLoading(true);
		const vehiclesParams = selectedVehicles.map((vehicle) => vehicle);
		const startTime = initialDate;
		const endTime = finalDate;

		interface RequestBody {
			vehicleIds: number[];
			startTime: string;
			endTime: string;
			pagecount: number;
			sortBy?: string;
			orderBy?: string;
			type?: string[];
		}

		const requestBody: RequestBody = {
			vehicleIds: vehiclesParams,
			startTime: startTime,
			endTime: endTime,
			pagecount: dataSizeLimit,
			...params,
		};

		/*if (typeof params === 'string') {
            // Handle when params is a string
            requestBody = {
                vehicleIds: vehiclesParams,
                startTime: startTime,
                endTime: endTime,
                pagecount: dataSizeLimit,
                pagenumber: activePage,
                // Add other default properties for the string case here
            };
        } else {
            // Handle when params is an eventParam object
            requestBody = {
                vehicleIds: vehiclesParams,
                startTime: startTime,
                endTime: endTime,
                pagecount: dataSizeLimit,
                // pagenumber: activePage,
                ...params,
            };
        }*/

		postData(EVENTS_API, '', requestBody)
			.then((response) => {
				dataSize.current = response?.data?.count || 0;

				const eventsData = response?.data?.data;

				setVehicleEvents(eventsData);

				if (eventsData) {
					const eventReduxData = {
						total: response.data.count,
						data: eventsData,
						activePage: params.pagenumber,
						filters: eventDataStore.filters,
					};
					dispatch('EVENT_DATA_STORE', eventReduxData);
				}

				if (!isFilterActive) {
					setVehicleEventsBackup(eventsData);
				}
				setLoading(false);
				setInitialLoading(false);
			})
			.catch((error) => {
				handleError('VehicleEvents.tsx => fetchData()', error);
				setLoading(false);
				setInitialLoading(false);
			});
	};

	// Event Filters
	const fetchFilteredEvents = (pageNumber: number) => {
		const param: eventParam = {
			sortBy: activeSort,
			orderBy: sortOrder,
			pagenumber: pageNumber,
		};
		if (eventDataStore.filters.length) {
			param.type = eventDataStore.filters;
			setIsFilterActive(true);
		} else {
			setIsFilterActive(false);
		}
		fetchData(param);
	};
	const fetchFilteredEventsRedux = () => {
		setVehicleEvents(eventDataStore.data);
		Store.dispatch({
			type: 'SET_PAGE_LIMIT',
			payload: eventDataStore.activePage,
		});
		setLoading(false);
		setInitialLoading(false);
	};
	const sort = (param: string) => {
		let activeSortTemp;
		let sortOrderTemp;
		if (activeSort !== param) {
			activeSortTemp = param;
			sortOrderTemp = 'asc';
		} else {
			if (sortOrder === 'desc') {
				activeSortTemp = param;
				sortOrderTemp = 'asc';
			} else {
				activeSortTemp = param;
				sortOrderTemp = 'desc';
			}
		}

		setActiveSort(activeSortTemp);
		setSortOrder(sortOrderTemp);
	};

	const exportAll = () => {
		const startTime = initialDate;
		const endTime = finalDate;

		interface RequestBody {
			vehicleIds: number[];
			startTime: string;
			endTime: string;
			sortBy: string;
			orderBy: string;
			fileType: string;
			type?: string[];
		}

		let requestBody: RequestBody;

		if (filters.events.length > 0) {
			requestBody = {
				vehicleIds: selectedVehicles,
				startTime: startTime,
				endTime: endTime,
				fileType: fileExtension,
				sortBy: activeSort,
				orderBy: sortOrder,
				type: filters.events,
			};
		} else {
			requestBody = {
				vehicleIds: selectedVehicles,
				startTime: startTime,
				endTime: endTime,
				fileType: fileExtension,
				sortBy: activeSort,
				orderBy: sortOrder,
			};
		}

		exportPostData(EXPORT_API, '/events', requestBody)
			.then((response) => {
				const contentDisposition = response.headers['content-disposition'];
				const matches = /filename="([^"]+)"/.exec(contentDisposition);
				if (matches) {
					const fileName = matches[1];
					fileDownload(response.data, fileName);
				}
			})
			.catch((error) => {
				handleError('VehicleEvents.tsx => exportAll() => exportPostData()', error);
			});
	};

	const openFilterModal = () => {
		setFilterModalOpen(true);
	};

	const closeFilterModal = () => {
		setTempFilters({ events: [] });
		setFilterModalOpen(false);
	};

	const onEventFilterChange = (e: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps) => {
		if (value) {
			setTempFilters({ ...tempFilters, events: value as string[] });
			setFilters({ ...tempFilters });
			dispatch('EVENT_DATA_STORE', { filters: value as string[] });
		}
	};

	useEffect(() => {
		if (vehicleEvents.length) {
			const formattedLogs = vehicleEvents.map((data, index) => {
				const serialNumData = index + 1 + (activePage - 1) * dataSizeLimit;

				return {
					Serial: serialNumData,
					Vehicle: data.vehicle.registrationNumber,
					VehicleID: data.vehicle.id,
					vehicleEventID: data.id,
					'Start Time': data.startTime !== '' && data.startTime ? isoToHumanReadable(data.startTime) : '-',
					Events: data.type,
					endtime: data.endTime !== '' && data?.endTime ? isoToHumanReadable(data?.endTime) : '-',
					startAddress: data.startAddress,
					endAddress: data.endAddress,
					startIotData: data.startIotData,
					endIotData: data.endIotData,
					duration: data.duration !== '' && data.duration ? convertSecondsToDuration(parseInt(data.duration)) : '-',
					value: data.value,
				};
			});
			setRowData(formattedLogs);
		} else {
			setRowData([]);
		}
	}, [vehicleEvents]);

	const handleSortChanged = (event: SortChangedEvent | null) => {
		if (event) {
			let sortedColumns = event.columnApi.getColumns();
			if (sortedColumns) {
				sortedColumns = sortedColumns.filter((col) => col.isSortAscending() || col.isSortDescending());

				if (sortedColumns.length === 1) {
					const clickedColumnId = sortedColumns[0].getColId();
					if (clickedColumnId === 'VehicleNum') {
						sort('vehicle');
					} else if (clickedColumnId === 'startTime') {
						sort('startTime');
					} else if (clickedColumnId === 'events') {
						sort('type');
					} else if (clickedColumnId === 'status') {
						sort('status');
					}
				}
			}
		}
	};

	const defaultColDef = useMemo(
		() => ({
			resizable: true,
		}),
		[]
	);

	const onGridReady = (params: GridReadyEvent) => {
		setGridApi(params);
		restoreState(params);
	};

	const restoreState = (params: GridReadyEvent) => {
		const colState = getLocalStorage();
		if (!colState) {
			return;
		}
		params.columnApi.applyColumnState({
			state: colState,
			applyOrder: true,
		});
	};

	const handleStopDrag = () => {
		const colState = gridApi?.columnApi.getColumnState();
		if (colState) setLocalStorage(colState);
	};

	return (
		<Container>
			<div style={{ display: 'flex', width: '100%' }}>
				<CategoryHeader>
					<Button primary icon title="Refresh" disabled={loading} onClick={() => fetchFilteredEvents(activePage)}>
						<Icon name="refresh" />
					</Button>
					<Expander />
					<Button
						icon
						primary={!eventDataStore.filters.length}
						color={eventDataStore.filters.length ? 'orange' : 'grey'}
						title={!selectedVehicles.length ? 'Please select a vehicle.' : 'Filter'}
						disabled={loading || !selectedVehicles.length}
						onClick={() => {
							setTempFilters({ ...filters });
							openFilterModal();
						}}>
						<Icon name="filter" />
					</Button>
					<Button.Group>
						<Button
							title={disableExportButton ? 'Please select only 1 Month to export' : 'Export All Events'}
							primary
							disabled={!vehicleEvents?.length || disableExportButton}
							onClick={exportAll}>
							Export
						</Button>
						<Dropdown
							disabled={!vehicleEvents?.length || disableExportButton}
							className="button icon"
							floating
							style={{ background: '#ffffff', color: '#1b3c71', borderColor: '#1b3c71' }}
							trigger={<></>}
							options={EXPORT_FILE_EXTENSION}
							defaultValue={'pdf'}
							onChange={(e, { value }: DropdownProps) => {
								if (typeof value === 'string') setFileExtension(value);
							}}
						/>
					</Button.Group>
				</CategoryHeader>
			</div>
			{initialLoading && <TransitionLoader />}
			{selectedVehicles.length !== 0 ? (
				isFilterActive && vehicleEventsBackup?.length !== 0 && vehicleEvents.length === 0 ? (
					<NoMatchFoundContainer>
						<Segment placeholder style={{ height: '100%', backgroundColor: `${THEME.COLORS.white}` }}>
							<Header icon>
								<Icon name="frown" />
								no match found for your filter choices.
							</Header>
						</Segment>
					</NoMatchFoundContainer>
				) : rowData.length === 0 ? (
					<PlaceHolder content={'No Data'} iconName={'cloud'} />
				) : (
					<div style={containerStyle}>
						<TableContainer style={gridStyle} className="ag-theme-balham">
							<AgGridReact
								columnDefs={columnDefs}
								rowData={rowData}
								defaultColDef={defaultColDef}
								onSortChanged={handleSortChanged}
								onGridReady={onGridReady}
								onDragStopped={handleStopDrag}
							/>
						</TableContainer>
					</div>
				)
			) : (
				<NoVehicleSelection />
			)}
			<CategoryFooter>
				<Expander />
				<PageSelector>
					<Dropdown
						fluid
						selection
						disabled={loading || !vehicleEvents?.length}
						defaultValue={dataSizeLimit}
						options={PAGE_COUNT_OPTIONS}
						onChange={(e, data: DropdownProps) => {
							if (data?.value) {
								const numericValue = Number(data.value); // Convert to number
								if (!isNaN(numericValue)) {
									// Check if it's a valid number
									handleDataSizeLimitChange(numericValue);
								}
							}
						}}
					/>
				</PageSelector>
				<Pagination
					activePage={activePage}
					disabled={loading || !vehicleEvents?.length}
					ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
					firstItem={{ content: <Icon name="angle double left" />, icon: true }}
					lastItem={{ content: <Icon name="angle double right" />, icon: true }}
					nextItem={null}
					onPageChange={(e, { activePage }) => {
						if (typeof activePage === 'number') {
							Store.dispatch({
								type: 'SET_PAGE_LIMIT',
								payload: activePage,
							});
							fetchFilteredEvents(activePage);
						}
						setTimeout(() => {
							if (document.getElementById('TableContainer')) {
								const TableContainer = document.getElementById('TableContainer');
								if (TableContainer !== null) TableContainer.scrollTo(0, 0);
							}
						}, 500);
					}}
					prevItem={null}
					totalPages={Math.ceil(dataSize.current / dataSizeLimit)}
				/>
			</CategoryFooter>

			{filterModalOpen && (
				<Modal
					onClose={() => {
						setTempFilters({ events: [] });
						closeFilterModal();
					}}
					open={filterModalOpen}
					size="mini">
					<Modal.Header>Filter</Modal.Header>
					<Modal.Content style={{ minHeight: '23em' }}>
						<Form>
							<Form.Group widths="equal">
								<Form.Field>
									<label>Events</label>
									<Dropdown
										placeholder="Choose..."
										fluid
										search
										multiple
										selection
										value={eventDataStore.filters}
										options={EVENTS_FILTER_DROPDOWN_OPTIONS}
										onChange={onEventFilterChange}
									/>
								</Form.Field>
							</Form.Group>
						</Form>
					</Modal.Content>
					<Modal.Actions>
						<FlexContainer>
							<Button
								onClick={() => {
									Store.dispatch({
										type: 'SET_PAGE_LIMIT',
										payload: 1,
									});
									setFilters({ events: [] });
									dispatch('EVENT_DATA_STORE', { filters: [] });
									closeFilterModal();
								}}>
								Reset
							</Button>
							<Expander />
							<Button
								color="black"
								onClick={() => {
									closeFilterModal();
								}}>
								Cancel
							</Button>
							<Button
								primary
								disabled={!eventDataStore.filters.length}
								onClick={() => {
									setFilters({ ...tempFilters });
									closeFilterModal();
								}}>
								Apply
							</Button>
						</FlexContainer>
					</Modal.Actions>
				</Modal>
			)}
		</Container>
	);
};

export default VehicleEvents;
