import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
`;

export const FiltersContainer = styled.section`
	width: 100%;

	margin-top: 1em;
	box-sizing: border-box;
	padding: 0 1em;

	display: flex;
	align-items: center;
	justify-content: space-around;
`;

export const StatusContainer = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;

	span {
		display: inline-block;
		margin-top: 0.25em;

		font-size: 0.8em;
	}

	.ui.checkbox .box:before,
	.ui.checkbox label:before {
		border: none !important;
	}

	.ui.checkbox input:checked ~ .box:after,
	.ui.checkbox input:checked ~ label:after {
		color: #fff;
	}

	&:nth-child(1) {
		.ui.checkbox .box:before,
		.ui.checkbox label:before {
			background: ${(props) => props.theme.COLORS.theme} !important;
		}
	}

	&:nth-child(2) {
		.ui.checkbox .box:before,
		.ui.checkbox label:before {
			background: ${(props) => props.theme.COLORS.vehicleMoving} !important;
		}
	}

	&:nth-child(3) {
		.ui.checkbox .box:before,
		.ui.checkbox label:before {
			background: ${(props) => props.theme.COLORS.vehicleIdle} !important;
		}
	}

	&:nth-child(4) {
		.ui.checkbox .box:before,
		.ui.checkbox label:before {
			background: ${(props) => props.theme.COLORS.vehicleIgnitionOff} !important;
		}
	}

	&:nth-child(5) {
		.ui.checkbox .box:before,
		.ui.checkbox label:before {
			background: ${(props) => props.theme.COLORS.vehicleNoDataHalfHour} !important;
		}
	}

	&:nth-child(6) {
		.ui.checkbox .box:before,
		.ui.checkbox label:before {
			background: ${(props) => props.theme.COLORS.vehicleNoDataOneDay} !important;
		}
	}

	&:nth-child(7) {
		.ui.checkbox .box:before,
		.ui.checkbox label:before {
			background: ${(props) => props.theme.COLORS.vehicleNoData} !important;
		}
	}
`;
