import styled from 'styled-components';
import { THEME } from '../../constants/Theme';

interface ActiveProps {
	active?: boolean;
}

export const CardsContainer = styled.section`
	width: 100%;
	flex-grow: 1;

	overflow-y: auto;
`;

export const AccordionTitleContainer = styled.section`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Card = styled.section`
	display: flex;
	align-items: stretch;
	position: relative;

	box-sizing: border-box;
	padding: 0.75em;
	margin: 0.75em;

	background: #fff;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;

	cursor: pointer;

	transition: box-shadow 0.2s ease;

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
	}
`;

export const CheckboxContainer = styled.section`
	margin-right: 1em;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const AllVehiclesCheck = styled.section`
	display: flex;
	justify-content: left;
	width: 100%;
	margin: 1em 0;
	font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
	padding: 0 5px 0.75em 1em;
	cursor: pointer;
`;

export const Content = styled.section`
	//flex-grow: 1;
`;

export const Expander = styled.section`
	flex-grow: 1;
`;

export const Header = styled.section<ActiveProps>`
	display: flex;
	justify-content: space-between;

	span {
		font-size: 1em;
		//font-weight: ${(props) => (props.active ? 'bold' : '')};
		font-weight: bold;
		//color: ${(props) => (props.active ? THEME.COLORS.theme : '')};
	}

	img {
		height: 1.43em;
		padding-left: 0.5em;
	}
`;

export const LeftAccordionTitleContainer = styled.section<ActiveProps>`
	display: flex;
	align-items: center;
	justify-content: center;

	font-weight: ${(props) => (props.active ? 'bold' : '')};
	color: ${(props) => (props.active ? THEME.COLORS.theme : '')};

	text-overflow: ellipsis;
	overflow: hidden;
	//white-space: nowrap;
`;

export const Status = styled.section`
	font-size: 0.9em;
	font-weight: bold;

	color: ${(props) => props.color};
`;

export const VehicleStatus = styled.section`
	font-size: 0.75em;
	font-weight: bold;
	line-height: normal;

	color: ${(props) =>
		props.color === 'Breakdown' ? THEME.COLORS.Breakdown : props.color === 'Paused' ? THEME.COLORS.Paused : ''};
`;

export const FuelIcon = styled.section`
	position: absolute;

	top: -8px;
	right: -11px;
`;
