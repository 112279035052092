export const ANDROID_APP_LINK = 'https://play.google.com/store/apps/details?id=com.tracknerd.lokkate';
export const IOS_APP_LINK = 'https://apps.apple.com/in/app/lokkate/id1546343210';

// Excel File Types
export const EXCEL_FILE_TYPES = [
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel.sheet.macroenabled.12',
	'application/vnd.ms-excel.sheet.binary.macroenabled.12',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
	'application/vnd.ms-excel.template.macroenabled.12',
	'text/xml',
	'application/vnd.ms-excel',
];

// CategoryHeader
export const HEADER_HEIGHT = '3.5em';

// CategoryFooter
export const FOOTER_HEIGHT = '3.5em';

// Firebase Config
export const FIREBASE_CONFIG = {
	apiKey: process.env.REACT_APP_FIREBASE_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
};

// Results Per Page
export const DATA_SIZE_LIMIT = 100;

// Tracking Link URL
export const OLD_LIVE_LINK_URL = '/track/link';
export const NEW_LIVE_LINK_URL = '/live/link';
export const TRACKING_LINK_URL = '/tracking/link';

// Verification Link URL
export const ASSET_VERIFICATION_LINK_URL = '/asset-verification-link';

// Pagination
export const PAGE_COUNT_OPTIONS = [
	{
		key: 0,
		text: 25,
		value: 25,
	},
	{
		key: 1,
		text: 50,
		value: 50,
	},
	{
		key: 2,
		text: 100,
		value: 100,
	},
	{
		key: 3,
		text: 300,
		value: 300,
	},
	{
		key: 4,
		text: 500,
		value: 500,
	},
	{
		key: 5,
		text: 1000,
		value: 1000,
	},
];

export const HOUR_OPTIONS = [
	{
		key: 1,
		text: '01',
		value: '01',
	},
	{
		key: 2,
		text: '02',
		value: '02',
	},
	{
		key: 3,
		text: '03',
		value: '03',
	},
	{
		key: 4,
		text: '04',
		value: '04',
	},
	{
		key: 5,
		text: '05',
		value: '05',
	},
	{
		key: 6,
		text: '06',
		value: '06',
	},
	{
		key: 7,
		text: '07',
		value: '07',
	},
	{
		key: 8,
		text: '08',
		value: '08',
	},
	{
		key: 9,
		text: '09',
		value: '09',
	},
	{
		key: 10,
		text: '10',
		value: '10',
	},
	{
		key: 11,
		text: '11',
		value: '11',
	},
	{
		key: 12,
		text: '12',
		value: '12',
	},
];

export const MINUTE_OPTIONS = [
	{
		key: 0,
		text: '00',
		value: '00',
	},
	{
		key: 1,
		text: '01',
		value: '01',
	},
	{
		key: 2,
		text: '02',
		value: '02',
	},
	{
		key: 3,
		text: '03',
		value: '03',
	},
	{
		key: 4,
		text: '04',
		value: '04',
	},
	{
		key: 5,
		text: '05',
		value: '05',
	},
	{
		key: 6,
		text: '06',
		value: '06',
	},
	{
		key: 7,
		text: '07',
		value: '07',
	},
	{
		key: 8,
		text: '08',
		value: '08',
	},
	{
		key: 9,
		text: '09',
		value: '09',
	},
	{
		key: 10,
		text: '10',
		value: '10',
	},
	{
		key: 11,
		text: '11',
		value: '11',
	},
	{
		key: 12,
		text: '12',
		value: '12',
	},
	{
		key: 13,
		text: '13',
		value: '13',
	},
	{
		key: 14,
		text: '14',
		value: '14',
	},
	{
		key: 15,
		text: '15',
		value: '15',
	},
	{
		key: 16,
		text: '16',
		value: '16',
	},
	{
		key: 17,
		text: '17',
		value: '17',
	},
	{
		key: 18,
		text: '18',
		value: '18',
	},
	{
		key: 19,
		text: '19',
		value: '19',
	},
	{
		key: 20,
		text: '20',
		value: '20',
	},
	{
		key: 21,
		text: '21',
		value: '21',
	},
	{
		key: 22,
		text: '22',
		value: '22',
	},
	{
		key: 23,
		text: '23',
		value: '23',
	},
	{
		key: 24,
		text: '24',
		value: '24',
	},
	{
		key: 25,
		text: '25',
		value: '25',
	},
	{
		key: 26,
		text: '26',
		value: '26',
	},
	{
		key: 27,
		text: '27',
		value: '27',
	},
	{
		key: 28,
		text: '28',
		value: '28',
	},
	{
		key: 29,
		text: '29',
		value: '29',
	},
	{
		key: 30,
		text: '30',
		value: '30',
	},
	{
		key: 31,
		text: '31',
		value: '31',
	},
	{
		key: 32,
		text: '32',
		value: '32',
	},
	{
		key: 33,
		text: '33',
		value: '33',
	},
	{
		key: 34,
		text: '34',
		value: '34',
	},
	{
		key: 35,
		text: '35',
		value: '35',
	},
	{
		key: 36,
		text: '36',
		value: '36',
	},
	{
		key: 37,
		text: '37',
		value: '37',
	},
	{
		key: 38,
		text: '38',
		value: '38',
	},
	{
		key: 39,
		text: '39',
		value: '39',
	},
	{
		key: 40,
		text: '40',
		value: '40',
	},
	{
		key: 41,
		text: '41',
		value: '41',
	},
	{
		key: 42,
		text: '42',
		value: '42',
	},
	{
		key: 43,
		text: '43',
		value: '43',
	},
	{
		key: 44,
		text: '44',
		value: '44',
	},
	{
		key: 45,
		text: '45',
		value: '45',
	},
	{
		key: 46,
		text: '46',
		value: '46',
	},
	{
		key: 47,
		text: '47',
		value: '47',
	},
	{
		key: 48,
		text: '48',
		value: '48',
	},
	{
		key: 49,
		text: '49',
		value: '49',
	},
	{
		key: 50,
		text: '50',
		value: '50',
	},
	{
		key: 51,
		text: '51',
		value: '51',
	},
	{
		key: 52,
		text: '52',
		value: '52',
	},
	{
		key: 53,
		text: '53',
		value: '53',
	},
	{
		key: 54,
		text: '54',
		value: '54',
	},
	{
		key: 55,
		text: '55',
		value: '55',
	},
	{
		key: 56,
		text: '56',
		value: '56',
	},
	{
		key: 57,
		text: '57',
		value: '57',
	},
	{
		key: 58,
		text: '58',
		value: '58',
	},
	{
		key: 59,
		text: '59',
		value: '59',
	},
];

// Vehicle Types
export const VEHICLE_TYPES = {
	Car: 1,
	Bike: 2,
	Truck: 3,
	Construction: 4,
	Bus: 5,
	Generator: 6,
	Container: 7,
	'Mini Truck': 8,
	'Auto Rickshaw': 9,
};

// Event Types
export const VEHICLE_STATUS = {
	Moving: 'Moving',
	Idle: 'Idle',
	Stopped: 'Ignition off',
	NoDataOneHour: 'No Data 1 Hour',
	NoDataOneDay: 'No Data 1 Day',
	NoData: 'Unknown',
};

export const EVENTS_FILTER_DROPDOWN_OPTIONS = [
	{
		key: 1,
		value: 'Ignition',
		text: 'Ignition',
	},
	{
		key: 2,
		value: 'Relay',
		text: 'Relay',
	},
	{
		key: 3,
		value: 'Geofence',
		text: 'Geo Fence',
	},
	{
		key: 4,
		value: 'Main Power',
		text: 'Main Power',
	},
	{
		key: 5,
		value: 'Ac',
		text: 'AC',
	},
	{
		key: 6,
		value: 'OverSpeed',
		text: 'Over Speeding',
	},
	{
		key: 7,
		value: 'SOS',
		text: 'SOS',
	},
	{
		key: 8,
		value: 'Idling',
		text: 'Idling',
	},
	{
		key: 9,
		value: 'Parking',
		text: 'Parking',
	},
];

export const DRIVER_DOCUMENTS_TYPE_OPTIONS = [
	{
		key: 1,
		value: 'Aadhaar',
		text: 'Aadhaar',
	},
	{
		key: 2,
		value: 'Driving License',
		text: 'Driving License',
	},
];
export const DOCUMENTS_TYPE_OPTIONS = [
	{
		key: 1,
		value: 'Road Tax',
		text: 'Road Tax',
	},
	{
		key: 2,
		value: 'Vehicle Fitness',
		text: 'Vehicle Fitness',
	},
	{
		key: 3,
		value: 'Vehicle Insurance',
		text: 'Vehicle Insurance',
	},
	{
		key: 4,
		value: 'Vehicle Registration',
		text: 'Vehicle Registration',
	},
	{
		key: 5,
		value: 'PUC',
		text: 'PUC',
	},
];

// Export File Extension Dropdown
export const EXPORT_FILE_EXTENSION = [
	{
		key: 1,
		text: 'Excel',
		value: 'xlsx',
	},
	{
		key: 2,
		text: 'PDF',
		value: 'pdf',
	},
];

export const REFUEL_AND_DRAIN_EVENTS_FILTER_DROPDOWN_OPTIONS = [
	{
		key: 1,
		value: 11,
		text: 'Refuel',
	},
	{
		key: 2,
		value: 12,
		text: 'Drain',
	},
];

export const EVENTS_DROPDOWN_OPTIONS = [
	{
		key: 1,
		value: 1,
		text: 'Ignition',
	},
	{
		key: 3,
		value: 3,
		text: 'Relay',
	},
	{
		key: 4,
		value: 4,
		text: 'Geo Fence',
	},
	{
		key: 5,
		value: 5,
		text: 'Main Power',
	},
	{
		key: 6,
		value: 6,
		text: 'Delivery',
	},
	{
		key: 7,
		value: 7,
		text: 'Over Speeding',
	},
	{
		key: 8,
		value: 8,
		text: 'AC',
	},
];

// Trips File Headers
export const TRIPS_FILE_HEADERS = [
	{ key: 'Vehicle' },
	{ key: 'Distance (km)' },
	{ key: 'Trip Duration' },
	{ key: 'Start Time' },
	{ key: 'Start Address' },
	{ key: 'End Time' },
	{ key: 'End Address' },
	{ key: 'Average Speed (km/h)' },
	{ key: 'Max Speed (km/h)' },
	{ key: 'Engine Hours' },
	{ key: 'Movement Hours' },
	{ key: 'Idle Hours' },
];

export const SUMMARY_INTERVAL_DROPDOWN_OPTIONS = [
	{ key: 1, value: 'daily', text: 'Daily Report' },
	{ key: 2, value: 'monthly', text: 'Monthly Report' },
];
export const circleOptions = {
	strokeColor: 'dodgerblue',
	strokeWeight: 2,
	fillColor: 'dodgerblue',
	fillOpacity: 0.1,
};

export const TRACK_SPEED_DROPDOWN = [
	{
		key: 1,
		text: '1x',
		value: 1,
	},
	{
		key: 2,
		text: '2x',
		value: 2,
	},
	{
		key: 3,
		text: '5x',
		value: 5,
	},
];

export const IMAGE_FILE_TYPES = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'];

// Documents
export const DOCUMENTS_ENTITY_OPTIONS = [
	{
		key: 1,
		value: 'vehicle',
		text: 'Vehicle',
	},
	{
		key: 2,
		value: 'driver',
		text: 'Driver',
	},
];

// Share Tracking Link
export const EXPIRY_UNIT_OF_TIME_OPTIONS = [
	{
		key: 1,
		value: 'minutes',
		text: 'Minutes',
	},
	{
		key: 2,
		value: 'hours',
		text: 'Hours',
	},
	{
		key: 3,
		value: 'days',
		text: 'Days',
	},
];

// Consignment Route Header
export const CONSIGNMENT_ROUTE_HEADER_HEIGHT = '4em';

// Event Types
export const EVENT_TYPES = {
	IGNITION: 1,
	MOVEMENT: 2,
	RELAY: 3,
	GEOFENCE: 4,
	MAIN_POWER: 5,
	DELIVERY: 6,
	OVER_SPEEDING: 7,
	AC: 8,
	SHARE_TRACKING_LINK: 10,
	REFUELING: 11,
	DRAINING: 12,
};
