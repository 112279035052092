import React, { useEffect } from 'react';
import Main from './main/Main';
import Store from '../../../redux/store';
import { Container } from './VehiclesStyles';
import SideBarNew2 from './sidebar/SideBarNew2';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/RootState';

const Vehicles = () => {
	const selectedVehicleFromGraph = useSelector((state: RootState) => state.sidebar.selectedVehicleFromGraph);

	useEffect(() => {
		if (!selectedVehicleFromGraph) {
			Store.dispatch({
				type: 'RESET_DATES',
			});
		}

		return () => {
			Store.dispatch({
				type: 'SHOW_SIDEBAR',
				payload: true,
			});
		};
	}, []);

	return (
		<Container>
			<SideBarNew2 />
			<Main />
		</Container>
	);
};

export default Vehicles;
