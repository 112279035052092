import { Trips } from '../types/trip';
import { getDistance } from './track';

export interface TripRouteData {
	lat: number;
	lng: number;
	status: string | null;
	ignition: boolean;
	speed: number;
	distance: number;
	altitude?: number;
	timestamp: string;
	totalOdometer: string;
	batteryLevel: number;
	soc?: number | null;
	dte?: number | null;
	ignitionStatus: boolean;
}

export function createTripRouteDTO(data: Trips): TripRouteData[] | null {
	const locations: TripRouteData[] = [];
	if (data.vehicle.iotData !== undefined) {
		let distance: number = 0;
		data.vehicle.iotData.forEach((coordinate, index) => {
			const Trackdistance = getDistance(
				data.vehicle.iotData[index - 1]?.latitude,
				data.vehicle.iotData[index - 1]?.longitude,
				coordinate?.latitude,
				coordinate?.longitude
			);
			locations.push({
				lat: coordinate.latitude,
				lng: coordinate.longitude,
				status: coordinate?.status ? coordinate?.status : null,
				ignition: coordinate.ignitionStatus,
				speed: coordinate.speed,
				distance: distance + Trackdistance,
				altitude: coordinate?.altitude ? coordinate.altitude : -1,
				timestamp: coordinate.timestamp,
				totalOdometer: coordinate?.totalOdometer ? Number(coordinate.totalOdometer).toLocaleString() : '0',
				batteryLevel: coordinate?.externalPowerVoltage ? coordinate.externalPowerVoltage : 0,
				soc: coordinate?.soc ? coordinate.soc : null,
				dte: coordinate?.dte ? coordinate.dte : null,
				ignitionStatus: coordinate?.ignitionStatus ? coordinate.ignitionStatus : false,
			});
			distance = Trackdistance !== null ? distance + Trackdistance : 0;
		});
	}

	return locations.length !== 0 ? locations : null;
}
