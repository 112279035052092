import styled from 'styled-components';
import { THEME } from '../../../../../constants/Theme';

interface ActiveTypeProps {
	activeType: boolean;
}

export const Container = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
`;

export const Placeholder = styled.section`
	box-sizing: border-box;
	padding: 3em 4em;

	position: absolute;

	background: rgba(255, 255, 255, 0.8);
	border-radius: 10px;

	span {
		font-size: 2em;
		font-weight: bold;
		color: #444;
	}
`;

export const ControlsContainer = styled.section`
	position: absolute;
	bottom: 2.2em;
	left: 1em;
	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: center;
`;
export const ShouldAdjustMap = styled.section`
	position: absolute;
	top: 9em;
	right: 0;
	display: flex;
	flex-direction: column;

	.ui.icon.button {
		color: black !important;
		background-color: #fafafa !important;
	}
`;

export const ShouldAdjustMapMapbox = styled.section`
	position: absolute;
	top: 15em;
	right: 0.2em;
	display: flex;
	flex-direction: column;
	z-index: 10;

	.button {
		border-radius: 25px;
		background-color: #ffffff;
		border: 3px solid #d4cfcd;
		color: black;
		font-weight: 1000;
	}
`;

export const MapTypesContainer = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 0.5em;
`;

export const MapTypesLabels = styled.section<ActiveTypeProps>`
	font-size: 0.85em;
	font-weight: bold;

	color: ${(props) => (props.activeType ? `${THEME.COLORS.theme}` : 'black')};
`;

export const MapTypeControls = styled.button<ActiveTypeProps>`
	width: 3em;
	height: 3em;

	box-sizing: border-box;
	padding: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 8px;
	border: 2px solid ${(props) => (props.activeType ? `${THEME.COLORS.theme}` : 'white')};

	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
		border-radius: 8px;

		object-fit: none;
	}
`;

export const ActiveMapTypes = styled.button`
	width: 5em;
	height: 5em;

	box-sizing: border-box;
	padding: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 8px;
	border: 2px solid white;

	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
		border-radius: 8px;

		object-fit: contain;
	}
`;
