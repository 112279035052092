import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import { Button, Dropdown, Icon, Label, Pagination } from 'semantic-ui-react';

import TripRoute from './route/TripRoute';
import PlaceHolder from '../../../../../components/placeHolder/dynamic/PlaceHolder';
import TrackNerdLoader from '../../../../../components/loader/Loader';
import { CategoryFooter } from '../../../../../components/STYLED/CategoryFooter';
import { Expander } from '../../../../../components/STYLED/Expander';
import { CategoryHeader } from '../../../../../components/STYLED/CategoryHeader';
import NoVehicleSelection from '../../../../../components/placeHolder/static/noVehicleSelection/NoVehicleSelection';
import { EXPORT_FILE_EXTENSION, PAGE_COUNT_OPTIONS } from '../../../../../constants/constants';
import { createTripsDTO, TripDTO } from '../../../../../dtos/trip';
import Store from '../../../../../redux/store';
import {
	Block,
	BlockContainer,
	Card,
	CardContainer,
	Container,
	Content,
	PageSelector,
	Row,
	StoppageContainer,
	Title,
	TripRouteContainer,
} from './TripsStyles';
import { getDiff, isoToHumanReadable } from '../../../../../utils/calendar';
import { handleError } from '../../../../../helper/view specific/messaging';
import TransitionLoader from '../../../../../components/loader/TransitionLoader';
import { exportPostData, postData } from '../../../../../helper/services/axios';
import { EXPORT_API, TRIP_HISTORY_API } from '../../../../../constants/api';
import { trackMixpanelEvent } from '../../../../../helper/services/mixpanel';
import { AddressLink, FormatTableCell, handleDataSizeLimitChange } from '../../../../../utils/common';
import { RootState } from '../../../../../redux/RootState';
import { dispatch } from '../../../../../helper/view specific/store';
import moment from 'moment/moment';

const Trips = () => {
	// Control Variables
	const { selectedVehicles, selectedVehiclesNumbers } = useSelector((state: RootState) => state.sidebar);
	const { initialDate, finalDate } = useSelector((state: RootState) => state.dates);
	const dataSizeLimit = useSelector((state: RootState) => state.ui.dataSizeLimit);
	const tripDataStore = useSelector((state: RootState) => state.reduceApi.tripDataStore);
	const activePage = useSelector((state: RootState) => state.ui.pageNumber);

	const firstRenderRef = useRef(true);

	const dataSize = useRef(0);

	const [initialLoading, setInitialLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [trips, setTrips] = useState<TripDTO[] | null>(null);
	const [fileExtension, setFileExtension] = useState<string | string[]>('pdf');
	const [activeTripIndex, setActiveTripIndex] = useState<number | null>(null);
	const [showTripRoute, setShowTripRoute] = useState(false);
	const [disableExportButton, setDisableExportButton] = useState(false);

	useEffect(() => {
		Store.dispatch({
			type: 'SET_SIDEBAR_CONFIG',
			payload: {
				showCalendar: true,
				multiSelectAllowed: true,
			},
		});

		Store.dispatch({
			type: 'SELECTED_TAB',
			payload: 'Trips',
		});

		if (tripDataStore?.total) {
			dataSize.current = tripDataStore?.total;
			fetchDataRedux();
		} else {
			Store.dispatch({
				type: 'SET_PAGE_LIMIT',
				payload: 1,
			});
		}

		return () => {
			Store.dispatch({
				type: 'RESTORE_SIDEBAR_CONFIG',
			});
			Store.dispatch({
				type: 'SELECTED_TAB',
				payload: 'Live',
			});
		};
	}, []);

	useEffect(() => {
		const difference = moment.duration(moment(finalDate).diff(moment(initialDate))).asDays();

		setDisableExportButton(() => difference > 7);

		if (!firstRenderRef.current) {
			if (selectedVehicles && selectedVehicles.length) {
				fetchData(activePage);
			}
		} else {
			if (selectedVehicles && selectedVehicles.length) {
				if (!tripDataStore.total) {
					fetchData(1);
				}
			}
			firstRenderRef.current = false;
		}

		if (selectedVehicles.length === 0) {
			setInitialLoading(false);
		}
	}, [selectedVehicles, selectedVehiclesNumbers, initialDate, finalDate, dataSizeLimit]);

	const fetchData = (pageNumber: number) => {
		setActiveTripIndex(null);
		setShowTripRoute(false);
		setLoading(true);

		const startTime = initialDate;
		const endTime = finalDate;
		const requestBody = {
			vehicleIds: selectedVehicles,
			startTime: initialDate,
			endTime: finalDate,
			pagecount: dataSizeLimit,
			pagenumber: pageNumber,
		};

		postData(TRIP_HISTORY_API, '', requestBody)
			.then((response) => {
				dataSize.current = response.data.count;
				const tripDtoData = createTripsDTO(response.data.data);
				if (tripDtoData) {
					const tripReduxData = {
						total: response.data.count,
						data: tripDtoData,
						activePage: pageNumber,
					};
					dispatch('TRIP_DATA_STORE', tripReduxData);
					if (document.getElementById('TableContainer')) {
						const tableContainer = document.getElementById('TableContainer');
						if (tableContainer !== null) tableContainer.scrollTo(0, 0);
					}
				}
				setTrips(tripDtoData);
				setLoading(false);
				setInitialLoading(false);
			})
			.catch((error) => {
				handleError('Trips.tsx => fetchData() => postData()', error);
				setLoading(false);
				setInitialLoading(false);
			});

		trackMixpanelEvent('trip_report', {
			vehicles: selectedVehiclesNumbers,
			startTime: startTime,
			endTime: endTime,
		});
	};

	const fetchDataRedux = () => {
		setTrips(tripDataStore.data);
		Store.dispatch({
			type: 'SET_PAGE_LIMIT',
			payload: tripDataStore.activePage,
		});
		setLoading(false);
		setInitialLoading(false);
		if (document.getElementById('TableContainer')) {
			const tableContainer = document.getElementById('TableContainer');
			if (tableContainer !== null) tableContainer.scrollTo(0, 0);
		}
	};
	const closeTripRouteView = () => {
		setShowTripRoute(false);
	};

	const exportAll = () => {
		const startTime = initialDate;
		const endTime = finalDate;
		const body = {
			vehicleIds: selectedVehicles,
			startTime: startTime,
			endTime: endTime,
			fileType: fileExtension,
		};

		exportPostData(EXPORT_API, '/trips', body)
			.then((response) => {
				const contentDisposition = response.headers['content-disposition'];
				const matches = /filename="([^"]+)"/.exec(contentDisposition);
				if (matches) {
					const fileName = matches[1];
					fileDownload(response.data, fileName);
				}
			})
			.catch((error) => {
				handleError('Trips.tsx => exportAll() => exportPostData()', error);
			});
	};

	return (
		<Container>
			<CategoryHeader>
				<Button
					style={{ backgroundColor: '#1B3C71', color: '#fff' }}
					icon
					title="Refresh"
					disabled={loading}
					onClick={() => fetchData(activePage)}>
					<Icon name="refresh" />
				</Button>
				<Expander />
				<Button.Group primary>
					<Button
						title={disableExportButton ? 'Please select only 7 Days to export' : 'Export All Trips'}
						disabled={!trips?.length || disableExportButton}
						primary
						onClick={exportAll}>
						Export
					</Button>
					<Dropdown
						disabled={!trips?.length || disableExportButton}
						className="button icon"
						style={{ background: '#ffffff', color: '#1b3c71', borderColor: '#1b3c71' }}
						floating
						trigger={<></>}
						options={EXPORT_FILE_EXTENSION}
						defaultValue={'pdf'}
						onChange={(e, { value }) => {
							if (typeof value === 'string') setFileExtension(value);
						}}
					/>
				</Button.Group>
			</CategoryHeader>
			<Content id="TableContainer">
				{initialLoading ? (
					<TrackNerdLoader />
				) : selectedVehicles.length !== 0 ? (
					trips && trips.length !== 0 ? (
						<CardContainer>
							{trips.map((trip, index) => {
								return (
									<section key={trip.id}>
										<Card
											onClick={() => {
												setActiveTripIndex(index);
												setShowTripRoute(true);
											}}>
											<Title>
												<h4>{trip.regNum}</h4>
											</Title>
											<Row>
												<Label circular color="green" empty />
												{trip.startAddress?.length ? (
													<AddressLink
														value={trip.startAddress}
														coordinates={{
															latitude: trip.startIotData.latitude,
															longitude: trip.startIotData.longitude,
														}}
														infoWindow={false}
													/>
												) : (
													<FormatTableCell iotData={trip.startIotData} text="View Start Address" />
												)}

												<section title="Trip Start Time">
													<Icon name="clock" color="blue" />
													<span>{isoToHumanReadable(trip.startTime)}</span>
												</section>
												<section title="Trip Start Odometer">
													<Icon name="tachometer alternate" color="blue" />
													<span>
														{trip.startIotData.totalOdometer == 0 ? 'N/A' : trip.startIotData.totalOdometer} KM
													</span>
												</section>
												<section title="Trip Start Bettary Voltage">
													<Icon name="battery full" color="green" />
													<span>{trip.startIotData.externalPowerVoltage} Volts</span>
												</section>
											</Row>
											<BlockContainer>
												<Block title="Distance">
													Distance
													<span>{trip.distance ? trip.distance : '0.00'} km</span>
												</Block>
												<Block title="Idle Time">
													Idle
													<span>{trip.idleHours}</span>
												</Block>
												<Block title="Avg Speed">
													Avg Speed
													<span>{trip.averageSpeed || '0.00'} km/h</span>
												</Block>
												<Block title="Max Speed">
													Max Speed
													<span>{trip.maxSpeed || '0.00'} km/h</span>
												</Block>
												{/*<Block title="Max Speed">*/}
												{/*	Odometer Difference*/}
												{/*	<span>{trip.odoDiffernce || '0.00'} km</span>*/}
												{/*</Block>*/}
												<Block title="">
													<span></span>
												</Block>
											</BlockContainer>
											<Row>
												<Label circular color="red" empty />
												{trip.endAddress?.length ? (
													<AddressLink
														value={trip.endAddress}
														coordinates={{
															latitude: trip.endIotData.latitude,
															longitude: trip.endIotData.longitude,
														}}
														infoWindow={false}
													/>
												) : (
													<FormatTableCell iotData={trip.endIotData} text="View End Address" />
												)}
												<section title="Trip End Time">
													<Icon name="clock" color="blue" />
													<span>{isoToHumanReadable(trip.endTime)}</span>
												</section>
												<section title="Trip End Odometer">
													<Icon name="tachometer alternate" color="blue" />
													<span>{trip.endIotData.totalOdometer == 0 ? 'N/A' : trip.endIotData.totalOdometer} KM</span>
												</section>
												<section title="Trip End Bettary Voltage">
													<Icon name="battery one" color="red" />
													<span>{trip.endIotData.externalPowerVoltage}Volts</span>
												</section>
											</Row>
										</Card>
										{trips[index + 1] !== undefined && (
											<StoppageContainer>
												{trips[index].vehicleId === trips[index + 1].vehicleId ? (
													<span>Stopped for {getDiff(trips[index + 1].endTime, trips[index].startTime)}</span>
												) : (
													<h4>{trips[index + 1].regNum}</h4>
												)}
											</StoppageContainer>
										)}
									</section>
								);
							})}
						</CardContainer>
					) : (
						<PlaceHolder content={'No Data'} iconName={'cloud'} />
					)
				) : (
					<NoVehicleSelection />
				)}
			</Content>

			<CategoryFooter>
				<Expander />
				<Button as="div" labelPosition="right">
					<Button basic color="blue">
						Total Trip Count
					</Button>
					<Label as="a" basic color="blue" pointing="left">
						{dataSize.current}
					</Label>
				</Button>
				<PageSelector>
					<Dropdown
						fluid
						search
						selection
						disabled={loading || !trips?.length}
						defaultValue={dataSizeLimit}
						options={PAGE_COUNT_OPTIONS}
						onChange={(e, { value }: DropdownProps) => {
							if (typeof value === 'number') handleDataSizeLimitChange(value);
						}}
					/>
				</PageSelector>
				<Pagination
					activePage={activePage}
					disabled={loading || !trips?.length}
					ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
					firstItem={{ content: <Icon name="angle double left" />, icon: true }}
					lastItem={{ content: <Icon name="angle double right" />, icon: true }}
					prevItem={null}
					nextItem={null}
					onPageChange={(e, { activePage }: DropdownProps) => {
						Store.dispatch({
							type: 'SET_PAGE_LIMIT',
							payload: activePage,
						});
						fetchData(activePage);
					}}
					totalPages={Math.ceil(dataSize.current / dataSizeLimit)}
				/>
			</CategoryFooter>

			{loading && !initialLoading && <TransitionLoader />}

			{showTripRoute && (
				<TripRouteContainer>
					{activeTripIndex !== null && trips !== null && (
						<TripRoute closeTripRouteView={closeTripRouteView} payload={trips[activeTripIndex]} />
					)}
				</TripRouteContainer>
			)}
		</Container>
	);
};
export default Trips;
