import styled from 'styled-components';

export const MainContainer = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	background: #ffffff;
`;

export const Header = styled.section`
	width: 100%;
	height: 5em;

	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding-bottom: 1em;

	background: #fff;
	border-bottom: 1px solid ${(props) => props.theme.COLORS.border};
`;

export const CalendarWrapper = styled.section`
	width: 24em;
	height: 100%;
`;

export const GraphsWrapper = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-evenly;
	//flex: 1;
	flex-wrap: wrap;

	background: #fafcfe;
	//overflow-y: auto;
`;

export const GraphContainer = styled.section`
	//flex-basis: 50%;
	//flex-grow: 1;
	//flex-wrap: wrap;
	width: 50%;
	height: 50%;
	//flex: 1;
	box-sizing: border-box;
	padding: 0.5em;
`;

export const GraphCard = styled.section`
	/*width: 100%;
    height: 100%;
    //padding: 6em;

    //flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    //position: relative;

    background: #fff;
    box-shadow:
        rgba(0, 0, 0, 0.05) 0 6px 24px 0,
        rgba(0, 0, 0, 0.08) 0 0 0 1px;
    border-radius: 10px;

    div {
        display: flex;
        justify-content: center;
    }*/
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;

	background: #fff;
	box-shadow:
		rgba(0, 0, 0, 0.05) 0 6px 24px 0,
		rgba(0, 0, 0, 0.08) 0 0 0 1px;
	border-radius: 10px;

	div {
		display: flex;
		justify-content: center;
		/* color: ${(props) => props.theme.COLORS.theme};
        white-space: nowrap; /!* Prevent text from wrapping *!/
        overflow: hidden; /!* Hide text overflow *!/
        text-overflow: ellipsis; /!* Add ellipsis for long text *!/*/
	}
`;

export const GraphHeader = styled.section`
	width: 100%;
	box-sizing: border-box;
	padding: 1em 2em;

	h1 {
		font-size: 1.25em;

		margin: 0;

		color: ${(props) => props.theme.COLORS.theme};
	}
`;
