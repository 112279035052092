import { User } from '../../types/user';

export interface DriverState {
	loading: boolean;
	switching: boolean;
	drivers: User[] | null;
	count: number;
	pageNumber: number;
	driversBackup: User[] | null;
	activeDriver: User | null;
}

interface DriverAction {
	type: string;
	payload?: User[] | number;
}

export default function driver(
	initialState: DriverState = {
		loading: true,
		switching: true,
		drivers: [],
		count: 0,
		pageNumber: 1,
		driversBackup: null,
		activeDriver: null,
	},
	action: DriverAction
) {
	switch (action.type) {
		case 'FEED_DRIVERS':
			return {
				...initialState,
				drivers: action.payload,
				driversBackup: action.payload,
				loading: false,
			};

		case 'DRIVERS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'DRIVERS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'DRIVERS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'DRIVERS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'DRIVERS_SWITCHING':
			return {
				...initialState,
				switching: true,
			};

		case 'DRIVERS_SWITCHED':
			return {
				...initialState,
				switching: false,
			};

		case 'SET_ACTIVE_DRIVER':
			return {
				...initialState,
				activeDriver: action.payload,
			};

		case 'APPEND_DRIVER':
			return {
				...initialState,
				drivers: initialState.drivers ? [...initialState.drivers, action.payload] : [action.payload],
			};

		case 'RESET_ACTIVE_DRIVER':
			return {
				...initialState,
				activeDriver: null,
			};

		case 'UPDATE_DRIVER':
			return {
				...initialState,
				drivers:
					initialState.drivers &&
					initialState.drivers.map((driver) => {
						if (initialState.activeDriver && driver.id !== initialState.activeDriver.id) {
							return driver;
						} else {
							return action.payload;
						}
					}),
			};

		default:
			return initialState;
	}
}
