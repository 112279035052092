interface locationState {
	buttonLoading: boolean;
	trackingLinkModalOpen: boolean;
	immobilizerModalOpen: boolean;
	mobilizerModalOpen: boolean;
	trackingLinkViewModelOpen: boolean;
	mapType: string;
	traffic: boolean;
}

interface locationAction {
	type: string;
	payload?: object;
}

export default function location(
	initialState: locationState = {
		buttonLoading: false,
		trackingLinkModalOpen: false,
		immobilizerModalOpen: false,
		mobilizerModalOpen: false,
		trackingLinkViewModelOpen: false,
		mapType: 'roadmap',
		traffic: false,
	},
	action: locationAction
) {
	switch (action.type) {
		case 'BUTTON_LOADING':
			return {
				...initialState,
				buttonLoading: true,
			};

		case 'BUTTON_LOADED':
			return {
				...initialState,
				buttonLoading: false,
			};

		case 'OPEN_TRACKING_LINK_MODAL':
			return {
				...initialState,
				trackingLinkModalOpen: true,
				buttonLoading: false,
			};

		case 'CLOSE_TRACKING_LINK_MODAL':
			return {
				...initialState,
				trackingLinkModalOpen: false,
				buttonLoading: false,
			};

		case 'OPEN_IMMOBILIZER_MODAL':
			return {
				...initialState,
				immobilizerModalOpen: true,
				buttonLoading: false,
			};

		case 'CLOSE_IMMOBILIZER_MODAL':
			return {
				...initialState,
				immobilizerModalOpen: false,
				buttonLoading: false,
			};

		case 'OPEN_MOBILIZER_MODAL':
			return {
				...initialState,
				mobilizerModalOpen: true,
				buttonLoading: false,
			};

		case 'CLOSE_MOBILIZER_MODAL':
			return {
				...initialState,
				mobilizerModalOpen: false,
				buttonLoading: false,
			};

		case 'OPEN_TRACKING_LINK_VIEW_MODAL':
			return {
				...initialState,
				trackingLinkViewModelOpen: true,
			};

		case 'CLOSE_TRACKING_LINK_VIEW_MODAL':
			return {
				...initialState,
				trackingLinkViewModelOpen: false,
			};

		case 'SET_MAP_TYPE':
			return {
				...initialState,
				mapType: action.payload,
			};

		case 'SHOW_TRAFFIC':
			return {
				...initialState,
				traffic: true,
			};

		case 'REMOVE_TRAFFIC':
			return {
				...initialState,
				traffic: false,
			};

		default:
			return initialState;
	}
}
