import Particles from 'react-tsparticles';
import React from 'react';
import { Button } from 'semantic-ui-react';
import { isSafari } from 'react-device-detect';
import { ISourceOptions } from 'tsparticles';

import { Container } from '../auth/Auth';
import { ParticleOptions } from '../../constants/particle-options';
import { AppLinksContainer, Container as Header, LogoContainer } from '../../components/auth/LoginForm';
import { ANDROID_APP_LINK, IOS_APP_LINK } from '../../constants/constants';
import LogoImage from '../../assets/images/tracknerd-logo.png';
import AppStoreImage from '../../assets/images/app-store.png';
import { isNewLiveTrackingLink, isOldLiveTrackingLink, isValid } from '../../helper/services/common';

interface PropType {
	isAndroid: boolean;
}

const Mobile = (props: PropType) => {
	const openApp = async () => {
		let appUrl = 'lokkate://';

		if (isNewLiveTrackingLink() || isOldLiveTrackingLink()) {
			const hash = isValid(window.location.pathname);
			appUrl = appUrl + 'shareTrackingHash/' + hash;
		}

		// Attempt to open the app URL
		const appLink = document.createElement('a');
		appLink.href = appUrl;
		appLink.click();
	};

	return (
		<Container>
			<Header>
				<LogoContainer>
					<img src={LogoImage} alt="Logo" style={{ height: '50%', width: '100%' }} />
				</LogoContainer>
				<h3 style={{ color: '#1b3c71' }}>Click to start tracking</h3>
				<AppLinksContainer>
					<div>
						{props.isAndroid ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									margin: 'auto',
								}}>
								<a href={ANDROID_APP_LINK} target="_blank" rel="noreferrer" onClick={openApp}>
									<Button primary style={{ height: '48px', width: '110px', borderRadius: 10 }}>
										Open App
									</Button>
								</a>
							</div>
						) : isSafari ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									margin: 'auto',
								}}>
								<a href={IOS_APP_LINK} target="_blank" rel="noreferrer">
									<img
										src={AppStoreImage}
										alt="App Store"
										style={{ height: '50px', width: '120px', marginTop: '2px' }}
									/>
								</a>
								<Button primary style={{ height: '48px', width: '110px', borderRadius: 10 }} onClick={openApp}>
									Open App
								</Button>
							</div>
						) : (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									margin: 'auto',
								}}>
								<a href={IOS_APP_LINK} target="_blank" rel="noreferrer" onClick={openApp}>
									<Button primary style={{ height: '48px', width: '110px', borderRadius: 10 }}>
										Open App
									</Button>
								</a>
							</div>
						)}
					</div>
				</AppLinksContainer>
			</Header>
			<Particles id="tsparticles" className="particle-container" options={ParticleOptions as ISourceOptions} />
		</Container>
	);
};

export default Mobile;
