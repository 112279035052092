import React from 'react';
import PlayStoreImage from '../../../assets/images/play-store.png';
import AppStoreImage from '../../../assets/images/app-store.png';
import Expire from '../../../assets/images/link-expired.png';
import Invalid from '../../../assets/images/link-invalid.png';

// Style
import { Container, ImageContainer, InfoContainer } from './InfoPageStyle';

interface InfoPageInterface {
	hasLinkExpired?: boolean | null;
	noVehicles: boolean | null;
	hasWrongURL?: boolean | null;
}

const InfoPage = (props: InfoPageInterface) => {
	return (
		<Container>
			<ImageContainer>
				{props.hasLinkExpired && <img src={Expire} alt="TrackNerdGPS" />}
				{props.hasWrongURL && <img src={Invalid} alt="TrackNerdGPS" />}
				{props.hasLinkExpired && <h3>Tracking link expired .</h3>}
				{props.hasWrongURL && <h3>Tracking link does not exist.</h3>}

				{props.noVehicles && <h3>No Vehicles to track.</h3>}
			</ImageContainer>
			<InfoContainer>
				<a href="https://play.google.com/store/apps/details?id=com.tracknerd.lokkate" target="_blank" rel="noreferrer">
					<img src={PlayStoreImage} alt="Play Store" />
				</a>
				<a href="https://apps.apple.com/in/app/lokkate/id1546343210" target="_blank" rel="noreferrer">
					<img src={AppStoreImage} alt="App Store" />
				</a>
			</InfoContainer>
		</Container>
	);
};

export default InfoPage;
