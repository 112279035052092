import React, { useEffect, useState } from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import {
	CHANGE_PASSWORD_API,
	DASHBOARD_SUMMARY_API,
	VEHICLE_FLEET_STATUS,
	VEHICLES_API,
	VERIFIED_ASSETS_API,
} from '../../constants/api';
import DashboardNavList from '../../components/vehicles/navigation/DashboardNavList';
import TrackNerdLoader from '../../components/loader/Loader';
import Vehicles from './vehicles/Vehicles';
import GeoFences from './geoFences/GeoFences';
import Drivers from './drivers/Drivers';
import { createOrganisationDTO, createVehicleGroupsDTONew } from '../../dtos/vehicle';
import LogoImage from '../../assets/images/tracknerd-logo-small.png';
import { handleError, handleSuccess } from '../../helper/view specific/messaging';
import { logout } from '../../helper/view specific/auth';
import { DashboardSetting } from './DashboardSetting';
import Store from '../../redux/store';
import { Container, DashboardContainer, Logo, Main, MenuBar, Nav } from './DashboardStyles';
import { Button, Form, Icon, Input, Modal } from 'semantic-ui-react';
import { initializePushNotificationService, resetPushNotificationService } from '../../helper/services/firebase';
import {
	initializeMapType,
	initializePushNotificationEnableStore,
	initializeShownGeoFences,
	initializeTrafficLayer,
} from '../../utils/common';
import axios from 'axios';
import { getAuthHeaders } from '../../utils/auth';
import Documents from './documents/Documents';
import { Expander } from '../../components/STYLED/Expander'; // import { themeColor } from '../../../constants/Theme';
import { getData } from '../../helper/services/axios';
import vehicleSlice from '../../redux/reducers/vehicleSlice'; // import { unSelectedVehicle } from './vehicles/sidebar/SideBarNew2';
import { RootState } from '../../redux/RootState';
import { useSelector } from 'react-redux';
import {
	leastUsedVehicle,
	mostEventVehicle,
	mostIdleVehicle,
	mostRunVehicle,
	mostUsedVehicle,
	Overview,
} from './overview/Overview';
import Pagenotfound from '../Pagenotfound';

// import { sidebarState } from "../../../redux/reducers/sidebar";

interface dashboardPropsInterface {
	match: {
		url: string;
	};
}

interface DashboardGraphs {
	mostRunVehicles: mostRunVehicle[];
	mostIdleVehicles: mostIdleVehicle[];
	mostEventVehicles: mostEventVehicle[];
	mostUsedVehicles: mostUsedVehicle[];
	leastUsedVehicles: leastUsedVehicle[];
}

const Dashboard = (props: dashboardPropsInterface) => {
	let permissions: string;
	const [loading, setLoading] = useState(true);
	const [passwordModalOpen, setPasswordModalOpen] = useState(false);
	const [notificationModalOpen, setNotificationModalOpen] = useState(false);
	const [newPassword, setNewPassword] = useState('');
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [passwordChanging, setPasswordChanging] = useState(false);
	const [asset, setAsset] = useState<string | null>(null);
	const [assetType, setAssetType] = useState<number | null>(null);
	const [assetVerifyModalOpen, setAssetVerifyModalOpen] = useState(false);
	const [linkSending, setLinkSending] = useState(false);
	const [dashboardGraphData, setDashboardGraphData] = useState<DashboardGraphs | null>(null);

	const selectedVehicles = useSelector((state: RootState) => state.sidebar.selectedVehicles);
	const intervalId = useSelector((state: RootState) => state.vehicle.intervalId);

	useEffect(() => {
		axios(VEHICLES_API + '/tree', getAuthHeaders())
			.then((response) => {
				if (Object.hasOwn(response?.data, 'organisations')) {
					const dtoResponse = createOrganisationDTO(response.data.organisations);

					if (dtoResponse !== undefined) {
						const [organisationData, vehicles, vehicleList] = dtoResponse;
						Store.dispatch({
							type: 'VEHICLES_DETAILS',
							payload: vehicles,
						});
						Store.dispatch({
							type: 'FEED_VEHICLES',
							payload: vehicleList,
						});

						Store.dispatch({
							type: 'FEED_ORGANISATIONS',
							payload: organisationData,
						});
					}
				} else if (Object.hasOwn(response?.data, 'vehicleGroups')) {
					const dtoResponse = createVehicleGroupsDTONew(response.data.vehicleGroups);

					if (dtoResponse !== undefined) {
						const [groupData, vehicles, vehicleList] = dtoResponse;
						Store.dispatch({
							type: 'VEHICLES_DETAILS',
							payload: vehicles,
						});
						Store.dispatch({
							type: 'FEED_VEHICLES',
							payload: vehicleList,
						});
						Store.dispatch({
							type: 'FEED_VEHICLE_GROUPS',
							payload: groupData,
						});
						Store.dispatch({
							type: 'FEED_ORGANISATIONS',
							payload: null,
						});
					}
				}

				// connectToFirebase2(vehicleList);
				// const id = vehicleList.map((obj) => obj.id);

				initializeShownGeoFences();
				initializeMapType();
				initializeTrafficLayer();
				//initializeFreshDeskChat();

				if (Notification.permission !== 'granted') resetPushNotificationService();
				initializePushNotificationService();
				initializePushNotificationEnableStore();
				setLoading(false);
			})
			.catch((error) => {
				handleError('Dashboard.tsx => UseEffect(): ', error);
				setLoading(false);
			});

		getData(DASHBOARD_SUMMARY_API)
			.then((res) => {
				setDashboardGraphData(res?.data?.metadata);
			})
			.catch((error) => {
				handleError('Dashboard.tsx => UseEffect(): => getData()', error);
			});

		const storedPermissions: string | null = localStorage.getItem('permissions');
		if (storedPermissions) {
			permissions = JSON.parse(storedPermissions);
		}

		Store.dispatch({
			type: 'PERMISSIONS',
			payload: permissions,
		});

		callTheVehicles(false);
		callTheVehicles(true);
		return () => {
			if (intervalId) clearInterval(intervalId);
		};
	}, []);

	const vehicleFleetStatus = () => {
		getData(VEHICLE_FLEET_STATUS)
			.then((res) => {
				const locationData = res.data.metadata.locationData;

				let data = [];
				if (selectedVehicles !== null) {
					if (selectedVehicles.length > 0) {
						for (let i = 0; i < locationData.length; i++) {
							if (!selectedVehicles.includes(locationData[i].id)) {
								data.push(locationData[i]);
							}
						}
					} else data = locationData;
				}
				Store.dispatch(vehicleSlice.actions.vehicleMultiUpdate(data));

				Store.dispatch({
					type: 'SET_COUNTERS',
					payload: {
						movingVehicles: res.data.metadata.count.moving,
						idleVehicles: res.data.metadata.count.idle,
						stoppedVehicles: res.data.metadata.count.ignitionOff,
						noDataOneHourVehicles: res.data.metadata.count.noData1Hour,
						noDataOneDayVehicles: res.data.metadata.count.noData1Day,
						noDataVehicles: res.data.metadata.count.unknown,
						totalVehicles: res.data.count,
					},
				});
			})
			.catch((error) => {
				handleError('Dashboard.tsx => vehicleFleetStatus()', error);
			});
	};

	const callTheVehicles = (isItInterval: boolean) => {
		if (isItInterval) {
			const startCallingVehicles = setInterval(() => {
				vehicleFleetStatus();
			}, 30000);

			Store.dispatch({ type: 'API_CALLS_INTERVAL_ID', payload: { startCallingVehicles } });
			localStorage.setItem('flee-status-id', String(startCallingVehicles));
		} else vehicleFleetStatus();
	};

	const changePassword = () => {
		setPasswordChanging(true);

		const query = {
			password: newPassword,
		};

		axios
			.post(CHANGE_PASSWORD_API + `/${localStorage.getItem('userId')}/change-password`, query, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				cleanupChangePassword();
				handleSuccess(response);
				logout().catch();
			})
			.catch((error) => {
				handleError('Dashboard.tsx => changePassword()', error);
				setPasswordChanging(false);
			});
	};

	const openPasswordModal = () => {
		setPasswordModalOpen(true);
	};

	const openPaymentPolicy = () => {
		window.open('https://support.tracknerd.io/portal/en/kb/articles/tracknerd-payment-policy', '_blank', 'noreferrer');
	};

	const openServicePolicy = () => {
		window.open(
			'https://support.tracknerd.io/portal/en/kb/articles/tracknerd-installation-service-repairs-policy',
			'_blank',
			'noreferrer'
		);
	};

	const openSendVerifyAssetLink = (assetType: number, asset: string): void => {
		setAsset(asset);
		setAssetType(assetType);
		setAssetVerifyModalOpen(true);
	};

	const closeSendVerifyAssetLink = () => {
		setAsset(null);
		setAssetType(null);
		setAssetVerifyModalOpen(false);
		setLinkSending(false);
	};

	const onAssetSendButtonClick = () => {
		setLinkSending(true);
		axios
			.post(
				VERIFIED_ASSETS_API,
				{ asset: asset, assetType: assetType },
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				closeSendVerifyAssetLink();
				handleSuccess(response);
			})
			.catch((error) => {
				handleError('Dashboard.tsx => onAssetSendButtonClick()', error);
				setLinkSending(false);
			});
	};

	const openNotificationModal = () => {
		setNotificationModalOpen(true);
	};

	const closeNotificationModal = () => {
		setNotificationModalOpen(false);
	};

	/*toggleChatVisibility() {
    localStorage.setItem('showFreshDeskChat', !this.props.freshDeskWidget);
    if (this.props.freshDeskWidget) {
      Store.dispatch({type: 'HIDE_FRESHDESK_WIDGET'});
      hideFreshDeskWidget();
    } else {
      Store.dispatch({type: 'SHOW_FRESHDESK_WIDGET'});
      showFreshDeskWidget();
    }
  }*/

	// const togglePushNotificationVisibility = async () => {
	//   setPushNotificationPreference();
	// };

	const cleanupChangePassword = () => {
		setPasswordModalOpen(false);
		setNewPassword('');
		setPasswordVisible(false);
		setPasswordChanging(false);
	};

	return loading ? (
		<Container>
			<TrackNerdLoader />
		</Container>
	) : (
		<Container>
			<Nav>
				<section>
					<DashboardNavList urlPrefix={props.match.url} />
				</section>
			</Nav>
			<DashboardContainer style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
				<MenuBar>
					<NavLink to={props.match.url} exact>
						<Logo>
							<section>
								<img src={LogoImage} alt="TrackNerdGPS" />
							</section>
						</Logo>
					</NavLink>
					{/*<LogoHeader>*/}
					<div
						style={{
							color: '#1b3c71',
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'baseline',
							marginTop: '10px',
						}}>
						<span style={{ fontSize: '1.8rem', fontWeight: 'bold', margin: '0 .5rem' }}>Lokkate</span>{' '}
						<span style={{ fontSize: '1rem', fontWeight: '600' }}> All Fleet. One Platform.</span>
					</div>
					<Expander />
					<DashboardSetting
						onChangePassword={openPasswordModal}
						onAssetVerifyClick={openSendVerifyAssetLink}
						onPaymentPolicyClick={openPaymentPolicy}
						onServicePolicyClick={openServicePolicy}
					/>
				</MenuBar>
				<Main>
					<Switch>
						<Route
							path={props.match.url}
							exact
							component={() => {
								if (dashboardGraphData) return <Overview graphData={dashboardGraphData} />;
							}}
						/>
						<Route path={props.match.url + 'vehicles'} component={Vehicles} />
						<Route path={props.match.url + 'geofences'} component={GeoFences} />
						<Route path={props.match.url + 'documents'} component={Documents} />
						<Route path={props.match.url + 'drivers'} component={Drivers} />
						<Route path="*" component={Pagenotfound} />

						{/*<Route path={this.props.match.url + 'notifications'} component={Notifications} />*/}
						{/*<Route path={this.props.match.url + 'delivery'} component={Delivery} />*/}
					</Switch>
				</Main>
				{passwordModalOpen && (
					<Modal
						size="mini"
						onClose={() => cleanupChangePassword()}
						onOpen={() => setPasswordModalOpen(true)}
						open={passwordModalOpen}>
						<Modal.Header>Change Password</Modal.Header>
						<Modal.Content>
							<Form>
								<Form.Group widths="equal">
									<Form.Field>
										<label>New Password</label>
										<Input
											fluid
											icon={
												<Icon
													name={passwordVisible ? 'eye' : 'eye slash'}
													link
													onClick={() => setPasswordVisible((prevState) => !prevState)}
												/>
											}
											type={passwordVisible ? 'text' : 'password'}
											onChange={(e) => setNewPassword(e.target.value)}
										/>
									</Form.Field>
								</Form.Group>
							</Form>
						</Modal.Content>
						<Modal.Actions>
							<Button onClick={() => cleanupChangePassword()}>Cancel</Button>
							<Button primary disabled={!newPassword} loading={passwordChanging} onClick={changePassword}>
								Submit
							</Button>
						</Modal.Actions>
					</Modal>
				)}
				{assetVerifyModalOpen && (
					<Modal
						dimmer="inverted"
						size="mini"
						open={assetVerifyModalOpen}
						// onOpen={openSendVerifyAssetLink}
						onClose={() => closeSendVerifyAssetLink()}
						closeOnEscape={false}>
						<Modal.Header>Send Verification</Modal.Header>
						<Modal.Content scrolling={false}>
							<p>Do you want to send verification link ?</p>
						</Modal.Content>
						<Modal.Actions>
							<Button color="black" onClick={closeSendVerifyAssetLink}>
								Cancel
							</Button>
							<Button positive loading={linkSending} onClick={onAssetSendButtonClick}>
								Send
							</Button>
						</Modal.Actions>
					</Modal>
				)}
				{notificationModalOpen && (
					<Modal
						size="mini"
						onClose={() => closeNotificationModal()}
						onOpen={() => openNotificationModal()}
						open={notificationModalOpen}>
						<Modal.Header>Manage Your Notifications</Modal.Header>
						{/*  <Modal.Content>
                 <Form>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>New Password</label>
                      <Input
                          fluid
                          icon={
                            <Icon
                                name={passwordVisible ? 'eye' : 'eye slash'}
                                link
                                onClick={this.togglePasswordVisibility}
                            />
                          }
                          type={passwordVisible ? 'text' : 'password'}
                          onChange={(e) => this.setState({newPassword: e.target.value})}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={this.cleanupChangePassword}>Cancel</Button>
                <Button
                    primary
                    disabled={!newPassword}
                    loading={passwordChanging}
                    onClick={this.changePassword}>
                  Submit
                </Button>
              </Modal.Actions>  */}
					</Modal>
				)}
			</DashboardContainer>
		</Container>
	);
};

// const matchStateToProps = (state) => ({
//
//     selectedVehicles: state.sidebar.selectedVehicles,
// });

export default Dashboard;
