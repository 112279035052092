import styled from 'styled-components';
import { THEME } from '../../constants/Theme';

export const MarkerInfo = styled.section`
	//min-width: 18em;
	max-width: 22em;
`;

export const EventsInfo = styled.section`
	//min-width: 18em;
	max-width: 22em;
`;

export const MarkerHeader = styled.section`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const MarkerInfoRegNo = styled.section`
	font-size: 1em;
	font-weight: bold;
`;

export const MarkerInfoDriver = styled.section`
	display: flex;
	flex-direction: row;
	align-items: center;

	i.icon {
		height: 100%;

		margin: 0 !important;
		padding-top: 0.1em;
		box-sizing: border-box;
	}

	span {
		display: inline-block;

		//margin-left: 0.5em;

		font-size: 0.9em;
		font-weight: bold;
	}
`;

export const MarkerInfoOdometer = styled.section`
	display: flex;
	flex-direction: row;
	align-items: center;

	i.icon {
		margin: 0 !important;
	}

	span {
		display: inline-block;

		//margin-left: 0.5em;

		font-size: 0.9em;
		font-weight: bold;
	}
`;

export const MarkerInfoIgnitionOn = styled.section`
	font-size: 0.9em;
	font-weight: bold;

	margin-bottom: 0.25em;

	color: ${(props) => props.theme.COLORS.positive};
`;

export const MarkerInfoMoving = styled.section`
	font-size: 0.9em;
	font-weight: bold;

	margin-bottom: 0.25em;

	color: ${(props) => props.theme.COLORS.vehicleMoving};
`;

export const MarkerInfoIdle = styled.section`
	font-size: 0.9em;
	font-weight: bold;

	margin-bottom: 0.25em;

	color: ${(props) => props.theme.COLORS.warning};
`;

export const MarkerInfoIgnitionOff = styled.section`
	font-size: 0.9em;
	font-weight: bold;

	margin-bottom: 0.25em;

	color: ${(props) => props.theme.COLORS.vehicleIgnitionOff};
`;

export const MarkerInfoNoNetwork = styled.section`
	font-size: 0.9em;
	font-weight: bold;

	margin-bottom: 0.25em;

	color: ${(props) => props.theme.COLORS.vehicleNoDataHalfHour};
`;

export const MarkerInfoRow = styled.section`
	display: flex;
	justify-content: space-between;

	font-size: 0.9em;
	font-weight: bold;
`;

export const MarkerInfoBatteryLevelRed = styled.span`
	color: ${(props) => props.theme.COLORS.batteryLevelRed};
`;

export const MarkerInfoBatteryLevelGreen = styled.span`
	color: ${(props) => props.theme.COLORS.batteryLevelGreen};
`;

export const MarkerInfoBatteryLevelYellow = styled.span`
	color: ${(props) => props.theme.COLORS.batteryLevelYellow};
`;

export const MarkerInfoBatteryLevelOrange = styled.span`
	color: ${(props) => props.theme.COLORS.batteryLevelOrange};
`;

export const MarkerInfoControls = styled.section`
	margin: 0.5em 0;
`;

export const MarkerInfoAddress = styled.section`
	margin: 0.5em 0 0.5em 0;

	font-size: 0.9em;
	font-weight: bold;
	color: ${(props) => props.theme.COLORS.theme};

	button {
		margin-left: 0.5em !important;
		padding: 0.25em 0.5em !important;

		border-radius: 2em !important;

		font-size: 0.95em !important;
	}
`;

export const EventsInfoBox = styled.section`
	width: 20em;
	max-height: 20em;

	box-sizing: border-box;
	padding: 0.25em;

	overflow-y: auto;
`;

export const EventsInfoHeader = styled.section`
	h4 {
		font-size: 0.9em;
		color: ${(props) => props.theme.COLORS.theme};
	}

	margin-bottom: 0.5em;
`;

interface EventsInfoCardProps {
	status?: string;
}

export const EventsInfoCard = styled.section<EventsInfoCardProps>`
	width: 100%;
	height: 2.5em;

	display: flex;
	align-items: center;
	justify-content: space-between;

	box-sizing: border-box;
	padding: 0.5em;
	margin-bottom: 0.5em;

	font-size: 0.8em;
	font-weight: bold;

	color: ${(props) => {
		return props.status ? props.theme.COLORS.eventTrueTextColor : props.theme.COLORS.eventFalseTextColor;
	}};

	background: ${(props) => {
		return props.status ? props.theme.COLORS.eventTrueBackground : props.theme.COLORS.eventFalseBackground;
	}};

	border: 1px solid
		${(props) => {
			return props.status ? props.theme.COLORS.eventTrueBorderColor : props.theme.COLORS.eventFalseBorderColor;
		}};

	border-radius: 3px;
`;

export const Status = styled.section`
	font-size: 0.9em;
	font-weight: bold;

	color: ${(props) => props.color};
`;

export const VehicleStatus = styled.section`
	font-size: 0.75em;
	font-weight: bold;
	line-height: normal;
	margin: 2px 0;

	color: ${(props) =>
		props.color === 'Breakdown' ? THEME.COLORS.Breakdown : props.color === 'Paused' ? THEME.COLORS.Paused : ''};
`;
