import { isoToCustom } from '../utils/calendar';
import { BatteryRechargeData } from '../types/batteryRecharge';

interface BatteryRechargeAPIResponse {
	id: string;
	timestamp: string;
	energyDrawn: number;
	totalSoc: number;
	chargedSoc: number;
	typeOfCharge: string;
	chargeDuration: string;
	chargeStartTime: string;
	chargeEndTime: string;
	vehicle: {
		id: number;
		registrationNumber: string;
	};
}

class BatteryRecharge {
	id: string;
	timestamp: string;
	energyDrawn: number;
	totalSoc: number;
	chargedSoc: number;
	typeOfCharge: string;
	chargeDuration: string;
	chargeStartTime: string;
	chargeEndTime: string;
	registrationNumber: string;
	vehicleId: number;

	constructor(
		id: string,
		timestamp: string,
		energyDrawn: number,
		totalSoc: number,
		chargedSoc: number,
		typeOfCharge: string,
		chargeDuration: string,
		chargeStartTime: string,
		chargeEndTime: string,
		registrationNumber: string,
		vehicleId: number
	) {
		this.id = id;
		this.timestamp = timestamp;
		this.energyDrawn = energyDrawn;
		this.totalSoc = totalSoc;
		this.chargedSoc = chargedSoc;
		this.typeOfCharge = typeOfCharge;
		this.chargeDuration = chargeDuration;
		this.chargeStartTime = chargeStartTime;
		this.chargeEndTime = chargeEndTime;
		this.registrationNumber = registrationNumber;
		this.vehicleId = vehicleId;
	}
}

export function createBatteryRechargeDTO(sumData: BatteryRechargeAPIResponse[]): BatteryRechargeData[] {
	let rechargeData: BatteryRechargeData[] = [];

	if (sumData && sumData.length) {
		rechargeData = sumData.map((data) => {
			const timestamp = isoToCustom(data.timestamp, 'DD-MM-YYYY hh:mm:ss');

			return new BatteryRecharge(
				data?.id,
				timestamp,
				data?.energyDrawn ? data.energyDrawn : 0,
				data?.totalSoc ? data.totalSoc : 0,
				data?.chargedSoc ? data.chargedSoc : 0,
				data?.typeOfCharge ? data.typeOfCharge : '-',
				data?.chargeDuration ? data.chargeDuration : '-',
				data?.chargeStartTime ? isoToCustom(data.chargeStartTime, 'DD-MM-YYYY hh:mm:ss') : '-',
				data?.chargeEndTime ? isoToCustom(data.chargeEndTime, 'DD-MM-YYYY hh:mm:ss') : '-',
				data.vehicle.registrationNumber,
				data.vehicle.id
			);
		});
	}

	return rechargeData;
}
