import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../constants/constants';
import { THEME } from '../../constants/Theme';

interface CategoryHeaderProps {
	borderRadius?: string;
}

export const CategoryHeader = styled.section<CategoryHeaderProps>`
	width: 100%;
	height: ${HEADER_HEIGHT};

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 0 1em;

	background: ${THEME.COLORS.headerBackground};
	border-bottom: 1px solid ${THEME.COLORS.headerBorder};
	border-top-left-radius: ${(props) => props.borderRadius || 0};
	border-top-right-radius: ${(props) => props.borderRadius || 0};

	.ui.button:disabled {
		pointer-events: auto !important;
	}
`;
