import React, { useState } from 'react';
import { loadGeoFences, setActiveGeoFence } from '../GeoFencesMiddleware';
import { useSelector } from 'react-redux';
import { SidebarContainer } from '../../../../components/STYLED/Containers';
import { Card, CardsContainer, Name, Size } from './SidebarStyles';

// Theme
import { THEME } from '../../../../constants/Theme';
import { Icon, Input } from 'semantic-ui-react';
import { SearchContainer } from '../GeoFencesStyles';
import { formatDistance } from '../../../../utils/common';
import { RootState } from '../../../../redux/RootState';
import { GeoFenceDTOData } from '../../../../dtos/geofence';

const Sidebar = () => {
	// Search

	const [searchQuery, setSearchQuery] = useState<string>('');

	const { geoFences, activeGeoFence } = useSelector((state: RootState) => state.geofence);
	const isGeofenceActive = (geoFence: GeoFenceDTOData) => {
		return activeGeoFence && activeGeoFence.id === geoFence.id;
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(e.target.value);
		if (e.target.value.length > 0) loadGeoFences(`&search=${e.target.value}`, true);
	};

	return (
		<SidebarContainer>
			{/*Search Box*/}
			<SearchContainer>
				<Input
					value={searchQuery}
					placeholder="Search..."
					{...(searchQuery && {
						icon: (
							<Icon
								name="times circle outline"
								link
								onClick={() => {
									setSearchQuery('');
									loadGeoFences('', true);
								}}
							/>
						),
					})}
					style={{ width: '100%' }}
					onChange={handleSearch}
				/>
			</SearchContainer>
			<CardsContainer>
				{geoFences &&
					geoFences.map((geoFence, index: number) => {
						return (
							<Card
								key={index}
								onClick={() => {
									setActiveGeoFence(geoFence);
								}}
								style={{
									borderRight: isGeofenceActive(geoFence) ? `3px solid ${THEME.COLORS.accent}` : '0px',
								}}>
								<section>
									<Name>
										<span>{geoFence.name}</span>
									</Name>
									<Size>
										<span>
											{geoFence.address} ({formatDistance(geoFence.radius)})
										</span>
									</Size>
								</section>
							</Card>
						);
					})}
			</CardsContainer>
		</SidebarContainer>
	);
};

export default Sidebar;
