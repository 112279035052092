import React from 'react';
import styled from 'styled-components';

import VerificationPageFooter from './VerificationPageFooter';
import Verified from '../../assets/images/email-verified.jpg';
import LogoImage from '../../assets/images/tracknerd-logo.png';

interface PropType {
	assetType: number;
}

const EmailVerified = (props: PropType) => {
	return (
		<Container>
			<Header>
				<Logo>
					<img src={LogoImage} alt="TrackNerdGPS" />
				</Logo>
			</Header>
			<Content>
				<ImageContainer>
					<img src={Verified} alt="TrackNerdGPS" />
					<Status>Your {props.assetType === 1 ? 'phone number' : 'email address'} has been verified.</Status>
				</ImageContainer>
				<VerificationPageFooter />
			</Content>
		</Container>
	);
};

export default EmailVerified;

const Container = styled.section`
	width: 100%;
	height: calc(100vh);

	display: block;
	align-items: center;
	justify-content: center;

	position: relative;
`;

const Content = styled.section`
	width: 100%;

	display: block;
	align-items: center;
	justify-content: center;

	position: relative;
`;

const Status = styled.section`
	box-sizing: border-box;
	padding: 0.75em 5em;

	font-size: 1.25em;
	font-weight: bold;

	border-radius: 10px;
	background-color: rgba(13%, 73%, 27%, 0.6);
`;

const Header = styled.section`
	width: 100%;
	height: 5em;

	box-sizing: border-box;
	padding: 1em 1em 0 1em;

	display: flex;
	justify-content: center;
	align-items: center;
`;

const Logo = styled.section`
	height: 100%;

	box-sizing: border-box;
	padding: 0.25em;

	display: flex;
	align-items: center;
	justify-content: center;

	img {
		height: 100%;
		max-height: 5em;
	}
`;

const ImageContainer = styled.section`
	margin: 5em 0 2em 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: relative;

	img {
		height: 120px;
		margin: 1em 0 4em 0;
	}
`;
