import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
	height: calc(100vh);

	display: block;
	align-items: center;
	justify-content: center;

	position: relative;
`;

export const Header = styled.section`
	width: 100%;
	height: 4em;

	box-sizing: border-box;
	padding: 0 1em;

	display: flex;
	align-items: center;

	border-bottom: 1px solid #ebebeb;
`;

export const Logo = styled.section`
	height: 100%;

	box-sizing: border-box;
	padding: 0.25em;

	display: flex;
	align-items: center;
	justify-content: center;

	img {
		height: 100%;
		max-height: 3em;
	}
`;

export const MapContainer = styled.section`
	width: 100%;
	height: calc(100vh - 4em);

	position: relative;
`;
