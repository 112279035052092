import styled from 'styled-components';

export const SearchContainer = styled.section`
	width: 100%;

	margin-top: 1em;
	box-sizing: border-box;
	padding: 0 1em;
`;

export const CardsContainer = styled.section`
	width: 100%;
	flex-grow: 1;

	background: #fafbfc;

	overflow-y: auto;
`;

export const Card = styled.section`
	width: 100%;

	box-sizing: border-box;
	padding: 0.5em 1.25em;
	border-bottom: 1px solid #eee;

	display: flex;
	align-items: center;

	background: #fff;
	cursor: pointer;

	&:hover {
		color: ${(props) => props.theme.COLORS.theme};
	}

	i.icon {
		margin: 0;
	}
`;

export const Name = styled.section`
	span {
		font-size: 1.2em;
	}
`;

export const Size = styled.section`
	span {
		font-size: 0.8em;
		font-weight: bold;
	}
`;
