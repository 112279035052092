import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';

import { VEHICLE_STATUS } from '../../../constants/constants';
import { Container, FiltersContainer, StatusContainer } from './VehicleFiltersStyles';
import { RootState } from '../../../redux/RootState';

interface VehicleProps {
	allVehicleSelect: () => void;
	movingVehicleSelect: () => void;
	idleVehicleSelect: () => void;
	stoppedVehicleSelect: () => void;
	noDataOneHourVehicleSelect: () => void;
	noDataOneDayVehicleSelect: () => void;
	noDataVehicleSelect: () => void;
}

const VehicleFilters = (props: VehicleProps) => {
	const {
		totalVehicles,
		movingVehicles,
		idleVehicles,
		stoppedVehicles,
		noDataOneHourVehicles,
		noDataOneDayVehicles,
		noDataVehicles,
	} = useSelector((state: RootState) => state.vehicle.counters);
	const filter = useSelector((state: RootState) => state.sidebar.filter);

	return (
		<Container>
			<FiltersContainer>
				<StatusContainer title="All Vehicles">
					<Checkbox checked={filter === 'all'} onClick={props.allVehicleSelect} />
					<span>{totalVehicles}</span>
				</StatusContainer>
				<StatusContainer title="Moving (Data arrived within 60 minutes)">
					<Checkbox checked={filter === VEHICLE_STATUS.Moving} onClick={props.movingVehicleSelect} />
					<span>{movingVehicles}</span>
				</StatusContainer>
				<StatusContainer title="Idle (Data arrived within 60 minutes)">
					<Checkbox checked={filter === VEHICLE_STATUS.Idle} onClick={props.idleVehicleSelect} />
					<span>{idleVehicles}</span>
				</StatusContainer>
				<StatusContainer title="Ignition Off (Data arrived within 60 minutes)">
					<Checkbox checked={filter === VEHICLE_STATUS.Stopped} onClick={props.stoppedVehicleSelect} />
					<span>{stoppedVehicles}</span>
				</StatusContainer>
				<StatusContainer title="No Data In Last 60 minutes">
					<Checkbox checked={filter === VEHICLE_STATUS.NoDataOneHour} onClick={props.noDataOneHourVehicleSelect} />
					<span>{noDataOneHourVehicles}</span>
				</StatusContainer>
				<StatusContainer title="No Data In Last 24 Hours">
					<Checkbox checked={filter === VEHICLE_STATUS.NoDataOneDay} onClick={props.noDataOneDayVehicleSelect} />
					<span>{noDataOneDayVehicles}</span>
				</StatusContainer>
				<StatusContainer title="No Data">
					<Checkbox checked={filter === VEHICLE_STATUS.NoData} onClick={props.noDataVehicleSelect} />
					<span>{noDataVehicles}</span>
				</StatusContainer>
			</FiltersContainer>
		</Container>
	);
};

export default VehicleFilters;
