import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../constants/constants';
import { THEME } from '../../../constants/Theme';

interface StyleProps {
	fontStyle?: string;
	fontColor?: string;
	borderRadius?: string;
}

export const Container = styled.section`
	width: 100%;
	height: 100%;

	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const MainContainer = styled.section`
	width: 100%;
	height: 100%;
	background-color: #f5f6fe;
`;

export const CardContainer = styled.section`
	height: calc(100% - 4em);

	display: flex;
	align-content: flex-start;
	//justify-content: space-evenly;
	flex-wrap: wrap;

	box-sizing: border-box;
	padding: 1em;

	background: #fafcfe;

	overflow-y: auto;
`;

export const Card = styled.section`
	//flex-basis: 1%;
	//flex-grow: 1;
	width: 19%;
	//max-width: 19%;

	margin: 0.5em;
	box-sizing: border-box;
	padding: 1em;

	background: #fff;
	border: 1px solid #ddd;
	border-radius: 10px;

	display: flex;
	//flex: 1;
	flex-direction: column;
	align-items: center;

	&:hover {
		box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
	}
`;

export const DocumentsPlaceholder = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: relative;

	h1 {
		position: absolute;
		top: 0;
		left: 0;

		margin: 0;
	}

	img {
		width: 128px;
	}
`;

export const ContentWrapper = styled.section`
	width: 100%;
	height: 100%;

	box-sizing: border-box;
	padding: 2em;

	background: #fafcfe;

	overflow-y: auto;
`;

export const Content = styled.section`
	width: 100%;
	max-width: 800px;

	box-sizing: border-box;
	padding: 2em 3em;
	margin: 0 auto;

	background: #fff;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0 1px 2px 0,
		rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
	border-radius: 10px;

	h1 {
		color: ${(props) => props.theme.COLORS.theme};
	}
`;

export const Title = styled.section`
	display: flex;
	align-items: center;
	justify-content: space-between;

	h2 {
		margin: 0;
		color: ${(props) => props.theme.COLORS.theme};
	}
`;

export const Block = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;

	margin: 2em 0;
`;

export const Info = styled.section`
	flex-grow: 1;
`;

export const CertificateType = styled.section`
	margin-bottom: 0.5em;

	span {
		font-size: 1.2em;
	}
`;

export const HorizontalBlock = styled.section`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const VerticalBlock = styled.section`
	display: contents;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

export const VehicleInfo = styled.section`
	justify-content: space-between;
	align-items: center;
	flex: 1;
	display: flex;
	width: 100%;

	img {
		width: 20%;
		height: 42px;
		margin-right: 1em;
	}

	section {
		display: flex;
		flex: 1;
		flex-direction: column;
	}
`;

export const VehicleNumber = styled.section`
	span {
		font-size: 1.2em;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

export const OrgName = styled.section`
	span {
		font-size: 0.8em;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

export const VehicleType = styled.section`
	margin-top: 1em;

	span {
		font-size: 0.95em;
		font-weight: bold;
		color: ${(props) => props.theme.COLORS.theme};
	}
`;

export const MainContent = styled.section`
	width: 100%;
	height: calc(100% - ${HEADER_HEIGHT});
`;

export const DocumentBlock = styled.section<StyleProps>`
	display: flex;
	flex: 1;
	min-width: 100%;
	//flex-direction: row;
	flex-wrap: wrap;
	//justify-content: space-evenly;
	align-items: center;
	border-radius: 5px;
	padding: 5px;
	margin: 0.25em;
	background-color: ${(props) => props.color};
	font-style: ${(props) => props.fontStyle};
	color: ${(props) => props.fontColor};
	cursor: pointer;

	&:hover {
		box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
	}
`;

export const DocumentName = styled.section`
	flex: 1;
	flex-wrap: wrap;
	flex-direction: row;

	span {
		color: ${(props) => props.color};
	}
`;

export const DocumentExpiryDate = styled.section`
	flex: 1;
`;

export const ToBeExpired = styled.section`
	span {
		color: ${(props) => props.color};
	}
`;

export const PageSelector = styled.section`
	width: 6em;

	margin-right: 1em;

	border: none;
	border-radius: 5px;
	box-shadow:
		rgba(60, 64, 67, 0.3) 0 1px 2px 0,
		rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
`;

export const Label = styled.section`
	width: 10em;

	span {
		font-size: 0.9em;
		font-weight: bold;
	}
`;

export const Value = styled.section`
	span {
		font-size: 0.9em;
	}
`;

export const PlaceHolder = styled.section`
	width: 100%;
	height: calc(100% - 4em);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background: #fafcfe;

	img {
		width: 400px;
	}

	h4 {
		margin: 3em 0;

		color: #888;
	}
`;

export const CategoryHeader = styled.section<StyleProps>`
	width: 100%;
	height: ${HEADER_HEIGHT};

	display: flex;
	align-items: center;
	justify-content: space-between;

	box-sizing: border-box;
	//padding: 0 1em;

	background-color: #f5f5f5;
	//border-bottom: 1px solid ${THEME.COLORS.headerBorder};
	border-top-left-radius: ${(props) => props.borderRadius || 0};
	border-top-right-radius: ${(props) => props.borderRadius || 0};

	.ui.button:disabled {
		pointer-events: auto !important;
	}
`;

export const DocumentTypeBlock = styled.button<StyleProps>`
	.ui.button {
		display: flex;
		max-width: 100%;
		flex-direction: row;
		flex-wrap: wrap;
		background-color: ${(props) => props.color};
		font-style: ${(props) => props.fontStyle};
		color: ${(props) => props.fontColor};
		cursor: pointer;
	}

	max-width: 15%;
	flex: 1;
	border: 0;
	padding: 0;
	margin: 0.25em;
	justify-content: space-between;
	align-items: center;
	border-radius: 5px;

	&:hover {
		box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
	}
`;

export const DocumentTypeHeader = styled.section`
	max-width: 90px;
	display: flex;
	flex: 1;
`;

export const DocumentCardFooter = styled.section`
	display: flex;
	justify-content: space-between;
`;

export const SearchContainer = styled.section`
	height: 4em;

	box-sizing: border-box;
	padding: 0.5em;

	.ui.input {
		height: 100%;
	}
`;

export const Filter = styled.section`
	//display: flex;
	//align-items: center;
`;

export const FilterCount = styled.section`
	margin-left: 0.5em;

	color: ${(props) => props.theme.COLORS.danger};
`;
