import { Vehicle } from '../../types/vehicle';
import { TripDTO } from '../../dtos/trip';
import { SummaryDTO } from '../../dtos/summary';
import { EventDTOInterface } from '../../types/event';
import { BatteryRechargeData } from '../../types/batteryRecharge';

export interface reduceApiState {
	eventDataStore: {
		total: number;
		activePage: number;
		data: EventDTOInterface[];
		filters: string[];
	};
	summaryDataStore: {
		total: number;
		activePage: number;
		report: string;
		data: SummaryDTO[][];
	};
	batteryDataStore: {
		total: number;
		activePage: number;
		data: BatteryRechargeData[];
	};
	trackDataStore: Vehicle[];
	tripDataStore: {
		total: number;
		activePage: number;
		data: TripDTO[];
	};
}

interface RootStateAction {
	type: string;
	payload?: object;
}

export default function reduceApi(
	initialState: reduceApiState = {
		eventDataStore: {
			total: 0,
			activePage: 1,
			data: [],
			filters: [],
		},
		summaryDataStore: {
			total: 0,
			activePage: 1,
			report: 'daily',
			data: [],
		},
		batteryDataStore: {
			total: 0,
			activePage: 1,
			data: [],
		},
		trackDataStore: [],
		tripDataStore: {
			total: 0,
			activePage: 1,
			data: [],
		},
	},
	action: RootStateAction
) {
	switch (action.type) {
		case 'EVENT_DATA_STORE':
			return {
				...initialState,
				eventDataStore: {
					...initialState.eventDataStore,
					...action.payload,
				},
			};
		case 'SUMMARY_DATA_STORE':
			return {
				...initialState,
				summaryDataStore: action.payload,
			};
		case 'BATTERY_DATA_STORE':
			return {
				...initialState,
				batteryDataStore: action.payload,
			};
		case 'TRACK_DATA_STORE':
			return {
				...initialState,
				trackDataStore: action.payload,
			};
		case 'TRIP_DATA_STORE':
			return {
				...initialState,
				tripDataStore: action.payload,
			};
		default:
			return initialState;
	}
}
