export interface sidebarState {
	selectedVehicles: object[] | null;
	unSelectedVehicle: object[];
	selectedVehicle: object[] | null;
	selectedGroups: [];
	selectedOrganisations: [];
	selectedFew: boolean;
	selectedAll: boolean;
	searchQuery: string;
	filter: string;
	selectedVehiclesNumbers: number[];
	selectedVehicleFromGraph: boolean;
}

interface sidebarAction {
	type: string;
	payload?: object;
}

export default function sidebar(
	initialState: sidebarState = {
		selectedVehicles: [],
		unSelectedVehicle: [],
		selectedVehicle: null,
		selectedGroups: [],
		selectedOrganisations: [],
		selectedFew: false,
		selectedAll: false,
		searchQuery: '',
		filter: 'all',
		selectedVehiclesNumbers: [],
		selectedVehicleFromGraph: false,
	},
	action: sidebarAction
) {
	switch (action.type) {
		case 'SET_VEHICLE_SELECTED_FROM_GRAPH':
			return {
				...initialState,
				selectedVehicleFromGraph: true,
			};

		case 'CLEAR_VEHICLE_SELECTED_FROM_GRAPH':
			return {
				...initialState,
				selectedVehicleFromGraph: false,
			};

		case 'SET_SELECTED_VEHICLES_NUMBERS':
			return {
				...initialState,
				selectedVehiclesNumbers: action.payload,
			};

		case 'SET_SELECTED_VEHICLES':
			return {
				...initialState,
				selectedVehicles: action.payload,
			};

		case 'SET_UNSELECTED_VEHICLES':
			return {
				...initialState,
				unSelectedVehicle: action.payload,
			};

		case 'SET_SELECTED_VEHICLE':
			return {
				...initialState,
				selectedVehicle: action.payload,
			};

		case 'SET_SELECTED_GROUPS':
			return {
				...initialState,
				selectedGroups: action.payload,
			};

		case 'SET_SELECTED_ORGANISATIONS':
			return {
				...initialState,
				selectedOrganisations: action.payload,
			};

		case 'CLEAR_SELECTED_VEHICLES':
			return {
				...initialState,
				selectedVehicles: [],
			};

		case 'SET_SELECTED_FEW':
			return {
				...initialState,
				selectedFew: true,
			};

		case 'CLEAR_SELECTED_FEW':
			return {
				...initialState,
				selectedFew: false,
			};

		case 'SET_SELECTED_ALL':
			return {
				...initialState,
				selectedAll: true,
			};

		case 'CLEAR_SELECTED_ALL':
			return {
				...initialState,
				selectedAll: false,
			};

		case 'SET_SEARCH_QUERY':
			return {
				...initialState,
				searchQuery: action.payload,
			};

		case 'CLEAR_SEARCH_QUERY':
			return {
				...initialState,
				searchQuery: '',
			};

		case 'SET_FILTER':
			return {
				...initialState,
				filter: action.payload,
			};

		case 'SIDEBAR_UNMOUNT':
			return {
				...initialState,
				selectedVehicles: [],
				selectedVehicle: null,
				selectedGroups: [],
				selectedOrganisations: [],
				selectedFew: false,
				selectedAll: false,
				searchQuery: '',
				filter: 'all',
			};

		default:
			return initialState;
	}
}
