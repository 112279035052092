import React, { useState } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Divider } from 'semantic-ui-react';
import fileDownload from 'js-file-download';

import { BackButtonContainer, Container, FileHeader, Header, MainContainer, PdfContainer } from './PdfViewStyles';
import Download from '../../../assets/images/download.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PdfProps {
	filename: string;
	source: string;
	onClose: () => void;
}

export default function PdfView(props: PdfProps) {
	const [numPages, setNumPages] = useState<number>(0);

	function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
		setNumPages(numPages);
	}

	return (
		<MainContainer>
			<Container>
				<Header>
					<FileHeader>{props.filename?.split('.')[0]}</FileHeader>
					<BackButtonContainer>
						<img
							title={'Download File'}
							src={Download}
							onClick={() => fileDownload(props.source, props.filename)}
							alt={'icon'}
							style={{ cursor: 'pointer' }}
						/>
						<Button circular color="black" icon="close" onClick={props.onClose} />
					</BackButtonContainer>
				</Header>
				<Divider />

				<PdfContainer>
					<Document file={props.source} onLoadSuccess={onDocumentLoadSuccess}>
						{Array.from(new Array(numPages), (el, index) => (
							<Page key={`page_${index + 1}`} pageNumber={index + 1} />
						))}
					</Document>
				</PdfContainer>
			</Container>
		</MainContainer>
	);
}
