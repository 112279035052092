import React, { useState } from 'react';
import { Icon, Input } from 'semantic-ui-react';

import { Container } from './SearchBoxStyles';

interface PropType {
	placeholder: string;
	title: string;
	onClearIconClick: () => void;
	onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const SearchBox: React.FC<PropType> = (props) => {
	const { placeholder, title, onClearIconClick, onChange } = props;
	const [searchQuery, setSearchQuery] = useState<string>('');

	const handleClearClick = () => {
		setSearchQuery(''); // Clear the search query
		onClearIconClick(); // Invoke the clear icon click callback
	};

	return (
		<Container>
			<Input
				value={searchQuery} // Bind the input value to searchQuery
				placeholder={placeholder}
				title={title}
				onChange={(e) => {
					setSearchQuery(e.target.value); // Update the searchQuery state
					onChange(e); // Invoke the provided onChange callback
				}}
				icon={
					searchQuery && ( // Display the clear icon only when searchQuery is not empty
						<Icon name="times circle outline" link onClick={handleClearClick} />
					)
				}
				style={{ width: '100%' }}
			/>
		</Container>
	);
};

export default SearchBox;
