import { Geofence } from '../types/geofence';

export interface GeoFenceDTOData {
	id: number;
	name: string;
	latitude: number;
	longitude: number;
	radius: number;
	address: string;
	org: {
		id: number;
		name: string;
	};
}

export function createGeoFenceDTO(data: Geofence[]): GeoFenceDTOData[] {
	const geoFences: GeoFenceDTOData[] = [];

	if (data !== undefined && data.length !== 0) {
		data.forEach((geoFence) => {
			geoFences.push({
				id: geoFence.id,
				name: geoFence.name,
				latitude: geoFence.latitude,
				longitude: geoFence.longitude,
				radius: geoFence.radius,
				address: geoFence.googleAddress,
				org: {
					id: geoFence?.organisation?.id,
					name: geoFence?.organisation?.name,
				},
			});
		});
	}

	return geoFences;
}

export function createSingleGeoFenceDTO(geofence: Geofence) {
	return createGeoFenceDTO([geofence])[0];
}
