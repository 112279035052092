import React, { useEffect, useRef, useState } from 'react';
import TrackNerdLoader from '../../../../components/loader/Loader';
import { GraphCard, GraphContainer, GraphHeader } from '../OverviewStyles';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';
import { THEME } from '../../../../constants/Theme';
import { dispatch, dispatchNoPayload } from '../../../../helper/view specific/store';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/RootState';
import { mostRunVehicle } from '../Overview';

interface DistanceProps {
	data: mostRunVehicle[];
}

interface GraphProps {
	Date: string;
	Distance: string;
	'Daily Top Performing Vehicles': number;
	Vehicle: string;
}

const Distance = (props: DistanceProps) => {
	const [graphData, setGraphData] = useState<GraphProps[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const navLinkRef = useRef<HTMLAnchorElement | null>(null);

	// const GeofencePermission = Store.getState().rolePermissions.permissions.Geofence;

	const GeofencePermission = useSelector((state: RootState) => state.rolePermissions.permissions.Geofence);

	useEffect(() => {
		formatData(props.data);
	}, []);

	const formatData = (reports: mostRunVehicle[]) => {
		const tempData: GraphProps[] = [];
		const formattedData: Record<string, Partial<mostRunVehicle>> = {};
		reports.forEach((data) => {
			if (!Object.hasOwn(formattedData, data.date)) {
				formattedData[data.date] = {
					['date']: data.date ? moment(data.date).format('DD-MM-YYYY') : '',
					totalFleetDistance: data.totalFleetDistance ? (parseInt(data.totalFleetDistance) / 1000).toFixed(0) : '',
					topVehicleDistance: data.topVehicleDistance ? parseInt((data.topVehicleDistance / 1000).toFixed(0)) : 0,
					registrationNumber: data.registrationNumber || '',
				};
			}
		});

		Object.keys(formattedData)
			.sort((start: string, end: string): number => {
				const startTime = moment(start, 'YYYY-MM-DD');
				const endTime = moment(end, 'YYYY-MM-DD');
				return startTime.diff(endTime);
			})
			.forEach((data) => {
				if (formattedData[data]) {
					tempData.push({
						Date: formattedData[data].date ?? '',
						Distance: formattedData[data].totalFleetDistance ?? '',
						'Daily Top Performing Vehicles': formattedData[data].topVehicleDistance ?? 0,
						Vehicle: formattedData[data].registrationNumber ?? '',
					});
				}
			});
		setGraphData([...tempData]);
		setLoading(false);
	};

	const barData = graphData.map((item) => ({
		Date: item.Date,
		Distance: parseFloat(item.Distance),
		'Daily Top Performing Vehicles': item['Daily Top Performing Vehicles'],
		Vehicle: item.Vehicle,
	}));

	return (
		<GraphContainer>
			{loading ? (
				<TrackNerdLoader />
			) : barData.length ? (
				<GraphCard>
					<GraphHeader>
						<h1>Daily MVPs</h1>
					</GraphHeader>
					<ResponsiveBar
						data={barData}
						// width={630}
						// height={400}
						// groupMode="grouped"
						enableGridY={false}
						enableGridX={false}
						keys={['Daily Top Performing Vehicles']}
						indexBy="Date"
						margin={{ top: 30, right: 50, bottom: 80, left: 50 }}
						padding={0.2}
						valueScale={{ type: 'symlog' }}
						indexScale={{ type: 'band', round: true }}
						borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
						enableLabel={true}
						axisTop={null}
						axisRight={null}
						// rotateLabel={90}
						// tooltipFormat={(value) => Number(value).toLocaleString('en-IN', { minimumFractionDigits: 0 }) + ' km'}
						axisBottom={{
							// enable: false,
							tickRotation: -45,
							format: (date) => `${moment(date, 'DD-MM-YYYY').format('D MMM')}`,
							tickPadding: 5,
							legendOffset: 40,
						}}
						tooltip={(e) => {
							const offsetX = -20;
							const offsetY = 5;
							const x = window.screenX + offsetX;
							const y = window.screenY + offsetY;
							return (
								<div
									style={{
										padding: '12px',
										color: 'black',
										background: 'white',
										display: 'flex',
										flexDirection: 'column',
										boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
										position: 'absolute',
										right: x + 'px',
										bottom: y + 'px',
										width: '200px',
									}}>
									<div
										style={{
											justifyContent: 'left',
											display: 'flex',
										}}>
										<strong>{'Top Performance:'}</strong>
									</div>
									<div style={{ justifyContent: 'left' }}>Vehicle: {e.data.Vehicle}</div>
									<div style={{ justifyContent: 'left' }}>Distance: {`${Number(e.value).toLocaleString()}`} kms</div>
									{/*<div style={{ justifyContent: "left" }}>{`All vehicles:`}</div>*/}
									{/*<div style={{ paddingTop: '0.5em', justifyContent: 'left' }}>*/}
									{/*	<strong>Fleet Distance: {`${Number(e.data['Distance']).toLocaleString()}`} kms</strong>*/}
									{/*</div>*/}
								</div>
							);
						}}
						axisLeft={null}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor="black"
						/*label={({ value }: { value: number | null }) => {
                            if (value) return <tspan y={-10}>{`${value.toLocaleString()} km`}</tspan>;
                            else return <tspan y={-10}>{'0 km'}</tspan>;
                        }}*/
						label={({ value }: { value: number | null }) => {
							if (value) return `${value.toLocaleString()} km`;
							else return '0 km';
						}}
						onClick={(node) => {
							if (GeofencePermission.manage || GeofencePermission.read) {
								dispatch('SET_SEARCH_QUERY', node.data['Vehicle']);
								dispatchNoPayload('SET_VEHICLE_SELECTED_FROM_GRAPH');
								dispatch('SELECTED_TAB', 'Track');
								dispatch('SET_DATES', {
									initialDate: moment(node.data.Date, 'DD-MM-YYYY').startOf('day').toISOString(),
									finalDate: moment(node.data.Date, 'DD-MM-YYYY').endOf('day').toISOString(),
								});

								if (navLinkRef?.current) navLinkRef?.current.click();
							}
						}}
						colors={[THEME.COLORS.vehicleNoDataOneDay]}
						legends={[
							{
								dataFrom: 'keys',
								anchor: 'bottom',
								direction: 'row',
								justify: false,
								translateX: 0,
								translateY: 70,
								itemsSpacing: 2,
								itemWidth: 100,
								itemHeight: 20,
								itemDirection: 'left-to-right',
								itemOpacity: 0.85,
								symbolSize: 15,
								symbolShape: 'circle',
								effects: [
									{
										on: 'hover',
										style: {
											itemOpacity: 1,
										},
									},
								],
							},
						]}
						animate={true}
						/*motionStiffness={90}
                        motionDamping={15}*/
					/>
				</GraphCard>
			) : (
				<GraphCard>
					<GraphHeader>
						<h1>Daily MVPs</h1>
					</GraphHeader>
					<div
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							flex: 1,
						}}>
						<p>No Data!</p>
					</div>
				</GraphCard>
			)}
			<NavLink
				ref={navLinkRef}
				to={{
					pathname: '/vehicles',
				}}
			/>
		</GraphContainer>
	);
};

export default Distance;
