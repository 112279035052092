import { GeoFenceDTOData } from '../../dtos/geofence';

export interface geofenceState {
	geoFences: GeoFenceDTOData[] | null;
	activeGeoFence: GeoFenceDTOData | null;
	geoFencesBackup: GeoFenceDTOData[] | null;
}

interface geoFanceAction {
	type: string;
	payload?: object;
}

export default function geofence(
	initialState: geofenceState = {
		geoFences: null,
		activeGeoFence: null,
		geoFencesBackup: null,
	},
	action: geoFanceAction
) {
	switch (action.type) {
		case 'FEED_GEO_FENCES':
			return {
				...initialState,
				geoFences: action.payload,
				geoFencesBackup: action.payload,
			};

		case 'FEED_SEARCHED_GEO_FENCES':
			return {
				...initialState,
				geoFences: action.payload,
			};

		case 'APPEND_GEO_FENCE':
			return {
				...initialState,
				geoFences: initialState.geoFences ? [...initialState.geoFences, action.payload] : [action.payload],
				geoFencesBackup: initialState.geoFences ? [...initialState.geoFences, action.payload] : [action.payload],
			};

		case 'SET_ACTIVE_GEO_FENCE':
			return {
				...initialState,
				activeGeoFence: action.payload,
			};

		case 'RESET_ACTIVE_GEO_FENCE':
			return {
				...initialState,
				activeGeoFence: null,
			};

		case 'UPDATE_GEO_FENCE':
			return {
				...initialState,
				geoFences:
					initialState.geoFences &&
					initialState.geoFences.map((geoFence) => {
						if (initialState.activeGeoFence && geoFence.id !== initialState.activeGeoFence.id) {
							return geoFence;
						} else {
							return action.payload;
						}
					}),
			};

		default:
			return initialState;
	}
}
