import React, { useEffect } from 'react';
import Main from './main/Main';
// import TrackNerdLoader from '../../common/loader/Loader';
import { loadGeoFences, resetActiveGeoFence } from './GeoFencesMiddleware';
import { Container } from './GeoFencesStyles';
import { useSelector } from 'react-redux';
import Sidebar from './sidebar/Sidebar';
import { RootState } from '../../../redux/RootState';

const GeoFences = () => {
	// const sidebarData = useSelector((state) => state.views.sidebarVisible);

	const { geoFencesBackup } = useSelector((state: RootState) => state.geofence);
	useEffect(() => {
		loadGeoFences();

		return () => resetActiveGeoFence();
	}, []);

	return (
		<Container>
			{/*{loading ? (
				<TrackNerdLoader />
			) : (*/}
			<>
				{geoFencesBackup?.length !== 0 && <Sidebar />}
				<Main />
			</>
			{/*)}*/}
		</Container>
	);
};

// Connect to Store

export default GeoFences;
